import React, { PureComponent } from "react";
import { InputRadioGroup as RadioGroup } from "../../../shared/forms";

class InputRadioGroup extends PureComponent {
  render() {
    const {
      Label,
      SalesforceField,
      Required,
      Title,
      Options,
      ...rest
    } = this.props;

    const options = Options.map((option) => {
      return {
        value: option.Value,
        label: option.Label,
      };
    });

    return (
      <RadioGroup
        label={Label}
        name={SalesforceField}
        required={Required}
        title={Title}
        options={options}
        {...rest}
      />
    );
  }
}

export default InputRadioGroup;
