import React from "react";

import Block from "../Block";
import { Iframe, Col } from "../../../shared/atoms";

const Podcast = (props) => {
  const { content } = props;
  const { ID, Title } = content;

  const src = `https://player.blubrry.com?podcast_id=${ID}`;
  return (
    <Block content={content} className="PodcastBlock">
      <Col xs={{ span: 12 }}>
        <Iframe
          className="Podcast"
          title={Title}
          height="178px"
          sourceUrl={src}
        />
      </Col>
    </Block>
  );
};

export default Podcast;
