import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { BodyText, DisplayText, Heading, Col } from "../../atoms";
import ButtonLink from "../ButtonLink";
import { composeClassName, StringUtils } from "../../utilities";

import "./ContentAligned.scss";

class ContentAligned extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    tag: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string.isRequired,
    bodySize: PropTypes.string,
    button1Theme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
    button1Text: PropTypes.string,
    button2Theme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
    button2Text: PropTypes.string,
    href1: PropTypes.string,
    internalLinkComponent1: PropTypes.any,
    link1Title: PropTypes.string,
    linkExternal1: PropTypes.bool,
    href2: PropTypes.string,
    internalLinkComponent2: PropTypes.any,
    link2Title: PropTypes.string,
    linkExternal2: PropTypes.bool,
    textCols: PropTypes.number,
  };

  static defaultProps = {
    tag: "",
    title: "",
    button1Theme: "Tertiary",
    button1Text: null,
    button2Theme: "Tertiary",
    button2Text: null,
    link1Title: "",
    linkExternal1: false,
    link2Title: "",
    linkExternal2: false,
    textCols: 6,
  };

  render() {
    const {
      className,
      tag,
      title,
      body,
      bodySize,
      button1Theme,
      button1Text,
      button2Theme,
      button2Text,
      href1,
      internalLinkComponent1,
      link1Title,
      linkExternal1,
      href2,
      internalLinkComponent2,
      link2Title,
      linkExternal2,
      textCols,
    } = this.props;

    const link1Props = {
      href: href1,
      internalLinkComponent: internalLinkComponent1,
      title: link1Title,
      external: linkExternal1,
    };

    const link2Props = {
      href: href2,
      internalLinkComponent: internalLinkComponent2,
      title: link2Title,
      external: linkExternal2,
    };

    const composedClassName = composeClassName(["Content", className]);

    let button1 = null;
    let button2 = null;

    if (button1Text) {
      button1 = (
        <ButtonLink
          className="ContentButton"
          theme={button1Theme}
          {...link1Props}>
          {button1Text}
        </ButtonLink>
      );
    }
    if (button1Text && button2Text) {
      button1 = (
        <ButtonLink
          className="ContentButton Two"
          theme={button1Theme}
          {...link1Props}>
          {button1Text}
        </ButtonLink>
      );
      button2 = (
        <ButtonLink
          className="ContentButton Two"
          theme={button2Theme}
          {...link2Props}>
          {button2Text}
        </ButtonLink>
      );
    }

    const tagElement = tag ? (
      <DisplayText size="M" className="ContentSubtitle">
        {StringUtils.formatMax(tag, 30)}
      </DisplayText>
    ) : null;
    const titleElement = title ? (
      <Heading size="M" className="ContentTitle">
        {title}
      </Heading>
    ) : null;

    const bodyElement = body ? (
      <BodyText size={bodySize} className="ContentBody">
        {body}
      </BodyText>
    ) : null;

    const buttonElements =
      button1 || button2 ? (
        <div className="Buttons">
          {button1}
          {button2}
        </div>
      ) : null;

    return (
      <Col
        className={composedClassName}
        xs={{ span: 12 }}
        md={{ span: textCols }}>
        {tagElement}
        {titleElement}
        {bodyElement}
        {buttonElements}
      </Col>
    );
  }
}

export default ContentAligned;
