/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { ModalWrapper, VideoPlayer } from "../../atoms";
import ButtonVideo from "../ButtonVideo";
import ButtonCloseModal from "../ButtonCloseModal";

import "./ModalVideo.scss";

class ModalVideo extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    allowFullScreen: PropTypes.bool,
    className: PropTypes.string,
    noDisplayText: PropTypes.bool,
    source: PropTypes.oneOf(["vimeo", "youtube"]),
  };

  constructor(props) {
    super(props);
    this.state = { open: false };
    this.closeButton = React.createRef();
    this.openButton = React.createRef();
  }

  _handleOpenModal = () => {
    this.setState({ open: true }, () => {
      this.closeButton.current.focus();
    });
  };

  _handleCloseModal = (event) => {
    if (
      event.type === "click" ||
      event.key === "Escape" ||
      event.key === "Esc"
    ) {
      this.setState({ open: false }, () => {
        this.openButton.current.focus();
      });
    }
  };

  render() {
    const {
      allowFullScreen,
      className,
      source,
      title,
      videoId,
      theme,
      noDisplayText,
      t,
    } = this.props;
    const { open } = this.state;

    return (
      <div className={className}>
        <ButtonVideo
          ariaLabel={t("ModalVideo__ButtonVideo__ariaLabel")}
          buttonRef={this.openButton}
          className="ModalVideoOpen"
          dataVideoId={videoId}
          noDisplayText={noDisplayText}
          onClickHandler={this._handleOpenModal}
          theme={theme}
        />
        <ModalWrapper
          open={open}
          closeHandler={(event) => {
            this._handleCloseModal(event);
          }}
          ariaLabel={t("ModalVideo__ModalWrapper__ariaLabel")}
          ariaDescribedBy={t("ModalVideo.ModalWrapper__ariaDescribedBy")}>
          <VideoPlayer
            allowFullScreen={allowFullScreen}
            source={source}
            title={title}
            videoId={videoId}
            autoplay
          />
          <ButtonCloseModal
            ariaLabel={t("ModalVideo.ButtonCloseModal__ariaLabel")}
            buttonRef={this.closeButton}
            className="ModalVideoClose"
            onClickHandler={(event) => {
              this._handleCloseModal(event);
            }}
            onKeyDownHandler={(event) => {
              this._handleCloseModal(event);
            }}
            tabIndex="1"
          />
        </ModalWrapper>
      </div>
    );
  }
}

export default withTranslation("translations")(ModalVideo);
