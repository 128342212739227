/* eslint-disable camelcase */
import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { extractStoryblokUrl } from "../../../shared/utilities";
import { StoryblokContext } from "../../../context";

import {
  filterWeStories,
  getWeStoriesMetadata,
  getPinnedStories,
} from "./utilities";

const withWeStories = (Component) => {
  return (props) => {
    return (
      <StoryblokContext.Consumer>
        {({ locale, currentHref, storyblokLinks }) => {
          return (
            <StaticQuery
              query={graphql`
                query {
                  allStoryblokEntry(
                    filter: { full_slug: { regex: "//we-stories//" } }
                  ) {
                    nodes {
                      uuid
                      id
                      name
                      created_at
                      uuid
                      content
                      slug
                      full_slug
                      is_startpage
                      parent_id
                      group_id
                    }
                  }
                }
              `}
              render={(data) => {
                // data contains all stories in all sites + locales
                const stories = data.allStoryblokEntry.nodes;
                const { content } = props;
                const { PinnedStories = [], Category } = content;

                const pinnedStories = getPinnedStories(
                  stories,
                  PinnedStories,
                  storyblokLinks,
                );
                const allStories = [...pinnedStories, ...stories];

                const filteredStories = filterWeStories({
                  stories: allStories,
                  locale: locale,
                  category: Category,
                  currentSlug: currentHref,
                });

                const formatted = filteredStories.map((story) => {
                  const link = extractStoryblokUrl(
                    { url: story.full_slug },
                    locale,
                    storyblokLinks,
                  );
                  return { ...getWeStoriesMetadata(story), link };
                });

                return <Component {...props} pages={formatted} />;
              }}
            />
          );
        }}
      </StoryblokContext.Consumer>
    );
  };
};

export default withWeStories;
