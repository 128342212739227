import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Pillars from "./IconPillars";
import { composeClassName } from "../../utilities";

class IconPillar extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** The selection for the displayed icon */
    icon: PropTypes.oneOf([
      "pillarLeaf",
      "pillarWater",
      "pillarKey",
      "pillarHeart",
      "pillarStar",
    ]).isRequired,
    title: PropTypes.string,
    /** The role of the icon, defaults to the role of an image */
    role: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    title: "",
    role: "img",
  };

  render() {
    const { icon, className, role, title } = this.props;
    const composedClassName = composeClassName([
      "IconPillar",
      title,
      role,
      className,
    ]);
    const iconLabel = `${icon}-label`;

    const pillar = Pillars[icon];
    const { path, transform1, transform2, viewBox } = pillar;

    return (
      <span className={composedClassName}>
        <svg
          width="48px"
          height="48px"
          viewBox={viewBox}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-labelledby={iconLabel}
          role={role}>
          <title id={iconLabel}>{title || `${icon} icon`}</title>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform={transform1}>
              <g transform={transform2}>
                <rect
                  id={`${icon}-icon`}
                  fill="#D8D8D8"
                  opacity="0"
                  x="0"
                  y="0"
                  width="48"
                  height="48"
                />
                {path}
              </g>
            </g>
          </g>
        </svg>
      </span>
    );
  }
}

export default IconPillar;
