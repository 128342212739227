const LOCALSTORAGE_TTL = "ttl";
const LOCALSTORAGE_LOCALE = "locale";
const LOCALSTORAGE_COUNTRYCODE = "countryCode";
const LOCALSTORAGE_ACCEPTCOOKIES = "AcceptCookies";

// eslint-disable-next-line import/prefer-default-export
export {
  LOCALSTORAGE_LOCALE,
  LOCALSTORAGE_TTL,
  LOCALSTORAGE_COUNTRYCODE,
  LOCALSTORAGE_ACCEPTCOOKIES,
};
