/* eslint-disable camelcase */
import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { Feed as SharedFeed } from "../../../shared/organisms";
import { withWeStories } from "../../utilities";

import Block from "../Block";

const Feed = (props) => {
  const { content, pages } = props;
  // TODO: translate themes to settings
  const themes = {
    default: SharedFeed,
  };

  const feedElement = React.createElement(themes.default, {
    pages: pages,
    internalLinkComponent: GatsbyLink,
  });

  return (
    <Block content={content} fluid className="FeedBlock">
      {feedElement}
    </Block>
  );
};

export default withWeStories(Feed);
