import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Col, BodyText, Heading, Button } from "../../atoms";
import { TextWithBackground } from "../../molecules";
import { Form, InputHidden, InputText, InputEmail } from "../../forms";
import { generateFormName, browser } from "../../utilities";

import "./LeadGeneratorSubscription.scss";

class LeadGeneratorSubscription extends PureComponent {
  static propTypes = {
    backgroundColor: PropTypes.string,
    whiteText: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    bodyText: PropTypes.string,
    campaignID: PropTypes.string,
    buttonTheme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
  };

  static defaultProps = {
    whiteText: false,
    buttonTheme: "Primary",
  };

  render() {
    const {
      backgroundColor,
      whiteText,
      title,
      subtitle,
      bodyText,
      campaignID,
      buttonTheme,
      currentHref,
      t,
    } = this.props;

    let bodyTextElement = null;
    let subtitleElement = null;

    if (bodyText) {
      bodyTextElement = (
        <BodyText
          className="BottomText"
          size="M"
          centered
          whiteText={whiteText}>
          {bodyText}
        </BodyText>
      );
    }

    if (subtitle) {
      subtitleElement = (
        <BodyText
          className="SmallTitle"
          size="S"
          centered
          whiteText={whiteText}>
          {subtitle}
        </BodyText>
      );
    }
    const formName = `subscription-${campaignID}`;
    const generatedFormName = generateFormName(formName, currentHref);
    const renderFields = [
      <InputHidden key="opt_in_ftc" name="opt_in_ftc" initialValue />,
      <InputHidden key="opt_in_mtw" name="opt_in_mtw" initialValue />,
      <InputText
        formName={formName}
        key="first_name"
        name="first_name"
        required
        placeholder={t("LeadGeneratorSubscription__InputTextFirstName__label")}
        label={t("LeadGeneratorSubscription__InputTextFirstName__label")}
        title={t("LeadGeneratorSubscription__InputTextFirstName__label")}
        whiteText={whiteText}
        className="SubscribeInputName"
      />,
      <InputText
        formName={formName}
        key="last_name"
        name="last_name"
        required
        placeholder={t("LeadGeneratorSubscription__InputTextLastName__label")}
        label={t("LeadGeneratorSubscription__InputTextLastName__label")}
        title={t("LeadGeneratorSubscription__InputTextLastName__label")}
        whiteText={whiteText}
        className="SubscribeInputName"
      />,
      <InputEmail
        formName={formName}
        key="email"
        name="email"
        placeholder={t("LeadGeneratorSubscription__InputEmail__label")}
        label={t("LeadGeneratorSubscription__InputEmail__label")}
        title={t("LeadGeneratorSubscription__InputEmail__label")}
        whiteText={whiteText}
        className="SubscribeInputEmail"
      />,
    ];

    return (
      <Col xs={{ span: 12 }} className="LeadGenSub">
        <TextWithBackground color={backgroundColor}>
          {subtitleElement}
          <Heading size="M" centered whiteText={whiteText}>
            {title}
          </Heading>
          {bodyTextElement}
          <div className="FormContainer">
            <Form
              formEncode
              dataAttr={{
                "data-netlify": "true",
              }}
              formName={generatedFormName}
              submitUrl={browser.window.location.pathname}>
              <InputHidden name="campaign_id" initialValue={campaignID} />
              {renderFields}
              <Button
                type="submit"
                theme={buttonTheme}
                size="L"
                className="SubscribeButton">
                {t("LeadGeneratorSubscription__ButtonSubscribe__label")}
              </Button>
            </Form>
          </div>
        </TextWithBackground>
      </Col>
    );
  }
}

export default withTranslation("translations")(LeadGeneratorSubscription);
