/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import { composeClassName } from "../../../utilities";
import { BodyText, Icon } from "../../../atoms";

import asFormElement from "../../asFormElement";
import { FormContext } from "../../Form";

import "./InputCheckbox.scss";

class InputCheckbox extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func,
    autoFocus: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    initialValue: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.string,
    /** The visual appearance of the input */
    styleType: PropTypes.oneOf(["checkbox", "toggle"]),
    whiteText: PropTypes.bool,
    error: PropTypes.bool,
    emptyValue: PropTypes.bool,
    fieldType: PropTypes.oneOf(["boolean"]),
  };

  static defaultProps = {
    initialValue: false,
    autoFocus: false,
    disabled: false,
    styleType: "checkbox",
    whiteText: false,
    emptyValue: false,
    fieldType: "boolean",
    tabIndex: "0",
  };

  _onChange = (event) => {
    event.preventDefault();
    const { value, disabled } = this.props;
    if (disabled) return;
    const { name, onChangeHandler } = this.props;

    if (event.type === "keypress") {
      if (event.charCode === 13 || event.charCode === 32) {
        onChangeHandler({ name: name, value: !value }); // eslint-disable-line
      }
    } else if (event.type === "click") {
      onChangeHandler({ name: name, value: !value }); // eslint-disable-line
    }
  };

  render() {
    const {
      autoFocus,
      value,
      className,
      disabled,
      label,
      name,
      styleType,
      tabIndex,
      whiteText,
      error,
      message,
    } = this.props;

    const composedClassName = composeClassName(["InputCheckbox", className]);

    let checkedIcon;
    if (value) {
      checkedIcon = (
        <Icon
          icon="checkmark"
          size="S"
          defaultColor={`${disabled ? "DefaultGrey" : "DefaultWhite"}`}
          className="CheckboxIcon"
        />
      );
    }

    let checkboxRole = "checkbox";
    let checkboxClassName = "Checkbox";
    let checkboxBody = checkedIcon;
    if (styleType === "toggle") {
      checkboxRole = "switch";
      checkboxClassName = "ToggleButton";
      checkboxBody = <div className="ToggleSwitch" />;
    }

    const spanClassName = composeClassName([
      checkboxClassName,
      value ? "Checked" : "",
      disabled ? "Disabled" : "",
      error ? "error" : "",
    ]);
    const labelClassName = composeClassName([
      "CheckboxLabel",
      disabled ? "Disabled" : "",
      whiteText ? "whiteText" : "",
    ]);

    let iconComponent = null;
    if (error) {
      iconComponent = (
        <Icon
          size="S"
          icon="close"
          className={composeClassName([error ? "error" : "", "ErrorIcon"])}
        />
      );
    }

    return (
      <div className="InputCheckboxContainer">
        <div className={composedClassName}>
          <input
            type="hidden"
            name={name}
            value={value}
            disabled={disabled}
            id={`${name}-value`}
          />
          <span
            aria-checked={value}
            aria-labelledby={`${name}-label`}
            className={spanClassName}
            role={checkboxRole}
            onClick={this._onChange}
            onKeyPress={this._onChange}
            tabIndex={tabIndex}
            {...(autoFocus ? { autoFocus } : {})}>
            {checkboxBody}
          </span>
          <label
            className={labelClassName}
            htmlFor={`${name}-value`}
            onClick={this._onChange}
            onKeyPress={this._onChange}>
            <BodyText size="S">{label}</BodyText>
          </label>
          {iconComponent}
        </div>
        <BodyText size="S" className="ErrorMessage">
          <Trans i18nKey={message}>{null}</Trans>
        </BodyText>
      </div>
    );
  }
}

export default asFormElement(InputCheckbox, FormContext);
