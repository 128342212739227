import WeorgPage from "./WeorgPage/WeorgPage"
import TripsPage from "./TripsPage/TripsPage"
import TyiPage from "./TyiPage/TyiPage"
import Category from "./Category/Category"

export {
  WeorgPage,
  TripsPage,
  TyiPage,
  Category,
};
