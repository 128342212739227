import React, { PureComponent } from "react";
import { Col, Iframe } from "../../../shared/atoms";
import Block from "../Block";

class WeSchoolsLibrary extends PureComponent {
  render() {
    const { content } = this.props;
    const { Title, Height, Fullwidth } = content;
    const heightPixels = parseInt(Height, 10);
    const SourceUrl =
      "https://stg.we.org/we-schools/program/educator-resources/library/";
    return (
      <Block
        content={content}
        fluid={Fullwidth}
        className="WeSchoolsLibraryBlock">
        <Col xs={{ span: 12 }}>
          <Iframe
            title={Title}
            sourceUrl={SourceUrl}
            height={`${heightPixels}px`}
          />
        </Col>
      </Block>
    );
  }
}

export default WeSchoolsLibrary;
