import React, { PureComponent } from "react";
import { DownloadableResources as SharedDownloadableResources } from "../../../shared/molecules";
import Block from "../Block";

class DownloadableResources extends PureComponent {
  render() {
    const { content } = this.props;
    const { Title, Label, Source, AltText, Options, Description } = content;

    const formattedOptions = Options.map((option) => {
      return {
        value: option.Link,
        label: option.Label,
      };
    });

    return (
      <Block content={content} className="DownloadableResourcesBlock">
        <SharedDownloadableResources
          title={Title}
          label={Label}
          options={formattedOptions}
          source={Source}
          description={Description}
          altText={AltText}
        />
      </Block>
    );
  }
}

export default DownloadableResources;
