import React, { PureComponent } from "react";
import { InputCheckbox as Checkbox } from "../../../shared/forms";

class InputCheckbox extends PureComponent {
  render() {
    const {
      Label,
      SalesforceField,
      Required,
      Title,
      Toggle,
      ...rest
    } = this.props;

    return (
      <Checkbox
        label={Label}
        styleType={Toggle}
        name={SalesforceField}
        required={Required}
        title={Title}
        {...rest}
      />
    );
  }
}

export default InputCheckbox;
