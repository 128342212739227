import React from "react";
import PropTypes from "prop-types";
import Stories from "../Stories/Stories";
import BaseTile from "../BaseTile";
import "./StoriesTile.scss";
import { composeClassName } from "../../utilities";

const StoriesTile = (props) => {
  const {
    className,
    internalLinkComponent,
    link,
    linkTitle,
    image,
    altText,
    longDesc,
    tag,
    articleTitle,
    whiteText,
  } = props;

  const composedClassName = composeClassName([className, "StoriesTile"]);

  return (
    <BaseTile
      md={{ span: 3 }}
      internalLinkComponent={internalLinkComponent}
      Link={link}
      LinkTitle={linkTitle}
      className={composedClassName}>
      <Stories
        image={image}
        altText={altText}
        longdesc={longDesc}
        tag={tag}
        articleTitle={articleTitle}
        whiteText={whiteText}
      />
    </BaseTile>
  );
};

StoriesTile.propTypes = {
  altText: PropTypes.string.isRequired,
  articleTitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  className: PropTypes.string,
  internalLinkComponent: PropTypes.any,
  link: PropTypes.object,
  linkTitle: PropTypes.string,
  longDesc: PropTypes.string,
  whiteText: PropTypes.bool,
};

export default StoriesTile;
