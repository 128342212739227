/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { BodyText, Col, DisplayText, Heading, Image } from "../../atoms";
import { ListItinerary } from "../../molecules";

import "./TripItinerary.scss";

const imagePropTypes = {
  source: PropTypes.string.isRequired,
  mobileSource: PropTypes.string,
  altText: PropTypes.string.isRequired,
  longdesc: PropTypes.string,
};

class TripItinerary extends PureComponent {
  static propTypes = {
    disclaimer: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
    ).isRequired,
    primaryImage: PropTypes.shape(imagePropTypes).isRequired,
    /* Recommendation is 2 images, these images won't be displayed in mobile view */
    secondaryImages: PropTypes.arrayOf(PropTypes.shape(imagePropTypes)),
    title: PropTypes.string,
  };

  static defaultProps = {
    title: "Itinerary",
  };

  render() {
    const {
      disclaimer,
      duration,
      items,
      price,
      primaryImage,
      secondaryImages,
      title,
      t,
    } = this.props;

    const list = <ListItinerary items={items} />;
    const description = (
      <div>
        <DisplayText size="S">
          {t("TripItinerary__Description__startingAt")}
        </DisplayText>
        <Heading size="S">{`$${price.toLocaleString()}*`}</Heading>
        <div className="TripDescription">
          <BodyText size="M">
            {t("TripItinerary__Description__duration", { duration })}
          </BodyText>
          <BodyText size="M">{disclaimer}</BodyText>
        </div>
      </div>
    );

    const image = (
      <div>
        <Image className="ItineraryPrimaryImage" {...primaryImage} />
      </div>
    );

    const images = secondaryImages.map((secondaryImage, index) => {
      return (
        <div
          className="ItinerarySecondaryImage"
          key={`secondaryImage-${index}`}>
          <Image {...secondaryImage} />
        </div>
      );
    });

    const contentDesktop = (
      <Fragment>
        <Col
          xs={{ span: 12 }}
          md={{ span: 6 }}
          className="TripItinerary Desktop">
          {list}
        </Col>
        <Col
          xs={{ span: 12 }}
          md={{ span: 6 }}
          className="TripItinerary Desktop">
          {description}
          {image}
          <div className="ItinerarySecondaryImagesContainer">{images}</div>
        </Col>
      </Fragment>
    );

    const contentMobile = (
      <Fragment>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          className="TripItinerary Mobile">
          {description}
          {image}
          {list}
        </Col>
      </Fragment>
    );

    return (
      <React.Fragment>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          className="TripItinerary Header">
          <Heading size="L" underlined underlineColor="#00aeef">
            {title}
          </Heading>
        </Col>
        {contentDesktop}
        {contentMobile}
      </React.Fragment>
    );
  }
}

export default withTranslation("translations")(TripItinerary);
