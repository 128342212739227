import React, { PureComponent } from "react";
import { BookingWidget as BookingWidgetOrganism } from "../../../shared/organisms";
import Block from "../../blocks/Block";

class BookingWidgetItem extends PureComponent {
  render() {
    const { content } = this.props;
    const {
      ImageAltText,
      ImageLongDesc,
      Image,
      MobileImage,
      TripDisplay,
      PriceDisplay,
      StatusDisplay,
      AgeDisplay,
      DepartureDisplay,
      CostWithFlightDisplay,
      CostWithoutFlightDisplay,
      Deposit,
      FootnoteDisplay,
      SalesforceTripId,
      TripName,
      TripDestination,
      TripDepartureDate,
      TripReturnDate,
      FullCalculatedTripCost,
      id,
      name,
    } = content;

    return (
      <Block content={content}>
        <BookingWidgetOrganism
          imageAltText={ImageAltText}
          imageLongdesc={ImageLongDesc}
          imageMobileSource={MobileImage}
          imageSource={Image}
          id={id}
          name={name}
          trip={TripDisplay}
          price={PriceDisplay}
          status={StatusDisplay}
          ageGroup={AgeDisplay}
          departure={DepartureDisplay}
          costWithFlight={CostWithFlightDisplay}
          costWithoutFlight={CostWithoutFlightDisplay}
          initialDeposit={Deposit}
          footnote={FootnoteDisplay}
          salesforceTripId={SalesforceTripId}
          tripName={TripName}
          tripDestination={TripDestination}
          tripDepartureDate={TripDepartureDate}
          tripReturnDate={TripReturnDate}
          fullCalculatedTripCost={FullCalculatedTripCost}
        />
      </Block>
    );
  }
}

export default BookingWidgetItem;
