/* eslint-disable operator-linebreak */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../shared/atoms";
import { GatsbyLink } from "../../../sharedgatsby";
import { withStoryblokLink } from "../../utilities";

class ButtonLink extends PureComponent {
  static propTypes = {
    Size: PropTypes.string,
  };

  static defaultProps = {
    Size: "M",
  };

  render() {
    const { Text, Link, Theme, Size } = this.props;
    return (
      <GatsbyLink tabIndex="-1" title={Text} {...Link}>
        <Button size={Size} theme={Theme} className="ButtonLink">
          {Text}
        </Button>
      </GatsbyLink>
    );
  }
}

export default withStoryblokLink(ButtonLink);
