import React from "react";
import PropTypes from "prop-types";
import { FieldSet as SharedFieldSet } from "../../../shared/molecules";
import contentToComponents from "../../utilities/contentToComponents";

const FieldSet = (props) => {
  const { Legend, Fields, whiteText } = props;

  const fields = contentToComponents(Fields, { whiteText });

  return (
    <SharedFieldSet legend={Legend} fields={fields} whiteText={whiteText} />
  );
};

FieldSet.propTypes = {
  Legend: PropTypes.string.isRequired,
  Fields: PropTypes.array,
  whiteText: PropTypes.bool,
};

export default FieldSet;
