/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import { DisplayText, BaseLink } from "../../atoms";

import "./NavigationColumn.scss";

const NavigationColumn = (props) => {
  const { className, title, links, internalLinkComponent } = props;
  const composedClassName = composeClassName(["NavigationColumn", className]);

  const linkElements = links.map((link, i) => {
    const { href, external, title: linkTitle, label } = link;
    return (
      <BaseLink
        key={`${label}-${i}`}
        className="NavigationLink"
        href={href}
        external={external}
        title={linkTitle}
        internalLinkComponent={internalLinkComponent}>
        {label}
      </BaseLink>
    );
  });

  return (
    <div className={composedClassName}>
      <DisplayText className="NavigationColumnLabel" size="S">
        {title.toUpperCase()}
      </DisplayText>
      {linkElements}
    </div>
  );
};

NavigationColumn.propTypes = {
  className: PropTypes.string,
  /** The title at the top of the column */
  title: PropTypes.string.isRequired,
  /** Only text BaseLinks should be passed into this component */
  links: PropTypes.array,
  internalLinkComponent: PropTypes.any,
};

NavigationColumn.defaultProps = {
  className: "",
  links: [],
  internalLinkComponent: null,
};

export default NavigationColumn;
