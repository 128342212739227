/* eslint-disable no-unused-vars */
import React from "react";
import Mosaic from "./Mosaic";
import image from "../../../shared/static/desktop2.png";

const MosaicVisualTest = () => {
  const longText =
    "Volutpat est velit egestas dui id. Dolor sit amet consectetur adipiscing elit pellentesque. Tincidunt id aliquet risus feugiat in ante.";

  const bodyTexts = [
    { name: "With Body Text", value: `Body Text ${longText}` },
    { name: "No Body Text", value: "" },
  ];

  const layouts = [
    { name: "8-4 layout", value: "8-4" },
    { name: "4-8 layout", value: "4-8" },
  ];

  const titles = [
    { name: "Long Title", value: `Long Title ${longText}` },
    { name: "No Title", value: "" },
  ];

  const subtitles = [
    { name: "Long Subtitle", value: `Long Subtitle ${longText}` },
    { name: "No Subtitle", value: "" },
  ];

  const altText = "Alt Text";
  const colors = {
    white: "base-white-color",
    navy: "brand-navy-color",
    green: "brand-green-color",
    red: "brand-red-color",
    yellow: "brand-yellow-color",
    orange: "brand-orange-color",
    purple: "brand-purple-color",
  };

  let key = 0;
  const components = [];
  titles.forEach((title) => {
    subtitles.forEach((subtitle) => {
      bodyTexts.forEach((bodyText) => {
        layouts.forEach((layout) => {
          const content = {
            Tile1AltText: altText,
            Tile1BackgroundColor: colors.purple,
            Tile1Body: bodyText.value,
            Tile1Image: image,
            Tile1LongDesc: "Long Desc",
            Tile1MobileImage: image,
            Tile1Subtitle: subtitle.value,
            Tile1TextColor: colors.white,
            Tile1Title: title.value,
            Tile2BAltText: altText,
            Tile2BBackgroundColor: colors.yellow,
            Tile2BBody: bodyText.value,
            Tile2BImage: image,
            Tile2BLongDesc: "Long Desc",
            Tile2BMobileImage: image,
            Tile2BSubtitle: subtitle.value,
            Tile2BTextColor: colors.navy,
            Tile2BTitle: title.value,
            Tile2CAltText: altText,
            Tile2CBackgroundColor: colors.orange,
            Tile2CBody: bodyText.value,
            Tile2CImage: image,
            Tile2CLongDesc: "Long Desc",
            Tile2CMobileImage: image,
            Tile2CSubtitle: subtitle.value,
            Tile2CTextColor: colors.white,
            Tile2CTitle: title.value,
            Tile2Layout: layout.value,
          };

          if (title.value || subtitle.value) {
            key++;
            const pageName = [
              layout.name,
              title.name,
              subtitle.name,
              bodyText.name,
            ].join(" | ");
            const pageContent = (
              <>
                <h1 key={`${key}-h1`} className="VisualTestTitle">
                  {pageName}
                </h1>
                <Mosaic key={key} content={content} />
              </>
            );
            components.push(pageContent);
          }
        });
      });
    });
  });
  return components;
};

export default MosaicVisualTest;
