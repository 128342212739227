import React, { useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";

import { Button } from "../../atoms";
import { createLiveAgent, composeClassName, browser } from "../../utilities";
import "./ButtonSalesforceChat.scss";

const ButtonSalesforceChat = (props) => {
  const { className, t } = props;
  const salesforceId = "573F00000004FwP";
  const buttonOnlineRef = useRef();
  const buttonOfflineRef = useRef();

  const composedClassName = composeClassName([
    "ButtonSalesforceChat",
    className,
  ]);

  const _startChat = () => {
    browser.window.liveagent.startChat(salesforceId);
  };

  useEffect(() => {
    createLiveAgent();
    const { liveagent } = browser.window;
    if (!liveagent) return;
    if (!browser.window._laq) {
      browser.window._laq = [];
    }
    browser.window._laq.push(() => {
      liveagent.showWhenOnline(salesforceId, buttonOnlineRef.current);
      liveagent.showWhenOffline(salesforceId, buttonOfflineRef.current);
    });

    liveagent.addButtonEventHandler(salesforceId, (event) => {
      if (event === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
        buttonOnlineRef.current.style.display = "";
      } else if (event === liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
        buttonOnlineRef.current.style.display = "none";
        buttonOfflineRef.current.style.display = "";
      }
    });

    liveagent.init(
      "https://d.la2-c2-ph2.salesforceliveagent.com/chat",
      "572F00000004Csd",
      "00DA0000000aNHz",
    );
  });

  return (
    <div className="PageContainer">
      <Button
        hidden
        key="OfflineButton"
        className={`${composedClassName} OfflineButton`}
        buttonRef={buttonOfflineRef}
        size="L"
        theme="Secondary"
        disabled>
        {t("ButtonSalesforceChat__offlineLabel")}
      </Button>
      <Button
        key="OnlineButton"
        className={`${composedClassName} OnlineButton`}
        buttonRef={buttonOnlineRef}
        size="L"
        theme="Secondary"
        onClickHandler={() => {
          _startChat();
        }}>
        {t("ButtonSalesforceChat__onlineLabel")}
      </Button>
    </div>
  );
};

export default withTranslation("translations")(ButtonSalesforceChat);
