/* eslint-disable jsx-a11y/click-events-have-key-events, no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-autofocus */
import React from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../../utilities";
import { BodyText } from "../../../atoms";
import { THEMES } from "./constants";

import "./InputRadioButton.scss";

const InputRadioButton = (props) => {
  const onChange = (event) => {
    event.preventDefault();
    const { value, disabled } = props;
    if (disabled) return;
    const { name, onChangeHandler } = props;

    if (event.type === "keypress") {
      if (event.charCode === 13 || event.charCode === 32) {
        onChangeHandler({ name, value }); // eslint-disable-line
      }
    } else if (event.type === "click") {
      onChangeHandler({ name, value }); // eslint-disable-line
    }
  };

  const {
    className,
    disabled,
    label,
    name,
    tabIndex,
    checked,
    value,
    theme,
    size,
    whiteText,
    error,
  } = props;

  const composedClassName = composeClassName([
    "InputRadioButton",
    whiteText ? "whiteText" : "",
    theme,
    className,
  ]);

  let checkedInside;
  if (checked) {
    checkedInside = <span className="CheckedInside" />;
  }

  let labelElement = null;
  if (theme === THEMES.default) {
    labelElement = (
      <label
        className="RadioButtonLabel"
        id={`${name}-label`}
        htmlFor={`${name}-value`}
        disabled={disabled}
        onClick={(event) => {
          onChange(event);
        }}
        onKeyPress={(event) => {
          onChange(event);
        }}>
        <BodyText>{label}</BodyText>
      </label>
    );
  }

  const radioClassName = composeClassName([
    "RadioButton",
    checked ? "Checked" : "Unchecked",
    disabled ? "Disabled" : "",
    error ? "error" : "",
  ]);

  return (
    <div className={composedClassName}>
      <input
        className="Hidden"
        type="radio"
        name={name}
        value={value}
        checked={checked}
        readOnly
        id={`${name}-value`}
      />
      <span
        aria-checked={checked}
        aria-labelledby={`${name}-label`}
        className={radioClassName}
        onClick={(event) => {
          onChange(event);
        }}
        onKeyPress={(event) => {
          onChange(event);
        }}
        role="radio"
        tabIndex={tabIndex}>
        {theme === THEMES.default ? (
          checkedInside
        ) : theme === THEMES.square ? (
          <BodyText size={size}>{label}</BodyText>
        ) : null}
      </span>
      {labelElement}
    </div>
  );
};

InputRadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(["L", "M", "S"]),
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.default, THEMES.square]),
  whiteText: PropTypes.bool,
};

InputRadioButton.defaultProps = {
  disabled: false,
  theme: THEMES.default,
  whiteText: false,
  size: "M",
};

export default InputRadioButton;
