import React, { PureComponent } from "react";
import { Heading } from "../../../shared/atoms";
import { TextWithBackground } from "../../../shared/molecules";
import { colors } from "../../../shared/colors";
import { StringUtils } from "../../../shared/utilities";
import { GatsbyBaseTile } from "../../../sharedgatsby";
import { withStoryblokLink } from "../../utilities";
import "../HalfImageTile/HalfTile.scss";

class HalfTextTile extends PureComponent {
  render() {
    const { key, global, ...TextProps } = this.props;
    const { BackgroundColor, TextColor, Title, Link, LinkTitle } = TextProps;
    if (Link && Link.href) {
      return (
        <GatsbyBaseTile
          whiteText={TextColor.includes("white")}
          xs={{ span: 12 }}
          md={{ span: 4 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
          Link={Link}
          LinkTitle={LinkTitle}
          className="HalfTile">
          <TextWithBackground
            color={colors[BackgroundColor].color}
            whiteText={TextColor.includes("white")}>
            <Heading className="Text" size="S" centered>
              {StringUtils.formatMax(Title, 30)}
            </Heading>
          </TextWithBackground>
        </GatsbyBaseTile>
      );
    }
    return (
      <GatsbyBaseTile
        whiteText={TextColor.includes("white")}
        xs={{ span: 12 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}
        xl={{ span: 4 }}
        className="HalfTile">
        <TextWithBackground
          color={colors[BackgroundColor].color}
          whiteText={TextColor.includes("white")}>
          <Heading className="Text" size="S" centered>
            {StringUtils.formatMax(Title, 30)}
          </Heading>
        </TextWithBackground>
      </GatsbyBaseTile>
    );
  }
}

export default withStoryblokLink(HalfTextTile);
