import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import asFormElement from "../../asFormElement";
import { Label, Icon, BodyText } from "../../../atoms";
import { composeClassName } from "../../../utilities";
import { FormContext } from "../../Form";

import "./Input.scss";

const Input = (props) => {
  const {
    approved,
    className,
    disabled,
    error,
    icon,
    label,
    name,
    placeholder,
    title,
    value,
    message,
    size,
    type,
    whiteText,
    formName,
    ...rest
  } = props;

  const onChange = (event) => {
    event.preventDefault();
    const { value: val } = event.target;
    props.onChangeHandler({ name: name, value: val });
  };

  const labelProps = {
    label,
    whiteText,
  };

  const composedClassName = composeClassName([
    "Input",
    approved ? "approved" : "",
    error ? "error" : "",
    className,
  ]);

  let iconComponent = null;
  let noIconStyles = { width: "100%" };
  if (icon) {
    const iconProps = { icon };
    noIconStyles = null;
    iconComponent = <Icon size={size} {...iconProps} />;
  }

  if (error) {
    noIconStyles = null;
    iconComponent = <Icon size={size} icon="close" className="ErrorIcon" />;
  }

  const htmlFor = `${name}_${formName}`;

  const inputGroupClassName = composeClassName([
    "InputGroup",
    error ? "error" : "",
  ]);
  return (
    <div className="InputContainer">
      <Label htmlFor={htmlFor} {...labelProps}>
        <div className={inputGroupClassName}>
          <input
            className={composedClassName}
            data-testid={rest["data-testid"]}
            disabled={disabled}
            id={htmlFor}
            name={name}
            onChange={(event) => {
              onChange(event);
            }}
            placeholder={placeholder}
            style={noIconStyles}
            title={title}
            type={type}
            value={value}
          />
          {iconComponent}
        </div>
        <BodyText
          data-testid={`${rest["data-testid"]}-error-message`}
          size={size}
          className="ErrorMessage">
          <Trans i18nKey={message}>{null}</Trans>
        </BodyText>
      </Label>
    </div>
  );
};

Input.propTypes = {
  /** Indicates if the user input is accepted */
  approved: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  /** Indicates if there is an error in the user input */
  error: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  /** Indicate purpose of text input for accessibility */
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  initialValue: PropTypes.string,
  type: PropTypes.oneOf(["text", "email", "hidden", "tel"]),
  /** Shorthand for required.
   * String or Function that returns a string is the error message displayed
   * If boolean, default error message is used. */
  required: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.bool,
  ]),
  emptyValue: PropTypes.any,
  /** Defined keys are validated against using standard validators.
   * String or Function that returns a string is the error message displayed
   * If boolean, default error message is used. */
  validateAgainst: PropTypes.object,
  fieldType: PropTypes.oneOf(["string"]),
  size: PropTypes.oneOf(["L", "M", "S"]),
  formName: PropTypes.string,
};

Input.defaultProps = {
  approved: false,
  className: "",
  disabled: false,
  error: false,
  icon: "",
  placeholder: "",
  initialValue: "",
  emptyValue: "",
  fieldType: "string",
  size: "S",
  formName: "",
};

export default asFormElement(Input, FormContext);
