import React, { PureComponent } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Image as SharedImage } from "../../../shared/atoms";
import { ImageLink } from "../../../shared/molecules";

import { withStoryblokLink } from "../../utilities";
import "./Image.scss";

class Image extends PureComponent {
  render() {
    const {
      Image: ImageSource,
      MobileImage,
      AltText,
      LongDesc,
      AspectRatio,
      Link,
      LinkTitle,
    } = this.props;

    const props = {
      className: `SimpleImage ${AspectRatio}`,
      source: ImageSource,
      mobileSource: MobileImage,
      altText: AltText,
      longDesc: LongDesc,
    };

    let linkProps;
    if (Link && Link.href) {
      linkProps = {
        href: Link.href,
        external: Link.external,
        internalLinkComponent: GatsbyLink,
        title: LinkTitle,
      };
    }

    const toReturn = linkProps ? (
      <ImageLink {...props} {...linkProps} />
    ) : (
      <SharedImage {...props} />
    );
    return toReturn;
  }
}

export default withStoryblokLink(Image);
