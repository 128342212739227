import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Icon, BaseLink as Link } from "../../atoms";
import { composeClassName } from "../../utilities";
import "./ButtonIcon.scss";
import "./IESupport.scss";

class ButtonIcon extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onClickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    /** The selection for the size of the button */
    size: PropTypes.oneOf(["L", "M", "S"]),
    tabIndex: PropTypes.string,
    /** The selection for the theme of the button */
    theme: PropTypes.oneOf(["Primary", "Tertiary", "Transparent"]),
    /** The selection for the HTML type of the button */
    type: PropTypes.oneOf(["button", "reset", "submit"]),
    icon: PropTypes.oneOf(Object.keys(Icon.IconPath)).isRequired,
    iconSize: PropTypes.oneOf(["L", "M", "S"]),
    /** The component used as the internal link handler when `internal` is true */
    internalLinkComponent: PropTypes.any,
    /** Open link in new tab if true */
    newTab: PropTypes.bool,
    /** The role of the icon */
    role: PropTypes.string,
    /** The title attribute of the anchor tag, required for accessibility */
    title: PropTypes.string,
    external: PropTypes.bool,
    href: PropTypes.string,
    a11yProps: PropTypes.object,
  };

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    external: false,
    href: "",
    internalLinkComponent: null,
    onClickHandler: null,
    role: "img",
    size: "M",
    tabIndex: "",
    theme: "Primary",
    title: "",
    type: "button",
    newTab: false,
    iconSize: "S",
  };

  render() {
    const {
      autoFocus,
      children,
      className,
      disabled,
      external,
      href,
      icon,
      iconSize,
      internalLinkComponent,
      onClickHandler,
      role,
      size,
      tabIndex,
      theme,
      title,
      type,
      a11yProps,
      newTab,
    } = this.props;

    const buttonProps = {
      autoFocus,
      children,
      disabled,
      onClickHandler,
      size,
      tabIndex,
      theme,
      type,
      a11yProps,
    };

    const iconProps = {
      icon,
      role,
    };

    const linkProps = {
      external,
      href,
      internalLinkComponent,
      title,
      newTab,
    };

    const composedClassName = composeClassName([
      className,
      "ButtonIcon",
      size,
      theme,
    ]);

    const defaultColor = theme === "Tertiary" ? "DefaultGrey" : "DefaultWhite";

    const button = (
      <Button className={composedClassName} {...buttonProps}>
        <Icon
          className="Icon"
          size={iconSize}
          defaultColor={defaultColor}
          {...iconProps}
        />
      </Button>
    );

    if (onClickHandler || disabled) {
      return button;
    }

    return (
      <Link className="ButtonIconContainer" tabIndex="-1" {...linkProps}>
        {button}
      </Link>
    );
  }
}

export default ButtonIcon;
