import React, { PureComponent } from "react";
import { PillarItem as SharedPillarItem } from "../../../shared/molecules";

class PillarItem extends PureComponent {
  render() {
    const { Icon, Title, BodyText, DefaultOpen, name, Index } = this.props;

    return (
      <SharedPillarItem
        icon={Icon}
        title={Title}
        defaultOpen={DefaultOpen}
        id={Index}
        name={name}>
        {BodyText}
      </SharedPillarItem>
    );
  }
}

export default PillarItem;
