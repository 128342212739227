import React from "react";

const playIconSmall = (
  <svg
    className="PlayIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <circle
        cx="16"
        cy="16"
        r="16"
        className="IconInternal"
        fill="rgba(25, 41, 79, .7)"
      />
      <path
        fill="#FFF"
        className="InconBorder"
        fillRule="nonzero"
        d="M14.08 22.24l7.472-5.6a.794.794 0 0 0 0-1.28l-7.472-5.6c-.528-.4-1.28-.016-1.28.64v11.2c0 .656.752 1.04 1.28.64zM16 0C7.168 0 0 7.168 0 16s7.168 16 16 16 16-7.168 16-16S24.832 0 16 0zm0 29.3C8.668 29.3 2.7 23.332 2.7 16 2.7 8.668 8.668 2.7 16 2.7c7.332 0 13.3 5.968 13.3 13.3 0 7.332-5.968 13.3-13.3 13.3z"
      />
    </g>
  </svg>
);
const playIconLarge = (
  <svg
    className="PlayIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64">
    <g fill="none" fillRule="evenodd">
      <circle
        cx="32"
        className="IconInternal"
        cy="32"
        r="32"
        fill="rgba(25, 41, 79, .7)"
      />
      <path
        fill="#FFF"
        className="InconBorder"
        fillRule="nonzero"
        d="M28.16 44.48l14.944-11.2c.864-.64.864-1.92 0-2.56L28.16 19.52c-1.056-.8-2.56-.032-2.56 1.28v22.4c0 1.312 1.504 2.08 2.56 1.28zM32 0C14.336 0 0 14.336 0 32s14.336 32 32 32 32-14.336 32-32S49.664 0 32 0zm0 58.6C17.337 58.6 5.4 46.663 5.4 32 5.4 17.337 17.337 5.4 32 5.4c14.663 0 26.6 11.937 26.6 26.6 0 14.663-11.937 26.6-26.6 26.6z"
      />
    </g>
  </svg>
);
export { playIconSmall, playIconLarge };
