import React from "react";
import SbEditable from "storyblok-react";
import Components from "../../components";

const contentToBlocks = (contentArray, globals) => {
  if (contentArray && contentArray instanceof Array) {
    return contentArray.map((content) => {
      const { _uid, component, ...props } = content;
      const allowedPlaceables = ["SlideImage", "Image", "TyiTabContent"];
      // Placeable props
      let propsData = { ...props, ...globals };

      if (!allowedPlaceables.includes(component)) {
        // Block props
        propsData = { content: propsData };
      }
      const currentComponent = React.createElement(
        Components[component],
        propsData,
      );
      return (
        <React.Fragment key={_uid}>
          <SbEditable content={content}>{currentComponent}</SbEditable>
        </React.Fragment>
      );
    });
  }
  return [];
};

export default contentToBlocks;
