import PropTypes from "prop-types";
import { withMarkdownParser } from "../../utilities";

const TextColumn = () => {
  return null;
};

TextColumn.propTypes = {
  BodyTextContent: PropTypes.string.isRequired,
};

export default withMarkdownParser(TextColumn, {
  textFields: "BodyTextContent",
});
