/* eslint-disable camelcase */
import axios from "axios";

import { browser } from "..";
import {
  LOCALSTORAGE_LOCALE,
  LOCALSTORAGE_TTL,
  LOCALSTORAGE_COUNTRYCODE,
} from "../../constants";

const _callIpStack = async () => {
  let response;
  try {
    response = await axios({
      method: "POST",
      url:
        "https://appeng-wesites.azurewebsites.net/api/ipstack?code=KWB0QGlo05MMtciYwMnQzU7JUyMx2imOnEXAfBwek1P6hgna8vYgyA==",
    });
  } catch (e) {
    response = null;
  }
  return response;
};

const _buildLocale = (countryCode, regionCode) => {
  const countryLocalePairs = {
    CA: "en-CA",
    GB: "en-GB",
    US: "en-US",
  };
  const date = new Date();
  date.setHours(date.getHours() + 4);

  let locale;
  if (countryCode === "CA" && regionCode === "QC") {
    locale = "fr-CA";
  } else {
    locale = countryLocalePairs[countryCode];
  }

  const keyValue = {
    [LOCALSTORAGE_COUNTRYCODE]: locale,
    [LOCALSTORAGE_TTL]: date.getTime(),
  };

  return keyValue;
};

const getUserLocale = async () => {
  const { localStorage } = browser.window;

  let storedLocale = localStorage.getItem(LOCALSTORAGE_LOCALE);
  try {
    storedLocale = JSON.parse(storedLocale);
  } catch (e) {
    storedLocale = null;
  }

  if (
    !storedLocale ||
    !storedLocale[LOCALSTORAGE_TTL] ||
    storedLocale[LOCALSTORAGE_TTL] < new Date().getTime()
  ) {
    localStorage.removeItem(LOCALSTORAGE_LOCALE);
    const response = await _callIpStack();

    if (!response || !response.data) return {};
    const { country_code, region_code } = response.data;
    if (!country_code || !region_code) return {};
    storedLocale = _buildLocale(country_code, region_code);
    localStorage.setItem(LOCALSTORAGE_LOCALE, JSON.stringify(storedLocale));
  }

  return storedLocale;
};

export default getUserLocale;
