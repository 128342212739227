import React, { PureComponent } from "react";
import contentToComponents from "../../utilities/contentToComponents";
import Block from "../Block";
import "./ButtonLayout.scss";
import "./IESupport.scss";

class ButtonLayout extends PureComponent {
  render() {
    const { content, locale } = this.props;
    const { Buttons, Size } = content;

    return (
      <Block content={content} className="ButtonLayoutBlock">
        <div className="ButtonLayoutHeight">
          <div className="ButtonLayout">
            {contentToComponents(Buttons, { Size, locale })}
          </div>
        </div>
      </Block>
    );
  }
}

export default ButtonLayout;
