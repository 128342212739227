import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./Divider.scss";

class Divider extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** HTML title tag to allow accessible titles */
    title: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, title } = this.props;
    const composedClassName = composeClassName(["Divider", className]);
    return <hr className={composedClassName} {...(title ? { title } : {})} />;
  }
}

export default Divider;
