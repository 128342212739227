/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { camelCase } from "lodash";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";

import { BaseLink, Logo, Icon } from "../../atoms";
import { NavigationItemMobile, LocaleSwitcher } from "../../molecules";
import { composeClassName } from "../../utilities";
import "./NavigationBarMobile.scss";

const navLinkPropTypes = PropTypes.shape({
  label: PropTypes.string,
  href: PropTypes.string,
  external: PropTypes.bool,
  title: PropTypes.string,
});

class NavigationBarMobile extends Component {
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    allowedLocales: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        icon: PropTypes.oneOf(["flagCA", "flagUS", "flagGB"]),
      }),
    ),
    className: PropTypes.string,
    logo: PropTypes.oneOf(["We", "MeToWe", "IwthEn", "IwthFr"]),
    logoHref: PropTypes.string,
    logoExternal: PropTypes.bool,
    internalLinkComponent: PropTypes.any.isRequired,
    navigationTabs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.String,
        subMenuTitle: PropTypes.String,
        subMenuBody: PropTypes.String,
        buttonLabel: PropTypes.String,
        buttonHref: PropTypes.String,
        buttonExternal: PropTypes.bool,
        columns: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            links: PropTypes.arrayOf(navLinkPropTypes),
          }),
        ),
      }),
    ),
    navigationLinks: PropTypes.arrayOf(navLinkPropTypes),
  };

  static defaultProps = {
    className: "",
    logo: "We",
    logoHref: "",
    logoExternal: true,
    navigationTabs: [],
    navigationLinks: [],
  };

  constructor(props) {
    super(props);
    this.state = this.initMenu(props.navigationTabs);
  }

  initMenu = (navigationTabs) => {
    const menuState = { menuOpen: false };
    navigationTabs.forEach((tab) => {
      const tabLabel = camelCase(tab.label);
      const tabState = { isOpen: false };
      tab.columns.forEach((column) => {
        const columnTitle = camelCase(column.title);
        tabState[columnTitle] = false;
      });
      menuState[tabLabel] = tabState;
    });
    return menuState;
  };

  handleClick = ({ tabKey, columnKey }) => {
    this.setState((prevState) => {
      const newState = { ...prevState };
      if (columnKey) {
        newState[tabKey][columnKey] = !newState[tabKey][columnKey];
      } else {
        newState[tabKey].isOpen = !newState[tabKey].isOpen;
      }
      return newState;
    });
  };

  renderNavLinks = () => {
    const { navigationLinks, internalLinkComponent } = this.props;
    return navigationLinks.map((link, i) => {
      const { href, external, label, title } = link;
      return (
        <NavigationItemMobile
          key={`${title}-${i}`}
          className="NavigationMobileLink">
          <BaseLink
            href={href}
            external={external}
            title={title}
            internalLinkComponent={internalLinkComponent}>
            {label}
          </BaseLink>
        </NavigationItemMobile>
      );
    });
  };

  renderLinks = (links) => {
    const { internalLinkComponent } = this.props;
    return links.map((link, i) => {
      const { href, external, title, label: linkLabel } = link;
      const linkKey = camelCase(linkLabel);

      return (
        <BaseLink
          key={`${linkKey}-${i}`}
          href={href}
          external={external}
          title={title}
          internalLinkComponent={internalLinkComponent}>
          {linkLabel}
        </BaseLink>
      );
    });
  };

  renderColumns = ({ columns, tabKey }) => {
    return columns.map((column, i) => {
      const { title: columnTitle, links } = column;
      const columnKey = camelCase(columnTitle);
      const isOpen = this.state[tabKey][columnKey]; /* eslint-disable-line */

      const columnLinks = this.renderLinks(links);

      return (
        <NavigationItemMobile
          key={`${columnKey}-${i}`}
          className="NavigationMobileColumn"
          label={columnTitle.toUpperCase()}
          isOpen={isOpen}
          onClickHandler={() => {
            this.handleClick({ tabKey, columnKey });
          }}>
          {columnLinks}
        </NavigationItemMobile>
      );
    });
  };

  renderNavTabs = () => {
    const { navigationTabs, internalLinkComponent } = this.props;
    return navigationTabs.map((tab, i) => {
      const { label: tabLabel, columns } = tab;
      const tabKey = camelCase(tabLabel);
      const { isOpen } = this.state[tabKey]; /* eslint-disable-line */

      const tabColumns = this.renderColumns({ columns, tabKey });

      return (
        <NavigationItemMobile
          key={`${tabKey}-${i}`}
          className="NavigationMobileTab"
          label={tabLabel}
          isOpen={isOpen}
          onClickHandler={() => {
            this.handleClick({ tabKey });
          }}
          internalLinkComponent={internalLinkComponent}>
          {tabColumns}
        </NavigationItemMobile>
      );
    });
  };

  render() {
    const {
      t,
      className,
      overrideLocaleHack,
      allowedLocales,
      currentLocale,
      logo,
      logoHref,
      logoExternal,
      internalLinkComponent,
    } = this.props;
    const { menuOpen } = this.state;

    const navLinks = this.renderNavLinks();
    const navTabs = this.renderNavTabs();

    const mainBar = (
      <nav className={composeClassName(["NavigationBar", "Mobile"])}>
        <Logo
          type={logo}
          href={logoHref}
          external={logoExternal}
          internalLinkComponent={internalLinkComponent}
        />
        <LocaleSwitcher
          overrideLocaleHack={overrideLocaleHack}
          allowedLocales={allowedLocales}
          currentLocale={currentLocale}
        />
        <BaseLink
          blank
          className="Hamburger"
          onClickHandler={() => {
            this.setState((prevState) => {
              return { menuOpen: !prevState.menuOpen };
            });
          }}>
          <Icon
            icon="hamburger"
            size="S"
            className="NavigationHamburger"
            defaultColor="DefaultGrey"
            title={t("NavigationBarMobile__Hamburger__title")}
            role="img"
          />
        </BaseLink>
      </nav>
    );

    const mainMenu = (
      <CSSTransition
        classNames="MenuSlide"
        timeout={600}
        key="Menu"
        unmountOnExit
        in={menuOpen}>
        <div className="NavigationMenuMobile">
          <a className="SkipToContentLink" href="#endOfNavbar">
            {t("NavigationBarDesktop__skipToContent")}
          </a>
          <div className={composeClassName(["NavigationBar", "Mobile"])}>
            <Logo
              type={logo}
              href={logoHref}
              external={logoExternal}
              internalLinkComponent={internalLinkComponent}
            />
            <BaseLink
              blank
              className="Close"
              onClickHandler={() => {
                this.setState((prevState) => {
                  return { menuOpen: !prevState.menuOpen };
                });
              }}>
              <Icon
                icon="close"
                size="S"
                className="NavigationClose"
                defaultColor="DefaultGrey"
                title={t("NavigationBarMobile__Hamburger__title")}
                role="img"
              />
            </BaseLink>
          </div>
          {navTabs}
          {navLinks}
        </div>
      </CSSTransition>
    );

    const composedClassName = composeClassName([
      "NavigationBarMobile",
      className,
    ]);

    return (
      <div className={composedClassName}>
        {mainBar}
        {mainMenu}
        <div id="endOfNavbar" />
      </div>
    );
  }
}

export default withTranslation("translations")(NavigationBarMobile);
