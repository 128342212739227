import React, { PureComponent } from "react";
import { Link } from "gatsby";

import { Col } from "../../../shared/atoms";
import { BodyTextMarkdown } from "../../../shared/molecules";
import { withMarkdownParser } from "../../utilities";
import Block from "../Block";

class Text extends PureComponent {
  render() {
    const { content } = this.props;
    const { BodyTextContent, Size, Centered, Columns } = content;
    const intColumns = Number(Columns);
    const offset = (12 - intColumns) / 2;

    return (
      <Block content={content} className="TextBlock">
        <Col xs={{ span: 12 }} md={{ span: intColumns, offset: offset }}>
          <BodyTextMarkdown
            size={Size}
            centered={Centered}
            internalLinkComponent={Link}>
            {BodyTextContent}
          </BodyTextMarkdown>
        </Col>
      </Block>
    );
  }
}

export default withMarkdownParser(Text, { textFields: ["BodyTextContent"] });
