import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import { ImageBackground, Heading, DisplayText } from "../../atoms";
import ModalVideo from "../ModalVideo";
import "./VideoDisplay.scss";

class VideoDisplay extends PureComponent {
  static propTypes = {
    allowFullScreen: PropTypes.bool,
    className: PropTypes.string,
    /** Video Player source */
    videoSource: PropTypes.oneOf(["vimeo", "youtube"]),
    /** Video Title, required for Accessibility */
    videoTitle: PropTypes.string.isRequired,
    /** Video Player URL Id, for each player source its different,
     * can be found at sharing options of the video */
    videoId: PropTypes.string.isRequired,
    videoButtonTheme: PropTypes.oneOf(["light", "dark"]),
    imgSource: PropTypes.string.isRequired,
    imgMobileSource: PropTypes.string,
    heading: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["Columns6", "Columns12"]),
  };

  static defaultProps = {
    size: "Columns12",
    videoButtonTheme: "dark",
  };

  render() {
    const {
      className,
      allowFullScreen,
      videoSource,
      videoTitle,
      videoId,
      videoButtonTheme,
      imgSource,
      imgMobileSource,
      heading,
      tag,
      size,
    } = this.props;

    const videoProps = {
      allowFullScreen: allowFullScreen,
      source: videoSource,
      title: videoTitle,
      videoId: videoId,
      theme: videoButtonTheme,
    };

    const imageBackgroundProps = {
      source: imgSource,
      mobileSource: imgMobileSource,
    };

    const composedClassName = composeClassName([
      "VideoDisplay",
      size,
      className,
    ]);

    return (
      <div className={composedClassName}>
        <ImageBackground
          {...imageBackgroundProps}
          className="VideoDisplayBackground">
          <ModalVideo {...videoProps} className="PlayButton" />
        </ImageBackground>
        {tag ? <DisplayText size="S">{tag}</DisplayText> : null}
        {heading ? <Heading size="XS">{heading}</Heading> : null}
      </div>
    );
  }
}

export default VideoDisplay;
