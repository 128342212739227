import React from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";

import replaceStoryblokCdn from "../../../components/utilities/replaceStoryblokCdn";

import "./ImageBackground.scss";

const ImageBackground = (props) => {
  const { className, children, mobileSource, source } = props;
  const mobileClassName = composeClassName([
    "ImageBackground",
    "MobileImage",
    className,
  ]);
  const desktopClassName = composeClassName([
    "ImageBackground",
    "DesktopImage",
    className,
  ]);

  const sourceFormatted = replaceStoryblokCdn(source);
  const mobileSourceFormatted = replaceStoryblokCdn(mobileSource);

  return (
    <>
      <div
        className={desktopClassName}
        style={{ backgroundImage: `url(${sourceFormatted})` }}>
        {children}
      </div>
      <div
        className={mobileClassName}
        style={{
          backgroundImage: `url(${mobileSourceFormatted || sourceFormatted})`,
        }}>
        {children}
      </div>
    </>
  );
};

ImageBackground.propTypes = {
  /** The source file for the main image */
  source: PropTypes.string.isRequired,
  /** The source file for the mobile image */
  mobileSource: PropTypes.string,
  className: PropTypes.string,
};

ImageBackground.defaultProps = {
  circular: false,
  className: "",
};

export default ImageBackground;
