import React from "react";
import PropTypes from "prop-types";
import IconPaths from "./IconPaths";

const IconCountry = (props) => {
  const { icon } = props;
  return (
    <svg
      className="IconCountry"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      {IconPaths[icon]}
    </svg>
  );
};

IconCountry.propTypes = {
  icon: PropTypes.oneOf(["flagCA", "flagUS", "flagGB"]).isRequired,
};

export default IconCountry;
