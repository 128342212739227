class FormValidations {
  static defaultMessages = {
    required: "FormValidations__ErrorMessage__required",
    email: "FormValidations__ErrorMessage__invalidEmail",
    phone: "FormValidations__ErrorMessage__invalidPhoneNumber",
  };

  static required = ({ emptyValue = "" }) => {
    return ({ value }) => {
      return value !== emptyValue;
    };
  };

  static email = () => {
    return ({ value }) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(String(value).toLowerCase());
    };
  };

  static phone = () => {
    const maxPhoneNumberLength = 20;
    const minPhoneNumberLength = 5;
    return ({ value }) => {
      const regex = /^(\+\d{1,3}\s?)?(\(\d{1,5}\)|\d{1,5})?[\s.\-/]?\d{1,5}[\s.\-/]?\d{1,5}$/;
      return (
        regex.test(String(value)) &&
        value.length <= maxPhoneNumberLength &&
        value.length >= minPhoneNumberLength
      );
    };
  };
}

export default FormValidations;
