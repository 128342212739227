import React from "react";
import PropTypes from "prop-types";
import Qs from "qs";
import { withTranslation } from "react-i18next";
import { Heading, BaseLink, BodyText, Label, Button } from "../../atoms";
import { ButtonLink } from "../../molecules";
import TabSection from "../TabSection";
import {
  Form,
  InputRadioButton,
  InputText,
  InputDropdown,
  asFormElement,
} from "../../forms";
import { FormContext } from "../../forms/Form";
import { composeClassName } from "../../utilities";
import {
  radioOptions,
  currencyOptions,
  countryOptions,
  countryPillarsOptions,
} from "./payload";

import "./DonationWidget.scss";

const DonationWidget = (props) => {
  const {
    buttonTheme,
    className,
    donateByPhoneLinkExternal,
    donateByPhoneLinkHref,
    donateByPhoneLinkTitle,
    initialAmountGroupValue,
    initialCountryPillarGroupValue,
    internalLinkComponent,
    linkCADHref,
    linkCADTitle,
    linkUSDHref,
    linkUSDTitle,
    radioTheme,
    t,
  } = props;
  let {
    buttonCADText,
    buttonDonateText,
    buttonUSDText,
    donateByPhoneLinkText,
    donateTitle,
    radioLabel,
    textDonateMonthly,
  } = props;

  const amountTitle = t("DonationWidget__InputTextAmount__title");
  const currencyLabel = t("DonationWidget__InputDropdownCurrency__label");
  const currencyPlaceholder = t(
    "DonationWidget__InputDropdownCurrency__placeholder",
  );
  const countryLabel = t("DonationWidget__InputDropdownCountry__label");
  const countryPlaceholder = t(
    "DonationWidget__InputDropdownCountry__placeholder",
  );
  const pillarLabel = t("DonationWidget__InputDropdownPillar__label");
  const pillarPlaceholder = t(
    "DonationWidget__InputDropdownPillar__placeholder",
  );
  const giveOneTimeText = t("DonationWidget__Content__giveOneTimeMessage");
  const giveMonthlyText = t("DonationWidget__Content__giveMonthlyMessage");

  donateTitle = donateTitle || t("DonationWidget__title");
  textDonateMonthly =
    textDonateMonthly || t("DonationWidget__DonateMonthly__text");
  radioLabel = radioLabel || t("DonationWidget__RadioAmount__label");
  buttonDonateText =
    buttonDonateText || t("DonationWidget__ButtonDonate__text");
  buttonCADText = buttonCADText || t("DonationWidget__ButtonCAD__text");
  buttonUSDText = buttonUSDText || t("DonationWidget__ButtonUSD__text");
  donateByPhoneLinkText =
    donateByPhoneLinkText || t("DonationWidget__DonateByPhoneLinkText");

  const donationAmountGroup = (_props) => {
    const { value, name, onChangeHandler } = _props;

    const handleAmountChange = (data) => {
      value.amountRadio = "";
      value.amountText = "";
      onChangeHandler({
        name: name,
        value: { ...value, [data.name]: data.value },
      });
    };

    const inputRadioButtons = radioOptions.map((option) => {
      return (
        <InputRadioButton
          key={`amountRadio${option.value}`}
          name="amountRadio"
          label={option.label}
          theme={radioTheme}
          noRegister
          value={option.value}
          checked={value.amountRadio === option.value}
          size="S"
          onChangeHandler={(args) => {
            handleAmountChange({ name: args.name, value: args.value });
          }}
        />
      );
    });
    return (
      <>
        {inputRadioButtons}
        <InputText
          name="amountText"
          placeholder="$"
          title={amountTitle}
          label=""
          value={value.amountText}
          noRegister
          initialValue={initialAmountGroupValue.amountText}
          size="S"
          onChangeHandler={(args) => {
            handleAmountChange({ name: args.name, value: args.value });
          }}
        />
      </>
    );
  };

  const WrappedDonationAmountGroup = asFormElement(
    donationAmountGroup,
    FormContext,
  );

  const donationCountryPillarGroup = (_props) => {
    const { value, name, onChangeHandler } = _props;
    const handleCountryPillarChange = (data) => {
      // reset pillar value to default, when switching country
      onChangeHandler({
        name: name,
        value: { ...value, pillar: "", [data.name]: data.value },
      });
    };

    const pillarOptions = [];
    const disabled = !value.country;
    if (value.country) {
      countryPillarsOptions[value.country].forEach((item) => {
        pillarOptions.push({ value: item.name, label: item.name });
      });
    }

    return (
      <>
        <InputDropdown
          key="country"
          label={countryLabel}
          options={countryOptions}
          name="country"
          onChangeHandler={(args) => {
            handleCountryPillarChange({ name: args.name, value: args.value });
          }}
          noRegister
          value={value.country}
          size="S"
          placeholder={countryPlaceholder}
          validate={false}
        />

        <InputDropdown
          className="Pillar"
          key="pillar"
          label={pillarLabel}
          options={pillarOptions}
          disabled={disabled}
          name="pillar"
          onChangeHandler={(args) => {
            handleCountryPillarChange({ name: args.name, value: args.value });
          }}
          value={value.pillar}
          noRegister
          size="S"
          placeholder={pillarPlaceholder}
          validate={false}
        />
      </>
    );
  };

  const WrappeddonationCountryPillarGroup = asFormElement(
    donationCountryPillarGroup,
    FormContext,
  );

  const serializeData = (data) => {
    const { countryPillar, amountGroup, ...rest } = data;
    return {
      cmd: "reset",
      source: "widget",
      amount: Object.values(amountGroup).find(String),
      ...countryPillar,
      ...rest,
    };
  };

  const handleSubmit = (data) => {
    const params = serializeData(data);
    const url = "https://forms.we.org/donation";
    const qs = Qs.stringify(params, { addQueryPrefix: true });
    window.location.assign(url + qs);
  };

  const linkCADProps = {
    href: linkCADHref,
    title: linkCADTitle,
    external: true,
  };

  const linkUSDProps = {
    href: linkUSDHref,
    title: linkUSDTitle,
    external: true,
  };

  let donateByPhoneLink;
  if (donateByPhoneLinkHref) {
    donateByPhoneLink = (
      <BaseLink
        className="DonateByPhoneLink"
        href={donateByPhoneLinkHref}
        external={donateByPhoneLinkExternal}
        internalLinkComponent={internalLinkComponent}
        title={donateByPhoneLinkTitle}
        whiteText>
        {donateByPhoneLinkText}
      </BaseLink>
    );
  }

  const composedClassName = composeClassName(["DonationWidget", className]);

  return (
    <div className={composedClassName}>
      <Heading whiteText size="XS">
        {donateTitle}
      </Heading>
      <div className="DonationWidgetContent">
        <TabSection
          tabs={[
            {
              key: "give-one-time",
              text: giveOneTimeText,
              content: [
                <div key="give-one-time" className="TabContent">
                  <Form
                    formName="donation"
                    collectUrlParams
                    submitUrl=""
                    onSubmit={(data) => {
                      handleSubmit(data);
                    }}>
                    <Label htmlFor="donationRadioGroup" label={radioLabel} />
                    <div className="DonationInputGroup">
                      <WrappedDonationAmountGroup
                        name="amountGroup"
                        initialValue={initialAmountGroupValue}
                      />
                    </div>
                    <InputDropdown
                      key="currency"
                      label={currencyLabel}
                      options={currencyOptions}
                      name="currency"
                      placeholder={currencyPlaceholder}
                      validate={false}
                    />
                    <WrappeddonationCountryPillarGroup
                      name="countryPillar"
                      initialValue={initialCountryPillarGroupValue}
                    />
                    <Button
                      type="submit"
                      className="DonationButton"
                      theme={buttonTheme}>
                      {buttonDonateText}
                    </Button>
                  </Form>
                </div>,
              ],
            },
            {
              key: "give-monthly",
              text: giveMonthlyText,
              content: [
                <div key="give-monthly" className="TabContent">
                  <BodyText
                    key="give-monthly"
                    className="GiveMonthly"
                    centered
                    size="S">
                    {textDonateMonthly}
                  </BodyText>
                  <ButtonLink
                    className="DonationButton"
                    theme={buttonTheme}
                    {...linkCADProps}>
                    {buttonCADText}
                  </ButtonLink>
                  <ButtonLink
                    className="DonationButton"
                    theme={buttonTheme}
                    {...linkUSDProps}>
                    {buttonUSDText}
                  </ButtonLink>
                </div>,
              ],
            },
          ]}
        />
      </div>
      {donateByPhoneLink}
    </div>
  );
};

DonationWidget.propTypes = {
  buttonCADText: PropTypes.string,
  buttonDonateText: PropTypes.string,
  buttonTheme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
  buttonUSDText: PropTypes.string,
  className: PropTypes.string,
  donateByPhoneLinkExternal: PropTypes.bool,
  donateByPhoneLinkHref: PropTypes.string,
  donateByPhoneLinkTitle: PropTypes.string,
  donateTitle: PropTypes.string,
  initialAmountGroupValue: PropTypes.object,
  initialCountryOption: PropTypes.object,
  initialCountryPillarGroupValue: PropTypes.object,
  initialCurrencyOption: PropTypes.object,
  initialPillarOption: PropTypes.object,
  internalLinkComponent: PropTypes.any,
  linkCADExternal: PropTypes.bool,
  linkCADHref: PropTypes.string,
  linkCADTitle: PropTypes.string,
  linkUSDExternal: PropTypes.bool,
  linkUSDHref: PropTypes.string,
  linkUSDTitle: PropTypes.string,
  radioLabel: PropTypes.string,
  radioTheme: PropTypes.oneOf(["Default", "Square"]),
  textDonateMonthly: PropTypes.string,
};

DonationWidget.defaultProps = {
  buttonTheme: "Secondary",
  className: "",
  donateByPhoneLinkExternal: false,
  donateByPhoneLinkHref: "",
  donateByPhoneLinkTitle: "",
  initialAmountGroupValue: { amountRadio: "", amountText: "" },
  initialCountryPillarGroupValue: { country: "", pillar: "" },
  linkCADExternal: true,
  linkCADHref:
    "https://secure.e2rm.com/registrant/donate.aspx?eventid=51311&langpref=en-CA&Referrer=http%3a%2f%2fwww.freethechildren.com%2fdonate%2fbecome-a-monthly",
  linkCADTitle: "",
  linkUSDExternal: true,
  linkUSDHref:
    "https://secure.e2rm.com/registrant/donate.aspx?eventid=51317&langpref=en-CA&Referrer=https%3a%2f%2fadmin.e2rm.com%2fEventSummary.aspx",
  linkUSDTitle: "",
  radioTheme: "Square",
};

export default withTranslation("translations")(DonationWidget);
