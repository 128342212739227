import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./BodyText.scss";

class BodyText extends PureComponent {
  static propTypes = {
    /** The selection for the size of the body text */
    size: PropTypes.oneOf(["L", "M", "S", "XS"]),
    /** Color of the text, defaults to brand navy. */
    whiteText: PropTypes.bool,
    centered: PropTypes.bool,
  };

  static defaultProps = {
    size: "M",
    whiteText: false,
    centered: false,
  };

  render() {
    const {
      size,
      children,
      className,
      whiteText,
      centered,
      ...rest
    } = this.props;
    const composedClassName = composeClassName([
      `BodyText ${size}`,
      className,
      whiteText ? "whiteText" : "",
      centered ? "Centered" : "",
    ]);

    return (
      <p data-testid={rest["data-testid"]} className={composedClassName}>
        {children}
      </p>
    );
  }
}

export default BodyText;
