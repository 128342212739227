import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Col, Heading } from "../../../shared/atoms";
import { colors } from "../../../shared/colors";
import Block from "../Block";
import "./Title.scss";

class Title extends PureComponent {
  static propTypes = {
    content: PropTypes.shape({
      IsHeadingOne: PropTypes.bool,
      Text: PropTypes.string.isRequired,
      Size: PropTypes.string.isRequired,
      Centered: PropTypes.bool,
      Underlined: PropTypes.bool,
      UnderlineColor: PropTypes.string.isRequired,
    }),
  };

  render() {
    const { content } = this.props;
    const {
      IsHeadingOne,
      Text,
      Size,
      Centered,
      Underlined,
      UnderlineColor,
      Columns,
    } = content;
    if (!UnderlineColor || !Size) {
      return null;
    }
    const underlineColorValue = colors[UnderlineColor].color;
    const offset = (12 - parseInt(Columns, 10)) / 2;
    return (
      <Block content={content} className="TitleBlock">
        <Col
          xs={{ span: 12 }}
          md={{ span: parseInt(Columns, 10), offset: offset }}>
          <Heading
            centered={Centered}
            size={IsHeadingOne ? "XL" : Size}
            underlined={Underlined}
            underlineColor={underlineColorValue}>
            {Text}
          </Heading>
        </Col>
      </Block>
    );
  }
}

export default Title;
