import React from "react";
import PropTypes from "prop-types";
import { BaseLink, Heading } from "../../atoms";
import { ImageLink } from "..";
import "./RelatedStory.scss";

const RelatedStory = (props) => {
  const {
    internalLinkComponent,
    link,
    storyImageAlt,
    storyPrimaryImage,
    storySecondaryImage,
    storyTitle,
  } = props;

  return (
    <div className="RelatedStory">
      <ImageLink
        altText={storyImageAlt}
        external={link.external}
        href={link.href}
        internalLinkComponent={internalLinkComponent}
        mobileSource={storySecondaryImage}
        source={storyPrimaryImage}
        title={storyTitle}
      />
      <Heading size="XS">
        <BaseLink
          href={link.href}
          external={link.external}
          internalLinkComponent={internalLinkComponent}
          title={storyTitle}>
          {storyTitle}
        </BaseLink>
      </Heading>
    </div>
  );
};

RelatedStory.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    // FIXME
    // external: PropTypes.boolean,
    external: PropTypes.any,
  }).isRequired,
  storyImageAlt: PropTypes.string.isRequired,
  storyPrimaryImage: PropTypes.string.isRequired,
  storySecondaryImage: PropTypes.string.isRequired,
  storyTitle: PropTypes.string.isRequired,
};

export default RelatedStory;
