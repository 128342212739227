/* eslint-disable import/prefer-default-export, space-in-parens */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown"; /* eslint-disable-line import/no-unresolved */
import { BaseLink } from "../../atoms";
import BulletList from "../BulletList";
import BulletListItem from "../BulletListItem";

class MarkdownParser extends PureComponent {
  static propTypes = {
    internalLinkComponent: PropTypes.any.isRequired,
    size: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const {
      children,
      internalLinkComponent,
      size,
      className,
      whiteText,
    } = this.props;
    // Define Renderers Wrappers for React-Markdown
    const MarkdownLink = (props) => {
      const external = props.href[0] !== "/";
      return (
        <BaseLink
          key={props.href[0]}
          unstyled
          whiteText={whiteText}
          internalLinkComponent={internalLinkComponent}
          href={props.href}
          external={external}>
          {props.children}
        </BaseLink>
      );
    };
    const MarkdownBulletList = (props) => {
      return <BulletList size={size}>{props.children}</BulletList>;
    };
    const MarkdownBulletListItem = (props) => {
      return <BulletListItem>{props.children}</BulletListItem>;
    };

    const renderers = {
      link: MarkdownLink,
      list: MarkdownBulletList,
      listItem: MarkdownBulletListItem,
    };
    if (children instanceof Array) return children;

    return (
      <ReactMarkdown
        className={className}
        source={children}
        renderers={renderers}
        allowedTypes={[
          "text",
          "break",
          "paragraph",
          "emphasis",
          "strong",
          "link",
          "list",
          "listItem",
        ]}
      />
    );
  }
}

export default MarkdownParser;
