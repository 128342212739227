import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { BannerWEStories as SharedBannerWEStories } from "../../../shared/molecules";
import { colors } from "../../../shared/colors";
import { withStoryblokLink } from "../../utilities";
import Block from "../Block";

const BannerWEStories = (props) => {
  const { content, ieClass } = props;
  const {
    IsHeadingOne,
    ButtonLink,
    ButtonText,
    ButtonTheme,
    ButtonTitle,
    Description,
    ImageDesktop,
    ImageMobile,
    VideoBackgroundSrc,
    SubTitle,
    Title,
    UnderlineColor,
    WhiteText,
  } = content;

  return (
    <Block content={content} fluid className="BannerWEStoriesBlock">
      <SharedBannerWEStories
        isHeadingOne={IsHeadingOne}
        buttonExternal={ButtonLink.external}
        buttonHref={ButtonLink.href}
        buttonLink={ButtonLink.href}
        buttonText={ButtonText}
        buttonTheme={ButtonTheme}
        buttonTitle={ButtonTitle}
        description={Description}
        imageDesktopSrc={ImageDesktop}
        imageMobileSrc={ImageMobile}
        videoBackgroundSrc={VideoBackgroundSrc}
        internalLinkComponent={GatsbyLink}
        subTitle={SubTitle}
        title={Title}
        underlineColor={colors[UnderlineColor].color}
        whiteText={WhiteText}
        ieClass={ieClass}
      />
    </Block>
  );
};

export default withStoryblokLink(BannerWEStories, {
  LinkFields: ["ButtonLink"],
});
