/* eslint-disable react/prefer-stateless-function */
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const withAllStoryblokLinks = (Component) => {
  return class extends React.Component {
    render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              allStoryblokLink {
                edges {
                  node {
                    uuid
                    real_path
                  }
                }
              }
            }
          `}
          render={(data) => {
            // convert data from array to dict
            const storyblokLinks = {};
            data.allStoryblokLink.edges.forEach((link) => {
              storyblokLinks[link.node.uuid] = link.node.real_path;
            });
            return (
              <Component {...this.props} storyblokLinks={storyblokLinks} />
            );
          }}
        />
      );
    }
  };
};

export default withAllStoryblokLinks;
