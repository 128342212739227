import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./BulletListItem.scss";

class BulletListItem extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, children } = this.props;
    const composedClassName = composeClassName(["BulletListItem", className]);
    return <li className={composedClassName}>{children}</li>;
  }
}

export default BulletListItem;
