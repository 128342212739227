import React, { PureComponent } from "react";
import Block from "../Block";
import { Testimonial as SharedTestimonial } from "../../../shared/molecules";

class Testimonial extends PureComponent {
  render() {
    const { content } = this.props;
    const {
      BackgroundColor,
      BackgroundImage,
      Source,
      SourceDescription,
      SourceSize,
      Text,
      TextSize,
      WhiteText,
    } = content;

    return (
      <Block content={content} className="TestimonialBlock">
        <SharedTestimonial
          backgroundColor={BackgroundColor}
          backgroundImage={BackgroundImage}
          source={Source}
          sourceSize={SourceSize}
          sourceDescription={SourceDescription}
          text={Text}
          textSize={TextSize}
          whiteText={WhiteText}
        />
      </Block>
    );
  }
}

export default Testimonial;
