import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "../../atoms";
import { composeClassName } from "../../utilities";
import "./ButtonGhost.scss";

class ButtonGhost extends PureComponent {
  static propTypes = {
    /** Optional attribute for keyboard Accessibility to fix tabindex issues */
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClickHandler: PropTypes.func,
    /** Optional attribute for keyboard Accessibility */
    tabIndex: PropTypes.string,
    /** The selection for the HTML type of the button */
    type: PropTypes.oneOf(["button", "reset", "submit"]),
  };

  render() {
    const {
      autoFocus,
      children,
      className,
      disabled,
      onClickHandler,
      tabIndex,
      type,
    } = this.props;
    const buttonProps = {
      autoFocus,
      disabled,
      onClickHandler,
      tabIndex,
      type,
    };
    const composedClassName = composeClassName([className, "ButtonGhost"]);
    return (
      <Button
        theme="Tertiary"
        size="L"
        {...buttonProps}
        className={composedClassName}>
        <div>
          <div>{children}</div>
          <Icon icon="chevronDown" size="S" />
        </div>
      </Button>
    );
  }
}

export default ButtonGhost;
