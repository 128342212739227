/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { composeClassName } from "../../utilities";

import "./ModalWrapper.scss";

class ModalWrapper extends PureComponent {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    className: PropTypes.string,
    open: PropTypes.bool,
  };

  static defaultProps = {
    ariaLabel: "modal",
    open: false,
  };

  render() {
    const {
      ariaLabel,
      ariaDescribedBy,
      children,
      className,
      closeHandler,
      open,
    } = this.props;

    const composedClassName = composeClassName(["ModalWrapper", className]);

    if (!open) {
      return null;
    }

    return (
      <div
        className={composedClassName}
        tabIndex="2"
        aria-modal="true"
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedBy}
        role="dialog"
        onKeyDown={closeHandler}
        onClick={closeHandler}>
        <div className="Modal">{children}</div>
      </div>
    );
  }
}

export default ModalWrapper;
