/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from "react";
import { extractStoryblokUrl } from "../../../shared/utilities";
import { StoryblokContext } from "../../../context";

const withStoryblokLink = (
  Component,
  { LinkFields = ["Link"], prefix = "content" } = {},
) => {
  return class extends PureComponent {
    render() {
      const contentProps = this.props[prefix] || this.props;
      return (
        <StoryblokContext.Consumer>
          {({ locale, storyblokLinks }) => {
            const linkProps = {};
            LinkFields.forEach((field) => {
              linkProps[field] = extractStoryblokUrl(
                contentProps[field],
                locale,
                storyblokLinks,
              );
            });

            let toReturn = { ...this.props };
            if (this.props[prefix]) {
              toReturn[prefix] = { ...contentProps, ...linkProps };
            } else {
              toReturn = { ...toReturn, ...linkProps };
            }

            return <Component {...toReturn} />;
          }}
        </StoryblokContext.Consumer>
      );
    }
  };
};

export default withStoryblokLink;
