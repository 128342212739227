import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";

import { IconNumber, BodyText, Heading } from "../../atoms";
import "./ListItinerary.scss";

class ListItinerary extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
    ).isRequired,
  };

  render() {
    const { className, items } = this.props;

    const itineraryItems = items.map((item, index) => {
      return (
        <li key={item.title} className="ItineraryItem">
          <div className="ItineraryItemNumber">
            <IconNumber number={index + 1} />
          </div>
          <div className="ItineraryItemContent">
            <div className="ItineraryItemHeader">
              <Heading size="S">{item.title}</Heading>
            </div>
            <BodyText size="M">{item.description}</BodyText>
          </div>
        </li>
      );
    });

    return (
      <div className={composeClassName(["ListItinerary", className])}>
        <div className="ItineraryItems">
          <ul>{itineraryItems}</ul>
        </div>
      </div>
    );
  }
}

export default ListItinerary;
