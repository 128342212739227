/**
 * Polifill for URLSearchParameters
 * @param {String} queryString
 * @param {Array} paramsToCollect
 */
const parseQuery = (queryString, paramsToCollect) => {
  const query = {};
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    if (decodeURIComponent(pair[0]) in paramsToCollect) {
      query[paramsToCollect[decodeURIComponent(pair[0])]] = decodeURIComponent(
        pair[1] || "",
      );
    }
  }
  return query;
};

export default parseQuery;
