import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import BaseLink from "../BaseLink";
import { composeClassName } from "../../utilities";
import MeToWeLogo from "./MeToWeLogo.svg";
import WeCharityLogo from "./WeCharityLogo.svg";
import IwthLogoEn from "./iwth-en.svg";
import IwthLogoFr from "./iwth-fr.svg";
import IwthFooterLogoEn from "./iwth-en-footer.svg";
import IwthFooterLogoFr from "./iwth-fr-footer.svg";

import "./Logo.scss";

class Logo extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf([
      "We",
      "MeToWe",
      "IwthEn",
      "IwthFr",
      "IwthEnFooter",
      "IwthFrFooter",
    ]).isRequired,
    href: PropTypes.string,
    title: PropTypes.string,
    internalLinkComponent: PropTypes.any.isRequired,
    external: PropTypes.bool,
  };

  static defaultProps = {
    type: "We",
    external: false,
  };

  render() {
    const weLogoSource = "https://static.we.org/logos/WE_RGB_blue_June2016.png";
    const {
      href,
      type,
      title,
      className,
      internalLinkComponent,
      external,
      t,
    } = this.props;
    let logo;
    let altText = t("Logo_weAltText");
    let logoClassName = "";

    switch (type) {
      case "MeToWe":
        logo = MeToWeLogo;
        altText = t("Logo__meToWeAltText");
        logoClassName = "MeToWeLogo";
        break;
      case "WeCharity":
        logo = WeCharityLogo;
        altText = t("Logo__weCharityAltText");
        logoClassName = "WeCharity";
        break;
      case "IwthEn":
        logo = IwthLogoEn;
        altText = t("Logo__iwthEnAltText");
        logoClassName = "IwthLogo";
        break;
      case "IwthFr":
        logo = IwthLogoFr;
        altText = t("Logo__iwthFrAltText");
        logoClassName = "IwthLogo";
        break;
      case "IwthEnFooter":
        logo = IwthFooterLogoEn;
        altText = t("Logo__iwthEnAltText");
        logoClassName = "IwthLogo";
        break;
      case "IwthFrFooter":
        logo = IwthFooterLogoFr;
        altText = t("Logo__iwthFrAltText");
        logoClassName = "IwthLogo";
        break;
      default:
        logo = weLogoSource;
        altText = t("Logo__weAltText");
    }

    return (
      <BaseLink
        internalLinkComponent={internalLinkComponent}
        href={href}
        external={external}
        title={title}
        className={composeClassName(["Logo", className, logoClassName])}>
        <img src={logo} alt={altText} />
      </BaseLink>
    );
  }
}

export default withTranslation("translations")(Logo);
