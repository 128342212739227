/* Splitting fields into a seperate folder, like we talked about.
 * The forms will be it's own repository, and will have dependency
 * on the SCL for the label. We may want to have "naked" versions of these fields too.
 * Split things into this folder as neccesary until we do the full split.
 */
import Input from "./Input";
import InputHidden from "./InputHidden";
import InputText from "./InputText";
import InputEmail from "./InputEmail";
import InputDropdown from "./InputDropdown";
import InputTextArea from "./InputTextArea";
import InputRadioButton from "./InputRadioButton";
import InputRadioGroup from "./InputRadioGroup";
import InputCheckbox from "./InputCheckbox";
import InputPhoneNumber from "./InputPhoneNumber";

export {
  Input,
  InputHidden,
  InputText,
  InputEmail,
  InputDropdown,
  InputTextArea,
  InputRadioButton,
  InputRadioGroup,
  InputCheckbox,
  InputPhoneNumber,
};
