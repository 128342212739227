import React, { PureComponent } from "react";
import Block from "../Block";
import { TestimonialHybrid as SharedTestimonialHybrid } from "../../../shared/molecules";

class TestimonialHybrid extends PureComponent {
  render() {
    const { content } = this.props;
    const {
      AltText,
      BackgroundColor,
      Image,
      Source,
      SourceDescription,
      Text,
      WhiteText,
    } = content;

    return (
      <Block content={content} className="TestimonialHybridBlock">
        <SharedTestimonialHybrid
          altText={AltText}
          backgroundColor={BackgroundColor}
          image={Image}
          source={Source}
          sourceDescription={SourceDescription}
          text={Text}
          whiteText={WhiteText}
        />
      </Block>
    );
  }
}

export default TestimonialHybrid;
