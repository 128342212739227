import React from "react";

const MediaItems = {
  socialTwitter: {
    path: (
      <path
        id="a-socialTwitter"
        d="M16 3.42a6.945 6.945 0 0 1-1.885.477c.677-.375 1.198-.968 1.443-1.675-.634.346-1.336.6-2.084.734A3.415 3.415 0 0 0 11.077 2C9.264 2 7.795 3.356 7.795 5.03c0 .236.028.468.084.69-2.728-.127-5.146-1.333-6.766-3.166A2.844 2.844 0 0 0 .67 4.077c0 1.052.58 1.979 1.46 2.522A3.483 3.483 0 0 1 .643 6.22v.038c0 1.468 1.13 2.693 2.632 2.971a3.564 3.564 0 0 1-1.482.052c.418 1.203 1.63 2.079 3.066 2.104a6.941 6.941 0 0 1-4.076 1.297c-.265 0-.526-.014-.783-.042A9.871 9.871 0 0 0 5.032 14c6.037 0 9.34-4.616 9.34-8.62 0-.13-.004-.261-.01-.392A6.4 6.4 0 0 0 16 3.42z"
      />
    ),
  },
  socialFacebook: {
    path: (
      <path
        id="a-socialFacebook"
        d="M9 16V8h3V6H9V4c.193-.78.413-1.343 2-1h1V0c-.25.074-1.112 0-2 0-2.205 0-3.633 1.329-4 4v2H4v2h2v8h3z"
      />
    ),
  },
  socialPinterest: {
    path: (
      <path
        id="a-socialPinterest"
        d="M8.234 0C3.51 0 1 2.81 1 5.874c0 1.42.855 3.193 2.224 3.755.207.087.32.05.368-.13.04-.135.22-.79.307-1.099.027-.099.013-.185-.074-.278-.454-.488-.815-1.377-.815-2.211 0-2.137 1.83-4.212 4.943-4.212 2.692 0 4.576 1.624 4.576 3.946 0 2.625-1.497 4.441-3.44 4.441-1.076 0-1.877-.784-1.623-1.754.307-1.155.908-2.396.908-3.23 0-.747-.454-1.365-1.383-1.365-1.095 0-1.983 1.007-1.983 2.36 0 .858.327 1.438.327 1.438L4.052 12.36c-.34 1.285.047 3.367.08 3.546.02.099.14.13.208.05.107-.13 1.416-1.866 1.783-3.12l.681-2.31c.361.605 1.403 1.112 2.512 1.112 3.3 0 5.684-2.693 5.684-6.035C14.987 2.397 12.034 0 8.234 0"
      />
    ),
  },
  email: {
    path: (
      <path
        id="a-email"
        d="M8.012 9.02L9 10l-1 1-1-1 .01-.01-.986-.977-1.499 1.483-1.027-.989 1.515-1.495-1.495-1.48.99-1.028 3.506 3.513-.002.002zm2.99-1l1.465 1.484-1.026.985L10 9.033l-.96.97-1.029-.99 3.514-3.506.989.988-1.511 1.525zM1.995 2h12.012C15.106 2 16 2.9 16 4.009v7.982A2.003 2.003 0 0 1 14.006 14H1.994C.894 14 0 13.1 0 11.991V4.01C0 2.902.893 2 1.994 2zm1.008 2C2.45 4 2 4.453 2 4.997v6.006c0 .55.456.997 1.002.997h9.996c.553 0 1.002-.453 1.002-.997V4.997c0-.55-.456-.997-1.002-.997H3.002z"
      />
    ),
  },
};

export default MediaItems;
