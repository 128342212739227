import React from "react";
import VisualTests from "../../components/blocks/index.visual";
import "../../components/content/BasePage/BasePage.scss";
import "./VisualTestingPage.scss";

const VisualTestingPage = (props) => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const testParam = params.get("test") || "undefined";

  let ToRender = <div>No Test Found</div>;
  if (VisualTests[testParam]) {
    const Test = VisualTests[testParam];
    ToRender = <Test />;
  }

  return (
    <div className="page">
      <div className="container-fluid content">{ToRender}</div>
    </div>
  );
};

export default VisualTestingPage;
