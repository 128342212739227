const payload = [
  {
    country_id: 1,
    name: "Kenya",
    pillars: [
      {
        pillar_id: 2,
        name: "Water",
      },
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 3,
        name: "Health",
      },
      {
        pillar_id: 5,
        name: "Food",
      },
      {
        pillar_id: 4,
        name: "Opportunity",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
  {
    country_id: 2,
    name: "India",
    pillars: [
      {
        pillar_id: 2,
        name: "Water",
      },
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 3,
        name: "Health",
      },
      {
        pillar_id: 5,
        name: "Food",
      },
      {
        pillar_id: 4,
        name: "Opportunity",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
  {
    country_id: 3,
    name: "Ecuador",
    pillars: [
      {
        pillar_id: 2,
        name: "Water",
      },
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 3,
        name: "Health",
      },
      {
        pillar_id: 5,
        name: "Food",
      },
      {
        pillar_id: 4,
        name: "Opportunity",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
  {
    country_id: 4,
    name: "Rural China",
    pillars: [
      {
        pillar_id: 2,
        name: "Water",
      },
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 3,
        name: "Health",
      },
      {
        pillar_id: 5,
        name: "Food",
      },
      {
        pillar_id: 4,
        name: "Opportunity",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
  {
    country_id: 5,
    name: "Sierra Leone",
    pillars: [
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
  {
    country_id: 6,
    name: "Haiti",
    pillars: [
      {
        pillar_id: 2,
        name: "Water",
      },
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 3,
        name: "Health",
      },
      {
        pillar_id: 5,
        name: "Food",
      },
      {
        pillar_id: 4,
        name: "Opportunity",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
  {
    country_id: 8,
    name: "Tanzania",
    pillars: [
      {
        pillar_id: 2,
        name: "Water",
      },
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 3,
        name: "Health",
      },
      {
        pillar_id: 5,
        name: "Food",
      },
      {
        pillar_id: 4,
        name: "Opportunity",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
  {
    country_id: 9,
    name: "Ethiopia",
    pillars: [
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
  {
    country_id: 10,
    name: "Priority Country",
    pillars: [
      {
        pillar_id: 2,
        name: "Water",
      },
      {
        pillar_id: 1,
        name: "Education",
      },
      {
        pillar_id: 3,
        name: "Health",
      },
      {
        pillar_id: 5,
        name: "Food",
      },
      {
        pillar_id: 4,
        name: "Opportunity",
      },
      {
        pillar_id: 17,
        name: "Priority Pillar",
      },
    ],
  },
];

const radioOptions = [
  { value: "75", label: "$75" },
  { value: "125", label: "$125" },
  { value: "250", label: "$250" },
];

const currencyOptions = [
  { value: 1, label: "CAD" },
  { value: 2, label: "USD" },
];

const countryOptions = payload.slice().map((item) => {
  return {
    value: item.country_id,
    label: item.name,
  };
});

const countryPillarsOptions = {};
payload.forEach((item) => {
  countryPillarsOptions[item.country_id] = item.pillars;
});

export { currencyOptions, countryPillarsOptions, countryOptions, radioOptions };
