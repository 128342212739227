import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Container, BodyText, Icon } from "../../atoms";
import LocaleSwitcher from "../LocaleSwitcher";
import "./LocaleSwitcherFirstTimeUser.scss";

const LocaleSwitcherFirstTimeUser = (props) => {
  const { allowedLocales, currentLocale, t, onClickHandler } = props;
  let { message } = props;
  message = message || t("LocaleSwitcherFirstTimeUser__message");

  return (
    <Container fluid className="LocaleSwitcherFirstTimeUser">
      <div className="LocaleSwitcherContainer">
        <BodyText size="S">{message}</BodyText>
        <LocaleSwitcher
          allowedLocales={allowedLocales}
          currentLocale={currentLocale}
        />
        <span
          role="button"
          tabIndex={0}
          onClick={(event) => {
            onClickHandler(event);
          }}
          onKeyPress={(event) => {
            onClickHandler(event);
          }}
          className="IconClose">
          <Icon icon="close" size="S" />
        </span>
      </div>
    </Container>
  );
};

LocaleSwitcherFirstTimeUser.propTypes = {
  message: PropTypes.string,
  currentLocale: PropTypes.string,
  allowedLocales: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
};

LocaleSwitcherFirstTimeUser.defaultProps = {
  message: "",
  hidden: true,
};

export default withTranslation("translations")(LocaleSwitcherFirstTimeUser);
