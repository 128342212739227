/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";
import { camelCase } from "lodash";

import { composeClassName, browser } from "../../utilities";
import { BaseLink, BodyText, Logo, Heading } from "../../atoms";
import { NavigationMenu, ButtonLink, LocaleSwitcher } from "../../molecules";
import "./NavigationBarDesktop.scss";

const NavigationDropdown = (props) => {
  const {
    title,
    body,
    buttonLabel,
    buttonHref,
    buttonExternal,
    internalLinkComponent,
    columns,
  } = props;

  const renderMenuButton = () => {
    if (buttonLabel && buttonHref) {
      return (
        <ButtonLink
          theme="Primary"
          size="M"
          href={buttonHref}
          external={buttonExternal}
          internalLinkComponent={internalLinkComponent}>
          {buttonLabel}
        </ButtonLink>
      );
    }
    return null;
  };

  const renderBody = () => {
    if (body) {
      return (
        <BodyText size="S" className="MenuBodyText">
          {body}
        </BodyText>
      );
    }
    return null;
  };

  const renderMenu = () => {
    if (columns) {
      return (
        <NavigationMenu
          internalLinkComponent={internalLinkComponent}
          columns={columns}
        />
      );
    }
    return null;
  };

  return (
    <div className="NavigationDropdown" role="menu" tabIndex={0}>
      <div className="SubMenuContent">
        <Heading size="S" className="MenuHeading">
          {title}
        </Heading>
        {renderBody()}
        {renderMenuButton()}
      </div>
      {renderMenu()}
    </div>
  );
};

const linkPropTypes = PropTypes.shape({
  label: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  external: PropTypes.bool,
});

const tabPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  subMenuTitle: PropTypes.string,
  subMenuBody: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonHref: PropTypes.string,
  buttonExternal: PropTypes.bool,
  columns: PropTypes.arrayOf(linkPropTypes),
});

class NavigationBarDesktop extends Component {
  static propTypes = {
    className: PropTypes.string,
    logo: PropTypes.oneOf(["We", "MeToWe", "IwthEn", "IwthFr"]),
    logoHref: PropTypes.string,
    logoExternal: PropTypes.bool,
    internalLinkComponent: PropTypes.any.isRequired,
    navigationLinks: PropTypes.arrayOf(linkPropTypes),
    navigationTabs: PropTypes.arrayOf(tabPropTypes),
    currentLocale: PropTypes.string.isRequired,
    allowedLocales: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        icon: PropTypes.oneOf(["flagCA", "flagUS", "flagGB"]),
      }),
    ),
  };

  static defaultProps = {
    className: "",
    logo: "We",
    logoHref: "",
    logoExternal: true,
    navigationLinks: [],
    navigationTabs: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      menuCurrent: null,
      menuExiting: null,
      menuEntering: null,
    };
    // reference to currently selected menu
    this.menuRef = React.createRef();
  }

  componentDidMount() {
    browser.document.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    browser.document.removeEventListener("click", this.handleOutsideClick);
  }

  handleClick = (tabKey) => {
    const { menuCurrent, menuEntering, menuExiting } = this.state;
    let newState;

    if (!menuCurrent) {
      newState = { menuCurrent: tabKey, menuEntering: tabKey };
    } else if (menuEntering) {
      if (menuEntering === tabKey) {
        newState = { menuEntering: null, menuExiting: tabKey };
      } else {
        newState = { menuCurrent: tabKey, menuEntering: tabKey };
      }
    } else if (menuExiting) {
      if (menuExiting === tabKey) {
        newState = { menuExiting: null, menuEntering: tabKey };
      } else {
        newState = {
          menuEntering: tabKey,
          menuCurrent: tabKey,
          menuExiting: null,
        };
      }
    } else if (menuCurrent === tabKey) {
      newState = { menuExiting: tabKey };
    } else {
      newState = { menuCurrent: tabKey };
    }
    this.setState(newState);
  };

  handleOutsideClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    const { menuCurrent } = this.state;
    this.handleClick(menuCurrent);
  };

  isMenuPresent = () => {
    const { menuCurrent, menuEntering, menuExiting } = this.state;
    if (!menuExiting && (menuCurrent || menuEntering)) {
      return true;
    }
    return false;
  };

  menuEntered = () => {
    const { menuEntering } = this.state;
    this.setState({ menuCurrent: menuEntering, menuEntering: null });
  };

  menuExited = () => {
    this.setState({ menuCurrent: null, menuExiting: null });
  };

  renderNavLinks = () => {
    const { navigationLinks, internalLinkComponent } = this.props;
    return navigationLinks.map((link, i) => {
      const { href, external, label, title } = link;
      return (
        <BaseLink
          key={`${label}-${i}`}
          href={href}
          external={external}
          title={title}
          internalLinkComponent={internalLinkComponent}>
          {label}
        </BaseLink>
      );
    });
  };

  renderNavTabs = () => {
    const { navigationTabs, internalLinkComponent } = this.props;
    const { menuCurrent } = this.state;
    const navTabs = [];
    const navMenus = {};
    navigationTabs.forEach((tab) => {
      const {
        label: tabLabel,
        subMenuTitle,
        subMenuBody,
        buttonLabel,
        buttonHref,
        buttonExternal,
        columns,
      } = tab;
      const tabKey = camelCase(tab.label);

      const isOpen = menuCurrent === tabKey;
      // Construct tabs to open menus
      const navTab = (
        <BaseLink
          key={tabKey}
          className={composeClassName(["NavigationTab", isOpen ? "Open" : ""])}
          onClickHandler={() => {
            this.handleClick(tabKey);
          }}
          blank>
          {tabLabel}
        </BaseLink>
      );
      navTabs.push(navTab);
      navMenus[tabKey] = (
        <NavigationDropdown
          title={subMenuTitle}
          body={subMenuBody}
          buttonLabel={buttonLabel}
          buttonHref={buttonHref}
          buttonExternal={buttonExternal}
          internalLinkComponent={internalLinkComponent}
          columns={columns}
        />
      );
    });
    return { navTabs, navMenus };
  };

  render() {
    const {
      overrideLocaleHack,
      allowedLocales,
      currentLocale,
      className,
      logo,
      logoHref,
      logoExternal,
      internalLinkComponent,
      t,
    } = this.props;
    const { menuCurrent } = this.state;

    const navLinks = this.renderNavLinks();

    // navTabs will render the clickable tabs to open the menus
    // navMenus dictionary will allow us to render the currently selected menu
    const { navTabs, navMenus } = this.renderNavTabs();

    const navBar = (
      <nav className="NavigationBar">
        <a className="SkipToContentLink" href="#endOfNavbar">
          {t("NavigationBarDesktop__skipToContent")}
        </a>
        <Logo
          type={logo}
          href={logoHref}
          external={logoExternal}
          internalLinkComponent={internalLinkComponent}
        />
        <div className="NavigationTabs">{navTabs}</div>
        <div className="NavigationLinks">{navLinks}</div>
        <LocaleSwitcher
          overrideLocaleHack={overrideLocaleHack}
          currentLocale={currentLocale}
          allowedLocales={allowedLocales}
        />
      </nav>
    );

    const navMenu = (
      <CSSTransition
        classNames="MenuSlide"
        timeout={1000}
        key="Menu"
        in={this.isMenuPresent()}
        mountOnEnter
        unmountOnExit
        onEntered={this.menuEntered}
        onExited={this.menuExited}>
        {navMenus[menuCurrent] || <></>}
      </CSSTransition>
    );

    const composedClassName = composeClassName([
      "NavigationBarDesktop",
      className,
    ]);

    return (
      <div
        className={composedClassName}
        ref={(node) => {
          this.node = node;
        }}>
        {navBar}
        {navMenu}
        <div id="endOfNavbar" />
      </div>
    );
  }
}

export default withTranslation("translations")(NavigationBarDesktop);
