/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, DisplayText, Col, BaseLink } from "../../atoms";
import { LOCALSTORAGE_ACCEPTCOOKIES } from "../../constants";
import browser from "../../utilities/browser";
import "./CookieBar.scss";

const CookieBar = (props) => {
  const [hidden, setHidden] = useState(
    browser.window.localStorage.getItem(LOCALSTORAGE_ACCEPTCOOKIES),
  );
  const { internalLinkComponent, t } = props;

  const message = t("CookieBar__message");
  const linkText = t("CookieBar__PolicyLink__text");
  const buttonText = t("CookieBar__Button__Text");
  const linkHref = "/en-GB/about-we/privacy-policy";

  const handleClick = () => {
    browser.window.localStorage.setItem(LOCALSTORAGE_ACCEPTCOOKIES, true);
    setHidden(true);
  };

  if (hidden) return null;

  return (
    <div className="CookieBar">
      <div className="container">
        <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 10 }}>
          <DisplayText size="S">
            {message}{" "}
            <BaseLink
              external
              href={linkHref}
              title={linkText}
              unstyled
              internalLinkComponent={internalLinkComponent}>
              {linkText}
            </BaseLink>
          </DisplayText>
        </Col>
        <Col
          className="ButtonWrapper"
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          lg={{ span: 2 }}>
          <Button
            size="S"
            theme="Tertiary"
            type="button"
            onClickHandler={() => {
              handleClick();
            }}>
            {buttonText}
          </Button>
        </Col>
      </div>
    </div>
  );
};

export default withTranslation("translations")(CookieBar);
