/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
const path = require("path");
const _ = require("lodash");

const { getContent } = require("./utilities");

function StoryblokPageCreator({ graphql, actions }) {
  this.graphql = graphql;
  this.createPage = actions.createPage;
  this.nonPublishableStories = [];
  this.publishableStories = [];
  this.partialsStories = [];
  this.storyblokEntry = null;

  this._getStoryblokStories = async (filterQuery = "{}") => {
    const query = `
        {
          allStoryblokEntry(filter: ${filterQuery}) {
            edges {
              node {
                id
                name
                created_at
                uuid
                content
                slug
                full_slug
                is_startpage
                parent_id
                group_id
              }
            }
          }
        }
      `;

    const stories = await this.graphql(query);
    try {
      if (!stories.data.allStoryblokEntry) {
        console.error(
          `No data obtained from Storyblok with filterQuery: ${filterQuery}.`,
        );
        return [];
      }
    } catch (TypeError) {
      console.log(filterQuery);
    }

    return stories.data.allStoryblokEntry.edges;
  };

  this.getPartials = async (site, locale) => {
    const siteQuery = (site !== "all" && site) || "([^/]+)";
    const localeQuery = (locale !== "all" && locale) || "([^/]+)";
    const query = `{ full_slug: { regex: "/^(${siteQuery}/${localeQuery}/___partials)/" }}`; // eslint-disable-line quotes

    const partials = await this._getStoryblokStories(query);
    console.info(`- Number of GLOBAL partials: ${partials.length}`);
    return partials;
  };

  /* Get all stories in Storyblok,
   * then divide stories into publishable and non publishable
   */

  //  TODO: Use array of sites instead of string parameter
  this.getStories = async (site, locale) => {
    const siteQuery = (site !== "all" && site) || "([^/]+)";
    const localeQuery = (locale !== "all" && locale) || "([^/]+)";
    const query = `{ full_slug: { regex: "/^(${siteQuery}/${localeQuery})/" }}`; // eslint-disable-line quotes

    const stories = await this._getStoryblokStories(query);
    console.info(`- Number of ALL stories: ${stories.length}`);

    const nonPublishableStories = stories.filter((story) => {
      // criteria for a stories to be non-publishable should be
      // defined here
      return (
        story.node.full_slug.includes("___partials") ||
        story.node.full_slug.includes("___data")
      );
    });

    // publisable stories are anything that's not in the non-publishable list
    const publishableStories = stories.filter((story) => {
      return !nonPublishableStories.includes(story);
    });

    console.info(
      `- Number of PUBLISHABLE stories: ${publishableStories.length}`,
    );
    console.info(
      `- Number of NON-PUBLISHABLE stories: ${nonPublishableStories.length}`,
    );

    return [publishableStories, nonPublishableStories];
  };

  /*
   * Obtain a mixed of partials by locale & by default locale
   * Example partialFullSlug: `we_org/en-CA/___partials/navigation`
   */
  this.filterSiteGlobalPartials = (
    partials,
    { site, locale, sourceLocale },
  ) => {
    const partialStories = {};

    // if the page is mirrored, sourceLocale is the locale of the original story
    // by filtering sourceLocale first then storyLocale, we ensure the following cases:
    // - if the story is mirrored, and the story's local partial is NOT found,
    //    the partial returned would be sourceLocale partial
    // - if the story is mirrored, and the story's local partial is found,
    //     the partial returned would be story's direct partial
    // - if the story is NOT mirrored,
    //     story's local partial would override the original story's local partials
    if (sourceLocale) {
      // Add sourceLocale first
      partials.forEach((partial) => {
        const partialFullSlug = partial.node.full_slug;
        const [
          partialSite,
          partialLocale,
          base,
          ...restPartial
        ] = partialFullSlug.split("/");
        if (partialSite === site && partialLocale === sourceLocale) {
          partialStories[restPartial.join("/")] = JSON.parse(
            partial.node.content,
          );
        }
      });
    }

    // Overide sourceLocale with provided locale
    partials.forEach((partial) => {
      const partialFullSlug = partial.node.full_slug;
      const [
        partialSite,
        partialLocale,
        base,
        ...restPartial
      ] = partialFullSlug.split("/");
      if (
        (site === "all" || partialSite === site) &&
        partialLocale === locale
      ) {
        partialStories[restPartial.join("/")] = JSON.parse(
          partial.node.content,
        );
      }
    });

    return partialStories;
  };

  /*
   * Obtain local partials (partials that live in the `___partials` folder,
   *   which is located in same folder to the provided story)
   *
   * Using the same overrding logic as `filterSiteGlobalPartials`
   *   Example
   *   - story's slug: `we_org/en-CA/careers/example-page
   *   - local partial's slub: `we_org/en-CA/careers/___partials/subnav
   */
  this.filterLocalPartials = (
    stories,
    { storyFullSlug, isRoot, sourceLocale },
  ) => {
    let localSlug = storyFullSlug.slice(0, -1);
    if (!isRoot) {
      localSlug = localSlug
        .split("/")
        .slice(0, -1)
        .join("/");
    }

    const partialStories = {};

    // Add sourceLocale first
    if (sourceLocale) {
      const [storyLocale, ...localSlugWithoutLocale] = localSlug.split("/");
      const localSlugDefaultLocale = [
        sourceLocale,
        ...localSlugWithoutLocale,
      ].join("/");

      stories.forEach((story) => {
        if (
          story.node.full_slug.includes(`${localSlugDefaultLocale}/___partials`)
        ) {
          partialStories[story.node.slug] = getContent(story);
        }
      });
    }

    // Overide sourceLocale with provided local slug
    stories.forEach((story) => {
      if (story.node.full_slug.includes(`${localSlug}/___partials`)) {
        partialStories[story.node.slug] = getContent(story);
      }
    });

    return partialStories;
  };

  this.createPageFromStory = (
    story,
    { overrideLocaleHack, allowedLocales, locale, site, siteUrl, sourceLocale },
  ) => {
    const url = story.node.full_slug || "/";
    const localePartials = this.filterSiteGlobalPartials(this.partials, {
      site,
      locale,
      sourceLocale,
    });
    const localPartials = this.filterLocalPartials(this.nonPublishableStories, {
      storyFullSlug: url,
      isRoot: story.node.is_startpage,
      sourceLocale,
    });

    this.createPage({
      path: url,
      component: this.storyblokEntry,
      context: {
        overrideLocaleHack: overrideLocaleHack,
        allowedLocales: allowedLocales,
        currentHref: url,
        localPartials: localPartials,
        locale: locale,
        site: site,
        partials: localePartials,
        siteUrl: siteUrl,
        story: story.node,
      },
    });
  };

  this.createPublishableStories = async ({
    site,
    locale,
    siteUrl,
    allowedLocales,
    overrideLocaleHack,
  }) => {
    for (const story of this.publishableStories) {
      const publishableStory = _.cloneDeep(story);

      // constructing story's slug based on buildSite & buildLocale
      const [
        baseSite,
        storyLocale,
        ...restStorySlug
      ] = publishableStory.node.full_slug.split("/");

      // site-specific + all locales
      if (site !== "all" && locale === "all") {
        publishableStory.node.full_slug = [storyLocale, ...restStorySlug].join(
          "/",
        );
        // site-locale-specific
      } else if (site !== "all" && locale !== "all") {
        publishableStory.node.full_slug = [...restStorySlug].join("/");
      }

      await this.createPageFromStory(publishableStory, {
        overrideLocaleHack,
        allowedLocales,
        locale: storyLocale,
        site,
        siteUrl,
      });
    }
  };

  this.createContentMirroringStories = async ({
    overrideLocaleHack,
    allowedLocales,
    locale,
    site,
    siteUrl,
  }) => {
    for (const story of this.publishableStories) {
      const content = getContent(story);
      if (content.MirroringLocales) {
        for (const mirroringLocale of content.MirroringLocales) {
          const mirroringStory = _.cloneDeep(story);
          // constructing the mirrored slug
          const [
            baseSite,
            storyLocale,
            ...restStoryUrl
          ] = story.node.full_slug.split("/");
          const mirroringSlug = [mirroringLocale, ...restStoryUrl].join("/");
          mirroringStory.node.full_slug = mirroringSlug;

          await this.createPageFromStory(mirroringStory, {
            overrideLocaleHack,
            allowedLocales,
            locale: mirroringLocale,
            site: site,
            siteUrl,
            sourceLocale: storyLocale,
          });
        }
      }
    }
  };

  /*
   * `createPages` build pages in the `public` folder based on different settings,
   *  namely: `buildEnvironment`, `buildSite`, and `buildLocale`.
   *
   *  With different `buildEnvironment`:
   *    - `editor`: no pages are built
   *    - `publisher`: only published will be built
   *
   *  With different `buildSite` & `buildLocale`, we basically have 2 cases:
   *      - site-specific pages. This will be used for multi-locale websites
   *      URL examples for `buildSite=we_org`, `builLocale=all`
   *          `https://we.org/en-CA/home`
   *          `https://we.org/fr-CA/home`
   *          `https://we.org/en-US/home`
   *          `https://we.org/en-GB/home`
   *      - site+locale-specific pages. This will be used for single-locale websites
   *      URL examples for `buildSite=we_org`, `buildLocale=en-CA`,
   *          `https://we.org/home`
   *          `https://we.org/we-stories/`
   *      URL examples for `buildSite=we_org`, `buildLocale=fr-CA`,
   *          `https://www.mouvementunis.org/home`
   *          `https://www.mouvementunis.org/we-stories`
   *
   *  The script depends on GATSBY_WE_BUILD_ENV, GATSBY_WE_BUILD_SITE and
   * GATSBY_WE_BUILD_LOCALE to determine
   *  which type of website to build. So it can be easly controlled via supplying
   *  the correct env vars.
   */
  this.createPages = async (
    environment,
    site,
    locale,
    siteUrl,
    allowedLocales,
    overrideLocaleHack,
  ) => {
    // check for enviroments
    if (!["editor", "publisher"].includes(environment)) {
      console.error("Incorrect GATSBY_WE_BUILD_ENV supplied");
      return [];
    }

    // check for sites
    if (
      site &&
      ![
        "all",
        "we_org",
        "tyi",
        "trips",
        "covid",
        "m2w",
        "iwanttohelp_en",
        "iwanttohelp_fr",
      ].includes(site)
    ) {
      console.error("Incorrect GATSBY_WE_BUILD_SITE supplied");
      return [];
    }

    let locales;
    if (allowedLocales) {
      locales = allowedLocales.map((allowedLocale) => {
        return allowedLocale.value;
      });
    }

    // check for locale
    if (locale && !["all", ...locales].includes(locale)) {
      console.error("Incorrect GATSBY_WE_BUILD_LOCALE supplied");
      return [];
    }

    console.info("== BUILD VARIABLES");
    console.info(`Environment: ${environment}`);
    console.info(`Site: ${site}`);
    console.info(`Locale: ${locale}`);

    console.info("== BUILD DETAILS");
    this.storyblokEntry = await path.resolve(
      "src/templates/StoryblokEntry.jsx",
    );

    if (environment === "editor") {
      // intentionally doing nothing here
    }

    if (environment === "publisher") {
      if (!site || site === "all") {
        // intentionally doing nothing here
      } else if (!locale || locale === "all") {
        // build site-specified pages with all included locales
        const [
          publishableStories,
          nonPublishableStories,
        ] = await this.getStories(site);
        this.publishableStories = publishableStories;
        this.nonPublishableStories = nonPublishableStories;
        this.partials = await this.getPartials(site);

        await this.createPublishableStories({
          overrideLocaleHack,
          allowedLocales,
          locale,
          site,
          siteUrl,
        });
        await this.createContentMirroringStories({
          overrideLocaleHack,
          allowedLocales,
          locale,
          site,
          siteUrl,
        });
      } else {
        // build site+locale-specified pages
        const [
          publishableStories,
          nonPublishableStories,
        ] = await this.getStories(site, locale);

        this.publishableStories = publishableStories;
        this.nonPublishableStories = nonPublishableStories;
        this.partials = await this.getPartials(site, locale);

        for (const story of publishableStories) {
          await this.createPageFromStory(story, {
            site,
            locale,
            siteUrl,
            overrideLocaleHack,
          });

          await this.createContentMirroringStories({
            allowedLocales,
            locale,
            site,
            siteUrl,
            overrideLocaleHack,
          });
        }
      }
    }

    return [this.publishableStories, this.nonPublishableStories];
  };
}

module.exports = StoryblokPageCreator;
