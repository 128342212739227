import React from "react";
import Input from "../Input";

const InputPhoneNumber = (props) => {
  const { validateAgainst = {} } = props;
  const { phone } = validateAgainst;
  validateAgainst.phone = phone || true;
  const inputPhoneNumberProps = {
    ...props,
    type: "tel",
    validateAgainst: validateAgainst,
    fieldType: "string",
  };
  return <Input {...inputPhoneNumberProps} />;
};

export default InputPhoneNumber;
