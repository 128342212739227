// eslint-disable-next-line import/prefer-default-export
export const detectIeSupportClass = () => {
  if (typeof window !== "undefined" && window.navigator) {
    const ua = window.navigator.userAgent;
    if (ua.indexOf("Trident/7.0") > -1) {
      return "ie-support ie11";
    }
    if (ua.indexOf("Trident/6.0") > -1) {
      return "ie-support ie10";
    }
    if (ua.indexOf("Trident/5.0") > -1) {
      return "ie-support ie9";
    }
  }
  return null;
};
