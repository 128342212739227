import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MediaItems from "./MediaItems";
import { composeClassName } from "../../utilities";

class IconSocialMedia extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** The selection for the displayed icon */
    icon: PropTypes.oneOf([
      "socialTwitter",
      "socialPinterest",
      "email",
      "socialFacebook",
    ]).isRequired,
    title: PropTypes.string,
    /** The role of the icon, defaults to the role of an image */
    role: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    title: "",
    role: "img",
  };

  render() {
    const { icon, className, role, title } = this.props;
    const composedClassName = composeClassName([
      "IconPillar",
      title,
      role,
      className,
    ]);
    const iconLabel = `${icon}-label`;

    const mediaItem = MediaItems[icon];
    const { path } = mediaItem;

    return (
      <span className={composedClassName}>
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-labelledby={iconLabel}
          role={role}>
          <title id={iconLabel}>{title || `${icon} icon`}</title>
          <g stroke="none" strokeWidth="1" fill="#19294f" fillRule="evenodd">
            <rect
              id={`${icon}-icon`}
              fill="#D8D8D8"
              opacity="0"
              x="0"
              y="0"
              width="24"
              height="24"
            />
            {path}
          </g>
        </svg>
      </span>
    );
  }
}

export default IconSocialMedia;
