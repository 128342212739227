import React, { PureComponent } from "react";
import { Button } from "../../../shared/atoms";

class SubmitButton extends PureComponent {
  render() {
    const { Text, Theme, Size } = this.props;
    return (
      <Button type="submit" size={Size} theme={Theme}>
        {Text}
      </Button>
    );
  }
}

export default SubmitButton;
