import React, { PureComponent } from "react";
import contentToComponents from "../../utilities/contentToComponents";
import Block from "../Block";
import "./Partners.scss";

class Partners extends PureComponent {
  render() {
    const { content, locale } = this.props;
    const { Images } = content;

    return (
      <Block content={content} className="PartnersBlock">
        {contentToComponents(Images, { locale })}
      </Block>
    );
  }
}

export default Partners;
