/* eslint-disable no-unused-vars */
import React from "react";
import asFormElement from "../../asFormElement";
import { FormContext } from "../../Form";

// Hidden inputs should have no UI elements or anything - make simple one from scratch. D
const InputHidden = ({
  name,
  value,
  onChangeHandler,
  fieldType = "string",
}) => {
  return (
    <input type="hidden" name={name} value={value} onChange={onChangeHandler} />
  );
};

export default asFormElement(InputHidden, FormContext);
