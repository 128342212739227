import React from "react";
import PropTypes from "prop-types";

import { composeClassName } from "../../utilities";
import { BannerBackground, BodyText, DisplayText, Heading } from "../../atoms";
import ButtonLink from "../ButtonLink";

import "./BannerWEStories.scss";

const BannerWEStories = (props) => {
  const {
    isHeadingOne,
    buttonExternal,
    buttonHref,
    buttonText,
    buttonTheme,
    buttonTitle,
    className,
    description,
    imageDesktopSrc,
    imageMobileSrc,
    internalLinkComponent,
    subTitle,
    title,
    underlineColor,
    videoBackgroundSrc,
    whiteText,
  } = props;

  let subTitleComponent;

  if (subTitle) {
    subTitleComponent = (
      <div className="BannerWEStoriesSubtitle">
        <DisplayText size="S" whiteText={whiteText}>
          {subTitle}
        </DisplayText>
        <div
          className="Underline"
          style={{ backgroundColor: underlineColor }}
        />
      </div>
    );
  }

  let titleComponent;
  if (title) {
    const size = isHeadingOne ? "XL" : "L";
    titleComponent = (
      <div className="BannerWEStoriesTitle">
        <Heading size={size} whiteText={whiteText}>
          {title}
        </Heading>
      </div>
    );
  }

  let descriptionComponent;
  if (description) {
    descriptionComponent = (
      <div className="BannerWEStoriesDescription">
        <BodyText size="M" whiteText={whiteText}>
          {description}
        </BodyText>
      </div>
    );
  }

  let buttonComponent;
  if (buttonText) {
    buttonComponent = (
      <div className="BannerWEStoriesButton">
        <ButtonLink
          external={buttonExternal}
          href={buttonHref}
          theme={buttonTheme}
          title={buttonTitle}
          internalLinkComponent={internalLinkComponent}>
          {buttonText}
        </ButtonLink>
      </div>
    );
  }

  return (
    <div
      data-social-media="true"
      className={composeClassName(["BannerWEStories", className])}>
      <BannerBackground
        className="BannerWEStoriesBackground"
        imageDesktopSrc={imageDesktopSrc}
        imageMobileSrc={imageMobileSrc}
        videoBackgroundSrc={videoBackgroundSrc}>
        <div className="BannerWEStoriesContent">
          {subTitleComponent}
          {titleComponent}
          {descriptionComponent}
          {buttonComponent}
        </div>
      </BannerBackground>
    </div>
  );
};

BannerWEStories.propTypes = {
  isHeadingOne: PropTypes.bool,
  imageDesktopSrc: PropTypes.string.isRequired,
  imageMobileSrc: PropTypes.string,
  videoBackgroundSrc: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  underlineColor: PropTypes.string,
  description: PropTypes.string,
  whiteText: PropTypes.bool,
  buttonExternal: PropTypes.bool,
  buttonHref: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTheme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
  buttonTitle: PropTypes.string,
  internalLinkComponent: PropTypes.any,
  className: PropTypes.string,
};

BannerWEStories.defaultProps = {
  isHeadingOne: false,
  buttonTheme: "Primary",
  whiteText: false,
  internalLinkComponent: null,
  underlineColor: "#1d2530",
};

export default BannerWEStories;
