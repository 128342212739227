/* eslint-disable react/prefer-stateless-function */
import React from "react";
import PropTypes from "prop-types";

import { ButtonLink } from "../../molecules";
import { Container, Col } from "../../atoms";

import "./Page404.scss";

class Page404 extends React.PureComponent {
  static propTypes = {
    message: PropTypes.string.isRequired,
    homeURL: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    internalLinkComponent: PropTypes.any.isRequired,
  };

  render() {
    const { message, homeURL, buttonText, internalLinkComponent } = this.props;

    return (
      <Container>
        <Col className="Page404" xs={{ span: 12 }}>
          <div className="Circle">
            <h1>404</h1>
          </div>
          <p>{message}</p>

          <div className="Buttons">
            <ButtonLink
              href={homeURL}
              title="home"
              internalLinkComponent={internalLinkComponent}>
              {buttonText}
            </ButtonLink>
          </div>
        </Col>
      </Container>
    );
  }
}

export default Page404;
