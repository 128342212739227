import React, { PureComponent } from "react";
import { Accordion as SharedAccordion } from "../../../shared/molecules";
import Block from "../Block";
import Components from "../../components";

class BookingWidget extends PureComponent {
  render() {
    const { content } = this.props;
    const { BookingWidgetItems, Name } = content;

    const bookingWidgetItems = BookingWidgetItems.map((widgetItem, index) => {
      const { _uid, component, ...props } = widgetItem;
      return (
        <React.Fragment key={_uid}>
          <Block content={widgetItem}>
            {React.createElement(Components[component], {
              content: props,
              id: index,
              name: Name,
            })}
          </Block>
        </React.Fragment>
      );
    });

    return (
      <Block content={content} className="BookingWidgetBlock">
        <SharedAccordion name={Name}>{bookingWidgetItems}</SharedAccordion>
      </Block>
    );
  }
}

export default BookingWidget;
