/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { camelCase } from "lodash";

import { composeClassName } from "../../utilities";
import { BodyText, Logo, BaseLink, DisplayText } from "../../atoms";
import {
  ButtonIcon,
  LocaleSwitcher,
  NavigationMenu,
  NavigationItemMobile,
} from "../../molecules";
import "./Footer.scss";

class Footer extends Component {
  static propTypes = {
    allowedLocales: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        icon: PropTypes.oneOf(["flagCA", "flagUS", "flagGB"]),
      }),
    ),
    currentLocale: PropTypes.string.isRequired,
    className: PropTypes.string,
    columns: PropTypes.array,
    socialButtons: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        theme: PropTypes.oneOf(["Primary", "Tertiary"]),
        title: PropTypes.string,
        href: PropTypes.string,
        external: PropTypes.bool,
      }),
    ),
    copyrightInfo: PropTypes.string,
    legalLinks: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        href: PropTypes.string,
        external: PropTypes.bool,
        title: PropTypes.string,
      }),
    ),
    internalLinkComponent: PropTypes.any.isRequired,
    logo: PropTypes.oneOf(["We", "MeToWe", "IwthEnFooter", "IwthFrFooter"]),
    logoHref: PropTypes.string,
    logoExternal: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    columns: [],
    socialButtons: [],
    copyrightInfo: "",
    legalLinks: [],
    logo: "We",
    logoHref: "",
    logoExternal: true,
  };

  constructor(props) {
    super(props);
    this.state = this.initMenu();
  }

  initMenu = () => {
    const { columns } = this.props;
    const menuState = {};
    columns.forEach((column) => {
      menuState[camelCase(column.title)] = false;
    });
    return menuState;
  };

  handleClick = (key) => {
    this.setState((prevState) => {
      return { ...prevState, [key]: !prevState[key] };
    });
  };

  renderLegalLinks = () => {
    const { legalLinks, internalLinkComponent } = this.props;
    if (legalLinks) {
      return (
        <div className="FooterLegalLinks">
          {legalLinks.map((link, index) => {
            const { label, href, external, title } = link;
            const divider = (
              <span className="BaseLinkSeparator" key={`${link.key}-after`}>
                &nbsp;|&nbsp;
              </span>
            );

            return (
              <Fragment key={`legal-link-${index}`}>
                <BaseLink
                  className="LegalLink"
                  internalLinkComponent={internalLinkComponent}
                  href={href}
                  external={external}
                  title={title}>
                  <DisplayText size="M">{label}</DisplayText>
                </BaseLink>
                {index < legalLinks.length - 1 ? divider : null}
              </Fragment>
            );
          })}
        </div>
      );
    }
    return null;
  };

  renderSocialButtons = () => {
    const { socialButtons, internalLinkComponent } = this.props;
    if (socialButtons) {
      return (
        <div className="FooterSocialButtons">
          {socialButtons.map((socialButton) => {
            const { icon, theme, title, href, external } = socialButton;
            return (
              <ButtonIcon
                key={`social-button-${icon}`}
                className="SocialButton"
                internalLinkComponent={internalLinkComponent}
                icon={icon}
                theme={theme}
                title={title}
                href={href}
                external={external}
              />
            );
          })}
        </div>
      );
    }
    return null;
  };

  renderLinks = (links) => {
    const { internalLinkComponent } = this.props;
    return links.map((link, i) => {
      const { href, label, title: linkTitle, external } = link;
      return (
        <BaseLink
          key={`${label}-${i}`}
          href={href}
          external={external}
          title={linkTitle}
          internalLinkComponent={internalLinkComponent}>
          {label}
        </BaseLink>
      );
    });
  };

  renderColumns = () => {
    const { columns, internalLinkComponent } = this.props;
    if (columns) {
      const mobileColumns = columns.map((column, i) => {
        const { title: columnTitle } = column;
        const columnKey = camelCase(column.title);
        const isOpen = this.state[columnKey]; /* eslint-disable-line */
        const mobileLinks = this.renderLinks(column.links);
        return (
          <NavigationItemMobile
            itemKey={`Footer-${i}`}
            key={`${columnTitle}-${i}`}
            label={columnTitle}
            onClickHandler={() => {
              this.handleClick(columnKey);
            }}
            isOpen={isOpen}>
            {mobileLinks}
          </NavigationItemMobile>
        );
      });
      return (
        <div className="FooterColumns">
          <NavigationMenu
            columns={columns}
            internalLinkComponent={internalLinkComponent}
          />
          {mobileColumns}
        </div>
      );
    }
    return null;
  };

  renderCopyrightInfo = () => {
    const { copyrightInfo } = this.props;
    if (copyrightInfo) {
      return (
        <BodyText size="M" className="Copyright">
          {copyrightInfo}
        </BodyText>
      );
    }
    return null;
  };

  render() {
    const {
      overrideLocaleHack,
      allowedLocales,
      currentLocale,
      className,
      internalLinkComponent,
      logo,
      logoHref,
      logoExternal,
    } = this.props;

    const composedClassName = composeClassName(["Footer", className]);

    const legalLinkElements = this.renderLegalLinks();
    const socialButtonElements = this.renderSocialButtons();
    const footerColumns = this.renderColumns();
    const copyrightElement = this.renderCopyrightInfo();

    return (
      <footer className={composedClassName}>
        <div className="FooterContent">
          <div className="LogoWrapper">
            <Logo
              type={logo}
              href={logoHref}
              internalLinkComponent={internalLinkComponent}
              external={logoExternal}
            />
          </div>
          {footerColumns}
          {socialButtonElements}
          <div>
            {copyrightElement}
            <LocaleSwitcher
              overrideLocaleHack={overrideLocaleHack}
              allowedLocales={allowedLocales}
              currentLocale={currentLocale}
            />
          </div>
          {legalLinkElements}
        </div>
      </footer>
    );
  }
}

export default withTranslation("translations")(Footer);
