import React, { PureComponent } from "react";
import { Image, DisplayText, Heading } from "../../../shared/atoms";
import { TextWithBackground } from "../../../shared/molecules";
import { colors } from "../../../shared/colors";
import { GatsbyBaseTile } from "../../../sharedgatsby";
import { withStoryblokLink } from "../../utilities";
import "./EnhancedTile.scss";

class EnhancedTile extends PureComponent {
  render() {
    const { key, global, ...TextProps } = this.props;
    const {
      BackgroundColor,
      TextColor,
      Subtitle,
      Title,
      Link,
      LinkTitle,
      ...ImageProps
    } = TextProps;

    const imageInfo = {
      altText: ImageProps.AltText,
      longdesc: ImageProps.Longdesc,
      mobileSource: ImageProps.MobileImage,
      source: ImageProps.Image,
    };

    return (
      <GatsbyBaseTile
        md={{ span: 4 }}
        Link={Link}
        LinkTitle={LinkTitle}
        whiteText={TextColor.includes("white")}
        className="EnhancedTile">
        <Image className="EnhancedTileImage" {...imageInfo} />
        <div className="EnhancedText">
          <TextWithBackground
            color={colors[BackgroundColor].color}
            whiteText={TextColor.includes("white")}>
            <DisplayText size="S" className="Subtitle">
              {Subtitle}
            </DisplayText>
            <Heading size="S" className="Title">
              {Title}
            </Heading>
          </TextWithBackground>
        </div>
      </GatsbyBaseTile>
    );
  }
}

export default withStoryblokLink(EnhancedTile);
