import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Heading, BaseLink, Image } from "../../atoms";
import {
  TextWithBackground,
  ButtonLink,
  DisplayTextMarkdown,
} from "../../molecules";
import { composeClassName } from "../../utilities";

import "./LeadGenerator.scss";

class LeadGenerator extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    imageSource: PropTypes.string,
    imageLongDesc: PropTypes.string,
    mobileImageSource: PropTypes.string,
    imageAltText: PropTypes.string,
    /** The text title inside the text-with-background section */
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonTheme: PropTypes.string,
    buttonHref: PropTypes.string,
    buttonIsExternal: PropTypes.bool,
    displayText: PropTypes.string,
    contactLinkHref: PropTypes.string,
    contactLinkIsExternal: PropTypes.bool,
    contactLinkText: PropTypes.string,
    contactLinkTitle: PropTypes.string,
    /** The background color */
    color: PropTypes.string,
    whiteText: PropTypes.bool,
    internalLinkComponent: PropTypes.any.isRequired,
  };

  static defaultProps = {
    className: "",
    imageSource: null,
    imageAltText: null,
    buttonText: "",
    buttonTheme: "Primary",
    buttonIsExternal: false,
    displayText: "",
    contactLinkHref: "",
    contactLinkIsExternal: false,
    contactLinkText: "",
    contactLinkTitle: null,
    color: "#ffffff",
    whiteText: false,
  };

  render() {
    const {
      className,
      imageSource,
      imageLongDesc,
      mobileImageSource,
      imageAltText,
      title,
      buttonText,
      buttonTheme,
      buttonHref,
      buttonIsExternal,
      displayText,
      contactLinkHref,
      contactLinkIsExternal,
      contactLinkText,
      contactLinkTitle,
      color,
      whiteText,
      internalLinkComponent,
    } = this.props;

    const withImage = !!(imageSource && imageAltText);

    const composedClassName = composeClassName([
      "LeadGenerator",
      withImage ? "WithImage" : "",
      className,
    ]);

    let image = null;
    if (withImage) {
      image = (
        <Image
          className="LeadImage"
          altText={imageAltText}
          source={imageSource}
          mobileSource={mobileImageSource}
          longdesc={imageLongDesc}
        />
      );
    }

    let buttonLink;
    if (buttonText) {
      buttonLink = (
        <ButtonLink
          size="L"
          theme={buttonTheme}
          href={buttonHref}
          external={buttonIsExternal}
          internalLinkComponent={internalLinkComponent}
          className="LeadCTA CTAButton">
          {buttonText}
        </ButtonLink>
      );
    }

    let contactLink;
    if (contactLinkText) {
      contactLink = (
        <BaseLink
          className="ContactLink"
          href={contactLinkHref}
          external={contactLinkIsExternal}
          title={contactLinkTitle}
          internalLinkComponent={internalLinkComponent}>
          {contactLinkText}
        </BaseLink>
      );
    } else {
      contactLink = (
        <DisplayTextMarkdown
          size="S"
          className="ContactLink"
          internalLinkComponent={internalLinkComponent}
        />
      );
    }

    return (
      <div className={composedClassName}>
        {image}
        <TextWithBackground
          className="LeadTextSection"
          color={color}
          whiteText={whiteText}>
          <Heading centered className="LeadHeading" size="S">
            {title}
          </Heading>
          {buttonLink}
          <DisplayTextMarkdown
            size="S"
            className="LeadDisplayText"
            internalLinkComponent={internalLinkComponent}>
            {displayText}
          </DisplayTextMarkdown>
          {contactLink}
        </TextWithBackground>
      </div>
    );
  }
}

export default LeadGenerator;
