/* eslint-disable no-unused-vars */
import React from "react";
import TileLayoutGallery from "./TileLayoutGallery";
import image from "../../../shared/static/desktop2.png";

const TileLayoutGalleryVisualTest = () => {
  const tiles = [
    {
      name: "4-tiles",
      value: 4,
    },
    {
      name: "3-tiles",
      value: 3,
    },
  ];

  const descriptions = [
    {
      name: "With text",
      value:
        "Volutpat est velit egestas dui id. [google.com](http://google.com) Dolor sit amet.Volutpat est velit egestas dui id.",
    },
    {
      name: "Without text",
      value: null,
    },
  ];

  const centeredOpts = [
    { name: "Centered", value: true },
    { name: "Uncentered", value: false },
  ];

  const hoverOpts = [
    { name: "Hovered", value: "test-hover" },
    { name: "Not Hovered", value: "" },
  ];

  let key = 0;
  const components = [];
  tiles.forEach((tile, idx) => {
    centeredOpts.forEach((centered) => {
      descriptions.forEach((desc) => {
        // Test 1 case for Centered + W/O desc + 3-tile
        // Test Uncentered + W/ desc + 3-and-4-tiles
        const hover = idx === 0 ? hoverOpts[0] : hoverOpts[1];

        if (
          (centered.value && !desc.value && tile.value === 3) ||
          (!centered.value && desc.value)
        ) {
          const tileContent = {
            Description: desc.value,
            ImageSource: image,
            AltText: "altText",
            component: "ImageGalleryTile",
            textClassName: hover.value,
          };

          key++;
          const pageName = [
            tile.name,
            desc.name,
            centered.name,
            hover.name,
          ].join(" | ");
          const pageContent = (
            <>
              <h1 key={`${key}-h1`} className="VisualTestTitle">
                {pageName}
              </h1>
              <TileLayoutGallery
                key={key}
                content={{
                  Centered: centered.value,
                  Tiles: Array(tile.value).fill(tileContent),
                }}
              />
            </>
          );
          components.push(pageContent);
        }
      });
    });
  });
  return components;
};

export default TileLayoutGalleryVisualTest;
