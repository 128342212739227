import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { TabSection as SharedTabSection } from "../../../shared/organisms";
import contentToBlocks from "../../utilities/contentToBlocks";
import Block from "../Block";

class TabSection extends PureComponent {
  static propTypes = {
    content: PropTypes.shape({
      Centered: PropTypes.bool,
    }),
  };

  render() {
    const { content, partials } = this.props;

    const { Tabs, Centered } = content;

    if (Tabs.length === 0) {
      return <div>Please add tabs.</div>;
    }

    const tabsData = Tabs.map((tab) => {
      const tabComponent = contentToBlocks(tab.Content, { partials });

      return {
        key: tab._uid,
        text: tab.Label,
        content: tabComponent,
      };
    });

    return (
      <Block content={content} className="TabSectionBlock">
        <SharedTabSection tabs={tabsData} centered={Centered} />
      </Block>
    );
  }
}

export default TabSection;
