import React from "react";
import PropTypes from "prop-types";

import BodyTextMarkdown from "../BodyTextMarkdown";
import { composeClassName } from "../../utilities";
import { DisplayText, Heading } from "../../atoms";

import "./WidgetContent.scss";

const WidgetContent = (props) => {
  const {
    internalLinkComponent,
    subtitle,
    title,
    body,
    bodySize,
    buttons,
    centered,
    whiteText,
  } = props;

  let subtitleElem = null;
  if (subtitle) {
    subtitleElem = (
      <DisplayText size="S" centered={centered} whiteText={whiteText}>
        {subtitle}
      </DisplayText>
    );
  }

  let titleElem = null;
  if (title) {
    titleElem = (
      <Heading size="M" centered={centered} whiteText={whiteText}>
        {title}
      </Heading>
    );
  }

  let bodyElem = null;
  if (body) {
    bodyElem = (
      <BodyTextMarkdown
        internalLinkComponent={internalLinkComponent}
        size={bodySize}
        centered={centered}
        whiteText={whiteText}>
        {body}
      </BodyTextMarkdown>
    );
  }

  const buttonsClassName = centered ? "Centered" : "";

  return (
    <div className="WidgetContent">
      {subtitleElem}
      {titleElem}
      {bodyElem}
      <div
        className={composeClassName([
          "WidgetContentButtons",
          buttonsClassName,
        ])}>
        {buttons}
      </div>
    </div>
  );
};

WidgetContent.propTypes = {
  internalLinkComponent: PropTypes.any,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  bodySize: PropTypes.string.isRequired,
  whiteText: PropTypes.bool,
  centered: PropTypes.bool,
  buttons: PropTypes.array,
};

WidgetContent.defaultProps = {
  internalLinkComponent: null,
  subtitle: null,
  title: null,
  body: null,
  bodySize: "M",
  whiteText: false,
  centered: false,
  buttons: [],
};

export default WidgetContent;
