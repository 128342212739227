import React, { PureComponent } from "react";
import { NavigationSub } from "../../partials";

class NavigationSubContainer extends PureComponent {
  render() {
    const { localPartials, currentHref, environment, locale } = this.props;
    if (typeof localPartials.subnav === "undefined") {
      return (
        <div>
          <p>You have NOT created the &quot;subnav&quot; partial yet.</p>
          <p>
            Please create this partial in the ___partials folder with the exact
            slug &quot;subnav&quot;
          </p>
          <p>
            The ___partials folder should be created within the same folder of
            this page
          </p>
        </div>
      );
    }

    return (
      <NavigationSub
        environment={environment}
        content={localPartials.subnav}
        currentHref={currentHref}
        locale={locale}
      />
    );
  }
}

export default NavigationSubContainer;
