const removeLastSlash = (urls) => {
  return urls.map((url) => {
    if (url.slice(-1) === "/") {
      return url.slice(0, url.length - 1);
    }
    return url;
  });
};

const addFirstSlash = (urls) => {
  return urls.map((url) => {
    if (url[0] !== "/") {
      return `/${url}`;
    }
    return url;
  });
};

const removeLocale = (urls) => {
  return urls.map((url) => {
    const splitted = url.split("/");
    if (["en-CA", "fr-CA", "en-US", "en-GB"].includes(splitted[1])) {
      return `/${splitted.slice(2, splitted.length).join("/")}`;
    }
    return url;
  });
};

const addMirrorLocale = (locale) => {
  return (urls) => {
    return urls.map((url) => {
      const [_, urlLocale, ...restUrl] = url.split("/"); // eslint-disable-line no-unused-vars
      if (["en-CA", "fr-CA", "en-US", "en-GB"].includes(urlLocale)) {
        if (urlLocale !== locale) {
          return ["", locale, ...restUrl].join("/");
        }
      }
      return "";
    });
  };
};

const removeFalsyUrl = (urls) => {
  // FalsyUrl could be:
  // - ""
  // - undefined
  // - null
  return urls.filter((url) => {
    return !!url;
  });
};

const run = (urls, rulesFunctions) => {
  // functions order matters
  let sanitized = urls;
  rulesFunctions.forEach((func) => {
    sanitized = func(sanitized);
  });
  return sanitized;
};

const sanitize = (urls) => {
  const formattedUrls = run(urls, [removeLastSlash, addFirstSlash]);
  const sanitized = [
    // normal URLs
    ...formattedUrls,
    // default URLs without locale
    ...run(formattedUrls, [removeLocale]),
    // mirrored URLs in different locales
    ...run(formattedUrls, [addMirrorLocale("en-CA")]),
    ...run(formattedUrls, [addMirrorLocale("en-US")]),
    ...run(formattedUrls, [addMirrorLocale("en-GB")]),
    ...run(formattedUrls, [addMirrorLocale("fr-CA")]),
    ...["/404/*"], // static URLs
  ];

  // the sanitiazation process creates a number of falsy URLs,
  // that needs to be removed
  const nonFalsySanitized = run(sanitized, [removeFalsyUrl]);

  // using Set to remove duplication
  return Array.from(new Set(nonFalsySanitized));
};

module.exports = {
  addFirstSlash,
  addMirrorLocale,
  removeFalsyUrl,
  removeLastSlash,
  removeLocale,
  run,
  sanitize,
};
