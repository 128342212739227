// These fields are meant for saleforce stuff, where it must always be in this format.
import React, { PureComponent } from "react";
import { InputPhoneNumber as PhoneNumber } from "../../../shared/forms";

class InputPhoneNumber extends PureComponent {
  render() {
    const { Label, Required, Placeholder, Title } = this.props;
    const inputPhoneNumberProps = {
      ...this.props,
      name: "Contact.Phone",
      required: Required,
      label: Label,
      placeholder: Placeholder,
      title: Title,
    };
    return <PhoneNumber {...inputPhoneNumberProps} />;
  }
}

export default InputPhoneNumber;
