/* eslint-disable eqeqeq */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import _ from "lodash";

import { composeClassName, browser } from "../../../utilities";
import asFormElement from "../../asFormElement";
import { Label, Icon, BodyText } from "../../../atoms";
import { FormContext } from "../../Form";
import InputDropdownOption from "../InputDropdownOption/InputDropdownOption";
import "./InputDropdown.scss";

class InputDropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func,
    placeholder: PropTypes.string,
    /** List of option object */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.oneOf(["flagCA", "flagUS", "flagGB"]),
      }),
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fieldType: PropTypes.oneOf(["string"]),
    validate: PropTypes.bool,
    size: PropTypes.oneOf(["L", "M", "S"]),
  };

  static defaultProps = {
    disabled: false,
    error: false,
    fieldType: "string",
    size: "S",
    validate: true,
  };

  constructor(props) {
    super(props);
    this.state = { listOpen: false };
    const { options, placeholder } = props;

    this.inputRefs = {};
    this.dropdownRef = React.createRef();
    this.headerRef = React.createRef();

    if (placeholder) this.inputRefs[placeholder] = React.createRef();
    options.forEach((option) => {
      this.inputRefs[option.label] = React.createRef();
    });
  }

  componentDidMount() {
    browser.document.addEventListener(
      "mousedown",
      this._handleOutsideClick,
      true,
    );
  }

  componentWillUnmount() {
    browser.document.removeEventListener(
      "mousedown",
      this._handleOutsideClick,
      true,
    );
  }

  _toggleList = () => {
    this.setState(
      (prevState) => {
        return { listOpen: !prevState.listOpen };
      },
      () => {
        this.headerRef.current.focus();
      },
    );
  };

  _handleOutsideClick = (e) => {
    if (!this.dropdownRef.current) return;
    const { listOpen } = this.state;
    const isInsideClick = this.dropdownRef.current.contains(e.target);
    if (!isInsideClick && listOpen) {
      e.preventDefault();
      e.stopPropagation();
      this._toggleList();
    }
  };

  _changeRef = (num, keyChar) => {
    const refKeys = Object.keys(this.inputRefs);
    const currentRef = browser.document.activeElement;
    let refIndex = refKeys.findIndex((key) => {
      return currentRef === this.inputRefs[key].current;
    });
    if (refIndex === -1 && keyChar === "ArrowUp") {
      refIndex = 0;
    }

    /**
     * This cycles through all the refKeys infinitely
     * Example: refKeys = [0, 1, 2, 3];
     * Moving forward from 3rd index to 0th index
     * refKeys[(4 + (3 + 1)) % 4] = refKeys[8 % 4] = refKeys[ 0 ]
     */
    this.inputRefs[
      refKeys[(refKeys.length + (refIndex + num)) % refKeys.length]
    ].current.focus();
  };

  _selectOption = (args) => {
    const { onChangeHandler } = this.props;
    onChangeHandler({ name: args.name, value: args.value });
    this._toggleList();
  };

  _handleKeyPress = (event) => {
    const { name } = this.props;
    const { listOpen } = this.state;
    const {
      key,
      shiftKey,
      target: {
        dataset: { value },
      },
    } = event;
    const refKeys = Object.keys(this.inputRefs);

    let shouldPreventDefault = true;

    switch (key) {
      case " ":
      case "Enter":
        if (event.target !== this.headerRef.current) {
          this._selectOption({ name, value });
        } else {
          this._toggleList();
        }
        break;
      case "ArrowUp":
        if (listOpen) {
          this._changeRef(-1, key);
        } else {
          this._toggleList();
        }
        break;
      case "ArrowDown":
        if (listOpen) {
          this._changeRef(1);
        } else {
          this._toggleList();
        }
        break;
      // Esc is for IE 11 and edge, Escape everything else
      case "Esc":
      case "Escape":
        this._toggleList();
        break;
      case "Home":
        this.inputRefs[refKeys[0]].current.focus();
        break;
      case "End":
        this.inputRefs[refKeys[refKeys.length - 1]].current.focus();
        break;
      case "Tab":
        if (listOpen) {
          const { activeElement } = browser.document;
          const firstElement = this.inputRefs[refKeys[0]].current;
          const lastElement = this.inputRefs[refKeys[refKeys.length - 1]]
            .current;

          if (shiftKey) {
            if (
              activeElement === firstElement ||
              activeElement === this.headerRef.current
            ) {
              shouldPreventDefault = false;
              this._toggleList();
            } else {
              this._changeRef(-1);
            }
          } else if (activeElement === lastElement) {
            shouldPreventDefault = false;
            this._toggleList();
          } else {
            this._changeRef(1);
          }
        } else {
          shouldPreventDefault = false;
        }
        break;
      default:
        shouldPreventDefault = false;
        break;
    }

    if (shouldPreventDefault) event.preventDefault();
  };

  render() {
    const {
      className,
      disabled,
      error,
      name,
      value,
      options,
      placeholder,
      whiteText,
      label,
      approved,
      message,
      validate,
      size,
    } = this.props;
    const { listOpen } = this.state;

    const composedClassName = composeClassName([
      "InputDropdown",
      approved ? "approved" : "",
      error ? "error" : "",
      value === "" ? "placeholder" : "",
      disabled ? "disabled" : "",
      className,
    ]);

    const htmlFor = name;

    const optionsWithPlaceholder = [...options];
    if (placeholder) {
      optionsWithPlaceholder.unshift({ label: placeholder, value: "" });
    }

    const dropdownItems = optionsWithPlaceholder.map((option) => {
      const isSelected = option.value == value;
      return (
        <InputDropdownOption
          ref={this.inputRefs[option.label]}
          key={`option-${option.value}`}
          className={isSelected ? "Selected" : ""}
          selected={isSelected}
          name={name}
          value={option.value}
          label={option.label}
          icon={option.icon}
          onClickHandler={() => {
            this._selectOption({ name: name, value: option.value });
          }}
          onKeyDownHandler={(e) => {
            this._handleKeyPress(e);
          }}
        />
      );
    });

    const selectedDropdownItem = _.find(dropdownItems, (item) => {
      return item.props.value == value;
    });

    const ariaProps = {
      "aria-haspopup": "listbox",
      "aria-labelledby": `${name}-dropdown-label`,
      id: `${name}-dropdown-control`,
    };

    let headerItem = null;
    const defaultItem = selectedDropdownItem || dropdownItems[0];
    if (defaultItem) {
      headerItem = React.cloneElement(defaultItem, {
        ariaProps: ariaProps,
        ref: this.headerRef,
        selected: false,
        className: "InputDropdownHeaderItem",
        onClickHandler: (e) => {
          this._toggleList(e);
        },
        onKeyDownHandler: (e) => {
          return this._handleKeyPress(e);
        },
      });
    }

    const id = `${name}-dropdown-label`;

    const dropdownItemsToRender = listOpen ? (
      <ul className="InputDropdownOptions" role="listbox" aria-labelledby={id}>
        {dropdownItems}
      </ul>
    ) : null;

    const labelProps = { id, label, whiteText, htmlFor };

    let errorMessage;
    if (validate) {
      errorMessage = (
        <BodyText size={size} className="ErrorMessage">
          <Trans i18nKey={message}>{null}</Trans>
        </BodyText>
      );
    }

    return (
      <div ref={this.dropdownRef} className="InputDropdownContainer">
        <input type="hidden" name={name} value={value} />
        <Label {...labelProps}>
          <div
            className={composedClassName}
            ref={(node) => {
              this.node = node;
            }}>
            <div className="InputDropdownHeader">
              {headerItem}
              <Icon className="DropdownIcon" size={size} icon="chevronDown" />
            </div>
            <div aria-expanded={listOpen}>{dropdownItemsToRender}</div>
          </div>
          {errorMessage}
        </Label>
      </div>
    );
  }
}

export default asFormElement(InputDropdown, FormContext);
