import React, { PureComponent } from "react";
import { Accordion as SharedAccordion } from "../../../shared/molecules";
import Block from "../Block";
import contentToComponents from "../../utilities/contentToComponents";

class Pillars extends PureComponent {
  render() {
    const { content, locale } = this.props;
    const { Name, PillarItems } = content;

    return (
      <Block className="PillarBlock" content={content}>
        <SharedAccordion name={Name}>
          {contentToComponents(PillarItems, {
            name: Name,
            getIndex: true,
            locale: locale,
          })}
        </SharedAccordion>
      </Block>
    );
  }
}

export default Pillars;
