import React from "react";
import Block from "../Block";
import { Map } from "../../partials";

const MapContainer = ({ partials, content }) => {
  if (typeof partials.map === "undefined") {
    return (
      <div>
        <p>You have NOT created the &quot;map&quot; partial yet.</p>
        <p>
          Please create this partial in the ___partials folder with the exact
          slug &quot;map&quot;
          </p>
      </div>
    );
  }

  return (
    <Block content={content}>
      <Map content={partials.map} />
    </Block>
  );
}

export default MapContainer;
