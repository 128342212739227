import React, { useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import PropTypes from "prop-types";

import { browser } from "../../utilities";
import { InputDropdown } from "../../forms";

import "./LocaleSwitcher.scss";

const COOKIE_NAME = "selected_locale";

const LocaleSwitcher = (props) => {
  const { currentLocale, allowedLocales, overrideLocaleHack } = props;
  const [locale, setLocale] = useState(currentLocale);
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);

  const handleOnChange = (target) => {
    const targetLocale = target.value;
    setLocale(targetLocale);

    const date = new Date();
    const ttl = new Date(date.setHours(date.getHours() + 4));

    if (
      overrideLocaleHack &&
      overrideLocaleHack.locale &&
      overrideLocaleHack.url &&
      overrideLocaleHack.locale === targetLocale
    ) {
      browser.window.location.href = overrideLocaleHack.url;
      return;
    }

    if (cookies[COOKIE_NAME] !== targetLocale) {
      removeCookie(COOKIE_NAME);
      setCookie(COOKIE_NAME, targetLocale, { path: "/", expires: ttl });
    }
    browser.window.location.reload();
  };

  return (
    <CookiesProvider>
      <div className="LocaleSwitcher">
        {/* TODO - change icon to be country icon */}
        <InputDropdown
          name="locale-switcher"
          value={locale}
          options={allowedLocales}
          onChangeHandler={handleOnChange}
        />
      </div>
    </CookiesProvider>
  );
};

LocaleSwitcher.propTypes = {
  currentLocale: PropTypes.string,
  /** List of locales supported by the website, stored in config */
  allowedLocales: PropTypes.array,
};

LocaleSwitcher.defaultProps = {
  currentLocale: "en-CA",
  allowedLocales: [{ label: "CA (EN)", value: "en-CA", icon: "flagCA" }],
};

export default LocaleSwitcher;
