// These fields are meant for saleforce stuff, where it must always be in this format.
import React from "react";
import { InputText } from "../../../shared/forms";

const GenericInput = (props) => {
  const {
    Label,
    Placeholder,
    SalesforceField,
    Required,
    Title,
    ...rest
  } = props;
  return (
    <InputText
      label={Label}
      placeholder={Placeholder}
      name={SalesforceField}
      required={Required}
      title={Title}
      {...rest}
    />
  );
};

export default GenericInput;
