import * as Sentry from "@sentry/browser";
import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import "./GatsbyPageErrorBoundary.scss";

class GatsbyPageErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children, t } = this.props;

    if (error) {
      // fallback UI
      return (
        <h1 className="PageError">
          {t("GatsbyPageErrorBoundary__errorMessage")}
        </h1>
      );
    }

    return children;
  }
}

export default withTranslation("translations")(GatsbyPageErrorBoundary);
