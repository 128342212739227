import React, { PureComponent } from "react";
import { Link as GatsbyLink } from "gatsby";
import { GenericContentAligned } from "../../../shared/organisms";
import Block from "../Block";
import { withStoryblokLink, withMarkdownParser } from "../../utilities";

class ContentGenericAligned extends PureComponent {
  render() {
    const { content } = this.props;

    const {
      ContentAlignment,
      ContentTag,
      ContentTitle,
      ContentBody,
      ContentBodySize,
      ContentButton1Theme,
      ContentButton1Text,
      Link1,
      Link1Title,
      ContentButton2Theme,
      ContentButton2Text,
      Link2,
      Link2Title,
      ContentMobileImage,
      ContentImageSource,
      ContentAltText,
      ContentLongDesc,
      ContentVideoButtonTheme,
      ContentVideoId,
      ContentVideoSource,
      ContentVideoTitle,
      ContentImageRatio,
      ColumnLayout,
    } = content;

    return (
      <Block content={content} className="ContentGenericAlignedBlock">
        <GenericContentAligned
          alignment={ContentAlignment}
          internalLinkComponent={GatsbyLink}
          cols={parseInt(ColumnLayout, 10)}
          title={ContentTitle}
          subtitle={ContentTag}
          bodyText={ContentBody}
          bodySize={ContentBodySize}
          button1={{
            theme: ContentButton1Theme,
            text: ContentButton1Text,
            href: Link1.href,
            external: Link1.external,
            title: Link1Title,
          }}
          button2={{
            theme: ContentButton2Theme,
            text: ContentButton2Text,
            href: Link2.href,
            external: Link2.external,
            title: Link2Title,
          }}
          image={{
            source: ContentImageSource,
            mobileSource: ContentMobileImage,
            altText: ContentAltText,
            longDesc: ContentLongDesc,
            imageRatio: ContentImageRatio,
          }}
          video={{
            videoButtonTheme: ContentVideoButtonTheme,
            videoSource: ContentVideoSource,
            videoId: ContentVideoId,
            videoTitle: ContentVideoTitle,
          }}
        />
      </Block>
    );
  }
}

export default withMarkdownParser(
  withStoryblokLink(ContentGenericAligned, {
    LinkFields: ["Link1", "Link2"],
  }),
  { textFields: ["ContentBody"] },
);
