// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-storyblok-entry-jsx": () => import("/opt/build/repo/src/templates/StoryblokEntry.jsx" /* webpackChunkName: "component---src-templates-storyblok-entry-jsx" */),
  "component---src-pages-404-en-ca-jsx": () => import("/opt/build/repo/src/pages/404/en-CA.jsx" /* webpackChunkName: "component---src-pages-404-en-ca-jsx" */),
  "component---src-pages-404-en-gb-jsx": () => import("/opt/build/repo/src/pages/404/en-GB.jsx" /* webpackChunkName: "component---src-pages-404-en-gb-jsx" */),
  "component---src-pages-404-en-us-jsx": () => import("/opt/build/repo/src/pages/404/en-US.jsx" /* webpackChunkName: "component---src-pages-404-en-us-jsx" */),
  "component---src-pages-404-fr-ca-jsx": () => import("/opt/build/repo/src/pages/404/fr-CA.jsx" /* webpackChunkName: "component---src-pages-404-fr-ca-jsx" */),
  "component---src-pages-editor-js": () => import("/opt/build/repo/src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-visual-testing-page-index-js": () => import("/opt/build/repo/src/pages/VisualTestingPage/index.js" /* webpackChunkName: "component---src-pages-visual-testing-page-index-js" */),
  "component---src-pages-visual-testing-page-visual-testing-page-jsx": () => import("/opt/build/repo/src/pages/VisualTestingPage/VisualTestingPage.jsx" /* webpackChunkName: "component---src-pages-visual-testing-page-visual-testing-page-jsx" */)
}

