import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import { composeClassName } from "../../utilities";

import "./SlideIndicator.scss";
import "./IESupport.scss";

const Indicator = ({ className }) => {
  return <div className={className} />;
};

const usePreviousIndex = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const SlideIndicator = ({ numberOfSlides, currentSlideIndex }) => {
  if (numberOfSlides < 2) {
    return null;
  }

  const [transitionDirection, setTransitionDirection] = useState(false);
  const prevSlideIndex = usePreviousIndex(currentSlideIndex);

  useEffect(() => {
    if (prevSlideIndex < currentSlideIndex) {
      setTransitionDirection("next");
    } else if (prevSlideIndex > currentSlideIndex) {
      setTransitionDirection("previous");
    }
  }, [currentSlideIndex]);

  const getSelectedIndicatorIndex = () => {
    if (numberOfSlides <= 5) {
      return currentSlideIndex;
    }
    switch (currentSlideIndex) {
      case 0:
        return 0;
      case 1:
        return 1;
      case numberOfSlides - 2:
        return 3;
      case numberOfSlides - 1:
        return 4;
      default:
        return 2;
    }
  };

  const renderIndicators = () => {
    const selectedIndex = getSelectedIndicatorIndex();
    const numberOfIndicators = Math.min(numberOfSlides, 5) + 2;

    const indicators = [];
    for (let i = 0; i < numberOfIndicators; i++) {
      const className = composeClassName([
        "Indicator",
        selectedIndex + 1 === i ? "Selected" : "",
        selectedIndex === i ? "LeftOfSelected" : "",
        selectedIndex + 2 === i ? "RightOfSelected" : "",
        (i === 0 || i === numberOfIndicators - 1) && numberOfSlides <= 5
          ? "Hidden"
          : "",
      ]);

      const key = `indicator-${i}`;
      indicators.push(<Indicator key={key} className={className} />);
    }
    return indicators;
  };

  const getClassNames = () => {
    const transitionClassNames = ["SlideIndicatorTransition"];
    transitionClassNames.push(numberOfSlides > 5 ? "IESupportIndicators" : "");
    if (
      transitionDirection === "previous" &&
      currentSlideIndex > 1 &&
      currentSlideIndex < numberOfSlides - 3
    ) {
      transitionClassNames.push("TranslateRight");
    } else if (
      transitionDirection === "next" &&
      currentSlideIndex < numberOfSlides - 2 &&
      currentSlideIndex > 2
    ) {
      transitionClassNames.push("TranslateLeft");
    }
    return composeClassName(transitionClassNames);
  };

  return (
    <div className="SlideIndicator">
      <CSSTransition
        className={getClassNames()}
        timeout={500}
        in={!!transitionDirection}
        onEntered={() => {
          setTransitionDirection(false);
        }}>
        <div>{renderIndicators()}</div>
      </CSSTransition>
    </div>
  );
};

SlideIndicator.propTypes = {
  numberOfSlides: PropTypes.number,
  currentSlideIndex: PropTypes.number,
};

SlideIndicator.defaultProps = {
  numberOfSlides: 0,
  currentSlideIndex: 0,
};

export default SlideIndicator;
