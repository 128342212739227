/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./Label.scss";

class Label extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** Indicates to which element ID this label referred */
    htmlFor: PropTypes.string.isRequired,
    /** Text to be rendered */
    label: PropTypes.string,
    whiteText: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    label: null,
    whiteText: false,
  };

  render() {
    const { className, htmlFor, children, label, whiteText } = this.props;
    const composedClassName = composeClassName(["Label", className]);
    const spanClassName = composeClassName([
      "LabelSpan",
      whiteText ? "whiteText" : "",
    ]);
    return (
      <label htmlFor={htmlFor} className={composedClassName}>
        <span className={spanClassName}>{label}</span>
        {children}
      </label>
    );
  }
}

export default Label;
