import React, { PureComponent } from "react";
import { DisplayText, Heading } from "../../../shared/atoms";
import { TextWithBackground } from "../../../shared/molecules";
import { StringUtils } from "../../../shared/utilities";
import { colors } from "../../../shared/colors";
import { GatsbyBaseTile } from "../../../sharedgatsby";
import { withStoryblokLink } from "../../utilities";
import "./TextTile.scss";

class TextTile extends PureComponent {
  render() {
    const { key, global, ...TextProps } = this.props;
    const {
      BackgroundColor,
      TextColor,
      Tag,
      Title,
      Tagline,
      Link,
      LinkTitle,
    } = TextProps;
    return (
      <GatsbyBaseTile
        xs={{ span: 6 }}
        md={{ span: 3 }}
        LinkTitle={LinkTitle}
        Link={Link}
        whiteText={TextColor.includes("white")}
        className="TextTile">
        <TextWithBackground
          color={colors[BackgroundColor].color}
          whiteText={TextColor.includes("white")}>
          <DisplayText size="M" className="Tag">
            {StringUtils.formatMax(Tag, 20)}
          </DisplayText>
          <Heading size="S" className="Title">
            {StringUtils.formatMax(Title, 30)}
          </Heading>
          <DisplayText size="L" className="Tagline">
            {StringUtils.formatMax(Tagline, 30)}
          </DisplayText>
        </TextWithBackground>
      </GatsbyBaseTile>
    );
  }
}

export default withStoryblokLink(TextTile, ["Link"]);
