import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import { BaseLink } from "../../atoms";
import "./TabSectionLink.scss";

class TabSectionLink extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    href: PropTypes.string,
    external: PropTypes.bool,
    internalLinkComponent: PropTypes.any,
    title: PropTypes.string,
    blank: PropTypes.bool,
    onClickHandler: PropTypes.func,
    tabIndex: PropTypes.string,
    whiteText: PropTypes.bool,
    sectionId: PropTypes.string,
  };

  static defaultProps = {
    active: false,
  };

  render() {
    const {
      className,
      active,
      href,
      external,
      internalLinkComponent,
      title,
      blank,
      onClickHandler,
      tabIndex,
      whiteText,
      children,
      sectionId,
    } = this.props;

    const linkProps = {
      active,
      href,
      external,
      internalLinkComponent,
      title,
      blank,
      onClickHandler,
      tabIndex,
      whiteText,
    };

    const composedClassName = composeClassName([
      "TabSectionLink",
      active ? "active" : "",
      className,
    ]);

    return (
      <div className={composedClassName}>
        <BaseLink
          className="Link"
          a11yProps={{
            id: `tab-${sectionId}`,
            role: "tab",
            "aria-controls": sectionId,
            "aria-selected": active,
          }}
          {...linkProps}>
          {children}
        </BaseLink>
      </div>
    );
  }
}

export default TabSectionLink;
