import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { colors } from "../../colors";
import { BodyText, DisplayText } from "../../atoms";
import { composeClassName } from "../../utilities";
import "./Testimonial.scss";

class Testimonial extends PureComponent {
  static propTypes = {
    source: PropTypes.string.isRequired,
    sourceDescription: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    backgroundColor: PropTypes.oneOf([
      "brand-navy-color",
      "brand-yellow-color",
      "base-light-blue-color",
    ]),
    backgroundImage: PropTypes.string,
    className: PropTypes.string,
    sourceSize: PropTypes.oneOf(["S", "M", "L"]),
    textSize: PropTypes.oneOf(["S", "M", "L"]),
    whiteText: PropTypes.bool,
  };

  static defaultProps = {
    backgroundColor: "brand-navy-color",
    source: "Source",
    sourceDescription: "Source Description",
    sourceSize: "S",
    text: "Quote",
    textSize: "M",
  };

  render() {
    const {
      backgroundColor,
      backgroundImage,
      className,
      source,
      sourceDescription,
      sourceSize,
      text,
      textSize,
      whiteText,
    } = this.props;

    const composedClassName = composeClassName(["Testimonial", className]);

    let background;
    if (backgroundImage) {
      background = {
        backgroundImage: `url(${backgroundImage})`,
      };
    } else {
      background = {
        backgroundColor: colors[backgroundColor].color,
      };
    }

    return (
      <div className={composedClassName} style={background}>
        <BodyText
          className="TestimonialQuote"
          size={textSize}
          whiteText={whiteText}
          centered>
          {text}
        </BodyText>
        <DisplayText
          className="TestimonialSource"
          size={sourceSize}
          whiteText={whiteText}
          centered>
          {source}
          <br />
          {sourceDescription}
        </DisplayText>
      </div>
    );
  }
}

export default Testimonial;
