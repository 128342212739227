import React, { PureComponent } from "react";
import Block from "../Block";
import { ReadMoreLayout as SharedReadMoreLayout } from "../../../shared/molecules";
import contentToBlocks from "../../utilities/contentToBlocks";
import "./ReadMoreLayout.scss";

class ReadMoreLayout extends PureComponent {
  render() {
    const { content } = this.props;
    const {
      OpenText: ReadLess,
      ClosedText: ReadMore,
      MainContent,
      Label,
    } = content;

    return (
      <Block content={content} className="ReadMoreLayoutBlock">
        <SharedReadMoreLayout
          readLess={ReadLess}
          readMore={ReadMore}
          label={Label}>
          {contentToBlocks(MainContent)}
        </SharedReadMoreLayout>
      </Block>
    );
  }
}

export default ReadMoreLayout;
