import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName, StringUtils } from "../../utilities";
import { DisplayText, BodyText } from "../../atoms";
import "./AccordionItem.scss";
import ButtonIcon from "../ButtonIcon/ButtonIcon";

class AccordionItem extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** DEPRECATED: The title displayed on the accordion item */
    title: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    defaultOpen: PropTypes.bool,
    /** The content of the top of the accordion, which displays when the accordion is collapsed */
    header: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  static defaultProps = {
    className: "",
    defaultOpen: false,
    header: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      open: props.defaultOpen,
    };
  }

  onClickHandler = () => {
    // Toggle state of being open
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  };

  render() {
    const {
      header,
      children,
      className,
      id,
      name,
      title,
      ...rest
    } = this.props;
    const { open } = this.state;
    const composedClassName = composeClassName([
      "AccordionItem",
      !open ? "Collapsed" : "",
      className,
    ]);

    let headerElements = header;
    if (title) {
      headerElements = (
        <DisplayText size="L" className="AccordionItemTitle">
          {StringUtils.formatMax(title, 110)}
        </DisplayText>
      );
    }

    let content = children ? <div>{children}</div> : null;

    if (typeof children === "string") {
      content = <BodyText size="M">{children}</BodyText>;
    }

    return (
      <li className={composedClassName} data-test-uuid={rest["data-test-uuid"]}>
        <div className="AccordionItemHeader">
          {headerElements}
          <div className="AccordionItemIcon">
            <ButtonIcon
              onClickHandler={this.onClickHandler}
              type="button"
              title={open ? "collapse" : "expand"}
              icon={open ? "chevronUp" : "chevronDown"}
              size="L"
              theme="Tertiary"
              a11yProps={{
                id: `${name}-control-${id}`,
                "aria-expanded": open,
                "aria-controls": `${name}-content-${id}`,
              }}
            />
          </div>
        </div>
        <div
          className={`AccordionItemContent ${!open ? "Collapsed" : ""}`}
          id={`${name}-content-${id}`}
          aria-hidden={!open}
          aria-labelledby={`${name}-control-${id}`}>
          {content}
        </div>
      </li>
    );
  }
}

export default AccordionItem;
