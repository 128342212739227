/* eslint-disable */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { composeClassName } from "../../utilities";
import { Heading, BodyText, DisplayText, Image } from "../../atoms";
import { AccordionItem, ButtonLink, KeyValuePair } from "../../molecules";

import "./BookingWidget.scss";

class BookingWidget extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** The name of the trip (Displayed) */
    trip: PropTypes.string.isRequired,
    /** The price of the trip (Displayed at the top) */
    price: PropTypes.number.isRequired,
    /** Optional footnote (Displayed) */
    footnote: PropTypes.string,
    /** Status of the trip - deposit and button link will change based on this (Displayed) */
    status: PropTypes.oneOf(["Upcoming", "Active", "Sold Out"]).isRequired,
    /** Age group (Displayed) */
    ageGroup: PropTypes.string.isRequired,
    /** Departure Location (Displayed) */
    departure: PropTypes.string.isRequired,
    /** Cost (Displayed) */
    costWithFlight: PropTypes.number.isRequired,
    /** Cost (Displayed) */
    costWithoutFlight: PropTypes.number.isRequired,
    /** Initial Deposit - the deposit displayed will change based on start date info / status */
    initialDeposit: PropTypes.number.isRequired,

    /** The salesforce information (Not displayed) */
    salesforceTripId: PropTypes.string.isRequired,
    tripName: PropTypes.string.isRequired,
    tripDestination: PropTypes.string.isRequired,
    tripDepartureDate: PropTypes.string.isRequired,
    tripReturnDate: PropTypes.string.isRequired,
    fullCalculatedTripCost: PropTypes.number.isRequired,

    /** Current Date, only used to overwrite */
    forcedCurrentDate: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    footnote: null,
  };

  _formatPrice = (price) => {
    return `$${Math.floor(price).toLocaleString()}`;
  };

  _fixEncodeURIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
      return `%${c.charCodeAt(0).toString(16)}`;
    });
  };

  render() {
    const {
      className,
      trip,
      price,
      footnote,
      status,
      ageGroup,
      departure,
      costWithFlight,
      costWithoutFlight,
      initialDeposit,
      id,
      name,
      imageAltText,
      imageLongDesc,
      imageMobileSource,
      imageSource,
      linkTitle,
      salesforceTripId,
      tripName,
      tripDestination,
      tripDepartureDate,
      tripReturnDate,
      fullCalculatedTripCost,
      forcedCurrentDate,
      t,
      ...rest
    } = this.props;

    const imageProps = {
      altText: imageAltText,
      longDesc: imageLongDesc,
      mobileSource: imageMobileSource,
      source: imageSource,
    };

    const dateParams = tripDepartureDate.substring(0, 10).split("-");
    const startDate = new Date(dateParams[0], dateParams[1] - 1, dateParams[2]);
    const currentDate = forcedCurrentDate ? forcedCurrentDate : new Date();

    const dateDifference =
      (startDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);

    // deposit display
    let depositDisplay = initialDeposit;
    if (dateDifference < 120) {
      depositDisplay =
        (parseInt(costWithFlight) - parseInt(initialDeposit)) / 2 +
        parseInt(initialDeposit);
    }
    if (dateDifference < 90) {
      depositDisplay = costWithFlight;
    }

    // button display logic and tripType logic (used in button link)
    let buttonText = t("BookingWidget__ButtonText__bookNow");
    let tripType;

    if (status === "Upcoming") {
      buttonText = t("BookingWidget__ButtonText__reserveASpot");
      tripType = "rl";
    }
    if (dateDifference < 90 || status === "Sold Out") {
      buttonText = t("BookingWidget__ButtonText__waitList");
      tripType = "wl";
    }

    // Book Now URL
    let bookingHref = `https://portal.metowe.com/booking?cmd=reset&salesforce_trip_id=${salesforceTripId}`;

    // Upcoming/Waitlist URL
    if (tripType === "rl" || tripType === "wl") {
      const linkParams = {
        type: tripType,
        tid: salesforceTripId,
        tn: tripName,
        td: tripDestination,
        tdd: tripDepartureDate,
        trd: tripReturnDate,
        fc: fullCalculatedTripCost,
        deposit_cost: depositDisplay,
      };

      const queryString = Object.keys(linkParams)
        .map((key) => {
          return `${this._fixEncodeURIComponent(
            key,
          )}=${this._fixEncodeURIComponent(linkParams[key])}`;
        })
        .join("&");

      bookingHref = `https://forms.metowe.com/metowe/trip_waitlist?cmd=reset&${queryString}`;
    }

    const composedClassName = composeClassName(["BookingWidget", className]);

    const accordionHeader = (
      <div className="headerContent">
        <div className="tripDate">
          <Image className="headingImage" {...imageProps} />
          <div>
            <Heading size="XS" className="trip">
              {trip}
            </Heading>
            <DisplayText size="S" className="dates">
              {tripDepartureDate.substring(0, 10)}
              {" - "}
              {tripReturnDate.substring(0, 10)}
            </DisplayText>
          </div>
        </div>
        <div className="tripPrice">
          <div>
            <DisplayText size="S"> Starting at:</DisplayText>
            <Heading size="S" className="price">
              {`${this._formatPrice(price)}*`}
            </Heading>
          </div>
          <ButtonLink
            theme="Primary"
            size="L"
            external
            href={bookingHref}
            title={linkTitle}>
            {buttonText}
          </ButtonLink>
        </div>
      </div>
    );

    const columnOne = (
      <ul className="infoColumn">
        <KeyValuePair
          title={t("BookingWidget__InfoColumn__status")}
          value={status}
          className="infoColumnStatus"
        />
        <KeyValuePair
          title={t("BookingWidget__InfoColumn__ageGroup")}
          value={ageGroup}
          className="infoColumnGroup"
        />
        <KeyValuePair
          title={t("BookingWidget__InfoColumn__departure")}
          value={departure}
          className="infoColumnDeparture"
        />
      </ul>
    );

    const columnTwo = (
      <ul className="infoColumn Two">
        <KeyValuePair
          className="infoColumnCostWithFlights"
          title={t("BookingWidget__InfoColumn__costWithFlight")}
          value={this._formatPrice(costWithFlight)}
        />
        <KeyValuePair
          className="infoColumnCostWithoutFlights"
          title={t("BookingWidget__InfoColumn__costWithoutFlight")}
          value={this._formatPrice(costWithoutFlight)}
        />
        <KeyValuePair
          className="infoColumnDeposit"
          title={t("BookingWidget__InfoColumn__deposit")}
          value={this._formatPrice(depositDisplay)}
        />
      </ul>
    );

    const priceInfo = <BodyText size="S">{footnote}</BodyText>;

    return (
      <AccordionItem
        className={composedClassName}
        header={accordionHeader}
        id={id}
        name={name}
        data-test-uuid={rest["data-test-uuid"]}>
        <div className="content">
          {columnOne}
          {columnTwo}
        </div>
        {priceInfo}
      </AccordionItem>
    );
  }
}

export default withTranslation("translations")(BookingWidget);
