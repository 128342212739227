import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Image, Heading } from "../../atoms";
import { BodyTextMarkdown } from "../../molecules";

import "./ProfileContent.scss";

class ProfileContent extends PureComponent {
  static propTypes = {
    source: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
    titleSize: PropTypes.oneOf(["XS", "S", "M", "L", "XL"]),
    bodyText: PropTypes.string,
    bodyTextSize: PropTypes.oneOf(["S", "M", "L"]),
    readMore: PropTypes.node,
    alignment: PropTypes.oneOf(["Right", "Left"]),
    internalLinkComponent: PropTypes.any,
  };

  static defaultProps = {
    readMore: null,
    alignment: "Right",
    titleSize: "S",
    bodyTextSize: "S",
  };

  render() {
    const {
      source,
      altText,
      title,
      titleSize,
      bodyText,
      bodyTextSize,
      readMore,
      alignment,
      internalLinkComponent,
    } = this.props;

    let titleElem = null;
    if (title) {
      titleElem = <Heading size={titleSize}>{title}</Heading>;
    }

    let bodyTextElem = null;
    if (bodyText) {
      bodyTextElem = (
        <BodyTextMarkdown
          size={bodyTextSize}
          className="ProfileContentBody"
          internalLinkComponent={internalLinkComponent}>
          {bodyText}
        </BodyTextMarkdown>
      );
    }

    let content;
    if (alignment === "Right") {
      content = (
        <React.Fragment>
          {source && altText ? (
            <Image source={source} altText={altText} circular />
          ) : null}
          <div className="TextContent">
            {titleElem}
            {bodyTextElem}
          </div>
          {readMore}
        </React.Fragment>
      );
    } else {
      content = (
        <React.Fragment>
          <div className="TextContent">
            {titleElem}
            {bodyTextElem}
          </div>
          {readMore}
          {source && altText ? (
            <Image source={source} altText={altText} circular />
          ) : null}
        </React.Fragment>
      );
    }

    return <div className="ProfileContent">{content}</div>;
  }
}

export default ProfileContent;
