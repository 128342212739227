import React, { PureComponent } from "react";
import {
  Image as ImageComponent,
  BodyText as Bodytext,
  Heading,
} from "../../../shared/atoms";
import { StringUtils } from "../../../shared/utilities";
import GatsbyBaseTile from "../../../sharedgatsby/GatsbyBaseTile";
import { withStoryblokLink } from "../../utilities";
import "./RoundTile.scss";

class RoundTile extends PureComponent {
  render() {
    const { key, global, Link, LinkTitle, ...RoundProps } = this.props;
    const { MobileImage, Image, AltText, LongDesc, ...rest } = RoundProps;
    const { Title, BodyText } = rest;
    return (
      <GatsbyBaseTile
        Link={Link}
        LinkTitle={LinkTitle}
        md={{ span: 4 }}
        className="RoundTile">
        <ImageComponent
          circular
          mobileSource={MobileImage}
          source={Image}
          longDesc={LongDesc}
          altText={AltText}
        />
        <div className="RoundTextWrapper">
          <Heading size="S" className="TitleText" centered>
            {StringUtils.formatMax(Title, 50)}
          </Heading>
          <Bodytext size="M" centered>
            {StringUtils.formatMax(BodyText, 120)}
          </Bodytext>
        </div>
      </GatsbyBaseTile>
    );
  }
}

export default withStoryblokLink(RoundTile);
