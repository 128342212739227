import React from "react";

import BasePage from "../BasePage";
import { Navigation, Footer } from "../../partials";

const LOGO_HREF = "https://metowe.com";

const TyiPage = (props) => {
  const { allowedLocales, environment, partials, locale } = props;

  return (
    <BasePage
      template={{
        navigation: (
          <Navigation
            environment={environment}
            logoExternal
            content={partials.navigation}
            logoHref={LOGO_HREF}
            currentLocale={locale}
          />
        ),
        footer: (
          <Footer
            allowedLocales={allowedLocales}
            logoExternal
            content={partials.footer}
            logoHref={LOGO_HREF}
            currentLocale={locale}
          />
        ),
      }}
      {...props}
    />
  );
};

export default TyiPage;
