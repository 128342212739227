import { createLiveAgent } from "./createLiveAgent/createLiveAgent";
import generateFormName from "./generateFormName/generateFormName";
import {
  extractStoryblokUrl,
  getRealStoryblokUrl,
} from "./extractStoryblokUrl";
import { camelize } from "./camelize/camelize";
import { compileDataAttributes } from "./compileDataAttributes/compileDataAttributes";
import browser from "./browser";
import composeClassName from "./composeClassName/composeClassName";
import parseQuery from "./parseQuery/parseQuery";
import StringUtils from "./StringUtils/StringUtils";
import { shouldDisplayLocaleSwitcher } from "./localization";

export {
  StringUtils,
  browser,
  camelize,
  compileDataAttributes,
  composeClassName,
  createLiveAgent,
  extractStoryblokUrl,
  generateFormName,
  getRealStoryblokUrl,
  parseQuery,
  shouldDisplayLocaleSwitcher,
};
