import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MarkdownParser from "../MarkdownParser";
import { composeClassName } from "../../utilities";

import "./DisplayTextMarkdown.scss";

class DisplayTextMarkdown extends PureComponent {
  static propTypes = {
    /** CSS class to be added along with default classes */
    className: PropTypes.string,
    /** The selection for the size of the text */
    size: PropTypes.oneOf(["L", "M", "S"]).isRequired,
    /** If true, the text is centered; otherwise it is left-aligned */
    centered: PropTypes.bool,
    /** Color of the text, defaults to brand navy. */
    whiteText: PropTypes.bool,
    internalLinkComponent: PropTypes.any,
  };

  static defaultProps = {
    centered: false,
    className: "",
    whiteText: false,
  };

  render() {
    const {
      children,
      size,
      centered,
      className,
      whiteText,
      internalLinkComponent,
    } = this.props;
    const composedClassName = composeClassName([
      `DisplayTextMarkdown${centered ? " Centered" : ""}`,
      size,
      className,
      whiteText ? "whiteText" : "",
    ]);

    return (
      <MarkdownParser
        className={composedClassName}
        internalLinkComponent={internalLinkComponent}>
        {children}
      </MarkdownParser>
    );
  }
}

export default DisplayTextMarkdown;
