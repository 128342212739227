import React from "react";
import PropTypes from "prop-types";
import { BannerBackground, Col } from "../../atoms";
import { DonationWidget } from "..";

import "./BannerDonationWidget.scss";
import "./IESupport.scss";

const BannerDonationWidget = (props) => {
  const {
    className,
    imageDesktopSrc,
    imageMobileSrc,
    imageOverlay,
    videoBackgroundSrc,
    ...donationWidgetProps
  } = props;

  const bannerClassName = imageOverlay ? "ImageOverlay" : "";

  return (
    <div data-social-media="true" className="BannerDonationWidget">
      <BannerBackground
        className={bannerClassName}
        imageDesktopSrc={imageDesktopSrc}
        imageMobileSrc={imageMobileSrc}
        imageOverlay={imageOverlay}
        videoBackgroundSrc={videoBackgroundSrc}>
        <Col xs={{ span: 12 }} md={{ offset: 6 }} xl={{ offset: 7 }}>
          <DonationWidget {...donationWidgetProps} />
        </Col>
      </BannerBackground>
    </div>
  );
};

BannerDonationWidget.propTypes = {
  className: PropTypes.string,
  imageDesktopSrc: PropTypes.string.isRequired,
  imageMobileSrc: PropTypes.string,
  videoBackgroundSrc: PropTypes.string,
};

export default BannerDonationWidget;
