// These fields are meant for saleforce stuff, where it must always be in this format.
import React, { PureComponent } from "react";
import { InputText } from "../../../shared/forms";

class InputLastName extends PureComponent {
  render() {
    const { Label, Placeholder } = this.props;
    const lnameProps = {
      ...this.props,
      ...{
        name: "last_name",
        required: true,
        label: Label,
        placeholder: Placeholder,
        title: "Last Name",
      },
    };
    return <InputText {...lnameProps} />;
  }
}

export default InputLastName;
