import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Col, Heading } from "../../../shared/atoms";
import Block from "../Block";

class ParagraphHeader extends PureComponent {
  static propTypes = {
    content: PropTypes.shape({
      Text: PropTypes.string.isRequired,
      Size: PropTypes.string.isRequired,
      Columns: PropTypes.number,
      Centered: PropTypes.bool,
    }),
  };

  render() {
    const { content } = this.props;
    const { Text, Size, Columns, Centered } = content;
    const colOffset = (12 - parseInt(Columns, 10)) / 2;

    return (
      <Block content={content} className="ParagraphHeaderBlock">
        <Col
          xs={{ span: 12 }}
          md={{ span: parseInt(Columns, 10), offset: colOffset }}>
          <Heading centered={Centered} size={Size}>
            {Text}
          </Heading>
        </Col>
      </Block>
    );
  }
}

export default ParagraphHeader;
