import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  composeClassName,
  compileDataAttributes,
  browser,
} from "../../utilities";

import replaceStoryblokCdn from "../../../components/utilities/replaceStoryblokCdn";

import "./BaseLink.scss";

class BaseLink extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** The link destination - a relative path for internal links, or absolute for external links */
    href: PropTypes.string,
    /** Whether or not the link's destination is on a separate site */
    external: PropTypes.bool,
    /** The component used as the internal link handler when `internal` is true */
    internalLinkComponent: PropTypes.any,
    /** The title attribute of the anchor tag */
    title: PropTypes.string,
    /** If true, the link will always appear as active */
    active: PropTypes.bool,
    /** If true, the link will have no destination and can have an onClickHandler */
    blank: PropTypes.bool,
    /** The onClick function for a blank link */
    onClickHandler: PropTypes.func,
    tabIndex: PropTypes.string,
    whiteText: PropTypes.bool,
    unstyled: PropTypes.bool,
    /** Open link in new tab if true */
    newTab: PropTypes.bool,
  };

  static defaultProps = {
    active: false,
    blank: false,
    href: "",
    external: false,
    internalLinkComponent: null,
    title: "",
    onClickHandler: null,
    unstyled: false,
    whiteText: false,
    newTab: false,
  };

  render() {
    const {
      active,
      blank,
      className,
      children,
      href,
      external,
      internalLinkComponent,
      onClickHandler,
      tabIndex,
      title,
      unstyled,
      whiteText,
      a11yProps,
    } = this.props;

    const composedClassName = composeClassName([
      "BaseLink",
      active ? "active" : "",
      className,
      whiteText ? "whiteText" : "",
      unstyled ? "" : "linkStyled",
    ]);
    const dataAttributeProps = compileDataAttributes(this.props);

    const hrefFormatted = replaceStoryblokCdn(href);

    if (blank) {
      return (
        <button
          {...a11yProps}
          type="button"
          title={title}
          className={composedClassName}
          {...dataAttributeProps}
          {...(tabIndex ? { tabIndex } : {})}
          onClick={onClickHandler}>
          {children}
        </button>
      );
    }

    if (!external) {
      // You can't render a lowercase react class like this.
      const LinkComponent = internalLinkComponent;

      let sameLinkHandler = null;
      if (browser.window.location.pathname === hrefFormatted) {
        sameLinkHandler = () => {
          browser.window.location.reload();
        };
      }

      return (
        <LinkComponent
          title={title}
          className={composedClassName}
          to={hrefFormatted}
          {...dataAttributeProps}
          {...(tabIndex ? { tabIndex } : {})}
          onClick={sameLinkHandler}>
          {children}
        </LinkComponent>
      );
    }

    return (
      <a
        title={title}
        className={composedClassName}
        {...dataAttributeProps}
        href={hrefFormatted}
        onClick={onClickHandler}
        {...(tabIndex ? { tabIndex } : {})}>
        {children}
      </a>
    );
  }
}

export default BaseLink;
