import React, { PureComponent } from "react";
import { Accordion as SharedAccordion } from "../../../shared/molecules";
import Block from "../Block";
import contentToComponents from "../../utilities/contentToComponents";

class Accordion extends PureComponent {
  render() {
    const { content, locale } = this.props;
    const { Name, AccordionItems } = content;

    return (
      <Block className="AccordionBlock" content={content}>
        <SharedAccordion name={Name}>
          {contentToComponents(AccordionItems, {
            name: Name,
            getIndex: true,
            locale: locale,
          })}
        </SharedAccordion>
      </Block>
    );
  }
}

export default Accordion;
