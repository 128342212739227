import React, { PureComponent } from "react";

import { colors } from "../../../shared/colors";
import Block from "../Block";
import { LeadGeneratorSubscription as SharedLeadGeneratorSubscription } from "../../../shared/organisms";

class LeadGeneratorSubscription extends PureComponent {
  render() {
    const { content, currentHref } = this.props;
    const {
      CampaignID,
      SmallTitle,
      Title,
      Body,
      BackgroundColor,
      TextColor,
      SubmitButtonTheme,
    } = content;
    const whiteText = TextColor.includes("white");
    return (
      <Block content={content} className="LeadGeneratorSubscriptionBlock">
        <SharedLeadGeneratorSubscription
          campaignID={CampaignID}
          backgroundColor={colors[BackgroundColor].color}
          subtitle={SmallTitle}
          title={Title}
          bodyText={Body}
          buttonTheme={SubmitButtonTheme}
          whiteText={whiteText}
          currentHref={currentHref}
        />
      </Block>
    );
  }
}

export default LeadGeneratorSubscription;
