/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { Helmet } from "react-helmet";
import Components from "../../components";
import { Container } from "../../../shared/atoms";
import { ButtonBackToTop, CookieBar } from "../../../shared/molecules";
import { browser, composeClassName } from "../../../shared/utilities";

import {
  detectIeSupportClass,
  ie11ImageToBackgroundShim,
} from "../../utilities";
import { Navigation, Footer } from "../../partials";

import "./BasePage.scss";
import "../FeatureQueries.scss";

const BasePage = (props) => {
  const {
    overrideLocaleHack,
    allowedLocales,
    currentHref,
    environment,
    languageData,
    localPartials,
    locale,
    content,
    pageTitle,
    partials,
    siteUrl,
    template = {},
  } = props;

  const {
    body,
    SeoDescription,
    SeoImage,
    AltText,
    SeoCustomTags = [],
    StoriesTitle,
    PrimaryImage,
    SecondaryImage,
    StoriesImageAlt,
    StoriesDescription,
  } = content;
  const ieClass = detectIeSupportClass();

  let blocks;
  // locale format is en-CA, fr-CA or en-US
  const [language, country] = locale.split("-"); // eslint-disable-line no-unused-vars

  if (body) {
    blocks = body.map((blockContent) => {
      return React.createElement(Components[blockContent.component], {
        content: blockContent,
        currentHref: currentHref,
        environment: environment,
        ieClass: ieClass,
        key: blockContent._uid,
        language: languageData,
        localPartials: localPartials,
        locale: locale,
        partials: partials,
      });
    });
  }

  const customMetaTags = SeoCustomTags.map((tag, index) => {
    const key = `${tag.name}-${index}`;
    return <meta key={key} name={tag.Name} content={tag.Content} />;
  });

  const logoHref =
    environment === "editor" ? `${siteUrl}/${locale}` : `/${locale}`;

  const chatButton = template.chatButton ? template.chatButton : null;

  let navigation = (
    <Navigation
      overrideLocaleHack={overrideLocaleHack}
      allowedLocales={allowedLocales}
      environment={environment}
      currentLocale={locale}
      content={partials.navigation}
      logoHref={logoHref}
    />
  );
  if (template.navigation) {
    navigation = template.navigation; // eslint-disable-line prefer-destructuring
  }

  let footer = (
    <Footer
      overrideLocaleHack={overrideLocaleHack}
      allowedLocales={allowedLocales}
      currentLocale={locale}
      content={partials.footer}
      logoHref={logoHref}
    />
  );
  if (template.footer) {
    footer = template.footer; // eslint-disable-line prefer-destructuring
  }

  // Store query string in Session Storage to further usage
  const localStorageQuerySearchKey = "initialUrlQuery";
  const searchStringStorage = browser.window.localStorage.getItem(
    localStorageQuerySearchKey,
  );
  const searchString = browser.window.location.search;
  if (searchStringStorage === null && searchString.length !== 0) {
    browser.window.localStorage.setItem(
      localStorageQuerySearchKey,
      searchString,
    );
  }

  const removeLoader = () => {
    // Set delay 500ms only when page is first time visited or refreshed
    const delay = browser.window.prevLocation ? 0 : 500;
    const wrapper = browser.document.getElementById("loader-wrapper");
    setTimeout(() => {
      // .remove() is not supported by IE11
      // .removeChild() is supported by all browsers.
      if (wrapper) wrapper.parentNode.removeChild(wrapper);
    }, delay);
  };

  useEffect(() => {
    // Remove loader, which is used to cover the flickering of unstyled elements on page load
    removeLoader();
    // temporary solution to fix IE image aspect ratio issue.
    if (ieClass) {
      // Selector is the class of a container that has <Image /> as it's child
      const selectors = [
        ".tile",
        ".GenericContentAligned",
        ".MarketingTile",
        ".BannerBackground",
        ".LeadGenerator",
        ".DownloadableResources",
        ".ModalMapStory",
      ];
      ie11ImageToBackgroundShim(selectors);
    }
  }, []);

  const cookieBar =
    locale === "en-GB" ? (
      <CookieBar internalLinkComponent={GatsbyLink} />
    ) : null;

  let favicon = "https://static.we.org/logos/favicon.ico";
  if (partials.navigation && partials.navigation.Favicon) {
    favicon = partials.navigation.Favicon;
  }
  const imageCard = SeoImage
    ? `https:${SeoImage}`
    : "https://static.we.org/logos/social-media-we-logo.png";
  const altText = AltText || SeoDescription;
  const ogLocale = locale.replace("-", "_");

  const contentClassName = composeClassName([
    "content",
    localPartials.subnav ? "with-subnav" : "",
  ]);
  const canonicalHref = (currentHref === "/"
    ? siteUrl
    : `${siteUrl}/${currentHref}`
  ).toLowerCase();

  return (
    <div className="page">
      <Helmet htmlAttributes={{ lang: language, class: ieClass }}>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={SeoDescription} />
        <title>{pageTitle}</title>
        <meta property="og:locale" content={ogLocale} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalHref} />
        <meta property="og:image" content={imageCard} />
        <meta property="og:image:alt" content={altText} />
        <meta property="og:description" content={SeoDescription} />
        <meta property="og:site_name" content={siteUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={SeoDescription} />
        <meta name="twitter:image:src" content={imageCard} />
        <link rel="shortcut icon" href={favicon} type="image/x-icon" />
        <link rel="icon" href={favicon} type="image/x-icon" />
        <link rel="canonical" href={canonicalHref} />
        {customMetaTags}
      </Helmet>
      <div id="loader-wrapper" />
      <ButtonBackToTop />
      <div className="nav-container">{navigation}</div>
      {chatButton}
      <Container fluid className={contentClassName}>
        {blocks}
      </Container>
      {cookieBar}
      {footer}
    </div>
  );
};

BasePage.propTypes = {
  template: PropTypes.object,
};

BasePage.defaultProps = {
  template: {},
};

export default BasePage;
