const StoryblokCdn = /\/\/a\.storyblok\.com\//;
const WeStaticCdn = "//staticsb.we.org/";

const replaceStoryblokCdn = (url) => {
  if (url && url.replace) {
    return url.replace(StoryblokCdn, WeStaticCdn);
  }
  return url;
};

export default replaceStoryblokCdn;
