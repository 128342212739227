import React, { PureComponent } from "react";
import { Author as SharedAuthor } from "../../../shared/molecules";
import Block from "../Block";

class Author extends PureComponent {
  render() {
    const { content } = this.props;
    const { AuthorName, PublishedDate, JobTitle, ReadTime, Divider } = content;

    return (
      <Block className="AuthorBlock" content={content}>
        <SharedAuthor
          authorName={AuthorName}
          publishedDate={PublishedDate}
          readTime={ReadTime}
          jobTitle={JobTitle}
          divider={Divider}
        />
      </Block>
    );
  }
}

export default Author;
