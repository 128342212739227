import React from "react";
import { VectorDraw } from "../../molecules";

export const _buildVectorShapeData = (mapData) => {
  return Object.keys(mapData).map((key) => {
    return {
      ...mapData[key],
      id: key,
      label: mapData[key].altLabel ? mapData[key].altLabel : key,
    };
  });
};

function MapDesktop({ mapData, onSelectState }) {
  const shapes = _buildVectorShapeData(mapData);

  return (
    <div className="MapDesktop" style={{ width: "100%", margin: "auto" }}>
      <div className="MapContainer">
        <VectorDraw
          onClickShape={onSelectState}
          textfield
          shapes={shapes}
          containerWidth="100%"
          containerHeight="auto"
        />
      </div>
    </div>
  );
}

export default MapDesktop;
