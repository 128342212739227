import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import { composeClassName } from "../../utilities";
import ButtonIcon from "../ButtonIcon";
import SlideIndicator from "../SlideIndicator";

import "./Carousel.scss";

class Carousel extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = { currentSlideIndex: 0 };
    this.timer = null;
  }

  renderArrows = () => {
    const prevArrow = (
      <ButtonIcon
        size="L"
        theme="Transparent"
        icon="chevronLeft"
        iconSize="M"
        onClickHandler={() => {
          this.ref.current.slickPrev();
        }}
      />
    );

    const nextArrow = (
      <ButtonIcon
        size="L"
        iconSize="M"
        theme="Transparent"
        icon="chevronRight"
        onClickHandler={() => {
          this.ref.current.slickNext();
        }}
      />
    );

    return { prevArrow, nextArrow };
  };

  render() {
    const { className, children } = this.props;
    const { currentSlideIndex } = this.state;
    const composedClassName = composeClassName(["Carousel", className]);

    const numberOfSlides = React.Children.count(children);

    const { prevArrow, nextArrow } = this.renderArrows();
    return (
      <div className={composedClassName}>
        <Slider
          ref={this.ref}
          infinite={false}
          dots={false}
          speed={500}
          beforeChange={(current, next) => {
            this.setState({ currentSlideIndex: next });
          }}
          nextArrow={nextArrow}
          prevArrow={prevArrow}>
          {children}
        </Slider>
        <SlideIndicator
          numberOfSlides={numberOfSlides}
          currentSlideIndex={currentSlideIndex}
        />
      </div>
    );
  }
}

export default Carousel;
