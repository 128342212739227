/* eslint-disable no-else-return */
/* eslint-disable camelcase, prefer-destructuring, no-unused-vars */
import _ from "lodash";
import qs from "qs";
import browser from "../browser";
import { siteMetadata } from "../../../../gatsby-config";

const getRealStoryblokUrl = (linkObject, storyblokLinks) => {
  if (linkObject.linktype === "story") {
    // linkObject's id is actually its uuid
    const uuid = linkObject.id || "not-existed";
    if (storyblokLinks[uuid]) {
      return storyblokLinks[uuid].trim();
    }
  }

  const { url, cached_url } = linkObject;
  return (url && !cached_url ? url : cached_url).trim();
};

/**
 * @param {*}
 *  - linkObject: Storyblok's link object
 *  - locale: locale used to change final URL
 *  - storyblokLinks: objectain containing all Storyblok's internal links
 * @returns {*} An object with href and a flag for internal or external
 */
const extractStoryblokUrl = (
  linkObject = {},
  locale = "en-CA",
  storyblokLinks = {},
) => {
  if (Object.keys(linkObject).length === 0) return {};
  const { linktype = "" } = linkObject;

  const {
    buildSite,
    buildLocale,
    localeCodes,
    environment,
    sites: siteList,
    siteUrl,
  } = siteMetadata;

  // When the href is a root page, the url = # and cached_url is the desired param
  const storyblokUrl = getRealStoryblokUrl(linkObject, storyblokLinks);

  if (!storyblokUrl) return { external: true, href: "" };

  if (environment === "editor") {
    let urlObject = {
      external: false,
      href: `/editor/?path=${storyblokUrl}`,
    };

    if (linktype === "url") {
      urlObject = {
        external: true,
        href: storyblokUrl,
      };
    }
    return urlObject;
  }

  // handling publisher environment
  const sites = Object.keys(siteList);
  // Origin is current base url
  const { pathname } = browser.window.location;
  // Remove previous anchor link, if any
  const browserHref = browser.window.location.href.split("#")[0];

  let href = storyblokUrl;
  let external = false;
  const currentPath = pathname;

  const sitesStr = sites.join("|");
  const localesStr = localeCodes.join("|");
  const regex = new RegExp(`.*(?=/(${localesStr})/(.*))`);

  let currentBasePath = "";
  let currentPathLocale = "";
  let currentPage = pathname.slice(1); // get rid of "/" in front.
  let newPath = "";

  if (regex.test(currentPath)) {
    [currentBasePath, currentPathLocale, currentPage] = currentPath.match(
      regex,
    );
  }

  const storyblokUrlLocale = (storyblokUrl.match(localesStr) || [
    currentPathLocale,
  ])[0];

  if (storyblokUrl.match(`${siteUrl}`)) {
    // New url domain part matches siteUrl or current page domain.
    // Assigns new_path to the path, after domain
    // https://www.we.org => /
    // https://www.we.org/new/path => /new/path
    // https://www.we.org/en-CA/new/path => /en-CA/new/path
    newPath = storyblokUrl.match(`(?:${siteUrl})(.*)`)[1] || "/";
  } else if (storyblokUrl.match(/^https?/)) {
    // New url has http and domain different from current site.
    // Assigns new_path to url.
    // Sets external to true.
    // https://www.google.com => https://www.google.com
    // https://travel.metowe.com/en-CA/path => https://travel.metowe.com/en-CA/path
    newPath = storyblokUrl;
    external = true;
  } else if (storyblokUrl.match(`^/?(${sitesStr}|${localesStr})`)) {
    // New url starts with site name or locale with or without / in front.
    // Assigns new_path to the path, after locale
    // we_org/en-CA/ => /
    // we_org/en-CA/new/path => /new/path
    // /we_org/en-CA/new/path => /new/path
    // en-CA/new/path => /new/path
    // /en-CA/new/path => /new/path
    newPath = storyblokUrl.split(storyblokUrlLocale).reverse()[0] || "/";
  } else if (storyblokUrl[0] === "/") {
    // New url starts with "/".
    // Assigns new_path to url
    // /new/path => /new/path
    newPath = storyblokUrl;
  } else if (storyblokUrl[0] === "#") {
    // New url starts with "#".
    // Assigns new_path to current/page + url
    // #section => /current/page#section
    newPath = currentPage ? `/${currentPage}${storyblokUrl}` : storyblokUrl;
  } else if (storyblokUrl[0] !== "/" && linktype === "story") {
    // New url doesn't start with "/" and storyblok link is internal.
    // Assigns new_path to url preppeanding "/".
    // new/path => /new/path
    newPath = `/${storyblokUrl}`;
  } else if (storyblokUrl[0] !== "/") {
    // New url doesn't start with "/" and storyblok link is external (remaining option).
    // Assigns new_path to current page + url.
    // new/path => /current/page/new/path
    newPath = currentPage
      ? `/${currentPage}/${storyblokUrl}`
      : `/${storyblokUrl}`;
  } else {
    // Catch unexpected cases.
    throw new TypeError("Something went wrong.");
  }

  const buildHref = () => {
    if (sites.includes(buildSite)) {
      if (buildLocale === "all") {
        if (storyblokUrl.match(`(${siteUrl})/(${localesStr})`)) {
          // New url domain part matches siteUrl or current page domain and has locale.
          // Assuming that if full url provided, it's locale should not change
          // storyblokUrl: https://we.org.ca/en-CA/new/path
          // => /new/path
          return newPath;
        }
        // All other cases are treated as internal,
        // therefor locale will be set to the one passed as param to the main function
        return `/${locale}${newPath}`;
      }
      // buildLocale="en-CA", "en-GB", etc...
      // This environment has only one locale and one site
      if (localeCodes.includes(buildLocale)) {
        if (storyblokUrl.match(`(${siteUrl})/(${localesStr})`)) {
          // New url domain part matches siteUrl or current page domain and has locale.
          // Assuming that full url should be treated as external
          // storyblokUrl: https://we.org.ca/en-CA...
          return storyblokUrl;
        }
        // All other cases are treated as internal,
        // and since there is only one locale in this env,
        // returning new path w/o locale.
        return newPath;
      }
    }

    // Catch unexpected cases.
    throw new TypeError(
      "Something went wrong. Perhaps 'buildSite' or 'buildLocale' value is invalid",
    );
  };

  if (!external) {
    href = buildHref();
  }

  // IE11 doesn't support startsWith()
  external = href.indexOf("http") === 0;
  return { external, href };
};

export { extractStoryblokUrl, getRealStoryblokUrl };
