import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, BaseLink } from "../../atoms";
import { composeClassName, compileDataAttributes } from "../../utilities";
import "./ButtonLink.scss";

class ButtonLink extends PureComponent {
  static propTypes = {
    /** The selection for the theme of the button */
    theme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
    /** The selection for the size of the button */
    size: PropTypes.oneOf(["L", "M", "S"]),
    className: PropTypes.string,
    /** Optional attribute for keyboard Accessibility to fix tabindex issues */
    autoFocus: PropTypes.bool,
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    /** Optional attribute for keyboard Accessibility */
    tabIndex: PropTypes.string,
    /** The link destination - a relative path for internal links, or absolute for external links */
    href: PropTypes.string,
    /** Whether or not the link's destination is on a separate site */
    external: PropTypes.bool,
    /** The component used as the internal link handler when `internal` is true */
    internalLinkComponent: PropTypes.any,
    /** The title attribute of the anchor tag */
    title: PropTypes.string,
    /** Open link in new tab if true */
    newTab: PropTypes.bool,
  };

  static defaultProps = {
    autoFocus: false,
    className: "",
    disabled: false,
    size: "M",
    theme: "Primary",
    href: "",
    external: false,
    internalLinkComponent: null,
    title: "",
    newTab: false,
  };

  render() {
    const {
      className,
      theme,
      size,
      autoFocus,
      ariaLabel,
      disabled,
      tabIndex,
      children,
      ...linkProps
    } = this.props;
    const composedClassName = composeClassName(["ButtonLink", className]);
    const dataAttributeProps = compileDataAttributes(this.props);
    return (
      <BaseLink
        {...linkProps}
        className={composedClassName}
        {...dataAttributeProps}>
        <Button
          size={size}
          theme={theme}
          autoFocus={autoFocus}
          disabled={disabled}
          type="button"
          ariaLabel={ariaLabel}
          tabIndex={tabIndex}>
          {children}
        </Button>
      </BaseLink>
    );
  }
}

export default ButtonLink;
