import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Col, Divider as DividerAtom } from "../../../shared/atoms";
import Block from "../Block";

class Divider extends PureComponent {
  static propTypes = {
    content: PropTypes.shape({
      Title: PropTypes.string.isRequired,
    }),
  };

  render() {
    const { content } = this.props;
    const { Title } = content;
    return (
      <Block content={content} className="DividerBlock">
        <Col xs={{ span: 12 }}>
          <DividerAtom title={Title} />
        </Col>
      </Block>
    );
  }
}

export default Divider;
