import React, { PureComponent } from "react";
import { Link } from "gatsby";
import { BaseLink } from "../../shared/atoms";

class GatsbyLink extends PureComponent {
  render() {
    const { children, ...props } = this.props;
    return (
      <BaseLink internalLinkComponent={Link} {...props}>
        {children}
      </BaseLink>
    );
  }
}

export default GatsbyLink;
