import React, { PureComponent } from "react";
import { Image as SharedImage, BodyText } from "../../../shared/atoms";
import { GatsbyBaseTile } from "../../../sharedgatsby";
import contentToComponents from "../../utilities/contentToComponents";
import "./CenteredTile.scss";

class CenteredTile extends PureComponent {
  render() {
    const { key, global, locale, Buttons, ...ImageProps } = this.props;

    const {
      Image,
      AltText,
      LongDesc,
      LogoImage,
      LogoAltText,
      ...TextProps
    } = ImageProps;

    const { Text } = TextProps;

    return (
      <GatsbyBaseTile
        xs={{ span: 12 }}
        md={{ span: 6 }}
        className="CenteredTile">
        <SharedImage
          className="CenteredTileImage"
          source={Image}
          altText={AltText}
          longDesc={LongDesc}
        />
        <SharedImage
          className="CenteredTileLogo"
          source={LogoImage}
          altText={LogoAltText}
        />

        <BodyText size="M">{Text}</BodyText>

        <div
          className={`CenteredTileButtons ${
            Buttons.length > 1 ? "Two" : "One"
          }`}>
          {contentToComponents(Buttons, { locale })}
        </div>
      </GatsbyBaseTile>
    );
  }
}

export default CenteredTile;
