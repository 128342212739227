/* eslint-disable no-unused-vars */
import React from "react";
import Banner from "./Banner";
import desktopImage from "../../../shared/static/desktop.png";
import mobileImage from "../../../shared/static/mobile.png";

const BannerVisualTest = () => {
  const titles = [
    { name: "With Title", value: "banner title" },
    { name: "Without Title", value: "" },
  ];

  const videoProps = {
    VideoId: "fbHbTBPu7U",
    VideoSource: "youtube",
    VideoTitle: "title",
  };

  const buttonTypes = [
    {
      name: "Button type : Ghostbutton",
      ctaType: "ghost",
      props: { CtaText: "this button?" },
    },
    {
      name: "Button type : Button",
      ctaType: "button",
      props: { CtaText: "this button?" },
    },
    {
      name: "Button type : Video Button",
      ctaType: "video",
      props: { videoProps },
    },
    { name: "No Button", ctaType: "", props: {} },
  ];

  const components = [];

  titles.forEach((title) => {
    buttonTypes.forEach((buttonType) => {
      const content = {
        ImageDesktop: desktopImage,
        ImageMobile: mobileImage,
        Title: title.value,
        CtaType: buttonType.ctaType,
        ...buttonType.props,
      };

      const pageName = [title.name, buttonType.name].join(" | ");

      components.push(
        <>
          <h1 className="VisualTestTitle">{pageName}</h1>
          <Banner content={content} />
        </>,
      );
    });
  });

  return components;
};

export default BannerVisualTest;
