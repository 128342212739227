import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./IconNumber.scss";

class IconNumber extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    number: PropTypes.number,
    backgroundColor: PropTypes.string,
    title: PropTypes.string,
    role: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    title: "",
    role: "img",
    number: 0,
    backgroundColor: "white",
  };

  render() {
    const { className, backgroundColor, number, role, title } = this.props;
    const composedClassName = composeClassName(["IconNumber", className]);

    return (
      <div
        className={composedClassName}
        style={{ backgroundColour: backgroundColor }}>
        <span role={role} title={title}>
          {number}
        </span>
      </div>
    );
  }
}

export default IconNumber;
