import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import uniqueId from "lodash/uniqueId";
import { composeClassName } from "../../utilities";
import "./Icon.scss";
import IconPaths from "./IconPaths";

class Icon extends PureComponent {
  static IconPath = IconPaths; // eslint-disable-line

  static propTypes = {
    className: PropTypes.string,
    /** The selection for the displayed icon */
    icon: PropTypes.oneOf(Object.keys(Icon.IconPath)).isRequired,
    /** The selection for the size of the icon */
    size: PropTypes.oneOf(["S", "M", "L"]).isRequired,
    /** The selection for the default color of the icon */
    defaultColor: PropTypes.oneOf([
      "DefaultGrey",
      "DefaultWhite",
      "DefaultNavy",
    ]),
    /** The description of the icon, defaults to the name of the icon */
    title: PropTypes.string,
    /** The role of the icon, defaults to the role of an image */
    role: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    size: "S",
    defaultColor: "DefaultGrey",
    title: "",
    role: "img",
  };

  constructor(props) {
    super(props);

    this.state = {
      id: 1,
    };
  }

  componentDidMount() {
    // This is a hack to force rerender of the Icon component
    // after rehydration.
    this.setState({
      id: typeof window !== "undefined" ? uniqueId("icon-") : 2,
    });
  }

  render() {
    const { icon, size, defaultColor, className, title, role } = this.props;
    const { id } = this.state;

    const composedClassName = composeClassName([
      `Icon ${icon} ${size} ${defaultColor}`,
      title,
      role,
      className,
    ]);

    let path = Icon.IconPath[icon];
    const fillClassName = "IconFill";
    path = React.cloneElement(path, { id: id, className: fillClassName });

    return (
      <span className={composedClassName}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="100%"
          viewBox="0 0 16 16">
          <defs>{path}</defs>
          <use
            className={fillClassName}
            fill="#A8AAB7"
            fillRule="evenodd"
            xlinkHref={`#${id}`}
          />
        </svg>
      </span>
    );
  }
}

export default Icon;
