import React from "react";
import { TabSectionPartial } from "../../partials";

const TabSectionPartialContainer = (props) => {
  const { partials, currentHref } = props;
  if (typeof partials.tabs === "undefined") {
    return (
      <div>
        <p>You have NOT created the &quot;Tab Section&quot; partial yet.</p>
        <p>
          Please create this partial in the ___partials folder with the exact
          slug &quot;tabs&quot;
        </p>
      </div>
    );
  }

  return (
    <TabSectionPartial content={partials.tabs} currentHref={currentHref} />
  );
};

export default TabSectionPartialContainer;
