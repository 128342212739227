import React from "react";
import PropTypes from "prop-types";

import {
  BannerBackground,
  BaseLink,
  Button,
  Col,
  Container,
  Heading,
} from "../../atoms";

import ButtonGhost from "../ButtonGhost";
import ButtonIcon from "../ButtonIcon";
import ModalVideo from "../ModalVideo";

import { composeClassName } from "../../utilities";

import "./Banner.scss";

const Banner = (props) => {
  const {
    buttonText,
    buttonTheme,
    buttonType,
    className,
    imageDesktopSrc,
    imageMobileSrc,
    videoBackgroundSrc,
    linkHref,
    linkExternal,
    linkTitle,
    internalLinkComponent,
    socialIcon,
    socialIconTheme,
    titleText,
    titleWhiteText,
    videoButtonTheme,
    videoId,
    videoSource,
    videoTitle,
  } = props;

  const headerComponent = <div />;

  let titleComponent;
  if (titleText) {
    titleComponent = (
      <Container>
        <Col xs={{ span: 12 }}>
          <Heading
            centered
            className="BannerTitle"
            size="XL"
            whiteText={titleWhiteText}>
            {titleText}
          </Heading>
        </Col>
      </Container>
    );
  }

  let buttonComponent;
  if (buttonType === "button" && buttonText) {
    buttonComponent = <Button theme={buttonTheme}>{buttonText}</Button>;
  }
  if (buttonType === "ghost" && buttonText) {
    buttonComponent = <ButtonGhost>{buttonText}</ButtonGhost>;
  }
  if (linkHref) {
    buttonComponent = (
      <BaseLink
        internalLinkComponent={internalLinkComponent}
        external={linkExternal}
        href={linkHref}
        title={linkTitle}>
        {buttonComponent}
      </BaseLink>
    );
  }
  if (buttonType === "social") {
    buttonComponent = (
      <ButtonIcon
        external={linkExternal}
        href={linkHref}
        icon={socialIcon}
        size="L"
        theme={socialIconTheme}
        internalLinkComponent={internalLinkComponent}
        title={linkTitle}
      />
    );
  }
  if (buttonType === "video") {
    buttonComponent = (
      <ModalVideo
        className="BannerModalVideo"
        source={videoSource}
        videoId={videoId}
        title={videoTitle}
        theme={videoButtonTheme}
      />
    );
  }

  return (
    <div
      data-social-media="true"
      className={composeClassName(["Banner", className])}>
      <BannerBackground
        imageDesktopSrc={imageDesktopSrc}
        imageMobileSrc={imageMobileSrc}
        videoBackgroundSrc={videoBackgroundSrc}>
        <div className="BannerHeaderComponent">{headerComponent}</div>
        <div className="BannerTitleComponent">{titleComponent}</div>
        <div className="BannerButtonComponent">{buttonComponent}</div>
      </BannerBackground>
    </div>
  );
};

Banner.propTypes = {
  className: PropTypes.string,
  imageDesktopSrc: PropTypes.string.isRequired,
  imageMobileSrc: PropTypes.string,
  videoBackgroundSrc: PropTypes.string,
  titleText: PropTypes.string,
  titleWhiteText: PropTypes.bool,
  buttonType: PropTypes.oneOf(["button", "ghost", "video", "social"]),
  /* link doesn't work for buttonType of `video` */
  linkHref: PropTypes.string,
  linkExternal: PropTypes.bool,
  linkTitle: PropTypes.string,
  internalLinkComponent: PropTypes.any,
  /* button options only works if buttonType is `button` */
  buttonTheme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
  buttonText: PropTypes.string,
  /* socialIcon options only works if buttonType is `social` */
  socialIcon: PropTypes.oneOf([
    "socialFacebook",
    "socialInstagram",
    "socialLinkedIn",
    "socialPinterest",
    "socialTwitter",
    "socialVimeo",
  ]),
  socialIconTheme: PropTypes.oneOf(["Primary", "Tertiary"]),
  /* video options only works if buttonType is `social` */
  videoSource: PropTypes.oneOf(["vimeo", "youtube"]),
  videoId: PropTypes.string,
  videoTitle: PropTypes.string,
  videoButtonTheme: PropTypes.oneOf(["light", "dark"]),
};

Banner.defaultProps = {
  buttonType: "button",
  buttonTheme: "Primary",
  socialIcon: "socialFacebook",
  socialIconTheme: "Primary",
  videoSource: "vimeo",
  videoButtonTheme: "light",
  internalLinkComponent: null,
};

export default Banner;
