import React from "react";
import PropTypes from "prop-types";
import { BaseLink, Icon } from "../../atoms";
import { composeClassName } from "../../utilities";
import "./NavigationItemMobile.scss";

const NavigationItemMobile = (props) => {
  const { className, label, onClickHandler, isOpen, children } = props;

  // Renders just the child with no openable dropdown if only one element is passed as child
  if (children && React.isValidElement(children)) {
    return (
      <div className={composeClassName(["NavigationItemMobile", className])}>
        {children}
      </div>
    );
  }

  const composedClassName = composeClassName([
    "NavigationItemMobile",
    className,
    isOpen ? "Open" : "",
  ]);
  const navItemContentClassNames = composeClassName([
    "NavigationItemContent",
    isOpen ? "" : "Collapsed",
  ]);

  const icon = (
    <Icon
      title={isOpen ? "collapse" : "expand"}
      icon={isOpen ? "chevronUp" : "chevronDown"}
      size="S"
      className="NavigationItemCaret"
      defaultColor="DefaultGrey"
    />
  );

  const ariaControlProps = {
    id: `NavigationItem-control-${label}`,
    "aria-expanded": isOpen,
    "aria-controls": `content-${label}`,
  };
  const ariaContentProps = {
    id: `NavigationItem-content-${label}`,
    "aria-hidden": !isOpen,
    "aria-labelledby": `NavigationItem-control-${label}`,
  };

  return (
    <div className={composedClassName} {...ariaControlProps}>
      <BaseLink
        className="NavigationItemMobileTitle"
        blank
        onClickHandler={onClickHandler}>
        {label}
        {icon}
      </BaseLink>
      <div className={navItemContentClassNames} {...ariaContentProps}>
        {children}
      </div>
    </div>
  );
};

NavigationItemMobile.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClickHandler: PropTypes.func,
  isOpen: PropTypes.bool,
  internalLinkComponent: PropTypes.any,
  children: PropTypes.any,
};

NavigationItemMobile.defaultProps = {
  className: "",
  label: "",
  isOpen: false,
};

export default NavigationItemMobile;
