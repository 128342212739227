import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import { TabSectionLink } from "../../molecules";
import "./TabSection.scss";

class TabSection extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string,
        content: PropTypes.array,
      }),
    ),
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
    };
  }

  setCurrentTab = (index) => {
    this.setState({ currentTab: index });
  };

  render() {
    const { className, tabs, centered } = this.props;

    const { currentTab } = this.state;

    const tabButtons = tabs.map((tab, index) => {
      const selected = currentTab === index;
      return (
        <li key={tab.key}>
          <TabSectionLink
            className="Tab"
            sectionId={tab.key}
            active={selected}
            tabIndex={selected ? "0" : "-1"}
            blank
            onClickHandler={() => {
              this.setCurrentTab(index);
            }}>
            {tab.text}
          </TabSectionLink>
        </li>
      );
    });

    const tabPanels = tabs.map((tab, index) => {
      const selected = currentTab === index;
      return (
        <div
          key={tab.key}
          role="tabpanel"
          aria-labelledby={`tab-${tab.key}`}
          id={tab.key}
          className={selected ? "" : "HiddenPanel"}>
          {tab.content}
        </div>
      );
    });

    const composedClassName = composeClassName(["TabSection", className]);

    const ulClassName = centered ? "TabBar Centered" : "TabBar";

    return (
      <div className={composedClassName}>
        <ul role="tablist" className={ulClassName}>
          {tabButtons}
        </ul>
        {tabPanels}
      </div>
    );
  }
}

export default TabSection;
