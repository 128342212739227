import React, { PureComponent } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Image, Heading } from "../../../shared/atoms";
import { BaseTile, ImageLink } from "../../../shared/molecules";
import { StringUtils } from "../../../shared/utilities";
import { withStoryblokLink } from "../../utilities";
import "./HalfTile.scss";

class HalfImageTile extends PureComponent {
  render() {
    const { Link, LinkTitle, Title, TextColor, ...imageProps } = this.props;

    const sharedProps = {
      altText: imageProps.AltText,
      longDesc: imageProps.LongDesc,
      source: imageProps.Image,
      mobileSource: imageProps.MobileImage,
    };
    const linkProps = {
      title: LinkTitle,
      internalLinkComponent: GatsbyLink,
      external: Link.external,
      href: Link.href,
    };

    const whiteText = TextColor.includes("white");

    let image = <Image {...sharedProps} />;
    if (Link && Link.href) {
      image = <ImageLink {...sharedProps} {...linkProps} />;
    }

    return (
      <BaseTile xs={{ span: 12 }} md={{ span: 4 }} className="HalfTile">
        {image}
        <Heading className="Text Image" size="M" whiteText={whiteText}>
          {StringUtils.formatMax(Title, 30)}
        </Heading>
      </BaseTile>
    );
  }
}

export default withStoryblokLink(HalfImageTile);
