import React, { PureComponent } from "react";
import { ImageLink } from "../../shared/molecules";
import GatsbyLink from "../GatsbyLink";

class GatsbyImageLink extends PureComponent {
  render() {
    // eslint-disable-next-line
    const props = this.props;
    return <ImageLink Link={GatsbyLink} {...props} />;
  }
}

export default GatsbyImageLink;
