import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Image, BaseLink } from "../../atoms";
import { composeClassName } from "../../utilities";

import "./ImageLink.scss";

class ImageLink extends PureComponent {
  static propTypes = {
    altText: PropTypes.string.isRequired,
    className: PropTypes.string,
    external: PropTypes.bool,
    href: PropTypes.string,
    longdesc: PropTypes.string,
    mobileSource: PropTypes.string,
    source: PropTypes.string.isRequired,
    title: PropTypes.string,
    /** Changes the component used to create links. */
    internalLinkComponent: PropTypes.any,
  };

  static defaultProps = {
    href: "",
    external: false,
    title: "",
    longdesc: "",
    internalLinkComponent: null,
  };

  render() {
    const {
      altText,
      className,
      external,
      href,
      longdesc,
      mobileSource,
      source,
      title,
      internalLinkComponent,
    } = this.props;

    const imageProps = {
      altText,
      mobileSource,
      source,
      longdesc,
    };
    const linkProps = {
      external,
      href,
      title,
    };

    const composedClassName = composeClassName(["ImageLinkWrapper", className]);
    return (
      <div className={composedClassName}>
        <BaseLink
          internalLinkComponent={internalLinkComponent}
          className="ImageLink"
          {...linkProps}>
          <Image className="ImageLinkImage" {...imageProps} />
        </BaseLink>
      </div>
    );
  }
}

export default ImageLink;
