/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { composeClassName } from "../../utilities";

import replaceStoryblokCdn from "../../../components/utilities/replaceStoryblokCdn";
import filenameFromPath from "../../../components/utilities/filenameFromPath";

import "./BannerBackground.scss";
import "./IESupport.scss";

const BannerBackground = (props) => {
  const {
    className,
    children,
    imageDesktopSrc,
    imageMobileSrc,
    videoBackgroundSrc,
    t,
  } = props;

  const composedClassName = composeClassName(["BannerBackground", className]);
  const notSupportedMsg = t("BannerBackground__notSupportedMessage");

  const imageDesktopSrcFormatted = replaceStoryblokCdn(imageDesktopSrc);
  const imageMobileSrcFormatted =
    replaceStoryblokCdn(imageMobileSrc) || imageDesktopSrcFormatted;
  const videoBackgroundSrcFormatted = replaceStoryblokCdn(videoBackgroundSrc);
  const altMobile = filenameFromPath(imageMobileSrcFormatted);
  const altDesktop = filenameFromPath(imageDesktopSrcFormatted);

  const mobileMedia = <img src={imageMobileSrcFormatted} alt={altMobile} />;
  let desktopMedia = <img src={imageDesktopSrcFormatted} alt={altDesktop} />;

  const mimeTYpe = videoBackgroundSrcFormatted.match(/webm$/i)
    ? "video/webm"
    : "video/mp4";
  if (videoBackgroundSrc) {
    desktopMedia = (
      <video loop autoPlay muted poster={imageDesktopSrcFormatted}>
        <source src={videoBackgroundSrcFormatted} type={mimeTYpe} />
        <track />
        {notSupportedMsg}
      </video>
    );
  }

  return (
    <>
      <div className={`${composedClassName} DesktopImage`}>
        {desktopMedia}
        {children}
      </div>
      <div className={`${composedClassName} MobileImage`}>
        {mobileMedia}
        {children}
      </div>
    </>
  );
};

BannerBackground.propTypes = {
  /** The source file for the main image */
  imageDesktopSrc: PropTypes.string.isRequired,
  /** The source file for the mobile image */
  imageMobileSrc: PropTypes.string,
  /** The source file for the desktop video */
  videoBackgroundSrc: PropTypes.string,
  className: PropTypes.string,
};

BannerBackground.defaultProps = {
  circular: false,
  className: "",
  imageDesktopSrc: "",
  imageMobileSrc: "",
  videoBackgroundSrc: "",
};

export default withTranslation("translations")(BannerBackground);
