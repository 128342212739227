import React, { PureComponent } from "react";
import { Link } from "gatsby";
import { LeadGenerator as SharedLeadGenerator } from "../../../shared/organisms";
import { StringUtils } from "../../../shared/utilities";
import { colors } from "../../../shared/colors";
import { withStoryblokLink, withMarkdownParser } from "../../utilities";
import Block from "../Block";

class LeadGenerator extends PureComponent {
  render() {
    const { content } = this.props;
    const {
      Image,
      MobileImage,
      ImageLongDesc,
      ImageAltText,
      Title,
      ButtonText,
      ButtonLink,
      ButtonTheme,
      DisplayText,
      ContactLinkText,
      ContactLinkTitle,
      ContactLink,
      BackgroundColor,
      TextColor,
    } = content;

    return (
      <Block content={content} className="LeadGeneratorBlock">
        <SharedLeadGenerator
          internalLinkComponent={Link}
          imageSource={Image}
          mobileImageSource={MobileImage}
          imageLongDesc={ImageLongDesc}
          imageAltText={ImageAltText}
          title={Title}
          buttonText={ButtonText}
          buttonTheme={ButtonTheme}
          buttonHref={ButtonLink.href}
          buttonIsExternal={ButtonLink.external}
          displayText={DisplayText}
          contactLinkText={StringUtils.formatMax(ContactLinkText, 70)}
          contactLinkTitle={ContactLinkTitle}
          contactLinkHref={ContactLink.href}
          contactLinkIsExternal={ContactLink.external}
          color={colors[BackgroundColor].color}
          whiteText={TextColor === "base-white-color"}
        />
      </Block>
    );
  }
}

export default withMarkdownParser(
  withStoryblokLink(LeadGenerator, {
    LinkFields: ["ButtonLink", "ContactLink"],
  }),
  { textFields: ["DisplayText"] },
);
