/* eslint-disable no-unused-vars */
import React, { PureComponent } from "react";
import ContentGenericAligned from "./ContentGenericAligned";
import desktopImage from "../../../shared/static/desktop.png";
import mobileImage from "../../../shared/static/mobile.png";

class MockInternalLink extends PureComponent {
  render() {
    const { children, to, ...rest } = this.props;
    return (
      <a {...rest} href={to}>
        {children}
      </a>
    );
  }
}
const ContentGenericAlignedVisualTest = (props) => {
  const imageProps = {
    ContentMobileImage: mobileImage,
    ContentImageSource: desktopImage,
    ContentAltText: "Image",
    ContentLongDesc: "Image",
  };

  const videoProps = {
    ContentVideoButtonTheme: "dark",
    ContentVideoId: "fbHbTBPu7U",
    ContentVideoSource: "youtube",
    ContentVideoTitle: "Video",
  };

  const button1 = {
    ContentButton1Theme: "Tertiary",
    ContentButton1Text: "Button 1",
    Link1: MockInternalLink,
    Link1Title: "home",
  };

  const button2 = {
    ContentButton2Theme: "Tertiary",
    ContentButton2Text: "Button 2",
    Link2: MockInternalLink,
    Link2Title: "home",
  };

  const bodyText =
    "Dr. Jane Goodall, an early advisor to our work, once noted that the only way to ensure environmental protection of sensitive areas is to ensure economic systems are in place to benefit the local community. This idea is at the core of the WE Villages development model and is integral to ME to WE Trips.";

  const bodySizes = [
    { name: "L Body Size", value: "L" },
    { name: "M Body Size", value: "M" },
    { name: "S Body Size", value: "S" },
  ];
  const alignments = [
    { name: "Left Aligned", value: "Left" },
    { name: "Right Aligned", value: "Right" },
  ];
  const columnLayouts = [
    { name: "6-6 Layout", value: "6" },
    { name: "3-9 Layout", value: "9" },
  ];
  const imageRatios = [
    { name: "1-1 Image", value: "OneToOne" },
    { name: "4-3 Image", value: "FourToThree" },
    { name: "16-9 Image", value: "SixteenToNine" },
  ];
  const videos = [
    { name: "With Video", value: videoProps },
    { name: "No Video", value: null },
  ];
  const buttonGroups = [
    {
      name: "Two Buttons",
      props: { ...button1, ...button2 },
    },
    {
      name: "One Button",
      props: button1,
    },
  ];
  const titles = [
    { name: "With Title", value: `long title ${bodyText}` },
    { name: "No Title", value: "" },
  ];
  const subtitles = [
    { name: "With Subtitle", value: `long subtitle ${bodyText}` },
    { name: "No Subtitle", value: "" },
  ];

  const tests = [];
  // test image ratios and videos
  alignments.forEach((alignment) => {
    columnLayouts.forEach((columnLayout) => {
      imageRatios.forEach((imageRatio) => {
        videos.forEach((video) => {
          const description = [
            alignment.name,
            columnLayout.name,
            imageRatio.name,
            video.name,
            titles[0].name,
            subtitles[0].name,
            bodySizes[1].name,
            "No Buttons",
          ].join(" | ");
          tests.push(
            <React.Fragment>
              <h1 className="VisualTestTitle">{description}</h1>
              <ContentGenericAligned
                content={{
                  ContentAlignment: alignment.value,
                  ContentTag: subtitles[0].value,
                  ContentTitle: titles[0].value,
                  ContentBody: bodyText,
                  ContentBodySize: bodySizes[1].value,
                  ContentImageRatio: imageRatio.value,
                  ColumnLayout: columnLayout.value,
                  ...imageProps,
                  ...video.value,
                }}
              />
              <br />
            </React.Fragment>,
          );
        });
      });
    });
  });

  // test buttons
  alignments.forEach((alignment) => {
    columnLayouts.forEach((columnLayout) => {
      buttonGroups.forEach((buttonGroup) => {
        const description = [
          alignment.name,
          columnLayout.name,
          imageRatios[0].name,
          videos[0].name,
          titles[0].name,
          subtitles[0].name,
          bodySizes[1].name,
          buttonGroup.name,
        ].join(" | ");
        tests.push(
          <React.Fragment>
            <h1 className="VisualTestTitle">{description}</h1>
            <ContentGenericAligned
              content={{
                ContentAlignment: alignment.value,
                ContentTag: subtitles[0].value,
                ContentTitle: titles[0].value,
                ContentBody: bodyText,
                ContentBodySize: bodySizes[1].value,
                ContentImageRatio: imageRatios[0].value,
                ColumnLayout: columnLayout.value,
                ...buttonGroup.props,
                ...imageProps,
              }}
            />
            <br />
          </React.Fragment>,
        );
      });
    });
  });

  // test subtitles and titles
  alignments.forEach((alignment) => {
    columnLayouts.forEach((columnLayout) => {
      titles.forEach((title) => {
        subtitles.forEach((subtitle) => {
          const description = [
            alignment.name,
            columnLayout.name,
            imageRatios[0].name,
            videos[0].name,
            title.name,
            subtitle.name,
            bodySizes[1].name,
            "Two Buttons",
          ].join(" | ");
          tests.push(
            <React.Fragment>
              <h1 className="VisualTestTitle">{description}</h1>
              <ContentGenericAligned
                content={{
                  ContentAlignment: alignment.value,
                  ContentTag: subtitle.value,
                  ContentTitle: title.value,
                  ContentBody: bodyText,
                  ContentBodySize: bodySizes[1].value,
                  ContentImageRatio: imageRatios[0].value,
                  ColumnLayout: columnLayout.value,
                  ...buttonGroups[0].props,
                  ...imageProps,
                }}
              />
              <br />
            </React.Fragment>,
          );
        });
      });
    });
  });

  return <React.Fragment>{tests}</React.Fragment>;
};

export default ContentGenericAlignedVisualTest;
