import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./TextWithBackground.scss";

class TextWithBackground extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** Hex code for background */
    color: PropTypes.string,
    whiteText: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    whiteText: false,
    color: "#FFFFFF",
  };

  _prepareChildren = (children, whiteText) => {
    if (!whiteText) return children;
    return React.Children.map(children, (child) => {
      if (!child) return null;
      return React.cloneElement(child, { whiteText: true });
    });
  };

  render() {
    const { className, color, whiteText, children } = this.props;
    const composedClassName = composeClassName([
      className,
      "TextWithBackground",
    ]);
    const toRender = this._prepareChildren(children, whiteText);
    return (
      <div className={composedClassName} style={{ backgroundColor: color }}>
        {toRender}
      </div>
    );
  }
}

export default TextWithBackground;
