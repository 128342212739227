/* eslint-disable react/destructuring-assignment */
import React from "react";
import { StaticQuery, graphql } from "gatsby";

// textFields is an array of field names that need to be parsed. E.g. ["BodyText"]
const withMarkdownParser = (
  Component,
  { textFields = [], prefix = "content" },
) => {
  return (props) => {
    const _parseString = (str, site, locale) => {
      let parsedText = str;
      if (!parsedText) return "";
      // Create array of regex that match each site
      const find = new RegExp(`(\\[[^\\)]+\\]\\()\\/\\b${site}\\b`, "gm");
      // Replace the the href with everything except `/${site}`
      // E.g. [Link](/we_org/en-CA) becomes [Link](/en-CA)
      const replace = "$1";
      parsedText = parsedText.replace(find, replace);

      // replace internal URL's locale with provided `locale`
      if (locale) {
        const originalLocales = ["en-CA", "en-GB", "fr-CA", "en-US"].filter(
          (originalLocale) => {
            return originalLocale !== locale;
          },
        );
        originalLocales.forEach((originalLocale) => {
          const findLocale = new RegExp(
            `(\\[[^\\)]+\\]\\()\\/\\b${originalLocale}\\b`,
            "gm",
          );
          // Replace the the href with everything except `/${site}`
          // E.g. [Link](en-CA/test) becomes [Link](en-GB/test)
          const replaceLocale = `$1/${locale}`;
          parsedText = parsedText.replace(findLocale, replaceLocale);
        });
      }

      return parsedText;
    };

    const _generateProps = (site) => {
      // If it is a block we need to grab props from content (or whatever prefix we want)
      const componentProps = props[prefix] || props;
      const { locale } = props;

      const parsedTextContent = {};

      textFields.forEach((textField) => {
        parsedTextContent[textField] = _parseString(
          componentProps[textField],
          site,
          locale,
        );
      });

      if (props[prefix]) {
        return { [prefix]: { ...componentProps, ...parsedTextContent } };
      }
      return { ...componentProps, ...parsedTextContent };
    };

    return (
      <StaticQuery
        query={graphql`
          query EnvQuery {
            site {
              siteMetadata {
                environment
                buildSite
              }
            }
          }
        `}
        render={(data) => {
          // Get list of sites that are going to be parsed out of urls
          const { environment, buildSite } = data.site.siteMetadata;
          // If environment is editor we do not want to parse out the site
          if (environment === "editor") return <Component {...props} />;
          // Create object for parsed text fields with correct keys
          const propsToReturn = _generateProps(buildSite);
          const newProps = { ...props, ...propsToReturn };
          return <Component {...newProps} />;
        }}
      />
    );
  };
};

export default withMarkdownParser;
