import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./Iframe.scss";

class Iframe extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    height: PropTypes.string,
    sourceUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: "",
    height: "",
  };

  render() {
    const { className, height, sourceUrl, title } = this.props;

    const composedClassName = composeClassName(["Iframe", className]);
    return (
      <div className="IframeContainer">
        <iframe
          height={height}
          className={composedClassName}
          title={title}
          src={sourceUrl}
          scrolling="yes"
        />
      </div>
    );
  }
}

export default Iframe;
