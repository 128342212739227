/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { IconSocialMedia } from "../../atoms";
import { browser } from "../../utilities";

class SocialMediaItem extends PureComponent {
  static propTypes = {
    mediaType: PropTypes.oneOf(["Twitter", "Facebook", "Email", "Pinterest"]),
  };

  render() {
    const { mediaType, t } = this.props;
    const currentPath = browser.window.location.href;
    const media = {
      Facebook: `https://www.facebook.com/sharer/sharer.php?u=${currentPath}`,
      Twitter: `https://twitter.com/share?url=${currentPath}`,
      Email: `mailto:?subject=Share Buttons Demo&body=${currentPath}`,
      Pinterest: `https://www.pinterest.com/pin/create/button/?url=${currentPath}`,
      Linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${currentPath}`,
    };

    const titleName = t("SocialMediaItem__title", { mediaType });
    const iconName = mediaType === "Email" ? "email" : `social${mediaType}`;

    return (
      <div className="SocialMediaItem">
        <a className="share-icon" href={media[mediaType]} title={titleName}>
          <span>
            <IconSocialMedia icon={iconName} />
          </span>
        </a>
      </div>
    );
  }
}

export default withTranslation("translations")(SocialMediaItem);
