import React, { useState } from "react";

import MapMobile from "../MapMobile";
import ModalMapStory from "../ModalMapStory";
import MapDesktop from "../MapDesktop";

import "./Map.scss";

const Map = (props) => {
  const { stories, internalLinkComponent } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState();

  const _handleSelectState = (stateId) => {
    if (!stories[stateId]) return;
    setSelectedState(stateId);
    setModalOpen(true);
  };

  const _handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <MapMobile {...props} onSelectState={_handleSelectState} />
      <MapDesktop {...props} onSelectState={_handleSelectState} />
      <ModalMapStory
        isOpen={modalOpen}
        story={stories[selectedState]}
        internalLinkComponent={internalLinkComponent}
        onClickHandler={_handleCloseModal}
        closeHandler={_handleCloseModal}
      />
    </>
  );
};

export default Map;
