import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName, StringUtils } from "../../utilities";
import { Image, Heading, BodyText, Col } from "../../atoms";

import "./GenericContent.scss";

class GenericContent extends PureComponent {
  static propTypes = {
    imageSource: PropTypes.string,
    imageAltText: PropTypes.string,
    imageLongDesc: PropTypes.string,
    imageMobileSource: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string.isRequired,
    className: PropTypes.string,
    buttons: PropTypes.array,
  };

  static defaultProps = {
    mobile: false,
    buttons: [],
  };

  render() {
    const {
      body,
      bodySize,
      centered,
      titleText,
      imageAltText,
      imageMobileSource,
      imageSource,
      imageLongDesc,
      className,
      buttons,
    } = this.props;

    const imageProps = {
      altText: imageAltText,
      longDesc: imageLongDesc,
      mobileSource: imageMobileSource,
      source: imageSource,
    };

    // TODO: Create utility for aspect ratio classes
    let image = null;
    if (imageSource) {
      image = (
        <div className="AspectRatio16-9">
          <Image
            className="GenericContentImage"
            key="generic-content-image"
            {...imageProps}
          />
        </div>
      );
    }

    let title = null;
    if (titleText) {
      title = (
        <Heading size="M" centered={centered}>
          {StringUtils.formatMax(titleText, 50)}
        </Heading>
      );
    }

    let bodyText = null;
    if (body) {
      bodyText = (
        <BodyText size={bodySize} className="BodyText" centered={centered}>
          {body}
        </BodyText>
      );
    }

    const buttonsClassName = centered ? "Centered" : "";

    return (
      <React.Fragment>
        <Col xs={{ span: 12 }}>
          <div className={composeClassName(["GenericContent", className])}>
            {image}
          </div>
        </Col>

        <Col xs={{ span: 12 }} md={{ span: 8, offset: 2 }}>
          <div className={composeClassName(["GenericContent", className])}>
            {title}
            {bodyText}
            <div
              className={composeClassName([
                "GenericContentButtons",
                buttonsClassName,
              ])}>
              {buttons}
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default GenericContent;
