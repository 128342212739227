import React from "react";
import PropTypes from "prop-types";

import {
  MarketingTileA,
  MarketingTileB,
  MarketingTileC,
  StoriesTile,
  Tile,
} from "../../molecules";
import StoriesLayout from "../StoriesLayout";
import { Container } from "../../atoms";
import { colors } from "../../colors";
import "./Feed.scss";

const Feed = (props) => {
  const { pages = [], internalLinkComponent } = props;

  const tiles = [];
  const storiesTiles = [];

  const tileBackgroundColor = "base-light-blue-color";
  const tileTextColor = "brand-navy-color";
  const marginBottom = "MarginBottom";
  const brandNavyBlue = colors["brand-navy-color"].color;

  pages.forEach((page, index) => {
    if (index < 18) {
      switch (index % 6) {
        case 0: {
          const marketingTileB = (
            <MarketingTileB
              // required props
              imageAltText={page.storyImageAlt}
              imageSource={page.storyPrimaryImage}
              title={page.storyTitle}
              // optional props
              backgroundColor={brandNavyBlue}
              body={page.storyDescription}
              className={marginBottom}
              href={page.link.href}
              imageMobileSource={page.storySecondaryImage}
              internalLinkComponent={internalLinkComponent}
              linkExternal={page.link.external}
              linkTitle={page.storyTitle}
              subTitle={page.storySubtitle}
              whiteText
            />
          );
          tiles.push(marketingTileB);
          break;
        }
        case 1: {
          const marketingTileC = (
            <MarketingTileC
              // required props
              imageAltText={page.storyImageAlt}
              imageSource={page.storyPrimaryImage}
              title={page.storyTitle}
              // optional props
              backgroundColor={brandNavyBlue}
              body={page.storyDescription}
              className={marginBottom}
              href={page.link.href}
              imageMobileSource={page.storySecondaryImage}
              internalLinkComponent={internalLinkComponent}
              linkExternal={page.link.external}
              linkTitle={page.storyTitle}
              subTitle={page.storySubtitle}
              whiteText
            />
          );
          tiles.push(marketingTileC);
          break;
        }
        case 5: {
          const marketingTileA = (
            <MarketingTileA
              // required props
              imageAltText={page.storyImageAlt}
              imageSource={page.storyPrimaryImage}
              title={page.storyTitle}
              // optional props
              backgroundColor={brandNavyBlue}
              body={page.storyDescription}
              className={marginBottom}
              href={page.link.href}
              imageMobileSource={page.storySecondaryImage}
              internalLinkComponent={internalLinkComponent}
              linkExternal={page.link.external}
              linkTitle={page.storyTitle}
              subTitle={page.storySubtitle}
              whiteText
            />
          );
          tiles.push(marketingTileA);
          break;
        }
        default: {
          const tile = (
            <Tile
              // required props
              backgroundColor={tileBackgroundColor}
              textColor={tileTextColor}
              title={page.storyTitle}
              // optional props
              altText={page.storyImageAlt}
              className={marginBottom}
              image={page.storySecondaryImage}
              internalLinkComponent={internalLinkComponent}
              link={page.link}
              imageMobileSource={page.storySecondaryImage}
              subTitle={page.storySubtitle}
              text={page.storyDescription}
            />
          );
          tiles.push(tile);
          break;
        }
      }
    } else {
      const storyTile = (
        <StoriesTile
          // required props
          altText={page.storyImageAlt}
          articleTitle={page.storyTitle}
          image={page.storyPrimaryImage}
          // optional props
          tag={page.storySubtitle}
          internalLinkComponent={internalLinkComponent}
          key={`StoryTile-${index}`} // eslint-disable-line react/no-array-index-key
          link={page.link}
        />
      );
      storiesTiles.push(storyTile);
    }
  });

  const storiesLayout = storiesTiles ? (
    <StoriesLayout
      titleText=""
      buttonText=""
      backgroundColor="base-white-color">
      {storiesTiles}
    </StoriesLayout>
  ) : null;

  return (
    <Container className="Feed">
      {tiles}
      {storiesLayout}
    </Container>
  );
};

Feed.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        external: PropTypes.boolean,
      }).isRequired,
      storyDescription: PropTypes.string.isRequired,
      storyImageAlt: PropTypes.string.isRequired,
      storyPrimaryImage: PropTypes.string.isRequired,
      storySecondaryImage: PropTypes.string.isRequired,
      storySubtitle: PropTypes.string.isRequired,
      storyTitle: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Feed;
