import React from "react";
import PropTypes from "prop-types";
import { TabSection } from "../../../shared/organisms";
import Block from "../../blocks/Block";
import contentToBlocks from "../../utilities/contentToBlocks";

import "./TabSectionPartial.scss";

const TabSectionPartial = (props) => {
  const { content, partials } = props;
  const { Tabs = [], Centered } = content;

  if (Tabs.length === 0) {
    return <h2>Please add tabs.</h2>;
  }

  const tabsData = Tabs.map((tab) => {
    const tabComponent = contentToBlocks(tab.Content, { partials });
    return {
      key: tab._uid,
      text: tab.Label,
      content: tabComponent,
    };
  });

  return (
    <Block content={content} className="TabSectionPartial">
      <TabSection key="tab-section" tabs={tabsData} centered={Centered} />
    </Block>
  );
};

TabSectionPartial.propTypes = {
  content: PropTypes.shape({
    Centered: PropTypes.bool,
  }),
};

export default TabSectionPartial;
