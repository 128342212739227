import React, { PureComponent } from "react";
import { Link } from "gatsby";

import { NavigationSub as SharedNavigationSub } from "../../../shared/organisms";
import { extractStoryblokUrl } from "../../../shared/utilities";
import { StoryblokContext } from "../../../context";

class NavigationSub extends PureComponent {
  render() {
    const { content, currentHref, locale } = this.props;
    const Links = content.Links || [];

    return (
      <StoryblokContext.Consumer>
        {({ storyblokLinks }) => {
          const items = Links.map((item) => {
            const linkObj = extractStoryblokUrl(
              item.Link,
              locale,
              storyblokLinks,
            );
            return {
              label: item.Text,
              href: linkObj.href,
              title: item.LinkTitle,
              external: linkObj.external,
            };
          });

          return (
            <SharedNavigationSub
              items={items}
              currentHref={currentHref}
              internalLinkComponent={Link}
            />
          );
        }}
      </StoryblokContext.Consumer>
    );
  }
}

export default NavigationSub;
