import React from "react";
import { Link } from "gatsby";
import { WidgetContent } from "../../../shared/molecules";
import { withMarkdownParser } from "../../utilities";
import contentToComponents from "../../utilities/contentToComponents";

const DonationWidgetContent = (props) => {
  const {
    Subtitle,
    Title,
    Body,
    BodySize,
    Buttons,
    Centered,
    WhiteText,
    locale,
  } = props;

  return (
    <WidgetContent
      internalLinkComponent={Link}
      subtitle={Subtitle}
      title={Title}
      body={Body}
      bodySize={BodySize}
      centered={Centered}
      whiteText={WhiteText}
      buttons={contentToComponents(Buttons, { locale })}
    />
  );
};

export default withMarkdownParser(DonationWidgetContent, {
  textFields: ["Body"],
});
