const colors = {
  "base-black-color": {
    name: "Base Black",
    value: "base-black-color",
    color: "#1d2530",
    type: "base",
  },
  "base-white-color": {
    name: "Base White",
    value: "base-white-color",
    color: "#ffffff",
    type: "base",
  },
  "brand-navy-color": {
    name: "Brand Navy",
    value: "brand-navy-color",
    color: "#19294f",
    type: "brand",
  },
  "brand-accessible-blue-color": {
    name: "Brand Accessible Blue",
    value: "brand-accessible-blue-color",
    color: "#167cba",
    type: "brand",
  },
  "brand-blue-color": {
    name: "Brand Blue",
    value: "brand-blue-color",
    color: "#00aeef",
    type: "brand",
  },
  "brand-orange-color": {
    name: "Brand Orange",
    value: "brand-orange-color",
    color: "#db3b00",
    type: "brand",
  },
  "brand-purple-color": {
    name: "Brand Purple",
    value: "brand-purple-color",
    color: "#6970b4",
    type: "brand",
  },
  "brand-red-color": {
    name: "Brand Red",
    value: "brand-red-color",
    color: "#e41647",
    type: "brand",
  },
  "brand-yellow-color": {
    name: "Brand Yellow",
    value: "brand-yellow-color",
    color: "#ffde30",
    type: "brand",
  },
  "brand-green-color": {
    name: "Brand Green",
    value: "brand-green-color",
    color: "#0d7f50",
    type: "brand",
  },
  "brand-grey-color": {
    name: "Brand Grey",
    value: "brand-grey-color",
    color: "#545555",
    type: "brand",
  },
  "base-icon-color": {
    name: "Base Icon",
    value: "base-icon-color",
    color: "#a8aab7",
    type: "base",
  },
  "base-blue-grey": {
    name: "Base Blue Grey",
    value: "base-blue-grey",
    color: "#c5d0de",
    type: "base",
  },
  "base-light-blue-color": {
    name: "Base Light Blue",
    value: "base-light-blue-color",
    color: "#f1f4f8",
    type: "base",
  },
  "base-elements-color": {
    name: "Base Elements",
    value: "base-elements-color",
    color: "#f4f6f9",
    type: "base",
  },
  "base-border-yellow-color": {
    name: "Base Border Yellow",
    value: "base-border-yellow-color",
    color: "#e5c72b",
    type: "base",
  },
  "base-border-grey-color": {
    name: "Base Border Grey",
    value: "base-border-grey-color",
    color: "#dbdcdf",
    type: "base",
  },
  "base-dark-grey-color": {
    name: "Base Dark Grey",
    value: "base-dark-grey-color",
    color: "#516173",
    type: "base",
  },
  "base-disabled-grey-color": {
    name: "Base Disabled Grey",
    value: "base-disabled-grey-color",
    color: "#e9edf1",
    type: "base",
  },
  "iwth-brand-dark-teal-color": {
    name: "(IWTH) Dark Teal",
    value: "iwth-brand-dark-teal-color",
    color: "#0097a4",
    type: "brand",
  },
  "iwth-brand-light-teal-color": {
    name: "(IWTH) Light Teal",
    value: "iwth-brand-light-teal-color",
    color: "#d9f2f5",
    type: "brand",
  },
  "iwth-navy-color": {
    name: "(IWTH) Navy Color",
    value: "iwth-navy-color",
    color: "#1a2b4f",
    type: "brand",
  },
  "iwth-dark-cyan-color": {
    name: "(IWTH) Dark Cyan",
    value: "iwth-dark-cyan-color",
    color: "#165261",
    type: "brand",
  },
  "iwth-magenta-color": {
    name: "(IWTH) Magenta",
    value: "iwth-magenta-color",
    color: "#872051",
    type: "brand",
  },
  "iwth-light-red-color": {
    name: "(IWTH) Light Red",
    value: "iwth-light-red-color",
    color: "#f65550",
    type: "brand",
  },
  "iwth-yellow-green-color": {
    name: "(IWTH) Yellow Green",
    value: "iwth-yellow-green-color",
    color: "#e0fa52",
    type: "brand",
  },
  "iwth-light-cyan-color": {
    name: "(IWTH) Light Cyan",
    value: "iwth-light-cyan-color",
    color: "#71cbbd",
    type: "brand",
  },
  "iwth-light-yellow-green-color": {
    name: "(IWTH) Light Yellow Green",
    value: "iwth-light-yellow-green-color",
    color: "#f0f7c4",
    type: "brand",
  },
  "iwth-magenta-pink-color": {
    name: "(IWTH) Magenta Pink",
    value: "iwth-magenta-pink-color",
    color: "#f3d2e6",
    type: "brand",
  },
  "": {
    name: "Transparent",
    value: "transparent",
    color: "rgba(0,0,0,0)",
    type: "base",
  },
};

module.exports = {
  colors,
};
