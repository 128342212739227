/* eslint-disable dot-notation */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-extra-boolean-cast */
import _ from "lodash";

const locales = ["en-CA", "en-US", "en-GB", "fr-CA"];
const sites = ["we_org", "tyi"];
const pages = ["", "other"];
const partialComponents = ["navigation", "footer"];
const dataCategoryComponents = ["category-0", "category-1"];
/*
 * Helper functions to generate fake Story
 */
let id = 0;
const _createStoryObject = ({
  name = "",
  content = {},
  slug = "/slug",
  fullSlug = "/we_org/en-CA",
  isStartPage = false,
}) => {
  return {
    id: (++id).toString(),
    name: name,
    created_at: Date(),
    published_at: Date(),
    uuid: Math.random() // random uuid
      .toString(36)
      .substring(7),
    content: JSON.stringify(content),
    slug: slug,
    full_slug: fullSlug,
    is_startpage: isStartPage,
    parent_id: "",
    group_id: "",
  };
};

const createFakeStory = (args) => {
  return {
    node: _createStoryObject(args),
  };
};

const createFakeStoryWithoutNode = (args) => {
  return _createStoryObject(args);
};

/*
 * Helper functions
 */
const formatString = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toUpperCase() : word;
    })
    .replace(/s+/g, "")
    .replace(/-/g, "");
};

/*
 * Create all fakeStores to be used in test
 *
 * Examples of fakeStories' keys
 * - `we_orgEnCAHome`
 * - `we_orgFrCAHome`
 * - `tyiEnCAHome`
 * - `tyiFrCAHome`
 *
 * Type of fakeStories created:
 * - Normal Stories
 * - Partial Stories
 * - Data Stories
 */
const fakeStories = {};
sites.forEach((site) => {
  locales.forEach((locale) => {
    pages.forEach((page) => {
      // Pages Stories
      const story = createFakeStory({
        name: page || "Home",
        content: { body: `<div>${site}-${locale}-${page || "Home"}</div>` },
        slug: page,
        fullSlug: `${site}/${locale}/${page}`,
        isStartPage: !!page ? false : true,
      });
      fakeStories[
        `${site}${formatString(locale)}${formatString(page)}`
      ] = story;
    });

    // Global Partials Stories
    partialComponents.forEach((partial) => {
      const story = createFakeStory({
        name: partial,
        content: { body: `<div>${partial} ${site} ${locale}</div>` },
        slug: partial,
        fullSlug: `${site}/${locale}/___partials/${partial}`,
        isStartPage: false,
      });
      fakeStories[
        `${site}${formatString(locale)}___partials-${partial}`
      ] = story;
    });

    // Data Stories
    dataCategoryComponents.forEach((category) => {
      const story = createFakeStory({
        name: category,
        content: { body: "" },
        slug: category,
        fullSlug: `${site}/${locale}/___data/categories/${category}`,
        isStartPage: false,
      });
      fakeStories[
        `${site}${formatString(locale)}___data-categories-${category}`
      ] = story;
    });
  });
});

// Get list of all partials on different sites and locales
const partialKeys = [];
sites.forEach((site) => {
  locales.forEach((locale) => {
    partialComponents.forEach((partialComponent) => {
      partialKeys.push(
        ...Object.keys(fakeStories).filter((story) => {
          return (
            story ===
            `${site}${formatString(locale)}${formatString(partialComponent)}`
          );
        }),
      );
    });
  });
});
const partialList = partialKeys.map((key) => {
  return fakeStories[key];
});

// Get list of all pages on different sites and locales
const pageKeys = [];
sites.forEach((site) => {
  locales.forEach((locale) => {
    pages.forEach((page) => {
      pageKeys.push(
        ...Object.keys(fakeStories).filter((story) => {
          return (
            story === `${site}${formatString(locale)}${formatString(page)}`
          );
        }),
      );
    });
  });
});
const pageList = pageKeys.map((key) => {
  return fakeStories[key];
});

/*
 * Custom fake stories
 */
fakeStories["we_orgEnCACareersHome"] = createFakeStory({
  name: "Career Home Page",
  content: { body: "WE.org En-CA Career Home Page" },
  slug: "home",
  fullSlug: "we_org/en-CA/careers/",
  isStartPage: true,
});

fakeStories["we_orgEnCACareersTest"] = createFakeStory({
  name: "Career Test Page",
  content: { body: "WE.org En-CA Career Test Page" },
  slug: "test",
  fullSlug: "we_org/en-CA/careers/test",
  isStartPage: false,
});

fakeStories["we_orgEnCACareersSubnav"] = createFakeStory({
  name: "Sub Navigation",
  content: { body: "WE.org En-CA Careers Subnav" },
  slug: "subnav",
  fullSlug: "we_org/en-CA/careers/___partials/subnav",
  isStartPage: false,
});

fakeStories["we_orgEnGBCareersHome"] = createFakeStory({
  name: "Career Home Page",
  content: { body: "WE.org En-GB Career Home Page" },
  slug: "home",
  fullSlug: "we_org/en-GB/careers/",
  isStartPage: true,
});

fakeStories["we_orgEnGBCareersTest"] = createFakeStory({
  name: "Career Test Page",
  content: { body: "WE.org En-GB Career Test Page" },
  slug: "test",
  fullSlug: "we_org/en-GB/careers/test",
  isStartPage: false,
});

fakeStories["we_orgEnGBCareersSubnav"] = createFakeStory({
  name: "Sub Navigation",
  content: { body: "WE.org En-GB Careers Subnav" },
  slug: "subnav",
  fullSlug: "we_org/en-GB/careers/___partials/subnav",
  isStartPage: false,
});

// create a copy of fakeStories to be used in tests to keep
// the pureness of test data
const createFakeStories = () => {
  return _.cloneDeep(fakeStories);
};

export {
  createFakeStories,
  createFakeStory,
  createFakeStoryWithoutNode,
  formatString,
  locales,
  pageList,
  partialComponents,
  partialList,
  sites,
};
