/* eslint-disable no-unused-vars */
import React from "react";
import SbEditable from "storyblok-react";

import { graphql } from "gatsby";

import { I18nextProvider } from "react-i18next";

import { i18n } from "../i18n";
import components from "../components/components";
import { StoryblokContext } from "../context";

class Editor {
  windowGet = (params) => {
    return new Promise((resolve, reject) => {
      window.storyblok.get(params, resolve, reject);
    });
  };

  windowGetAll = (params) => {
    return new Promise((resolve, reject) => {
      window.storyblok.getAll(params, resolve, reject);
    });
  };

  // TODO: missing test
  loadStoryblokBridge = (accessToken, callback) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    // token can be obtained via queryString or site's config
    const token = this.getParam("token") || accessToken;
    script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${token}`;
    script.onload = callback;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  // TODO: missing test
  getParam = (val) => {
    let result = "";
    let tmp = [];

    window.location.search
      .substr(1)
      .split("&")
      .forEach((item) => {
        tmp = item.split("=");
        if (tmp[0] === val) {
          result = decodeURIComponent(tmp[1]);
        }
      });
    return result;
  };

  loadPartialData = async (site, locale) => {
    const data = await this.windowGetAll({
      version: "draft",
      starts_with: `${site}/${locale}/___partials`,
    });

    const partials = {};
    data.stories.forEach((partialStory) => {
      // paritialStory's slug is structured as we_org/en-CA/___partials/navigation
      const [
        partialSite,
        partialLocale,
        partialBase,
        ...partialSlug
      ] = partialStory.full_slug.split("/");
      if (partialLocale === locale && partialSite === site) {
        partials[partialStory.slug] = partialStory.content;
      }
    });

    return partials;
  };

  // if the page's full slug is: we_org/en-CA/careers/home
  // localParials are located in: we_org/en-CA/careers/___partials
  loadLocalPartialData = async (storyFullSlug, isFolderRoot) => {
    let localSlug = storyFullSlug.slice(0, -1); // remove the root path "/"
    if (!isFolderRoot) {
      localSlug = localSlug
        .split("/")
        .slice(0, -1)
        .join("/");
    }

    const data = await this.windowGetAll({
      version: "draft",
      starts_with: `${localSlug}/___partials/`,
    });

    const partialStories = data.stories;

    const partials = {};
    partialStories.forEach((partialStory) => {
      partials[partialStory.slug] = partialStory.content;
    });

    return partials;
  };

  loadStoryData = async (storySlug) => {
    const storyData = await this.windowGet({
      slug: storySlug,
      version: "draft",
    });

    const [
      baseSite,
      storyLocale,
      ...restStorySlug
    ] = storyData.story.full_slug.split("/");

    // load all global partials living under `___partials` by storyLocale
    const partials = await this.loadPartialData(baseSite, storyLocale);
    // load all local partials living under `storyFullSlug/___partials`
    const localPartials = await this.loadLocalPartialData(
      storyData.story.full_slug,
      storyData.story.is_startpage,
    );
    i18n.changeLanguage(storyLocale);
    const currentHref = this.getCurrentHref(window.location.search);

    return {
      story: storyData.story,
      partials: partials,
      localPartials: localPartials,
      locale: storyLocale,
      currentHref: currentHref,
      siteUrl: baseSite,
    };
  };

  getCurrentHref = (queryString) => {
    const currentHref = queryString
      .split("&")
      .find((query) => {
        return query.includes("path=");
      })
      .split("=")[1];
    return currentHref;
  };
}

class StoryblokEntryEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentHref: null,
      localPartials: null,
      partials: null,
      story: null,
      siteUrl: null,
    };
    this.editor = new Editor();
  }

  componentDidMount() {
    // Unlike other generated-with-content page, the Editor doesn't get
    // Storyblok's data via Gatsby build, but by using `storyblokBridge`
    // Thus, the Editor has access to ALL Storyblok's data
    const { data } = this.props;
    this.editor.loadStoryblokBridge(
      data.storyblokSpace.accessToken,
      this.initStoryblokEvents,
    );
  }

  componentWillReceiveProps() {
    const { data } = this.props;
    this.editor.loadStoryblokBridge(
      data.storyblokSpace.accessToken,
      this.initStoryblokEvents,
    );
  }

  initStoryblokEvents = async () => {
    // load data from Storyblok
    const storySlug = this.editor.getParam("path");
    this.setState(await this.editor.loadStoryData(storySlug));
    this.forceUpdate();

    // handlers for Storyblok's events
    const sb = window.storyblok;

    sb.on(["change", "published"], (payload) => {
      // currently doing nothing
    });

    sb.on("input", (payload) => {
      const { story } = this.state;
      if (story && payload.story.id === story.id) {
        this.setState({ story: payload.story });
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  };

  render() {
    const { data } = this.props;
    const { environment, allowedLocales } = data.site.siteMetadata; //eslint-disable-line

    if (environment === "publisher") {
      return (
        <div>
          <p>This page should not be available on production.</p>
          <strong>TODO: </strong>
          <p>redirect to home page</p>
        </div>
      );
    }

    const {
      currentHref,
      localPartials,
      locale,
      partials,
      story,
      siteUrl,
    } = this.state;

    if (!story) {
      return <div />;
    }

    const { content } = story;
    const editorPage = React.createElement(components[content.component], {
      currentHref: currentHref,
      key: content._uid,
      localPartials: localPartials,
      locale: locale,
      content: content,
      pageTitle: story.name,
      partials: partials,
      siteUrl: siteUrl,
      environment: environment,
      allowedLocales: allowedLocales,
    });

    return (
      <div>
        <SbEditable content={content}>
          <I18nextProvider i18n={i18n}>
            <StoryblokContext.Provider
              value={{
                locale,
                siteUrl,
                currentHref,
              }}>
              {editorPage}
            </StoryblokContext.Provider>
          </I18nextProvider>
        </SbEditable>
      </div>
    );
  }
}

export { Editor }; // for testing purpose

export default StoryblokEntryEditor;

/* Using GraphQL query to access to site's metadata
 * This will be "magically" available as `data` props
 * for the StoryblokEntryEditor component.
 *
 * For this magic to  work, query needs to be exposed via
 * `export`.
 *
 */
export const query = graphql`
  query {
    site {
      siteMetadata {
        allowedLocales {
          label
          value
          icon
        }
        environment
      }
    }
    storyblokSpace {
      accessToken
    }
  }
`;
