/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

import { composeClassName } from "../../utilities";
import NavigationColumn from "../NavigationColumn";

import "./NavigationMenu.scss";

const NavigationMenu = (props) => {
  const { className, internalLinkComponent, columns } = props;

  const menuColumns = columns.map((column, i) => {
    const { title, links } = column;
    return (
      <NavigationColumn
        key={`${title}-${i}`}
        title={title}
        links={links}
        internalLinkComponent={internalLinkComponent}
      />
    );
  });

  const composedClassName = composeClassName(["NavigationMenu", className]);

  return <div className={composedClassName}>{menuColumns}</div>;
};

NavigationMenu.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.array,
    }),
  ),
  internalLinkComponent: PropTypes.any,
};

NavigationMenu.defaultProps = {
  className: "",
  columns: [],
  internalLinkComponent: null,
};

export default NavigationMenu;
