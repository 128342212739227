import React from "react";
import PropTypes from "prop-types";
import BodyTextMarkdown from "../BodyTextMarkdown/BodyTextMarkdown";
import { Col } from "../../atoms";
import "./MultiColumnBodyTextMarkdown.scss";

const MultiColumnBodyTextMarkdown = (props) => {
  const { size, centered, textColumns, internalLinkComponent } = props;

  const columns = textColumns.length;

  return textColumns.map((column) => {
    return (
      <Col
        key={column.key}
        xs={{ span: 12 }}
        md={{ span: Math.max(4, 12 / columns) }}
        className="MultiColumn">
        <BodyTextMarkdown
          size={size}
          centered={centered}
          internalLinkComponent={internalLinkComponent}>
          {column.text}
        </BodyTextMarkdown>
      </Col>
    );
  });
};

MultiColumnBodyTextMarkdown.propTypes = {
  size: PropTypes.string.isRequired,
  centered: PropTypes.bool,
  textColumns: PropTypes.array.isRequired,
  internalLinkComponent: PropTypes.object.isRequired,
};

export default MultiColumnBodyTextMarkdown;
