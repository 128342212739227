import { extractStoryblokUrl } from "../../../shared/utilities";

const mapLink = (link = {}, currentLocale, storyblokLinks) => {
  const { Link, LinkTitle, Text } = link;
  const { href, external } = extractStoryblokUrl(
    Link,
    currentLocale,
    storyblokLinks,
  );
  return {
    href: href,
    external: external,
    title: LinkTitle,
    label: Text,
  };
};

const mapNavigationColumn = (column = {}, currentLocale, storyblokLinks) => {
  const { Title, Links = [] } = column;
  const links = Links.map((link) => {
    return mapLink(link, currentLocale, storyblokLinks);
  });
  return {
    title: Title,
    links: links,
  };
};

const mapNavigationTab = (tab = {}, currentLocale, storyblokLinks) => {
  const {
    Title,
    SubMenuTitle,
    SubMenuBody,
    ButtonText,
    ButtonLink,
    Columns = [],
  } = tab;
  const columns = Columns.map((column) => {
    return mapNavigationColumn(column, currentLocale, storyblokLinks);
  });
  const { href, external } = extractStoryblokUrl(
    ButtonLink,
    currentLocale,
    storyblokLinks,
  );
  return {
    label: Title,
    subMenuTitle: SubMenuTitle,
    subMenuBody: SubMenuBody,
    buttonLabel: ButtonText,
    buttonHref: href,
    buttonExternal: external,
    columns: columns,
  };
};

const mapSocialButton = (socialButton = {}, currentLocale, storyblokLinks) => {
  const { Link, LinkTitle, Icon, Theme } = socialButton;
  const { href, external } = extractStoryblokUrl(
    Link,
    currentLocale,
    storyblokLinks,
  );
  return {
    icon: Icon,
    theme: Theme,
    title: LinkTitle,
    href: href,
    external: external,
  };
};

export { mapLink, mapNavigationColumn, mapNavigationTab, mapSocialButton };
