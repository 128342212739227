import React from "react";
import PropTypes from "prop-types";
import { Heading, BodyText, Image } from "../../atoms";
import TextWithBackground from "../TextWithBackground/TextWithBackground";
import { colors } from "../../colors";
import { composeClassName } from "../../utilities";
import BaseTile from "../BaseTile/BaseTile";
import "./Tile.scss";

const Tile = (props) => {
  const {
    internalLinkComponent,
    backgroundColor,
    textColor,
    title,
    text,
    link,
    linkTitle,
    altText,
    longDesc,
    mobileImage,
    image,
    className,
  } = props;

  const composedClassName = composeClassName(["Tile", className]);

  const imageInfo = {
    altText: altText,
    longDesc: longDesc,
    mobileSource: mobileImage,
    source: image,
  };

  return (
    <BaseTile
      xs={{ span: 12 }}
      md={{ span: 4 }}
      className={composedClassName}
      internalLinkComponent={internalLinkComponent}
      Link={link}
      LinkTitle={linkTitle}
      whiteText={textColor.includes("white")}>
      <Image {...imageInfo} />
      <TextWithBackground
        color={colors[backgroundColor].color}
        whiteText={textColor.includes("white")}>
        <Heading size="XS" className="Title">
          {title}
        </Heading>
        <BodyText size="XS" className="Text">
          {text}
        </BodyText>
      </TextWithBackground>
    </BaseTile>
  );
};

Tile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  internalLinkComponent: PropTypes.any,
  link: PropTypes.object,
  linkTitle: PropTypes.string,
  longDesc: PropTypes.string,
  mobileImage: PropTypes.string,
  text: PropTypes.string,
};

export default Tile;
