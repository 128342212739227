import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import { GenericContent as SharedGenericContent } from "../../../shared/molecules";
import Components from "../../components";
import Block from "../Block";

class GenericContent extends PureComponent {
  static propTypes = {
    content: PropTypes.shape({
      BodyTextContent: PropTypes.string.isRequired,
      Size: PropTypes.string.isRequired,
      Centered: PropTypes.bool,
    }),
  };

  render() {
    const { content, partial, locale } = this.props;

    const {
      Image,
      MobileImage,
      AltText,
      LongDesc,
      Title,
      BodyTextContent,
      BodyTextSize,
      Centered,
      Buttons,
    } = content;

    // TODO: Move to utility once Luke's PR merged
    const contentToComponents = (contentArray, globals) => {
      if (contentArray && contentArray instanceof Array) {
        return contentArray.map((cnt) => {
          const { _uid, component, ...props } = cnt;
          return (
            <React.Fragment key={_uid}>
              <SbEditable content={cnt}>
                {React.createElement(Components[component], {
                  ...globals,
                  ...props,
                })}
              </SbEditable>
            </React.Fragment>
          );
        });
      }
      return [];
    };

    return (
      <Block content={content} className="GenericContentBlock">
        <SharedGenericContent
          imageMobileSource={MobileImage}
          imageSource={Image}
          imageAltText={AltText}
          imageLongDesc={LongDesc}
          titleText={Title}
          body={BodyTextContent}
          bodySize={BodyTextSize}
          centered={Centered}
          buttons={contentToComponents(Buttons, { partial, locale })}
        />
      </Block>
    );
  }
}

export default GenericContent;
