/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { getContent } from "../../../../storyblokPageCreator";
import { siteMetadata } from "../../../../gatsby-config";
import { getRealStoryblokUrl } from "../../../shared/utilities";

const getWeStoriesMetadata = (story) => {
  // select only data that matters
  const {
    StoryCategories,
    StoryDescription,
    StoryImageAlt,
    StoryPrimaryImage,
    StorySecondaryImage,
    StorySubtitle,
    StoryTitle,
  } = getContent(story);

  return {
    storyCategories: StoryCategories,
    storyDescription: StoryDescription,
    storyImageAlt: StoryImageAlt,
    storyPrimaryImage: StoryPrimaryImage,
    storySecondaryImage: StorySecondaryImage,
    storySubtitle: StorySubtitle,
    storyTitle: StoryTitle,
  };
};

const deconstructStoryblokUrl = ({ sites, locales, url }) => {
  const sitesStr = sites.join("|");
  const localesStr = locales.join("|");

  const regexStr = `/?(${sitesStr})?/?(${localesStr})?/?(.*)`;
  const regex = new RegExp(regexStr);

  const [wholeString, site, locale, path] = url.match(regex);
  return { site, locale, path };
};

const _queryDataFromStory = (regex, storyContent) => {
  const group = storyContent.match(regex);
  let match = [];

  if (group) {
    [, match] = group;
  }

  return match;
};

const _hasMatchingLocale = (story, locale) => {
  const regex = new RegExp(`.*/${locale}(/.*|$)`);
  return regex.test(story.full_slug);
};

const _hasMatchingCategory = (story, category) => {
  const storyCategories = _queryDataFromStory(
    /"StoryCategories":(\[(".*[^"]")?\])/,
    story.content,
  );
  return storyCategories.includes(category);
};

const _hasMatchingMirroringLocale = (story, locale) => {
  const mirroringLocales = _queryDataFromStory(
    /"MirroringLocales":(\[(".*[^"]")?\])/,
    story.content,
  );
  return mirroringLocales.includes(locale);
};

const _hasSameSlug = (story, currentSlug, { sites, locales }) => {
  // story's fullSlug is always /site/locale/slug
  // currentSlug could either be:
  //  - /site/locale/slug
  //  - /locale/slug
  //  - /slug
  const storyPath = deconstructStoryblokUrl({
    sites: sites,
    locales: locales,
    url: story.full_slug,
  }).path;
  const currentPath = deconstructStoryblokUrl({
    sites: sites,
    locales: locales,
    url: currentSlug,
  }).path;
  return storyPath === currentPath;
};

const _deduplicateStories = (stories, locale) => {
  // O(n) = n.log(n) right now. refactor if needed
  const cached = {};

  stories.forEach((story, index) => {
    const storyPath = story.full_slug.replace(
      /.*\/[a-z]{2}-[A-Z]{2}(\/.*|$)/,
      "$1",
    );
    const mirroringLocales = _queryDataFromStory(
      /"MirroringLocales":(\[(".*[^"]")?\])/,
      story.content,
    );

    if (!(cached[storyPath] && mirroringLocales.includes(locale))) {
      let prevIndex;
      if (cached[storyPath]) {
        prevIndex = cached[storyPath].index;
      }
      cached[storyPath] = {
        story: story,
        index: typeof prevIndex !== "undefined" ? prevIndex : index,
      };
    }
  });

  const uniqueStoriesWithIndex = Object.keys(cached).map((key) => {
    return cached[key];
  });

  const uniqueStories = uniqueStoriesWithIndex
    .sort((a, b) => {
      return a.index - b.index;
    })
    .map((unique) => {
      return unique.story;
    });

  return uniqueStories;
};

const getPinnedStories = (stories, storyLinks, storyblokLinks) => {
  const pinUrls = storyLinks.map((storyLink) => {
    return getRealStoryblokUrl(storyLink.Link, storyblokLinks);
  });

  return stories.filter((story) => {
    return pinUrls.includes(story.full_slug);
  });
};

const filterWeStories = ({ category, currentSlug, locale, stories }) => {
  const { siteCodes, localeCodes } = siteMetadata;

  const filteredStories = stories.filter((story) => {
    return (
      _hasMatchingCategory(story, category) &&
      !_hasSameSlug(story, currentSlug, {
        sites: siteCodes,
        locales: localeCodes,
      }) &&
      (_hasMatchingLocale(story, locale) ||
        _hasMatchingMirroringLocale(story, locale))
    );
  });

  return _deduplicateStories(filteredStories, locale);
};

export {
  _hasMatchingCategory, // for testing purposes
  _hasMatchingMirroringLocale, // for testing purposes
  deconstructStoryblokUrl,
  filterWeStories,
  getPinnedStories,
  getWeStoriesMetadata,
};
