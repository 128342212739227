import React from "react";
import { SocialMediaBar as SharedSocialMediaBar } from "../../../shared/organisms";
import Block from "../Block";

const SocialMediaBar = (props) => {
  const { content } = props;
  const { ShareText } = content;
  return (
    <Block content={content} className="SocialMediaBarBlock">
      <SharedSocialMediaBar shareText={ShareText} />
    </Block>
  );
};

export default SocialMediaBar;
