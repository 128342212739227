import React, { PureComponent } from "react";
import { InputTextArea as Textarea } from "../../../shared/forms/fields";

class InputTextarea extends PureComponent {
  render() {
    const {
      Label,
      Placeholder,
      SalesforceField,
      Title,
      MaxLength,
      Required,
      ...rest
    } = this.props;

    const { validateAgainst = {} } = this.props;

    if (Required) {
      validateAgainst.required = true;
    }
    return (
      <Textarea
        label={Label}
        placeholder={Placeholder}
        name={SalesforceField}
        required={Required}
        title={Title}
        maxLength={MaxLength}
        {...rest}
      />
    );
  }
}

export default InputTextarea;
