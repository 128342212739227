import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ModalVideo from "../ModalVideo";
import {
  Image as ImageComponent,
  ImageBackground,
  DisplayText,
  Heading,
} from "../../atoms";
import "./Stories.scss";
import { composeClassName } from "../../utilities";

class Stories extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    image: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    longdesc: PropTypes.string,
    video: PropTypes.shape({
      source: PropTypes.string,
      videoId: PropTypes.string,
      title: PropTypes.string,
      theme: PropTypes.oneOf(["light", "dark"]),
    }),
    tag: PropTypes.string.isRequired,
    articleTitle: PropTypes.string.isRequired,
    whiteText: PropTypes.bool,
  };

  render() {
    const {
      className,
      image,
      altText,
      longdesc,
      video,
      tag,
      articleTitle,
      whiteText,
    } = this.props;

    const modalProps = {
      ...video,
    };

    const storiesTextComponent = (
      <div>
        {tag ? (
          <DisplayText whiteText={whiteText} size="S">
            {tag}
          </DisplayText>
        ) : null}
        {articleTitle ? (
          <Heading whiteText={whiteText} size="XS">
            {articleTitle}
          </Heading>
        ) : null}
      </div>
    );

    const classNameList = [className, "Stories"];

    let storiesTileContent = (
      <div>
        <ImageComponent
          className="StoriesImage"
          source={image}
          altText={altText}
          longdesc={longdesc}
        />
        {storiesTextComponent}
      </div>
    );

    if (video && video.videoId && video.title) {
      classNameList.push("WithVideo");

      storiesTileContent = (
        <div>
          <ImageBackground className="StoriesImage Video" source={image}>
            <ModalVideo
              className="StoriesImage PlayButton"
              noDisplayText
              {...modalProps}
            />
          </ImageBackground>
          {storiesTextComponent}
        </div>
      );
    }

    const composedClassName = composeClassName(classNameList);

    return <div className={composedClassName}>{storiesTileContent}</div>;
  }
}

export default Stories;
