/* eslint-disable import/prefer-default-export */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import { globalHistory } from "@reach/router";
import { anchorate } from "anchorate";
import * as Sentry from "@sentry/browser";
import SmoothScroll from "smooth-scroll";

import { browser } from "./src/shared/utilities";

export const onClientEntry = () => {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN_URL,
    release: process.env.GATSBY_SENTRY_RELEASE,
    environment: process.env.GATSBY_SENTRY_ENVIRONMENT || "dev",
    beforeSend: (event, hint) => {
      // temporarily disable sending event to Sentry
      // TODO: filter events properly
      return null;
      // const stackTrace = hint.originalException.stack;
      // const { breadcrumbs } = event;
      // // the event fire right before the Exception
      // const lastCrumb = breadcrumbs[breadcrumbs.length - 1];
      // if (
      //   lastCrumb.category === "xhr" &&
      //   lastCrumb.data.url.search("page-data.json") !== -1
      // ) {
      //   return null;
      // }
      // if (
      //   stackTrace.search("Cannot read property 'startChat' of undefined") !==
      //   -1
      // ) {
      //   return null;
      // }
      // return event;
    },
    debug: false,
  });
};

export const onRouteUpdate = ({ prevLocation }) => {
  if (typeof browser.window !== "undefined") {
    browser.window.prevLocation = prevLocation;
  }

  const smoothScroll = new SmoothScroll("a[href*='#']");
  anchorate({
    scroller: (element) => {
      if (!element) {
        return false;
      }
      // For scrolling to anchor links on a new page we are first scrolling to the top
      // and then smooth scrolling to the element. The reason for this is that when the
      // anchor link is clicked and the new page opens it starts off on the specified element.
      window.scroll({ top: 0, left: 0 });
      smoothScroll.animateScroll(element);
      return true;
    },
  });
};

export const onInitialClientRender = () => {
  // This is a workaround for a bug in Gatsby.
  // Browser's go back button not working for some pages.
  // See https://github.com/gatsbyjs/gatsby/issues/8357 for more details.
  globalHistory._onTransitionComplete();
};
