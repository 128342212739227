import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Heading, DisplayText, Image, Col, Container } from "../../atoms";
import { BodyTextMarkdown, ButtonCloseModal } from "../../molecules";

import "./ModalMapStory.scss";

if (typeof window !== "undefined" && process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#___gatsby");
};

const MapStory = ({
  location,
  title,
  banner,
  author,
  altText,
  content,
  internalLinkComponent,
}) => {
  const locationElement = (
    <DisplayText className="StoryLocation" size="M">
      {location}
    </DisplayText>
  );
  const titleElement = <Heading size="M">{title}</Heading>;
  const imageElement = <Image source={banner} altText={altText} />;
  const authorElement = (
    <DisplayText className="StoryAuthor" size="M">
      {author}
    </DisplayText>
  );
  const bodyElement = (
    <BodyTextMarkdown size="S" internalLinkComponent={internalLinkComponent}>
      {content}
    </BodyTextMarkdown>
  );

  return (
    <Col className="MapStory" xs={{ span: 12 }}>
      {location && locationElement}
      {title && titleElement}
      {banner && imageElement}
      {author && authorElement}
      {content && bodyElement}
    </Col>
  );
};

const storyShape = {
  location: PropTypes.string,
  title: PropTypes.string,
  banner: PropTypes.string,
  altText: PropTypes.string,
  author: PropTypes.string,
  content: PropTypes.string,
  state: PropTypes.string,
};

MapStory.propTypes = {
  ...storyShape,
  internalLinkComponent: PropTypes.any.isRequired,
};

MapStory.defaultProps = {
  location: "",
  title: "",
  banner: "",
  altText: "",
  author: "",
  content: "",
  state: "",
  internalLinkComponent: () => { },
};

const _getScreenReaderLabel = (story) => {
  if (!story) {
    return undefined;
  }

  return story.title ? story.title : story.state;
};

const ModalMapStory = ({
  isOpen,
  story,
  internalLinkComponent,
  onClickHandler,
  closeHandler,
}) => {
  const screenReaderLabel = _getScreenReaderLabel(story);

  return (
    <Modal
      closeTimeoutMS={250}
      contentLabel={screenReaderLabel}
      className="ModalMapStory"
      isOpen={isOpen}
      onRequestClose={closeHandler}
      overlayClassName="ModalOverlay">
      <ButtonCloseModal onClickHandler={onClickHandler} />
      <div className="ModalBody">
        <Container>
          <MapStory {...story} internalLinkComponent={internalLinkComponent} />
        </Container>
      </div>
    </Modal>
  );
};

ModalMapStory.propTypes = {
  isOpen: PropTypes.bool,
  story: PropTypes.shape(storyShape),
  internalLinkComponent: PropTypes.any.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default ModalMapStory;
