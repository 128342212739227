import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Container, Heading } from "../../atoms";
import ButtonLink from "../../molecules/ButtonLink";
import { colors } from "../../colors";
import { composeClassName } from "../../utilities";
import "./StoriesLayout.scss";

class StoriesLayout extends PureComponent {
  static propTypes = {
    titleText: PropTypes.string.isRequired,
    titleSize: PropTypes.oneOf(["S", "M", "L"]),
    underlined: PropTypes.bool,
    underlineColor: PropTypes.string,
    whiteText: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    buttonTheme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
    href: PropTypes.string,
    external: PropTypes.bool,
    linkTitle: PropTypes.string,
    backgroundColor: PropTypes.oneOf([
      "base-white-color",
      "base-light-blue-color",
      "brand-navy-color",
      "brand-yellow-color",
    ]),
    centered: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    titleSize: "L",
    underlined: true,
    underlineColor: "#00aeef",
    whiteText: false,
    buttonTheme: "Primary",
    backgroundColor: "base-white-color",
    centered: false,
    className: "",
  };

  render() {
    const {
      titleText,
      titleSize,
      underlined,
      underlineColor,
      whiteText,
      buttonText,
      buttonTheme,
      href,
      external,
      linkTitle,
      backgroundColor,
      centered,
      className,
      children,
      internalLinkComponent,
    } = this.props;

    const composedClassName = composeClassName(["StoriesLayout", className]);

    let button = null;
    if (href && buttonText) {
      button = (
        <ButtonLink
          href={href}
          external={external}
          internalLinkComponent={internalLinkComponent}
          title={linkTitle}
          theme={buttonTheme}>
          {buttonText}
        </ButtonLink>
      );
    }

    let heading = null;
    if (titleText && titleSize) {
      heading = (
        <Heading
          className="StoriesLayoutHeading"
          size={titleSize}
          underlined={underlined}
          underlineColor={underlineColor}
          whiteText={whiteText}>
          {titleText}
        </Heading>
      );
    }

    const childrenToRender = [];
    React.Children.forEach(children, (child) => {
      childrenToRender.push(child);
    });

    return (
      <Container
        className={composedClassName}
        style={{ backgroundColor: colors[backgroundColor].color }}>
        {heading}
        <Container className={centered ? "CenteredTiles" : ""}>
          {childrenToRender}
          {button}
        </Container>
      </Container>
    );
  }
}

export default StoriesLayout;
