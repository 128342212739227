/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unused-prop-types */

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import { composeClassName } from "../../../utilities";
import { BodyText, Label, Icon } from "../../../atoms";
import InputRadioButton from "../InputRadioButton";

import asFormElement from "../../asFormElement";
import { FormContext } from "../../Form";

import "./InputRadioGroup.scss";

class InputRadioGroup extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    /** The value has to be one of the values provided with options */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // eslint-disable-next-line react/no-unused-prop-types
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangeHandler: PropTypes.func,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    /** An `options` object with the list of options and one checked value */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
      }).isRequired,
    ),
    theme: PropTypes.oneOf(["Default", "Square"]),
    whiteText: PropTypes.bool,
    message: PropTypes.string,
    /** Indicates if there is an error in the user input */
    error: PropTypes.bool,
    fieldType: PropTypes.oneOf(["string"]),
  };

  static defaultProps = {
    autoFocus: false,
    theme: "Default",
    whiteText: false,
    className: "",
    error: false,
    fieldType: "string",
  };

  render() {
    const {
      options,
      autoFocus,
      className,
      label,
      onChangeHandler,
      name,
      value,
      theme,
      whiteText,
      message,
      error,
    } = this.props;

    const composedClassName = composeClassName([
      "InputRadioGroup",
      error ? "error" : "",
      className,
    ]);

    let iconComponent = null;
    if (error) {
      iconComponent = <Icon size="S" icon="close" className="ErrorIcon" />;
    }

    const inputRadioButtons = options.map((item) => {
      return (
        <InputRadioButton
          theme={theme}
          checked={item.value === value}
          disabled={item.disabled}
          key={`${item.value}-${name}`}
          label={item.label}
          name={name}
          onChangeHandler={onChangeHandler}
          tabIndex="0"
          value={item.value}
          whiteText={whiteText}
          error={error}
        />
      );
    });

    return (
      <div
        className={composedClassName}
        role="radiogroup"
        id={`${name}-radiogroup`}
        autoFocus={autoFocus}>
        <Label
          htmlFor={`${name}-radiogroup`}
          whiteText={whiteText}
          label={label}>
          {inputRadioButtons}
        </Label>
        {iconComponent}
        <BodyText size="S" className="ErrorMessage">
          <Trans i18nKey={message}>{null}</Trans>
        </BodyText>
      </div>
    );
  }
}

export default asFormElement(InputRadioGroup, FormContext);
