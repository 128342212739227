/* eslint-disable operator-linebreak */
import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { colors } from "../../../shared/colors";
import Block from "../Block";
import { Mosaic as SharedMosaic } from "../../../shared/organisms";
import { withStoryblokLink } from "../../utilities";

const Mosaic = (props) => {
  const { content } = props;

  const {
    Tile1AltText,
    Tile1BackgroundColor,
    Tile1Body,
    Tile1Image,
    Tile1Link,
    Tile1LinkTitle,
    Tile1LongDesc,
    Tile1MobileImage,
    Tile1Subtitle,
    Tile1TextColor,
    Tile1Title,
    Tile2BAltText,
    Tile2BBackgroundColor,
    Tile2BBody,
    Tile2BImage,
    Tile2BLink,
    Tile2BLinkTitle,
    Tile2BLongDesc,
    Tile2BMobileImage,
    Tile2BSubtitle,
    Tile2BTextColor,
    Tile2BTitle,
    Tile2CAltText,
    Tile2CBackgroundColor,
    Tile2CBody,
    Tile2CImage,
    Tile2CLink,
    Tile2CLinkTitle,
    Tile2CLongDesc,
    Tile2CMobileImage,
    Tile2CSubtitle,
    Tile2CTextColor,
    Tile2CTitle,
    Tile2Layout,
  } = content;

  const whiteText = "base-white-color";
  return (
    <Block content={content} className="MosaicBlock">
      <SharedMosaic
        tile1={{
          backgroundColor: colors[Tile1BackgroundColor].color,
          body: Tile1Body,
          href: Tile1Link.href,
          altText: Tile1AltText,
          longDesc: Tile1LongDesc,
          mobileImage: Tile1MobileImage,
          image: Tile1Image,
          internalLinkComponent: GatsbyLink,
          external: Tile1Link.external,
          linkTitle: Tile1LinkTitle,
          subtitle: Tile1Subtitle,
          title: Tile1Title,
          whiteText: Tile1TextColor === whiteText,
        }}
        tile2={{
          backgroundColor: colors[Tile2BBackgroundColor].color,
          body: Tile2BBody,
          href: Tile2BLink.href,
          altText: Tile2BAltText,
          longDesc: Tile2BLongDesc,
          mobileImage: Tile2BMobileImage,
          image: Tile2BImage,
          internalLinkComponent: GatsbyLink,
          external: Tile2BLink.external,
          linkTitle: Tile2BLinkTitle,
          subtitle: Tile2BSubtitle,
          title: Tile2BTitle,
          whiteText: Tile2BTextColor === whiteText,
        }}
        tile3={{
          backgroundColor: colors[Tile2CBackgroundColor].color,
          body: Tile2CBody,
          href: Tile2CLink.href,
          altText: Tile2CAltText,
          longDesc: Tile2CLongDesc,
          mobileImage: Tile2CMobileImage,
          image: Tile2CImage,
          internalLinkComponent: GatsbyLink,
          external: Tile2CLink.external,
          linkTitle: Tile2CLinkTitle,
          subtitle: Tile2CSubtitle,
          title: Tile2CTitle,
          whiteText: Tile2CTextColor === whiteText,
        }}
        internalLinkComponent={GatsbyLink}
        columnLayout={Tile2Layout}
      />
    </Block>
  );
};

export default withStoryblokLink(Mosaic, {
  LinkFields: ["Tile2BLink", "Tile2CLink", "Tile1Link"],
});
