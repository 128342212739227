import React from "react";
import { Link } from "gatsby";
import { NavigationBar } from "../../../shared/organisms";
import { mapNavigationTab, mapLink } from "../../utilities";
import { StoryblokContext } from "../../../context";

const Navigation = (props) => {
  const {
    content,
    logoHref,
    logoExternal,
    currentLocale,
    allowedLocales,
    overrideLocaleHack,
  } = props;
  const { ExternalLinks = [], Categories = [], Logo } = content;

  return (
    <StoryblokContext.Consumer>
      {({ storyblokLinks }) => {
        const navigationLinks = ExternalLinks.map((link) => {
          return mapLink(link, currentLocale, storyblokLinks);
        });
        const navigationTabs = Categories.map((tab) => {
          return mapNavigationTab(tab, currentLocale, storyblokLinks);
        });

        return (
          <NavigationBar
            overrideLocaleHack={overrideLocaleHack}
            currentLocale={currentLocale}
            allowedLocales={allowedLocales}
            logo={Logo}
            logoExternal={logoExternal}
            logoHref={logoHref}
            navigationTabs={navigationTabs}
            navigationLinks={navigationLinks}
            internalLinkComponent={Link}
          />
        );
      }}
    </StoryblokContext.Consumer>
  );
};

export default Navigation;
