/* eslint-disable react/no-unused-state, no-unused-vars */
import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import _ from "lodash";
import { BodyText } from "../atoms";
import { browser, parseQuery } from "../utilities";
// Temporary solution to approved message, should be replaced by render prop.
import "./Form.scss";

const FormContext = React.createContext({});

class Form extends Component {
  static propTypes = {
    formName: PropTypes.string.isRequired,
    formEncode: PropTypes.bool,
    headers: PropTypes.object,
    method: PropTypes.oneOf(["POST", "GET", "PUT"]),
    onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    submitUrl: PropTypes.string,
    submissionMessage: PropTypes.string,
    /** Collects parameters in the current URL and adds them to submission object.
     * If `true`, defaults to collecting GTM params.
     * Can provide object with key as param to collect and value as key for submission.
     * Defaults to true and will only work if it finds the keys in the URL. */
    collectUrlParams: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  };

  static defaultProps = {
    formEncode: false,
    headers: {},
    method: "POST",
    onSubmit: false,
    submitUrl: "/",
    submissionMessage: "Thank you for your submission.",
    collectUrlParams: true,
  };

  constructor(props) {
    super(props);
    // State will hold the values and validations,
    // and be the single source of truth for the entire form
    this.state = {
      values: {},
      validations: {},
      formIsValid: true,
      fetchSuccessful: false,
      fetchFailure: false,
      submitAttempts: 0,
      initialValues: {}, // Lets us reset the form
      fieldTypes: {},
    };
  }

  // Passed to fields via context so they can register to the form state.
  // register() is call from asFormElement to "mutate" the state before the Form is rendered
  register = (name, initialValue, validateAgainst, fieldType) => {
    const { values, validations, initialValues, fieldTypes } = this.state;

    // Write state initial form values into  state
    values[name] = initialValue;
    initialValues[name] = initialValue;
    fieldTypes[name] = fieldType;

    // Sets validations for field into state
    if (validateAgainst) {
      validations[name] = {
        error: false,
        message: false,
        validateAgainst: validateAgainst,
      };
      this.setState({ validations });
    }
    this.setState({ values, initialValues, fieldTypes });
  };

  // Passed to fields via context so they can make their changes on the form state.
  handleOnChange = ({ name, value }) => {
    const newState = _.cloneDeep(this.state);
    const { values, validations, formIsValid } = newState;

    values[name] = value;
    // Only checks for validity on keypress if the form has been submitted.
    // TODO: Track error and message state throughout,
    // only display on submit - otherwise there is a lag
    if (!formIsValid && validations[name]) {
      validations[name] = this._validate(value, validations[name]);
      this.setState({ validations });
    }

    this.setState({ values: values, fetchSuccessful: false });
  };

  _validate = (value, validations) => {
    const checkedValidations = { ...validations };
    checkedValidations.error = false;
    checkedValidations.message = false;

    const { validateAgainst } = validations;

    // Iterate over all the validations from a field
    Object.keys(validateAgainst).forEach((key) => {
      const { emptyValue } = validateAgainst[key];
      const isError = !validateAgainst[key].fn({
        value,
        emptyValue,
      });
      checkedValidations.validateAgainst[key].error = isError;
      checkedValidations.error = checkedValidations.error || isError;

      // If there's an error, set the message for the field to it
      if (isError) {
        const { message } = validateAgainst[key];
        checkedValidations.message =
          typeof message === "string" ? message : message();
      }
    });

    // Required always takes precedence for error messages if it's in error.
    if (
      checkedValidations.error &&
      checkedValidations.required &&
      checkedValidations.required.error
    ) {
      const { message } = checkedValidations.required;
      checkedValidations.message =
        typeof message === "string" ? message : message();
    }
    return checkedValidations;
  };

  _encode = (data) => {
    return Object.keys(data)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
      })
      .join("&");
  };

  _sendForm = async (data) => {
    const { submitUrl, headers, method, formEncode } = this.props;
    let formData = data;
    const options = {};

    // Set content type accordingly
    if (formEncode) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
      formData = this._encode(data);
    } else {
      headers["Content-Type"] = "application/json";
    }

    const response = await axios({
      url: submitUrl,
      method: method,
      headers: headers,
      data: formData,
    });
    return response;
  };

  _getFormValidStatus = (values, validations) => {
    let formIsValid = true;
    const newValidations = { ...validations };

    // validate all the fields
    Object.keys(validations).forEach((name) => {
      const validated = this._validate(values[name], validations[name]);
      newValidations[name] = validated;
      formIsValid = formIsValid && !validated.error;
    });

    return { formIsValid, newValidations };
  };

  _handleSubmit = (event) => {
    event.preventDefault();
    const localStorageQuerySearchKey = "initialUrlQuery";
    const {
      validations,
      values,
      initialValues,
      submitAttempts,
      fieldTypes,
    } = this.state;
    const {
      onSubmit,
      formEncode,
      formName,
      submitUrl,
      collectUrlParams,
    } = this.props;
    const { formIsValid, newValidations } = this._getFormValidStatus(
      values,
      validations,
    );

    this.setState(
      {
        fetchSuccessful: false,
        formIsValid: formIsValid,
        validations: newValidations,
        submitAttempts: submitAttempts + 1,
      },
      async () => {
        let collectedParams = {};
        // If this is not false we check for UTM params
        if (collectUrlParams) {
          let paramsToCollect;
          // In the case of a boolean true, use the default utm params for collection
          if (collectUrlParams === true) {
            paramsToCollect = {
              utm_campaign: "CampaignMember.Marketing_ID__c",
              utm_source: "CampaignMember.Marketing_Source__c",
              utm_medium: "CampaignMember.Marketing_Medium__c",
              utm_term: "CampaignMember.Marketing_Keyword__c",
            };
            // In the case of a different object being passed, use that instead.
          } else paramsToCollect = { ...collectUrlParams };
          // Turn the search params into a usable object
          const searchString = browser.window.localStorage.getItem(
            localStorageQuerySearchKey,
          );
          collectedParams =
            searchString != null
              ? parseQuery(searchString, paramsToCollect)
              : {};
        }

        // Collect all data that must be send together.
        const data = {
          "form-name": formName,
          "current-page": browser.window.location,
          ...values,
          ...collectedParams,
          fieldTypes: JSON.stringify({ ...fieldTypes }),
        };

        // If the form is invalid, don't do anything else except call the parents onSubmit
        if (!formIsValid) {
          if (onSubmit) {
            onSubmit(data, false);
          }
          return;
        }

        if (submitUrl) {
          const response = await this._sendForm(data);
          if (onSubmit) {
            onSubmit(data, response.statusCode);
          }

          // TODO: provide real error catching
          if (response.status !== 200) {
            this.setState({ fetchFailure: true, fetchSuccessful: false });
          } else {
            this.setState({
              fetchSuccessful: true,
              fetchFailure: false,
              values: initialValues,
            });
            // Clear Stored query Props from Session Storage
            browser.window.localStorage.removeItem(localStorageQuerySearchKey);
          }
        } else {
          onSubmit(data);
        }
      },
    );
  };

  render() {
    const { values, validations, submitAttempts, fetchSuccessful } = this.state;
    const { submissionMessage, children, formName, dataAttr = {} } = this.props;
    // Render the context and the form
    return (
      <FormContext.Provider
        value={{
          register: this.register,
          onChange: this.handleOnChange,
          values: values,
          validations: validations,
          submitAttempts: submitAttempts,
        }}>
        <form
          onSubmit={this._handleSubmit}
          name={formName}
          noValidate // Turns off browser validation
          {...dataAttr}>
          {children}
          {fetchSuccessful ? (
            <BodyText
              data-testid="form-submission-message"
              size="L"
              className="submitted">
              {submissionMessage}
            </BodyText>
          ) : null}
        </form>
      </FormContext.Provider>
    );
  }
}

export default Form;
export { FormContext };
