import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./FieldSet.scss";
import "./IESupport.scss";

class FieldSet extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    legend: PropTypes.string.isRequired,
    fields: PropTypes.array,
    whiteText: PropTypes.bool,
  };

  static defaultProps = {
    legend: "legend",
    whiteText: false,
  };

  render() {
    const { className, legend, fields, whiteText } = this.props;

    const composedClassName = composeClassName(["FieldSet", className]);

    return (
      <div className={composedClassName}>
        <fieldset>
          <legend className={whiteText ? "whiteText" : ""}>{legend}</legend>
          {fields}
        </fieldset>
      </div>
    );
  }
}
export default FieldSet;
