import React from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../../utilities";
import { Icon, IconCountry, BodyText } from "../../../atoms";
import "./InputDropdownOption.scss";

const InputDropdownOption = React.forwardRef((props, ref) => {
  const {
    icon,
    value,
    name,
    className,
    label,
    selected,
    onClickHandler,
    onKeyDownHandler,
    ariaProps,
  } = props;

  const composedClassName = composeClassName([
    "InputDropdownOption",
    className,
  ]);

  const iconComponent = icon ? <IconCountry size="S" icon={icon} /> : null;
  const checkmarkIcon = selected ? (
    <Icon size="S" icon="checkmark" className="CheckmarkIcon" />
  ) : null;

  const _handleOnClick = () => {
    onClickHandler({ name, value });
  };

  return (
    <li
      {...ariaProps}
      aria-selected={selected}
      role="option"
      className={composedClassName}
      data-value={value}
      ref={ref}
      tabIndex="0"
      onClick={() => {
        _handleOnClick();
      }}
      onKeyDown={(e) => {
        return onKeyDownHandler(e);
      }}>
      {iconComponent}
      <BodyText size="S">{label}</BodyText>
      {checkmarkIcon}
    </li>
  );
});

InputDropdownOption.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onClickHandler: PropTypes.func.isRequired,
  icon: PropTypes.string,
  selected: PropTypes.bool,
};

InputDropdownOption.defaultProps = {
  className: "",
  icon: null,
  selected: false,
};

export default InputDropdownOption;
