/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved  */
/* eslint-disable global-require */

// Format of accepted URLs should be:
// - Having "/" in the beginning path of the URL
// - Having no "/" at the end of the URL, if the URL is a folder's URL, the "/" should be removed
//
//
// Example of proper URL:
// /en-CA/about-we/we-charity
// /en-CA/about-we/we-charity/*
const { sanitize } = require("./rules");

let we_orgNoIndexUrls = [];
let tripsNoIndexUrls = [];
let tyiNoIndexUrls = [];
try {
  we_orgNoIndexUrls = require("../sources/we_org-no-index-no-follow");
} catch (error) {
  we_orgNoIndexUrls = [];
}
try {
  tripsNoIndexUrls = require("../sources/trips-no-index-no-follow");
} catch (error) {
  tripsNoIndexUrls = [];
}
try {
  tyiNoIndexUrls = require("../sources/tyi-no-index-no-follow");
} catch (error) {
  tyiNoIndexUrls = [];
}

const noIndexUrls = {
  we_org: sanitize(we_orgNoIndexUrls),
  trips: sanitize(tripsNoIndexUrls),
  tyi: sanitize(tyiNoIndexUrls),
};

module.exports = {
  noIndexUrls,
};
