import React, { PureComponent } from "react";
import SmoothScroll from "smooth-scroll";
import { withTranslation } from "react-i18next";

import ButtonIcon from "../ButtonIcon";
import { composeClassName, browser } from "../../utilities";

import "./ButtonBackToTop.scss";

class ButtonBackToTop extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { scrolled: false };
  }

  componentDidMount() {
    browser.window.onscroll = () => {
      this._handleOnScroll();
    };
    this.smoothScroll = new SmoothScroll();
  }

  _handleOnScroll = () => {
    if (
      browser.window.pageYOffset >=
      browser.document.documentElement.offsetHeight / 5
    ) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  render() {
    const { className, t } = this.props;
    const { scrolled } = this.state;

    let buttonSize = "L";
    if (browser.window.innerWidth < 1024) {
      buttonSize = "M";
    }
    if (browser.window.innerWidth < 768) {
      buttonSize = "S";
    }

    const composedClassName = composeClassName(["ButtonBackToTop", className]);

    let backToTop = null;

    if (scrolled) {
      backToTop = (
        <ButtonIcon
          className={composedClassName}
          size={buttonSize}
          theme="Primary"
          icon="chevronUp"
          title={t("ButtonBackToTop__title")}
          tabIndex="-1"
          onClickHandler={() => {
            this.smoothScroll.animateScroll(0);
          }}
        />
      );
    }

    return <div className="PageContainer">{backToTop}</div>;
  }
}

export default withTranslation("translations")(ButtonBackToTop);
