/* eslint-disable react/prefer-stateless-function */
import React from "react";

import BasePage from "../BasePage";
import { ButtonSalesforceChat } from "../../../shared/molecules";

const TripsPage = (props) => {
  const {
    content: { SalesforceChat },
  } = props;

  const chatButton = SalesforceChat ? <ButtonSalesforceChat /> : null;

  return (
    <BasePage
      template={{
        chatButton,
      }}
      {...props}
    />
  );
};

export default TripsPage;
