import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { composeClassName } from "../../utilities";

import {
  BaseLink,
  BodyText,
  Col,
  DisplayText,
  Heading,
  Image,
} from "../../atoms";
import TextWithBackground from "../TextWithBackground";

import "./MarketingTileC.scss";

class MarketingTileC extends PureComponent {
  static propTypes = {
    imageSource: PropTypes.string.isRequired,
    imageAltText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    /** Must be a hex code! */
    backgroundColor: PropTypes.string,
    body: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string,
    imageLongDesc: PropTypes.string,
    imageMobileSource: PropTypes.string,
    internalLinkComponent: PropTypes.any,
    linkExternal: PropTypes.bool,
    linkTitle: PropTypes.string,
    mobile: PropTypes.bool,
    subTitle: PropTypes.string,
    tabIndex: PropTypes.string,
    whiteText: PropTypes.bool,
  };

  static defaultProps = {
    backgroundColor: "#0d7f50",
    linkExternal: false,
    mobile: false,
    whiteText: false,
  };

  render() {
    const {
      backgroundColor,
      body,
      className,
      href,
      imageAltText,
      imageMobileSource,
      imageSource,
      internalLinkComponent,
      linkExternal,
      linkTitle,
      subTitle,
      title,
      whiteText,
      tabIndex,
    } = this.props;

    const imageProps = {
      source: imageSource,
      mobileSource: imageMobileSource,
      internalLinkComponent: null,
      altText: imageAltText,
    };
    const image = (
      <Image
        className="TileImage"
        key="marketing-tile-c-image"
        {...imageProps}
      />
    );

    const textDesktop = (
      <TextWithBackground
        color={backgroundColor}
        whiteText={whiteText}
        className="TileText Desktop"
        key="marketing-tile-c-text">
        {subTitle ? (
          <DisplayText size="S" className="TileSubtitle">
            {subTitle}
          </DisplayText>
        ) : null}
        {title ? (
          <Heading size="S" className="TileTitle">
            {title}
          </Heading>
        ) : null}
        {body ? (
          <BodyText size="XS" className="TileBody">
            {body}
          </BodyText>
        ) : null}
      </TextWithBackground>
    );

    const textMobile = (
      <TextWithBackground
        color={backgroundColor}
        whiteText={whiteText}
        className="TileText Mobile"
        key="marketing-tile-c-text">
        {subTitle ? (
          <DisplayText size="S" className="TileSubtitle">
            {subTitle}
          </DisplayText>
        ) : null}
        {title ? (
          <Heading size="S" className="TileTitle">
            {title}
          </Heading>
        ) : null}
        {body ? (
          <BodyText size="XS" className="TileBody">
            {body}
          </BodyText>
        ) : null}
      </TextWithBackground>
    );

    const children = (
      <React.Fragment>
        {textDesktop}
        {image}
        {textMobile}
      </React.Fragment>
    );

    let childrenWithLink;
    if (href) {
      const linkProps = {
        href: href,
        internalLinkComponent: internalLinkComponent,
        title: linkTitle,
        external: linkExternal,
      };
      childrenWithLink = (
        <BaseLink className="TileLink" {...linkProps}>
          {children}
        </BaseLink>
      );
    }

    return (
      <Col
        className={composeClassName(["MarketingTileC", className])}
        xl={{ span: 4 }}
        lg={{ span: 4 }}
        md={{ span: 4 }}
        xs={{ span: 12 }}
        tabIndex={tabIndex}>
        {childrenWithLink || children}
      </Col>
    );
  }
}

export default MarketingTileC;
