/* eslint-disable react/prefer-stateless-function */
import React from "react";
import { Link } from "gatsby";
import { Page404 } from "../../shared/organisms";

class Page404EnCa extends React.PureComponent {
  render() {
    return (
      <Page404
        message="The page you requested is not available"
        homeURL="/en-CA"
        buttonText="GO HOME"
        internalLinkComponent={Link}
      />
    );
  }
}

export default Page404EnCa;
