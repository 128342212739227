import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { BodyText, DisplayText } from "../../atoms";
import { composeClassName } from "../../utilities";
import "./KeyValuePair.scss";

class KeyValuePair extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  };

  render() {
    const { className, title, value } = this.props;

    const composedClassName = composeClassName(["KeyValuePair", className]);
    const titleKey = `${title}:`;
    return (
      <li className={composedClassName}>
        <DisplayText size="M" className="key">
          {titleKey}
        </DisplayText>
        <BodyText size="M" className="value">
          {value}
        </BodyText>
      </li>
    );
  }
}

export default KeyValuePair;
