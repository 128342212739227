class StringUtils {
  static formatMax = (string = "", maxLength = 0) => {
    if (string.length <= maxLength) {
      return string;
    }
    return `${string.substring(0, maxLength)}...`;
  };
}

export default StringUtils;
