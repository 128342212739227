import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import FormValidations from "./validations";

const asFormElement = (Element, FormContext) => {
  // eslint-disable-next-line react/no-multi-comp
  return class AsForm extends PureComponent {
    static contextType = FormContext;

    static propTypes = {
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      noRegister: PropTypes.bool,
      fieldType: PropTypes.oneOf(["string", "boolean"]),
      ...Element.propTypes,
    };

    static defaultProps = {
      value: "",
      noRegister: false,
      fieldType: "string",
      ...Element.defaultProps,
    };

    constructor(props, context) {
      super(props);
      if (typeof context.register === "function" && !props.noRegister) {
        this._constructValidations(props, context);
      }
    }

    _constructValidations = (props, context) => {
      const {
        name,
        initialValue = "",
        required,
        emptyValue = "",
        fieldType,
      } = props;
      const { validateAgainst = {} } = props;

      const validateAgainstObject = {};
      // Process validation object on element for use in form
      if (Object.keys(validateAgainst).length > 0) {
        Object.keys(validateAgainst).forEach((key) => {
          // Set message to default if not provided
          const message =
            typeof validateAgainst[key] === "boolean"
              ? FormValidations.defaultMessages[key]
              : validateAgainst[key];
          validateAgainstObject[key] = {
            error: false,
            fn: FormValidations[key](props),
            message: message,
          };
        });
      }
      // Add required shorthand to the object
      if (required) {
        // Set message to default if not provided
        const message =
          typeof required === "boolean"
            ? FormValidations.defaultMessages.required
            : required;
        validateAgainstObject.required = {
          error: false,
          fn: FormValidations.required({ emptyValue }),
          emptyValue: emptyValue,
          message: message,
        };
      }

      // Register to the Form state
      const { register } = context;
      register(name, initialValue, validateAgainstObject, fieldType);
    };

    _isApproved = (submitAttempts, error) => {
      if (submitAttempts > 0) {
        if (!error) return error;
      }

      return false;
    };

    render() {
      // If there is no Form context to register to, just return the element.
      const {
        onChange,
        register,
        submitAttempts,
        validations,
        values,
      } = this.context;

      const { name, noRegister } = this.props;

      if (typeof register !== "function" || noRegister) {
        return <Element {...this.props} />;
      }

      // Pass down element's value
      const value = values[name];

      // Pass error, message, and approved as expected by elements
      const error =
        typeof validations[name] === "object" ? validations[name].error : false;

      const defaultErrorMessage = "Form__defaultErrorMessage";
      const message =
        typeof validations[name] === "object"
          ? validations[name].message
          : defaultErrorMessage;
      const approved = this._isApproved(submitAttempts, error);

      // Props-proxy
      return (
        <Element
          {...this.props}
          approved={approved}
          error={error}
          message={message}
          onChangeHandler={onChange}
          value={value}
        />
      );
    }
  };
};

export default asFormElement;
