/* eslint-disable camelcase */
import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { RelatedStories as SharedRelatedStories } from "../../../shared/organisms";
import { withWeStories } from "../../utilities";

import Block from "../Block";

const RelatedStories = (props) => {
  const { content, pages } = props;
  const { NumberOfStories, Title } = content;
  // TODO: translate themes to settings
  const themes = {
    default: SharedRelatedStories,
  };

  const relatedStoriesElement = React.createElement(themes.default, {
    title: Title,
    pages: pages.slice(0, NumberOfStories),
    internalLinkComponent: GatsbyLink,
  });

  return (
    <Block content={content} className="RelatedStoriesBlock">
      {relatedStoriesElement}
    </Block>
  );
};

export default withWeStories(RelatedStories);
