/* eslint-disable import/prefer-default-export */
export const compileDataAttributes = (props = {}) => {
  const dataAttributes = {};
  Object.keys(props).forEach((key) => {
    if (key.match(/^data-/g)) {
      dataAttributes[key] = props[key];
    }
  });

  return dataAttributes;
};
