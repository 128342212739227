import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Banner as SharedBanner } from "../../../shared/molecules";

import Block from "../Block";
import { withStoryblokLink } from "../../utilities";

const Banner = (props) => {
  const { content, ieClass } = props;
  const {
    VideoBackgroundSrc,
    CtaText,
    CtaTheme,
    CtaType,
    ImageDesktop,
    ImageMobile,
    Link,
    LinkTitle,
    SocialIcon,
    SocialIconTheme,
    Title,
    TitleColor,
    VideoButtonTheme,
    VideoId,
    VideoSource,
    VideoTitle,
  } = content;

  return (
    <Block content={content} fluid className="BannerBlock">
      <SharedBanner
        buttonText={CtaText}
        buttonTheme={CtaTheme}
        buttonType={CtaType}
        ieClass={ieClass}
        imageDesktopSrc={ImageDesktop}
        imageMobileSrc={ImageMobile}
        internalLinkComponent={GatsbyLink}
        linkExternal={Link.external}
        linkHref={Link.href}
        linkTitle={LinkTitle}
        socialIcon={SocialIcon}
        socialIconTheme={SocialIconTheme}
        titleText={Title}
        titleWhiteText={TitleColor === "white"}
        videoBackgroundSrc={VideoBackgroundSrc}
        videoButtonTheme={VideoButtonTheme}
        videoId={VideoId}
        videoSource={VideoSource}
        videoTitle={VideoTitle}
      />
    </Block>
  );
};

export default withStoryblokLink(Banner);
