import {
  WeorgPage,
  TripsPage,
  TyiPage,
  Category,
} from "./content"

import {
  Navigation,
  Footer,
  NavigationSub,
  TabSectionPartial,
  Map,
} from "./partials"

import {
  ProfileContent,
  Pillars,
  ReadMoreLayout,
  TileLayoutCentered,
  ButtonLayout,
  DownloadableResources,
  TileLayoutImageLink,
  TileLayoutGallery,
  Banner,
  BannerWEStories,
  BannerDonationWidget,
  Carousel,
  Divider,
  SocialMediaBar,
  ContentGenericAligned,
  TestimonialHybrid,
  LeadGenerator,
  LeadGeneratorSubscription,
  Mosaic,
  ParagraphHeader,
  Partners,
  RelatedStories,
  Title,
  TileLayoutHalf,
  TileLayout,
  TileLayoutText,
  TileLayoutEnhanced,
  TileLayoutStories,
  TileLayoutRound,
  TileLayoutVideo,
  TileLayoutVideoStories,
  Text,
  Accordion,
  BookingWidget,
  GenericContent,
  FormBuilder,
  NavigationSubContainer,
  TabSection,
  TabSectionPartialContainer,
  WeSchoolsLibrary,
  TripItinerary,
  Testimonial,
  Author,
  BroadcastPopUp,
  MultiColumnText,
  Feed,
  Podcast,
  MapContainer,
} from "./blocks"

import {
  CenteredTile,
  ImageTile,
  DualImageTile,
  HalfImageTile,
  HalfTextTile,
  ImageGalleryTile,
  TextTile,
  EnhancedTile,
  StoriesTile,
  Tile,
  RoundTile,
  VideoTile,
  VideoStoriesTile,
} from "./tiles"

import {
  PillarItem,
  BookingWidgetItem,
  DonationWidgetContent,
  DownloadableLink,
  Image,
  SlideImage,
  NavigationColumn,
  NavigationCategory,
  NavigationLink,
  NavigationSubItem,
  ButtonLink,
  SocialButton,
  AccordionItem,
  FieldSet,
  InputFirstName,
  InputLastName,
  InputEmail,
  InputPhoneNumber,
  SubmitButton,
  GenericInput,
  TabWithContent,
  TyiTabContent,
  TripItineraryItem,
  InputTextarea,
  KeyValue,
  InputDropdown,
  InputCheckbox,
  InputRadioGroup,
  TextColumn,
  Link,
  MapStory,
} from "./placeables"

import {
  MetaTagCustom,
} from "./seo"

const components = {
  Accordion: Accordion,
  AccordionItem: AccordionItem,
  Author: Author,
  Banner: Banner,
  BannerDonationWidget: BannerDonationWidget,
  BannerWEStories: BannerWEStories,
  BasePage: WeorgPage,
  BookingWidget: BookingWidget,
  BookingWidgetItem: BookingWidgetItem,
  BroadcastPopUp: BroadcastPopUp,
  ButtonLayout: ButtonLayout,
  ButtonLink: ButtonLink,
  Carousel: Carousel,
  Category: Category,
  CenteredTile: CenteredTile,
  ContentGenericAligned: ContentGenericAligned,
  Divider: Divider,
  DonationWidgetContent: DonationWidgetContent,
  DownloadableLink: DownloadableLink,
  DownloadableResources: DownloadableResources,
  DualImageTile: DualImageTile,
  EnhancedTile: EnhancedTile,
  Feed: Feed,
  FieldSet: FieldSet,
  Footer: Footer,
  FormBuilder: FormBuilder,
  GenericContent: GenericContent,
  GenericInput: GenericInput,
  HalfImageTile: HalfImageTile,
  HalfTextTile: HalfTextTile,
  Image: Image,
  ImageGalleryTile: ImageGalleryTile,
  ImageTile: ImageTile,
  InputCheckbox: InputCheckbox,
  InputDropdown: InputDropdown,
  InputEmail: InputEmail,
  InputFirstName: InputFirstName,
  InputLastName: InputLastName,
  InputPhoneNumber: InputPhoneNumber,
  InputRadioGroup: InputRadioGroup,
  InputTextarea: InputTextarea,
  KeyValue: KeyValue,
  LeadGenerator: LeadGenerator,
  LeadGeneratorSubscription: LeadGeneratorSubscription,
  Link: Link,
  Map: Map,
  MapContainer: MapContainer,
  MapStory: MapStory,
  MetaTagCustom: MetaTagCustom,
  Mosaic: Mosaic,
  MultiColumnText: MultiColumnText,
  Navigation: Navigation,
  NavigationCategory: NavigationCategory,
  NavigationColumn: NavigationColumn,
  NavigationLink: NavigationLink,
  NavigationSub: NavigationSub,
  NavigationSubContainer: NavigationSubContainer,
  NavigationSubItem: NavigationSubItem,
  ParagraphHeader: ParagraphHeader,
  Partners: Partners,
  PillarItem: PillarItem,
  Pillars: Pillars,
  Podcast: Podcast,
  ProfileContent: ProfileContent,
  ReadMoreLayout: ReadMoreLayout,
  RelatedStories: RelatedStories,
  RoundTile: RoundTile,
  SlideImage: SlideImage,
  SocialButton: SocialButton,
  SocialMediaBar: SocialMediaBar,
  StoriesTile: StoriesTile,
  SubmitButton: SubmitButton,
  TabSection: TabSection,
  TabSectionPartial: TabSectionPartial,
  TabSectionPartialContainer: TabSectionPartialContainer,
  TabWithContent: TabWithContent,
  Testimonial: Testimonial,
  TestimonialHybrid: TestimonialHybrid,
  Text: Text,
  TextColumn: TextColumn,
  TextTile: TextTile,
  Tile: Tile,
  TileLayout: TileLayout,
  TileLayoutCentered: TileLayoutCentered,
  TileLayoutEnhanced: TileLayoutEnhanced,
  TileLayoutGallery: TileLayoutGallery,
  TileLayoutHalf: TileLayoutHalf,
  TileLayoutImageLink: TileLayoutImageLink,
  TileLayoutRound: TileLayoutRound,
  TileLayoutStories: TileLayoutStories,
  TileLayoutText: TileLayoutText,
  TileLayoutVideo: TileLayoutVideo,
  TileLayoutVideoStories: TileLayoutVideoStories,
  Title: Title,
  TripItinerary: TripItinerary,
  TripItineraryItem: TripItineraryItem,
  TripsPage: TripsPage,
  TyiPage: TyiPage,
  TyiTabContent: TyiTabContent,
  VideoStoriesTile: VideoStoriesTile,
  VideoTile: VideoTile,
  WeSchoolsLibrary: WeSchoolsLibrary,
};

export default components;
