import React, { PureComponent } from "react";
import { VideoDisplay } from "../../../shared/molecules";
import { composeClassName } from "../../../shared/utilities";
import { GatsbyBaseTile } from "../../../sharedgatsby";
import "./VideoTile.scss";

class VideoTile extends PureComponent {
  render() {
    const { key, partials, length, index, ...TileProps } = this.props;
    const {
      Title,
      Category,
      VideoSource,
      VideoTitle,
      VideoId,
      VideoButtonTheme,
      Image,
      MobileImage,
    } = TileProps;

    let numColumns = 6;
    if (length === 1 || (length % 2 && index === length - 1)) {
      numColumns = 12;
    }

    const composedClassName = composeClassName(["VideoTile"]);

    return (
      <GatsbyBaseTile
        xs={{ span: 12 }}
        md={{ span: numColumns }}
        className={composedClassName}>
        <VideoDisplay
          heading={Title}
          tag={Category}
          size={`Columns${numColumns}`}
          videoSource={VideoSource}
          videoTitle={VideoTitle}
          videoId={VideoId}
          videoButtonTheme={VideoButtonTheme}
          imgSource={Image}
          imgMobileSource={MobileImage}
        />
      </GatsbyBaseTile>
    );
  }
}

export default VideoTile;
