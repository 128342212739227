import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { browser } from "../../utilities";
import {
  MarketingTileA,
  MarketingTileB,
  MarketingTileC,
} from "../../molecules";

import "./Mosaic.scss";

const tilePropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  whiteText: PropTypes.bool,
  altText: PropTypes.string,
  longDesc: PropTypes.string,
  backgroundColor: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  linkTitle: PropTypes.string,
  href: PropTypes.string,
  external: PropTypes.bool,
};

class Mosaic extends PureComponent {
  static propTypes = {
    tile1: PropTypes.shape(tilePropTypes),
    tile2: PropTypes.shape(tilePropTypes),
    tile3: PropTypes.shape(tilePropTypes),
    internalLinkComponent: PropTypes.any,
    columnLayout: PropTypes.string,
  };

  render() {
    const {
      tile1,
      tile2,
      tile3,
      internalLinkComponent,
      columnLayout,
    } = this.props;
    const isMobile = browser.window.innerWidth < 768;

    /** Tile 1 */
    let tileAComponent;
    if (tile1.title || tile1.subtitle || tile1.body || tile1.image) {
      tileAComponent = (
        <MarketingTileA
          className="MarketingTile"
          key="marketing-tile-1"
          backgroundColor={tile1.backgroundColor}
          body={tile1.body}
          href={tile1.href}
          imageAltText={tile1.altText}
          imageLongDesc={tile1.longDesc}
          imageMobileSource={tile1.mobileImage}
          imageSource={tile1.image}
          internalLinkComponent={internalLinkComponent}
          linkExternal={tile1.external}
          linkTitle={tile1.linkTitle}
          mobile={isMobile}
          subTitle={tile1.subtitle}
          title={tile1.title}
          whiteText={tile1.whiteText}
        />
      );
    }

    /* Tile 2 */
    const tile2Component = (
      <MarketingTileB
        className="MarketingTile"
        key="marketing-tile-2"
        backgroundColor={tile2.backgroundColor}
        body={tile2.body}
        href={tile2.href}
        imageAltText={tile2.altText}
        imageLongDesc={tile2.longDesc}
        imageMobileSource={tile2.mobileImage}
        imageSource={tile2.image}
        internalLinkComponent={internalLinkComponent}
        linkExternal={tile2.external}
        linkTitle={tile2.linkTitle}
        mobile={isMobile}
        subTitle={tile2.subtitle}
        title={tile2.title}
        whiteText={tile2.whiteText}
      />
    );

    /* Tile 3 */
    const tile3Component = (
      <MarketingTileC
        className="MarketingTile"
        key="marketing-tile-3"
        backgroundColor={tile3.backgroundColor}
        body={tile3.body}
        href={tile3.href}
        imageAltText={tile3.altText}
        imageLongDesc={tile3.longDesc}
        imageMobileSource={tile3.mobileImage}
        imageSource={tile3.image}
        internalLinkComponent={internalLinkComponent}
        linkExternal={tile3.external}
        linkTitle={tile3.linkTitle}
        mobile={isMobile}
        subTitle={tile3.subtitle}
        title={tile3.title}
        whiteText={tile3.whiteText}
      />
    );

    /* Tile B */
    let tileBComponent;
    if (
      tile2.title ||
      tile2.subtitle ||
      tile2.body ||
      tile2.image ||
      tile3.title ||
      tile3.subtitle ||
      tile3.body ||
      tile3.image
    ) {
      tileBComponent = [tile2Component, tile3Component];
      if (columnLayout === "4-8") {
        tileBComponent = [tile3Component, tile2Component];
      }
    }

    return (
      <React.Fragment>
        {tileAComponent}
        {tileBComponent}
      </React.Fragment>
    );
  }
}

export default Mosaic;
