import React, { PureComponent } from "react";
import { Link } from "gatsby";
import Block from "../Block";
import { ProfileContent as SharedProfileContent } from "../../../shared/organisms";
import contentToBlocks from "../../utilities/contentToBlocks";
import { withMarkdownParser } from "../../utilities";

import "./ProfileContent.scss";

class ProfileContent extends PureComponent {
  render() {
    const { content } = this.props;
    const {
      Alignment,
      Source,
      AltText,
      Title,
      TitleSize,
      BodyText,
      BodyTextSize,
      ReadMore,
    } = content;

    return (
      <Block content={content} className="ProfileContentBlock">
        <SharedProfileContent
          alignment={Alignment}
          source={Source}
          altText={AltText}
          title={Title}
          titleSize={TitleSize}
          bodyText={BodyText}
          bodyTextSize={BodyTextSize}
          readMore={contentToBlocks(ReadMore, {
            BlockPaddingTop: "None",
            BlockPaddingBottom: "None",
          })}
          internalLinkComponent={Link}
        />
      </Block>
    );
  }
}

export default withMarkdownParser(ProfileContent, { textFields: ["BodyText"] });
