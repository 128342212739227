/* eslint-disable no-unused-vars */
import React from "react";
import Author from "./Author";

const AuthorVisualTest = (props) => {
  const AllInfoNoDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        PublishedDate: "12/18/2009",
        JobTitle: "Engineer",
        ReadTime: "15min",
        Divider: false,
      }}
    />
  );

  const AllInfoDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        PublishedDate: "12/18/2009",
        JobTitle: "Engineer",
        ReadTime: "15min",
        Divider: true,
      }}
    />
  );

  const NoReadTimeNoDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        PublishedDate: "12/18/2009",
        JobTitle: "Engineer",
        Divider: false,
      }}
    />
  );

  const NoReadTimeDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        PublishedDate: "12/18/2009",
        JobTitle: "Engineer",
        Divider: true,
      }}
    />
  );

  const NoJobTitleNoDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        PublishedDate: "12/18/2009",
        ReadTime: "15min",
        Divider: false,
      }}
    />
  );

  const NoJobTitleDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        PublishedDate: "12/18/2009",
        ReadTime: "15min",
        Divider: true,
      }}
    />
  );

  const NoDateNoDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        JobTitle: "Engineer",
        ReadTime: "15min",
        Divider: false,
      }}
    />
  );

  const NoDateDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        JobTitle: "Engineer",
        ReadTime: "15min",
        Divider: true,
      }}
    />
  );

  const JobTitleOnlyNoDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        JobTitle: "Engineer",
        Divider: false,
      }}
    />
  );

  const JobTitleOnlyDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        JobTitle: "Engineer",
        Divider: true,
      }}
    />
  );

  const AuthorNameNoDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        Divider: false,
      }}
    />
  );

  const AuthorNameDivider = (
    <Author
      content={{
        AuthorName: "Lucas Marcelli",
        Divider: true,
      }}
    />
  );

  return (
    <React.Fragment>
      {AllInfoNoDivider}
      {AllInfoDivider}
      {NoReadTimeNoDivider}
      {NoReadTimeDivider}
      {NoJobTitleNoDivider}
      {NoJobTitleDivider}
      {NoDateNoDivider}
      {NoDateDivider}
      {JobTitleOnlyNoDivider}
      {JobTitleOnlyDivider}
      {AuthorNameNoDivider}
      {AuthorNameDivider}
    </React.Fragment>
  );
};

export default AuthorVisualTest;
