import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Col, BaseLink } from "../../atoms";
import { composeClassName } from "../../utilities";
import "./tiles.scss";

class BaseTile extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    xs: PropTypes.shape({ span: PropTypes.number }),
    sm: PropTypes.shape({ span: PropTypes.number }),
    md: PropTypes.shape({ span: PropTypes.number }).isRequired,
    lg: PropTypes.shape({ span: PropTypes.number }),
    Link: PropTypes.any,
    internalLinkComponent: PropTypes.any,
  };

  static defaultProps = {
    xs: { span: 12 },
    internalLinkComponent: null,
  };

  render() {
    const {
      className,
      children,
      xs,
      md,
      sm,
      lg,
      Link,
      LinkTitle,
      internalLinkComponent,
      whiteText = false,
    } = this.props;
    const composedClassName = composeClassName(["tile", className]);

    if (Link && Link.href) {
      return (
        <Col className={composedClassName} xs={xs} md={md} sm={sm} lg={lg}>
          <BaseLink
            internalLinkComponent={internalLinkComponent}
            title={LinkTitle}
            {...{ whiteText }} // Passthrough to link
            {...Link}>
            {children}
          </BaseLink>
        </Col>
      );
    }
    return (
      <Col className={composedClassName} xs={xs} md={md} sm={sm} lg={lg}>
        {children}
      </Col>
    );
  }
}

export default BaseTile;
