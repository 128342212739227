import React, { PureComponent } from "react";

import { Button, Icon } from "../../atoms";
import { composeClassName } from "../../utilities";

import "./ButtonCloseModal.scss";

class ButtonCloseModal extends PureComponent {
  render() {
    const {
      ariaLabel,
      autoFocus,
      buttonRef,
      className,
      onClickHandler,
      onKeyDownHandler,
      tabIndex,
    } = this.props;

    const buttonProps = {
      ariaLabel,
      autoFocus,
      buttonRef,
      onClickHandler,
      onKeyDownHandler,
      tabIndex,
    };

    const composedClassName = composeClassName(["ButtonCloseModal", className]);

    return (
      <Button className={composedClassName} {...buttonProps}>
        <Icon size="S" icon="close" />
      </Button>
    );
  }
}

export default ButtonCloseModal;
