/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { DisplayText } from "../../atoms";
import { SocialMediaItem } from "../../molecules";
import "./SocialMediaBar.scss";
import { browser } from "../../utilities";

class SocialMediaBar extends React.Component {
  static propTypes = {
    shareText: PropTypes.string,
    mediaTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        mediaType: PropTypes.string,
      }),
    ),
  };

  static defaultProps = {
    mediaTypes: [
      { id: "facebook", mediaType: "Facebook" },
      { id: "twitter", mediaType: "Twitter" },
      { id: "pinterest", mediaType: "Pinterest" },
      { id: "email", mediaType: "Email" },
    ],
  };

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    browser.window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    browser.window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const element = browser.document.getElementById("SocialMediaBlock");
    element.className = "SocialMediaBlock hide";

    const banner = browser.document.querySelectorAll("[data-social-media]")[0];
    const scrollTop = banner.getBoundingClientRect().top;

    if (!banner || scrollTop < -banner.clientHeight) {
      element.className = "SocialMediaBlock show";
    }
  }

  render() {
    const { mediaTypes, t } = this.props;
    let { shareText } = this.props;
    shareText = shareText || t("SocialMediaBar__shareText");

    const socialMediaItems = mediaTypes.map((item) => {
      return <SocialMediaItem key={item.id} mediaType={item.mediaType} />;
    });

    return (
      <div className="SocialMediaBlock hide" id="SocialMediaBlock">
        <div className="ShareText">
          <DisplayText size="S">{shareText}</DisplayText>
        </div>
        <div className="SocialMediaItemWrapper">{socialMediaItems}</div>
      </div>
    );
  }
}

export default withTranslation("translations")(SocialMediaBar);
