import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { Tile as TileMolecule } from "../../../shared/molecules";
import { withStoryblokLink } from "../../utilities";

const Tile = (props) => {
  const {
    Link,
    LinkTitle,
    AltText,
    LongDesc,
    MobileImage,
    Image,
    TextColor,
    BackgroundColor,
    Title,
    Text,
  } = props;

  return (
    <TileMolecule
      internalLinkComponent={GatsbyLink}
      link={Link}
      linkTitle={LinkTitle}
      textColor={TextColor}
      altText={AltText}
      text={Text}
      image={Image}
      longDesc={LongDesc}
      mobileImage={MobileImage}
      backgroundColor={BackgroundColor}
      title={Title}
    />
  );
};

Tile.propTypes = {
  BackgroundColor: PropTypes.string.isRequired,
  TextColor: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  Text: PropTypes.string,
  Link: PropTypes.object,
  LinkTitle: PropTypes.string,
  AltText: PropTypes.string.isRequired,
  LongDesc: PropTypes.string,
  MobileImage: PropTypes.string,
  Image: PropTypes.string.isRequired,
};

export default withStoryblokLink(Tile);
