import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  BaseTile,
  BodyTextMarkdown,
  TextWithBackground,
} from "../../../shared/molecules";
import { Image } from "../../../shared/atoms";
import "./ImageGalleryTile.scss";

const ImageGalleryTile = (props) => {
  const {
    Description,
    AltText,
    LongDesc,
    ImageSource,
    MobileImageSource,
    textClassName, // className used for testing.
  } = props;

  return (
    <BaseTile
      className="ImageGalleryTile"
      xs={{ span: 12 }}
      md={{ span: 6 }}
      lg={{ span: 3 }}>
      <Image
        altText={AltText}
        longDesc={LongDesc}
        source={ImageSource}
        mobileSource={MobileImageSource}
      />
      <TextWithBackground whiteText className={textClassName}>
        <BodyTextMarkdown internalLinkComponent={GatsbyLink} size="M" whiteText>
          {Description}
        </BodyTextMarkdown>
      </TextWithBackground>
    </BaseTile>
  );
};

export default ImageGalleryTile;
