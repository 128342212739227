import React, { PureComponent } from "react";
import Block from "../Block";
import { TripItinerary as SharedTripItinerary } from "../../../shared/organisms";

class TripItinerary extends PureComponent {
  render() {
    const { content } = this.props;
    const {
      Title,
      Price,
      Duration,
      Disclaimer,
      PrimaryImageSource,
      PrimaryImageMobileSource,
      PrimaryImageAltText,
      PrimaryImageLongDesc,
      SecondaryImageLeftSource,
      SecondaryImageLeftAltText,
      SecondaryImageLeftLongDesc,
      SecondaryImageRightSource,
      SecondaryImageRightAltText,
      SecondaryImageRightLongDesc,
      TripItineraryItems,
    } = content;

    const primaryImage = {
      source: PrimaryImageSource,
      mobileSource: PrimaryImageMobileSource,
      altText: PrimaryImageAltText,
      longdesc: PrimaryImageLongDesc,
    };

    const leftSecondaryImage = {
      source: SecondaryImageLeftSource,
      altText: SecondaryImageLeftAltText,
      longdesc: SecondaryImageLeftLongDesc,
    };

    const rightSecondaryImage = {
      source: SecondaryImageRightSource,
      altText: SecondaryImageRightAltText,
      longdesc: SecondaryImageRightLongDesc,
    };

    const items = TripItineraryItems.map((item) => {
      return {
        title: item.Title,
        description: item.Description,
      };
    });

    return (
      <Block content={content} className="TripItineraryBlock">
        <SharedTripItinerary
          disclaimer={Disclaimer}
          duration={Duration}
          items={items}
          price={Price}
          primaryImage={primaryImage}
          secondaryImages={[leftSecondaryImage, rightSecondaryImage]}
          title={Title}
        />
      </Block>
    );
  }
}

export default TripItinerary;
