import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Block from "../Block";
import MultiColumnBodyTextMarkdown from "../../../shared/molecules/MultiColumnBodyTextMarkdown";

const MultiColumnText = (props) => {
  const { content } = props;
  const { Size, Centered } = content;
  let { TextColumns } = content;

  TextColumns = TextColumns.map((column) => {
    return { text: column.BodyTextContent, key: column._uid };
  });

  return (
    <Block content={content} className="MultiColumnTextBlock">
      <MultiColumnBodyTextMarkdown
        size={Size}
        centered={Centered}
        textColumns={TextColumns}
        internalLinkComponent={Link}
      />
    </Block>
  );
};

MultiColumnText.propTypes = {
  content: PropTypes.shape({
    Size: PropTypes.string.isRequired,
    Centered: PropTypes.bool,
    TextColumns: PropTypes.array.isRequired,
  }).isRequired,
};

export default MultiColumnText;
