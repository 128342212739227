/* eslint-disable no-unused-vars */
import React from "react";
import BannerDonationWidget from "./BannerDonationWidget";
import desktopImage from "../../../shared/static/desktop2.png";

const BannerDonationWidgetVisualTest = () => {
  const donateTitles = [
    {
      name: "Without Donate Title (uses default)",
      value: "",
    },
    {
      name: "With Donate Title",
      value: "Donate Title",
    },
  ];

  const donateByPhoneLinkOpts = [
    {
      name: "With Donate By Phone Link",
      value: { linktype: "url", url: "http://google.com" },
    },
    {
      name: "Without Donate By Phone Link",
      value: { linktype: "url", url: "" },
    },
  ];

  const imageOverlays = [
    { name: "Without Overlay", value: false },
    { name: "With Overlay", value: true },
  ];

  const buttonCADText = "Donate in CAD";
  const buttonUSDText = "Donate in USD";
  const donateByPhoneLinkText = "Donate By Phone";
  const textDonateMonthly = "Make monthly donations";

  let key = 0;
  const components = [];
  donateTitles.forEach((donateTitle) => {
    key++;
    const imageOverlay = key === 2 ? imageOverlays[0] : imageOverlays[1];
    const donateByPhoneLink =
      key === 2 ? donateByPhoneLinkOpts[0] : donateByPhoneLinkOpts[1];
    const content = {
      ButtonCADText: buttonCADText,
      ButtonUSDText: buttonUSDText,
      DonateByPhoneLink: donateByPhoneLink.value,
      DonateByPhoneLinkText: donateByPhoneLinkText,
      DonateTitle: donateTitle.value,
      ImageDesktop: desktopImage,
      ImageOverlay: imageOverlay.value,
      TextDonateMonthly: textDonateMonthly,
    };

    const pageName = [
      donateTitle.name,
      imageOverlay.name,
      donateByPhoneLink.name,
    ].join(" | ");
    const pageContent = (
      <>
        <h1 key={`${key}-h1`} className="VisualTestTitle">
          {pageName}
        </h1>
        <BannerDonationWidget key={key} content={content} />
      </>
    );
    components.push(pageContent);
  });

  return components;
};

export default BannerDonationWidgetVisualTest;
