import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import MarkdownParser from "../MarkdownParser";

import "./BodyTextMarkdown.scss";

class BodyTextMarkdown extends PureComponent {
  static propTypes = {
    /** The selection for the size of the body text */
    size: PropTypes.oneOf(["L", "M", "S"]),
    /** Color of the text, defaults to brand navy. */
    whiteText: PropTypes.bool,
    centered: PropTypes.bool,
    internalLinkComponent: PropTypes.any,
    className: PropTypes.any,
  };

  static defaultProps = {
    size: "M",
    whiteText: false,
    centered: false,
    className: "",
  };

  render() {
    const {
      size,
      children,
      className,
      whiteText,
      centered,
      internalLinkComponent,
    } = this.props;

    const composedClassName = composeClassName([
      `BodyTextMarkdown ${size}`,
      className,
      whiteText ? "whiteText" : "",
      centered ? "Centered" : "",
    ]);

    return (
      <MarkdownParser
        size={size}
        className={composedClassName}
        whiteText={whiteText}
        internalLinkComponent={internalLinkComponent}>
        {children}
      </MarkdownParser>
    );
  }
}

export default BodyTextMarkdown;
