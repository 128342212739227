import React, { PureComponent } from "react";

import { ModalWrapper, Image } from "../../../shared/atoms";
import { ButtonCloseModal } from "../../../shared/molecules";
import { browser } from "../../../shared/utilities";
import "./BroadcastPopUp.scss";
import weImpactImage from "./broadcast-button-03@3x.png";
import Block from "../Block";

class BroadcastPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: !browser.window.localStorage.popUpVisited };
    this.closeButton = React.createRef();
  }

  _handleCloseModal = () => {
    this.setState({ open: false }, () => {
      browser.window.localStorage.popUpVisited = "visited";
    });
  };

  render() {
    const { content, locale } = this.props;
    const { open } = this.state;

    return (
      <Block content={content} className="BroadcastPopUpBlock">
        <div className="BroadcastModal">
          <ModalWrapper
            open={open}
            closeHandler={this._handleCloseModal}
            ariaLabel="Broadcast Pop-up Modal"
            ariaDescribedBy="Open broadcast pop-up in a modal">
            <a
              href={`/${locale}/`}
              onClick={this._handleCloseModal}
              className="BroadcastPopUp-Anchor-PopUpBtn">
              <div className="ImageContainer">
                <Image source={weImpactImage} altText="we-impact-button" />
                <h2>
                  Click this button
                  <br />
                  and unlock an impact
                </h2>
              </div>
            </a>

            <ButtonCloseModal
              ariaLabel="Modal Close"
              buttonRef={this.closeButton}
              className="ModalImageClose BroadcastPopUp-ButtonCloseModal-CloseBtn"
              onClickHandler={this._handleCloseModal}
            />
          </ModalWrapper>
        </div>
      </Block>
    );
  }
}
export default BroadcastPopUp;
