import React, { useState, useRef } from "react";
import { Raphael, Paper, Set, Path, Text } from "react-raphael";
import "./VectorDraw.scss";
import "./IESupport.scss";

const ANIMATION_LENGTH = 500;

function _darkenColor(hex, amt) {
  let colour = Raphael.color(hex);
  colour = Raphael.hsb(colour.h, colour.s, colour.v - amt);
  return colour;
}

function _buildHandleShapeMouseOver(fill) {
  return function handleShapeMouseOver() {
    this.animate(
      {
        fill: _darkenColor(fill, 0.1),
      },
      ANIMATION_LENGTH,
    );
  };
}

function _buildHandleShapeMouseOut(fill) {
  return function handleMouseOut() {
    this.animate({ fill }, ANIMATION_LENGTH);
  };
}

const _deriveTextLocation = (boundingBoxPath, labelOffsetX, labelOffsetY) => {
  const center = {
    x: boundingBoxPath.x + boundingBoxPath.width / 2,
    y: boundingBoxPath.y + boundingBoxPath.height / 2,
  };

  return {
    x: center.x + labelOffsetX,
    y: center.y + labelOffsetY,
  };
};

function Shape({
  data: {
    id,
    path,
    label = "",
    color: { fill, border },
    labelOffsetX = 0,
    labelOffsetY = 0,
    whiteText = true,
    fontSize = 8,
  } = {},
  onClick,
}) {
  const [textLocation, setTextLocation] = useState({
    x: 0,
    y: 0,
  });
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef();

  const _handleClick = () => {
    onClick(id);
  };

  const _handleLoad = (loadedPath) => {
    const boundingBoxPath = loadedPath.getBBox();

    const newTextLocation = _deriveTextLocation(
      boundingBoxPath,
      labelOffsetX,
      labelOffsetY,
    );
    setTextLocation(newTextLocation);
  };

  const _handleMouseOut = _buildHandleShapeMouseOut(fill);
  const _handleShapeMouseOver = _buildHandleShapeMouseOver(fill);
  const _handleKeydown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      onClick(id);
    }
  };

  return (
    <Set>
      <Path
        d={path}
        attr={{
          fill: fill,
          "stroke-width": 0,
        }}
        load={_handleLoad}
        mouseover={_handleShapeMouseOver}
        mouseout={_handleMouseOut}
        click={() => {
          ref.current.focus();
          _handleClick();
        }}
      />
      <Text
        x={textLocation.x}
        y={textLocation.y}
        text={label}
        attr={{
          fill: whiteText ? "#FFF" : fill,
          "font-family": "Soleil",
          "font-size": fontSize,
          "font-weight": 600,
        }}
        click={_handleClick}
      />
      <Set toFront>
        <Path
          d={path}
          toFront
          hide={!isFocused}
          attr={{
            stroke: border,
            "stroke-width": 2,
          }}
        />
      </Set>
      <div
        role="button"
        aria-label={id}
        onKeyDown={_handleKeydown}
        ref={ref}
        className="FocusElement"
        onFocus={() => {
          return setIsFocused(true);
        }}
        onBlur={() => {
          return setIsFocused(false);
        }}
        tabIndex={0}
      />
    </Set>
  );
}

function VectorDraw({
  containerHeight,
  containerWidth,
  shapes = {},
  onClickShape,
}) {
  if (!Raphael) return null;

  return (
    <Paper
      viewbox="0 0 700 405 true"
      width={containerWidth}
      height={containerHeight}>
      {shapes.map((shapeConfig) => {
        return (
          <Shape
            data={shapeConfig}
            key={shapeConfig.id}
            onClick={onClickShape}
          />
        );
      })}
    </Paper>
  );
}

export default VectorDraw;
