import React from "react";
import SbEditable from "storyblok-react";
import Components from "../../components";

const contentToComponents = (contentArray, globals = {}) => {
  if (contentArray && contentArray instanceof Array) {
    return contentArray.map((content, index) => {
      const { _uid, component, ...props } = content;
      const Index = globals.getIndex ? { Index: index } : null;

      return (
        <React.Fragment key={_uid}>
          <SbEditable content={content}>
            {React.createElement(Components[component], {
              ...globals,
              ...props,
              ...Index,
            })}
          </SbEditable>
        </React.Fragment>
      );
    });
  }
  return [];
};

export default contentToComponents;
