import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import { composeClassName } from "../../../shared/utilities";
import { colors } from "../../../shared/colors";
import "./Block.scss";

// This is the base block, leaving it named Block to avoid confusion.
// Do not add this to settings.js

class Block extends PureComponent {
  static propTypes = {
    /** True if fluid, false if not. */
    fluid: PropTypes.bool,
    /** Children are rendered into the container */
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    /** Additional classnames for block */
    className: PropTypes.string,
  };

  static defaultProps = {
    fluid: false,
    className: "",
  };

  render() {
    const { fluid, children, content, className, style = {} } = this.props;
    const type = fluid ? "block-fluid" : "block";
    let spacingClass = "";
    if (
      undefined !== content.BlockMarginTop &&
      content.BlockMarginTop.length > 0
    ) {
      spacingClass += ` MarginTop-${content.BlockMarginTop}`;
    }
    if (
      undefined !== content.BlockMarginBottom &&
      content.BlockMarginBottom.length > 0
    ) {
      spacingClass += ` MarginBottom-${content.BlockMarginBottom}`;
    }
    if (
      undefined !== content.BlockPaddingTop &&
      content.BlockPaddingTop.length > 0
    ) {
      spacingClass += ` PaddingTop-${content.BlockPaddingTop}`;
    }
    if (
      undefined !== content.BlockPaddingBottom &&
      content.BlockPaddingBottom.length > 0
    ) {
      spacingClass += ` PaddingBottom-${content.BlockPaddingBottom}`;
    }

    if (
      content.BlockBackgroundColor &&
      undefined !== colors[content.BlockBackgroundColor]
    ) {
      style.backgroundColor = colors[content.BlockBackgroundColor].color;
    }

    let blockHtmlId = null;
    if (undefined !== content.BlockHtmlId) {
      blockHtmlId = content.BlockHtmlId.replace(/\s+/g, "-").toLowerCase();
    }

    const composedClassName = composeClassName([className, type, spacingClass]);

    return (
      <SbEditable content={content}>
        <div className={composedClassName} style={style} id={blockHtmlId}>
          {children}
        </div>
      </SbEditable>
    );
  }
}

export default Block;
