import React from "react";
import TileLayoutVideoStories from "./TileLayoutVideoStories";
import desktopImage from "../../../shared/static/desktop.png";

const TileLayoutVideoStoriesVisualTest = () => {
  const tiles = [
    {
      name: "3-tiles",
      value: 3,
    },
    {
      name: "4-tiles",
      value: 4,
    },
  ];
  const centeredOpts = [
    { name: "Centered", value: true },
    { name: "Uncentered", value: false },
  ];
  const underlines = [
    {
      name: "With Underline",
      value: true,
    },
    {
      name: "Without Underline",
      value: false,
    },
  ];

  const backgroundColors = [
    {
      name: "Colored Background",
      value: "base-blue-grey",
    },
    {
      name: "Default Background",
      value: "base-white-color",
    },
  ];
  const buttons = [
    {
      name: "Without Button",
      value: {},
    },
    {
      name: "With Button",
      value: {
        cached_url: "we_org",
        fieldtype: "multilink",
        linktype: "story",
        url: "",
      },
    },
  ];
  const whiteTexts = [
    {
      name: "With White Text",
      value: true,
    },
    {
      name: "Without White Text",
      value: false,
    },
  ];
  const titles = [
    {
      name: "Without Title",
      value: "",
    },
    {
      name: "With Title",
      value: "Block Title",
    },
  ];
  const titleSizes = [
    {
      name: "Title Size L",
      value: "L",
    },
    {
      name: "Title Size M",
      value: "M",
    },
    {
      name: "Title Size S",
      value: "S",
    },
  ];
  let key = 0;
  const components = [];
  titleSizes.forEach((titleSize) => {
    buttons.forEach((button) => {
      // Using Large Title , Underline, WhiteText Background for Case# 1
      const whiteText = key === 0 ? whiteTexts[0] : whiteTexts[1];
      const underline = whiteText.value ? underlines[0] : underlines[1];
      const backgroundColor = whiteText.value
        ? backgroundColors[0]
        : backgroundColors[1];
      const centered = whiteText.value ? centeredOpts[0] : centeredOpts[1];
      // 3 Tiles for first 2 cases
      const tile = key < 2 ? tiles[0] : tiles[1];
      // No Title for Case# 2
      const title = key === 1 ? titles[0] : titles[1];

      const tileContent = {
        AltText: "Test",
        ArticleTitle: "Testing Article Title",
        Image: desktopImage,
        ImageGroup: "",
        LongDesc: "",
        Tag: "GLOBAL DEVELOPMENT",
        VideoId: "238413604",
        VideoSource: "vimeo",
        VideoTitle: "238413604",
        component: "VideoStoriesTile",
      };

      const pageName = [
        centered.name,
        whiteText.name,
        backgroundColor.name,
        underline.name,
        title.name,
        titleSize.name,
        button.name,
      ].join(" | ");

      const pageContent = (
        <>
          <h1 key={`${key}-h1`} className="VisualTestTitle">
            {`${key}: ${pageName}`}
          </h1>
          <TileLayoutVideoStories
            key={`TileLayoutVideoStories-${key}`}
            content={{
              Tiles: Array(tile.value).fill(tileContent),
              buttonText: "Button Text",
              ButtonTheme: "Primary",
              CTA: "test",
              Link: button.value,
              LinkTitle: "Test Link Title",
              BackgroundColor: backgroundColor.value,
              Centered: centered.value,
              TitleSize: titleSize.value,
              TitleText: title.value,
              Underlined: underline.value,
              WhiteText: whiteText.value,
            }}
          />
        </>
      );
      components.push(pageContent);
      key++;
    });
  });

  return components;
};

export default TileLayoutVideoStoriesVisualTest;
