import React, { PureComponent } from "react";
import { AccordionItem as AccordionItemMolecule } from "../../../shared/molecules";

class AccordionItem extends PureComponent {
  render() {
    const { Title, DefaultOpen, BodyText, name, Index } = this.props;

    return (
      <AccordionItemMolecule
        title={Title}
        defaultOpen={DefaultOpen}
        id={Index}
        name={name}>
        {BodyText}
      </AccordionItemMolecule>
    );
  }
}

export default AccordionItem;
