import React, { PureComponent } from "react";
import { Link as GatsbyLink } from "gatsby";

import { StoriesLayout } from "../../../shared/organisms";
import { withStoryblokLink } from "../../utilities";
import contentToComponents from "../../utilities/contentToComponents";
import Block from "../Block";

import "./TileLayoutStories.scss";

class TileLayoutStories extends PureComponent {
  render() {
    const { content, locale } = this.props;
    const {
      Tiles,
      TitleText,
      TitleSize,
      Underlined,
      WhiteText,
      CTA,
      ButtonTheme,
      Link,
      LinkTitle,
      BackgroundColor,
      Centered,
    } = content;
    return (
      <Block fluid content={content} className="TileLayoutStoriesBlock">
        <StoriesLayout
          titleText={TitleText}
          titleSize={TitleSize}
          underlined={Underlined}
          whiteText={WhiteText}
          buttonText={CTA}
          buttonTheme={ButtonTheme}
          href={Link.href}
          internalLinkComponent={GatsbyLink}
          external={Link.external}
          linkTitle={LinkTitle}
          backgroundColor={BackgroundColor}
          centered={Centered}>
          {contentToComponents(Tiles, { WhiteText, locale })}
        </StoriesLayout>
      </Block>
    );
  }
}

export default withStoryblokLink(TileLayoutStories);
