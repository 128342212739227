/* eslint-disable import/prefer-default-export */
const defaultColorOptions = [
  {
    name: "primary",
    value: "brand-navy-color",
  },
  {
    name: "secondary",
    value: "brand-yellow-color",
  },
  {
    name: "tertiary",
    value: "base-light-blue-color",
  },
];

const sizes = [
  {
    name: "L",
    value: "L",
  },
  {
    name: "M",
    value: "M",
  },
  {
    name: "S",
    value: "S",
  },
];

const headingSizes = [
  {
    name: "XL",
    value: "XL",
  },
  {
    name: "L",
    value: "L",
  },
  {
    name: "M",
    value: "M",
  },
  {
    name: "S",
    value: "S",
  },
  {
    name: "XS",
    value: "XS",
  },
];

const columnOptions = [
  {
    name: "8",
    value: 8,
  },
  {
    name: "10",
    value: 10,
  },
  {
    name: "12",
    value: 12,
  },
];

const imageRatioOptions = [
  { name: "1:1", value: "OneToOne" },
  { name: "2:1", value: "TwoToOne" },
  { name: "3:1", value: "ThreeToOne" },
  { name: "4:3", value: "FourToThree" },
  { name: "16:9", value: "SixteenToNine" },
];

const breakpoints = {
  xl: 1440,
  lg: 1024,
  md: 768,
  sm: 576,
};

const mediaQueries = {};
Object.keys(breakpoints).forEach((bp) => {
  mediaQueries[bp] = `(min-width: ${breakpoints[bp]}px)`;
});

const hiddenCampaignFields = {
  utm_campaign: "CampaignMember.Marketing_ID__c",
  utm_source: "CampaignMember.Marketing_Source__c",
  utm_medium: "CampaignMember.Marketing_Medium__c",
  utm_term: "CampaignMember.Marketing_Keyword__c",
  fieldTypes: "fieldTypes",
};

export {
  breakpoints,
  columnOptions,
  defaultColorOptions,
  headingSizes,
  hiddenCampaignFields,
  imageRatioOptions,
  mediaQueries,
  sizes,
};
