import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Heading, Divider } from "../../atoms";
import { RelatedStory } from "../../molecules";
import "./RelatedStories.scss";

const RelatedStories = (props) => {
  const { title, pages, internalLinkComponent } = props;

  const relatedStories = pages.map((page, i) => {
    const divider = i < pages.length - 1 ? <Divider /> : null;
    const key = `${page.storyTitle} ${i}`;
    return (
      <Fragment key={key}>
        <RelatedStory
          link={page.link}
          storyImageAlt={page.storyImageAlt}
          storyPrimaryImage={page.storyPrimaryImage}
          storySecondaryImage={page.storySecondaryImage}
          storyTitle={page.storyTitle}
          internalLinkComponent={internalLinkComponent}
        />
        {divider}
      </Fragment>
    );
  });
  return (
    <div className="RelatedStories">
      <div className="RelatedStoriesBorder">
        <Heading size="S" className="RelatedStoriesTitle">
          {title}
        </Heading>
        {relatedStories}
      </div>
    </div>
  );
};

RelatedStories.propTypes = {
  title: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        external: PropTypes.boolean,
      }).isRequired,
      storyImageAlt: PropTypes.string.isRequired,
      storyPrimaryImage: PropTypes.string.isRequired,
      storySecondaryImage: PropTypes.string.isRequired,
      storyTitle: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default RelatedStories;
