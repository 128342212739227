import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { composeClassName } from "../../utilities";

import replaceStoryblokCdn from "../../../components/utilities/replaceStoryblokCdn";

import "./Image.scss";

class Image extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** The source file for the main image */
    source: PropTypes.string.isRequired,
    /** The source file for the mobile image */
    mobileSource: PropTypes.string,
    /** The essential information conveyed by an image the purposes of the image */
    altText: PropTypes.string.isRequired,
    /** A long description is used for each img element
     *  that does not have Alt text conveying the same information as the image. */
    longdesc: PropTypes.string,
    /** If true, the image is in a circular container */
    circular: PropTypes.bool,
  };

  static defaultProps = {
    circular: false,
    className: "",
  };

  render() {
    const {
      altText,
      className,
      circular,
      longdesc,
      mobileSource,
      source,
    } = this.props;
    // If we don't care about it updating on resize, we don't need any state
    const composedClassName = composeClassName([
      "Image",
      className,
      circular ? "Circular" : "",
    ]);

    const formattedSource = replaceStoryblokCdn(source);
    const formattedMobileSource =
      replaceStoryblokCdn(mobileSource) || formattedSource;

    return (
      <React.Fragment>
        <div className={`${composedClassName} Desktop`}>
          <img
            src={formattedSource}
            alt={altText}
            {...(longdesc ? { longdesc } : {})}
          />
        </div>
        <div className={`${composedClassName} Mobile`}>
          <img
            src={formattedMobileSource}
            alt={altText}
            {...(longdesc ? { longdesc } : {})}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Image;
