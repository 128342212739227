import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import DisplayText from "../../atoms/DisplayText";
import Divider from "../../atoms/Divider";
import "./BulletList.scss";

class BulletList extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    /** The title at the top of the list */
    title: PropTypes.string,
    size: PropTypes.oneOf(["L", "M", "S"]),
    whiteText: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    size: "M",
    whiteText: false,
  };

  _renderTitleSection = (title, size, whiteText) => {
    if (undefined !== title && title.length > 0) {
      return (
        <React.Fragment>
          <DisplayText size={size} whiteText={whiteText}>
            {title}
          </DisplayText>
          <Divider className="BulletListDivider" />
        </React.Fragment>
      );
    }
    return "";
  };

  render() {
    const { title, children, className, size, whiteText } = this.props;

    const composedClassName = composeClassName([
      "BulletList",
      className,
      size,
      whiteText ? "WhiteText" : "",
    ]);

    const childElements = children.map((child, index) => {
      const key = `list-item-${index}`;
      return React.cloneElement(child, { key });
    });
    return (
      <div className={composedClassName}>
        {this._renderTitleSection(title, size, whiteText)}
        <ul>{childElements}</ul>
      </div>
    );
  }
}

export default BulletList;
