/* eslint-disable react/prefer-stateless-function */

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import { composeClassName } from "../../../shared/utilities";
import Components from "../../components";
import Block from "../Block";
import "./TileLayoutBase.scss";

class TileLayoutBase extends PureComponent {
  static propTypes = {
    content: PropTypes.shape({
      Tiles: PropTypes.arrayOf(PropTypes.any),
      Centered: PropTypes.bool,
    }),
  };

  render() {
    const { content, partials, className, locale } = this.props;
    let centerClass = "";
    if (content.Centered) {
      centerClass = "CenteredTiles";
    }
    const composedClassName = composeClassName([
      "TileBlock",
      className,
      centerClass,
    ]);
    return (
      <Block content={content} className={composedClassName}>
        {content.Tiles.map((tile, index) => {
          const { _uid, component, ...props } = tile;
          const { length } = content.Tiles;
          return (
            <React.Fragment key={_uid}>
              <SbEditable content={tile}>
                {React.createElement(Components[component], {
                  partials,
                  length,
                  index,
                  locale,
                  ...props,
                })}
              </SbEditable>
            </React.Fragment>
          );
        })}
      </Block>
    );
  }
}

export default TileLayoutBase;
