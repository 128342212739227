import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { composeClassName } from "../../utilities";

import "./Accordion.scss";

class Accordion extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { children, className, name, t } = this.props;
    const composedClassName = composeClassName(["Accordion", className]);
    const childrenToRender = React.Children.map(children, (child, index) => {
      return React.cloneElement(child, { name: name, id: index });
    });

    return (
      <ul className={composedClassName} aria-label={t("Accordian__ariaLabel")}>
        {childrenToRender}
      </ul>
    );
  }
}

export default withTranslation("translations")(Accordion);
