/* eslint-disable react/prefer-stateless-function */
// This function intentionally returns null,
// it is used exclusively for collecting props from storyblok
// This file must remain in place to avoid breaking the imports from the setup script

import React from "react";

class NavigationSubItem extends React.PureComponent {}

export default NavigationSubItem;
