/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import { Label, Icon, BodyText } from "../../../atoms";
import { composeClassName } from "../../../utilities";

import asFormElement from "../../asFormElement";
import { FormContext } from "../../Form";

import "./InputTextArea.scss";

class InputTextArea extends PureComponent {
  static propTypes = {
    /** Indicates if the user input is accepted */
    approved: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    /** Indicates if there is an error in the user input */
    error: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func,
    placeholder: PropTypes.string,
    /** Indicate purpose of text input for accessibility */
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
    initialValue: PropTypes.string,
    maxLength: PropTypes.number,
    fieldType: PropTypes.oneOf(["string"]),
  };

  static defaultProps = {
    approved: false,
    className: "",
    disabled: false,
    error: false,
    icon: "",
    placeholder: "",
    fieldType: "string",
    maxLength: 255,
  };

  _onChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { value } = target;
    const { name } = this.props;
    this.props.onChangeHandler({ name, value }); // eslint-disable-line
  };

  render() {
    const {
      className,
      disabled,
      error,
      icon,
      label,
      name,
      placeholder,
      title,
      value,
      message,
      whiteText,
      maxLength,
    } = this.props;
    // eslint-disable-next-line no-multi-assign
    let limitMessage = "";
    if (value !== undefined && value.length >= maxLength) {
      limitMessage = (
        <Trans i18nKey="InputTextArea__ErrorMessage__limitMessage">
          {null}
        </Trans>
      );
    }

    const labelProps = {
      label,
      whiteText,
    };

    const inputGroupClassName = composeClassName([
      "InputTextAreaGroup",
      error ? "error" : "",
    ]);

    const composedClassName = composeClassName([
      "InputTextArea",
      error ? "error" : "",
      className,
    ]);

    let iconComponent = null;
    let noIconStyles;
    if (icon) {
      const iconProps = { icon };
      noIconStyles = null;
      iconComponent = <Icon size="S" {...iconProps} />;
    }

    if (error) {
      noIconStyles = null;
      iconComponent = <Icon size="S" icon="close" className="ErrorIcon" />;
    }

    const htmlFor = name;

    return (
      <div className="TextAreaContainer">
        <Label htmlFor={htmlFor} {...labelProps}>
          <div className={inputGroupClassName}>
            <textarea
              maxLength={maxLength}
              className={composedClassName}
              id={htmlFor}
              disabled={disabled}
              name={name}
              onChange={this._onChange}
              placeholder={placeholder}
              style={noIconStyles}
              title={title}
              value={value}
            />
            {iconComponent}
          </div>
          <BodyText size="S" className="ErrorMessage">
            <Trans i18nKey={message}>{null}</Trans>
            {limitMessage}
          </BodyText>
        </Label>
      </div>
    );
  }
}

export default asFormElement(InputTextArea, FormContext);
