import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { BannerDonationWidget as SharedBannerDonationWidget } from "../../../shared/organisms";
import Block from "../Block";
import { withStoryblokLink } from "../../utilities";
import "./BannerDonationWidget.scss";

const BannerDonationWidget = (props) => {
  const { content, ieClass } = props;
  const {
    ButtonCADText,
    ButtonUSDText,
    DonateByPhoneLink,
    DonateByPhoneLinkText,
    DonateByPhoneLinkTitle,
    DonateTitle,
    ImageDesktop,
    ImageMobile,
    ImageOverlay,
    TextDonateMonthly,
    VideoBackgroundSrc,
  } = content;

  return (
    <Block className="BannerDonationWidgetBlock" content={content} fluid>
      <SharedBannerDonationWidget
        buttonCADText={ButtonCADText}
        buttonUSDText={ButtonUSDText}
        donateByPhoneLinkExternal={DonateByPhoneLink.external}
        donateByPhoneLinkHref={DonateByPhoneLink.href}
        donateByPhoneLinkText={DonateByPhoneLinkText}
        donateByPhoneLinkTitle={DonateByPhoneLinkTitle}
        donateTitle={DonateTitle}
        ieClass={ieClass}
        imageDesktopSrc={ImageDesktop}
        imageMobileSrc={ImageMobile}
        imageOverlay={ImageOverlay}
        internalLinkComponent={GatsbyLink}
        textDonateMonthly={TextDonateMonthly}
        videoBackgroundSrc={VideoBackgroundSrc}
      />
    </Block>
  );
};

export default withStoryblokLink(BannerDonationWidget, {
  LinkFields: ["DonateByPhoneLink"],
});
