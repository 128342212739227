import React from "react";
import NavigationBarDesktop from "../NavigationBarDesktop";
import NavigationBarMobile from "../NavigationBarMobile";
import "./NavigationBar.scss";

const NavigationBar = (props) => {
  return (
    <React.Fragment>
      <NavigationBarMobile {...props} />
      <NavigationBarDesktop {...props} />
    </React.Fragment>
  );
};

export default NavigationBar;
