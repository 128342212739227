import React, { PureComponent } from "react";
import { Image as ImageComponent } from "../../../shared/atoms";
import "./SlideImage.scss";

class SlideImage extends PureComponent {
  render() {
    const { Image, MobileImage, AltText, LongDesc } = this.props;

    return (
      <ImageComponent
        className="SlideImage"
        source={Image}
        mobileSource={MobileImage}
        altText={AltText}
        longDesc={LongDesc}
      />
    );
  }
}

export default SlideImage;
