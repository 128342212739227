import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";

import "./DisplayText.scss";

class DisplayText extends PureComponent {
  static propTypes = {
    /** CSS class to be added along with default classes */
    className: PropTypes.string,
    /** The selection for the size of the text */
    size: PropTypes.oneOf(["L", "M", "S"]).isRequired,
    /** If true, the text is centered; otherwise it is left-aligned */
    centered: PropTypes.bool,
    /** Color of the text, defaults to brand navy. */
    whiteText: PropTypes.bool,
    dataAttributes: PropTypes.object,
  };

  static defaultProps = {
    centered: false,
    className: "",
    whiteText: false,
  };

  render() {
    const {
      children,
      size,
      centered,
      className,
      dataAttributes,
      whiteText,
    } = this.props;
    const composedClassName = composeClassName([
      `DisplayText${centered ? " Centered" : ""}`,
      size,
      className,
      whiteText ? "whiteText" : "",
    ]);

    if (size === "L") {
      return (
        <h6 className={composedClassName} {...dataAttributes}>
          {children}
        </h6>
      );
    }
    if (["M", "S"].includes(size)) {
      return (
        <span className={composedClassName} {...dataAttributes}>
          {children}
        </span>
      );
    }
    return null;
  }
}

export default DisplayText;
