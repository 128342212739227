/* eslint-disable no-continue */
/* eslint-disable import/prefer-default-export */

// This function is a temporary solution to fix image ratio bug for IE11
// selectorArray: Array of selectors
export const ie11ImageToBackgroundShim = (selectorArray) => {
  const querySelector = selectorArray
    .map((selector) => {
      if (selector === ".BannerBackground") return selector;
      return `${selector} .Image`;
    })
    .join(", ");

  const imageContainers = document.querySelectorAll(querySelector);
  if (imageContainers && imageContainers.length > 0) {
    for (let i = 0; i < imageContainers.length; i++) {
      const container = imageContainers[i];
      const img = container.getElementsByTagName("img")[0];
      if (!img) continue;
      const { src } = img;
      container.style.backgroundImage = `url(${src})`;
      container.classList.add("ie-background-image");
    }
  }
};
