import React from "react";
import { Link } from "gatsby";
import { Page404 } from "../../shared/organisms";
import { browser } from "../../shared/utilities";

// TODO: Remove ComponentDidMount, if condition when we.org/fr-CA is live

class Page404FrCa extends React.PureComponent {
  componentDidMount() {
    browser.window.location.pathname = "/fr-CA";
  }

  render() {
    return (
      <Page404
        message="La page que vous avez demandée n'est pas disponible"
        homeURL="/fr-CA"
        buttonText="Rentrer chez soi"
        internalLinkComponent={Link}
      />
    );
  }
}

export default Page404FrCa;
