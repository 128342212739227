import React from "react";
import Input from "../Input";

const InputEmail = (props) => {
  const { validateAgainst = {} } = props;
  const { email, required } = validateAgainst;
  validateAgainst.email = email || true;
  validateAgainst.required = required || true;
  const inputEmailProps = {
    ...props,
    ...{ type: "email", validateAgainst: validateAgainst, fieldType: "string" },
  };
  return <Input {...inputEmailProps} />;
};

export default InputEmail;
