import Author from "./Author/Author.visual";
import Banner from "./Banner/Banner.visual";
import BannerDonationWidget from "./BannerDonationWidget/BannerDonationWidget.visual";
import ContentGenericAligned from "./ContentGenericAligned/ContentGenericAligned.visual";
import TileLayoutGallery from "./TileLayoutGallery/TileLayoutGallery.visual";
import Mosaic from "./Mosaic/Mosaic.visual";
import TileLayoutVideoStories from "./TileLayoutVideoStories/TileLayoutVideoStories.visual";

export default {
  Author,
  Banner,
  BannerDonationWidget,
  ContentGenericAligned,
  TileLayoutGallery,
  Mosaic,
  TileLayoutVideoStories,
};
