import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { DisplayText, Heading, Image, ImageBackground, Col } from "../../atoms";
import { ModalVideo, ButtonLink, BodyTextMarkdown } from "../../molecules";
import { composeClassName, StringUtils } from "../../utilities";

import "./GenericContentAligned.scss";

class GenericContentAligned extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    alignment: PropTypes.oneOf(["Left", "Right"]),
    internalLinkComponent: PropTypes.any,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    bodyText: PropTypes.string.isRequired,
    bodySize: PropTypes.string,
    image: PropTypes.shape({
      source: PropTypes.string.isRequired,
      mobileSource: PropTypes.string,
      altText: PropTypes.string.isRequired,
      longDesc: PropTypes.string,
      imageRatio: PropTypes.oneOf(["OneToOne", "FourToThree", "SixteenToNine"]),
    }),
    video: PropTypes.shape({
      videoButtonTheme: PropTypes.oneOf(["light", "dark"]),
      videoSource: PropTypes.oneOf(["vimeo", "youtube"]),
      videoId: PropTypes.string,
      videoTitle: PropTypes.string,
    }),
    button1: PropTypes.shape({
      theme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
      text: PropTypes.string,
      href: PropTypes.string,
      external: PropTypes.bool,
      title: PropTypes.string,
    }),
    button2: PropTypes.shape({
      theme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "IwthPrimary", "IwthSecondary", "IwthTertiary"]),
      text: PropTypes.string,
      href: PropTypes.string,
      external: PropTypes.bool,
      title: PropTypes.string,
    }),
    cols: PropTypes.oneOf([6, 9]),
  };

  static defaultProps = {
    title: "",
    subtitle: "",
    alignment: "Right",
    button1: {
      theme: "Tertiary",
    },
    button2: {
      theme: "Tertiary",
    },
    cols: 6,
    video: {
      videoTitle: "",
      videoSource: "youtube",
      videoId: null,
      videoButtonTheme: null,
    },
  };

  render() {
    const {
      className,
      alignment,
      internalLinkComponent,
      title,
      subtitle,
      bodyText,
      bodySize,
      image,
      button1,
      button2,
      cols,
      video,
    } = this.props;

    const { source, mobileSource, altText, longDesc, imageRatio } = image;
    const { videoTitle, videoSource, videoId, videoButtonTheme } = video;

    let button1Element = null;
    let button2Element = null;

    if (button1.text && (internalLinkComponent || button1.href)) {
      button1Element = (
        <ButtonLink
          theme={button1.theme}
          internalLinkComponent={internalLinkComponent}
          href={button1.href}
          external={button1.external}
          title={button1.title}>
          {button1.text}
        </ButtonLink>
      );
    }

    if (button2.text && (internalLinkComponent || button2.href)) {
      button2Element = (
        <ButtonLink
          theme={button2.theme}
          internalLinkComponent={internalLinkComponent}
          href={button2.href}
          external={button2.external}
          title={button2.title}>
          {button2.text}
        </ButtonLink>
      );
    }

    let subtitleSection = null;
    if (subtitle) {
      subtitleSection = (
        <DisplayText size="S">
          {StringUtils.formatMax(subtitle, 30)}
        </DisplayText>
      );
    }

    let titleSection = null;
    if (title) {
      titleSection = (
        <Heading size="M">{StringUtils.formatMax(title, 50)}</Heading>
      );
    }

    let bodyTextSection = null;
    if (bodyText) {
      bodyTextSection = (
        <BodyTextMarkdown
          size={bodySize}
          internalLinkComponent={internalLinkComponent}>
          {bodyText}
        </BodyTextMarkdown>
      );
    }

    let buttonSection = null;
    if (button1Element || button2Element) {
      buttonSection = (
        <div className="Buttons">
          {button1Element}
          {button2Element}
        </div>
      );
    }

    const composedClassName = composeClassName([
      "GenericContentAligned",
      className,
    ]);

    const contentSection = (
      <Col
        className={`${composedClassName} ContentSection`}
        xs={{ span: 12 }}
        md={{ span: cols }}>
        <div className="ContentSectionWrapper">
          {subtitleSection}
          {titleSection}
          {bodyTextSection}
          {buttonSection}
        </div>
      </Col>
    );

    let imageContent;
    if (videoId && videoTitle) {
      imageContent = (
        <ImageBackground
          className={`Columns${12 - cols}-${imageRatio}`}
          source={source}
          mobileSource={mobileSource}>
          <ModalVideo
            className="PlayButton"
            title={videoTitle}
            videoId={videoId}
            source={videoSource}
            theme={videoButtonTheme}
          />
        </ImageBackground>
      );
    } else {
      imageContent = (
        <Image
          className={`Columns${12 - cols}-${imageRatio}`}
          source={source}
          mobileSource={mobileSource}
          altText={altText}
          longDesc={longDesc}
        />
      );
    }

    const imageSectionMobileOrRight = (
      <Col
        className={`${composedClassName} ImageSection Mobile ${
          alignment === "Left" ? "Hidden" : ""
        }`}
        xs={{ span: 12 }}
        md={{ span: 12 - cols }}>
        {imageContent}
      </Col>
    );
    const imageSectionDesktop = (
      <Col
        className={`${composedClassName} ImageSection Desktop ${
          alignment === "Right" ? "Hidden" : ""
        }`}
        xs={{ span: 12 }}
        md={{ span: 12 - cols }}>
        {imageContent}
      </Col>
    );

    return (
      <React.Fragment>
        {imageSectionMobileOrRight}
        {contentSection}
        {imageSectionDesktop}
      </React.Fragment>
    );
  }
}

export default GenericContentAligned;
