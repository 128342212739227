const getContent = (story) => {
  // get and parse content from story
  let storyContent = "{}";

  if (story.node && story.node.content) {
    storyContent = story.node.content;
  }

  if (story.content) {
    storyContent = story.content;
  }

  return JSON.parse(storyContent);
};

export { getContent }; // eslint-disable-line import/prefer-default-export
