/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import SbEditable from "storyblok-react";

import Components from "../../components";
import Block from "../Block";
import {
  Col,
  Heading,
  BodyText as SharedBodyText,
  DisplayText,
  Container,
} from "../../../shared/atoms";
import { Form, InputHidden } from "../../../shared/forms";
import { colors } from "../../../shared/colors";
import { generateFormName, browser } from "../../../shared/utilities";
import { withStoryblokLink } from "../../utilities";
import { hiddenCampaignFields as hiddenCampaignFieldsNames } from "../../sharedVariables";

import "./FormBuilder.scss";

const requiredContactFields = {
  InputEmail: (
    <InputHidden key="hidden-field-email" name="email" initialValue="" />
  ),
  InputLastName: (
    <InputHidden
      key="hidden-field-last_name"
      name="last_name"
      initialValue=""
    />
  ),
  InputFirstName: (
    <InputHidden
      key="hidden-field-first_name"
      name="first_name"
      initialValue=""
    />
  ),
};

class FormBuilder extends Component {
  _renderHiddenContactFields = (fields) => {
    const existingContactFields = fields
      .filter((field) => {
        if (Object.keys(requiredContactFields).includes(field.component)) {
          return field;
        }
      })
      .map((field) => {
        return field.component;
      });
    const hiddenContactFields = [];
    Object.keys(requiredContactFields).forEach((field) => {
      if (!existingContactFields.includes(field)) {
        hiddenContactFields.push(requiredContactFields[field]);
      }
    });

    return hiddenContactFields;
  };

  _renderHiddenCampaignFields = () => {
    const hiddenCampaignFields = Object.keys(hiddenCampaignFieldsNames).map(
      (key, value) => {
        const hiddenFieldKey = `hidden-field-key-${value}`;
        return (
          <InputHidden
            key={hiddenFieldKey}
            name={hiddenCampaignFieldsNames[key]}
            initialValue=""
          />
        );
      },
    );

    return hiddenCampaignFields;
  };

  _handleSubmit = (data, isValid) => {
    const { content } = this.props;
    const { SubmissionLink, SubmissionNewTab } = content;
    // If invalid form submission or no submission page, don't execute
    if (isValid === false || !SubmissionLink.href) return;

    // If link is external we want to change the entire href
    if (SubmissionLink.external) {
      // If new tab is true, open in a new tab
      if (SubmissionNewTab) {
        const win = browser.window.open(SubmissionLink.href, "_blank");
        win.focus();
      } else {
        browser.window.location.href = SubmissionLink.href;
      }
    } else if (SubmissionNewTab) {
      // If link is internal and in a new tab we want to concatenate the origin and the pathname
      const win = browser.window.open(
        `${browser.window.location.origin}/${SubmissionLink.href}`,
        "_blank",
      );
      win.focus();
    } else {
      // If link is internal but not in a new tab, just change the pathname
      browser.window.location.pathname = SubmissionLink.href;
    }
  };

  render() {
    const { content, currentHref } = this.props;
    const {
      Fields = [],
      CampaignID,
      TitleText = "",
      TitleSize,
      TextColor,
      BackgroundColor,
      BodyText,
      BodySize,
      SubmissionText,
    } = content;

    const whiteText = TextColor.includes("white");
    const formName = CampaignID || TitleText;
    const renderFields = Fields.map((field) => {
      const { _uid, component, ...props } = field;
      const renderField = React.createElement(Components[component], {
        ...props,
        ...{ whiteText, formName },
      });
      return (
        <SbEditable
          content={field}
          name={props.SalesForceField || renderField.type.name}
          key={_uid}>
          {renderField}
        </SbEditable>
      );
    });
    const generatedFormName = generateFormName(formName, currentHref);
    const hiddenCampaignIdField = CampaignID ? (
      <InputHidden name="campaign_id" initialValue={CampaignID} />
    ) : null;
    const hiddenContactFields = this._renderHiddenContactFields(Fields);
    const hiddenCampaignFields = this._renderHiddenCampaignFields();

    const body = BodySize.Display ? (
      <DisplayText size="L" whiteText={whiteText}>
        {BodyText}
      </DisplayText>
    ) : (
      <SharedBodyText size={BodySize} whiteText={whiteText}>
        {BodyText}
      </SharedBodyText>
    );

    return (
      <Block
        fluid
        className="FormBlock"
        content={content}
        style={{ backgroundColor: colors[BackgroundColor].color }}>
        <Container>
          <Col xs={{ span: 12 }} className="FormHeader">
            <Heading
              size={TitleSize}
              whiteText={whiteText}
              underlined
              underlineColor={colors[TextColor].color}>
              {TitleText}
            </Heading>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 6 }} className="ContentArea">
            {body}
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 6 }}>
            <Form
              formName={generatedFormName}
              formEncode
              submissionMessage={SubmissionText}
              dataAttr={{
                "data-netlify": "true",
              }}
              submitUrl={browser.window.location.pathname}
              onSubmit={this._handleSubmit}>
              {hiddenCampaignIdField}
              {hiddenContactFields}
              {hiddenCampaignFields}
              {renderFields}
            </Form>
          </Col>
        </Container>
      </Block>
    );
  }
}

// testing purpose
export { FormBuilder };

export default withStoryblokLink(FormBuilder, {
  LinkFields: ["SubmissionLink"],
});
