import React from "react";
import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";
import { StoriesTile as StoriesTileMolecule } from "../../../shared/molecules";
import { withStoryblokLink } from "../../utilities";

const StoriesTile = (props) => {
  const {
    Link,
    LinkTitle,
    Image,
    AltText,
    LongDesc,
    ArticleTitle,
    Tag,
    WhiteText,
  } = props;

  return (
    <StoriesTileMolecule
      internalLinkComponent={GatsbyLink}
      link={Link}
      linkTitle={LinkTitle}
      image={Image}
      altText={AltText}
      longDesc={LongDesc}
      tag={Tag}
      articleTitle={ArticleTitle}
      whiteText={WhiteText}
    />
  );
};

StoriesTile.propTypes = {
  Link: PropTypes.object,
  LinkTitle: PropTypes.string,
  Image: PropTypes.string.isRequired,
  AltText: PropTypes.string.isRequired,
  LongDesc: PropTypes.string,
  Tag: PropTypes.string.isRequired,
  ArticleTitle: PropTypes.string.isRequired,
  WhiteText: PropTypes.bool,
};

export default withStoryblokLink(StoriesTile);
