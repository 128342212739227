const enCA = { label: "CA (EN)", value: "en-CA", icon: "flagCA" };
const frCA = { label: "CA (FR)", value: "fr-CA", icon: "flagCA" };
const enUS = { label: "US (EN)", value: "en-US", icon: "flagUS" };
const enGB = { label: "UK (EN)", value: "en-GB", icon: "flagGB" };
const localeCodes = [enCA, frCA, enUS, enGB].map((locale) => {
  return locale.value;
});

// buildEnv is one of ["editor", "publisher"]
const buildEnvironment = process.env.GATSBY_WE_BUILD_ENV || "editor";
// buildSite is one of ["all", "we_org", "tyi", "trips", "covid"]
const buildSite = process.env.GATSBY_WE_BUILD_SITE || "all";
// buildLocale is one of ["all", "en-CA", "fr-CA", "en-US"]
const buildLocale = process.env.GATSBY_WE_BUILD_LOCALE || "all";

/* Config: sites generic */
const sites = {
  we_org: {
    name: "WE.org",
    locales: [enCA, frCA, enUS, enGB],
  },
  trips: {
    name: "TRIPS",
    locales: [enCA, enUS],
  },
  tyi: {
    name: "TYI",
    locales: [enCA, enUS],
  },
  covid: {
    name: "COVID",
    locales: [enCA, enUS, enGB],
  },
  m2w: {
    name: "MEtoWE.com",
    locales: [enCA, frCA, enUS, enGB],
  },
  iwanttohelp_en: {
    name: "IWantToHelp",
    locales: [enCA, frCA],
  },
  iwanttohelp_fr: {
    name: "Jeveux Aider",
    locales: [frCA, enCA],
  },
  wecharity: {
    name: "WeCharity.org",
    locales: [enCA, frCA],
  },
};
const siteCodes = Object.keys(sites);

let allowedLocales;
if (sites[buildSite]) {
  allowedLocales = sites[buildSite].locales;
} else {
  allowedLocales = [enCA];
}

/* Config: siteUrl */
const siteUrls = {
  we_org: process.env.GATSBY_SITE_WEORG_URL,
  tyi: process.env.GATSBY_SITE_TYI_URL,
  trips: process.env.GATSBY_SITE_TRIPS_URL,
  covid: process.env.GATSBY_SITE_COVID_URL,
  m2w: process.env.GATSBY_SITE_M2W_URL,
  iwanttohelp_en: process.env.GATSBY_SITE_IWANTTOHELP_EN_URL,
  iwanttohelp_fr: process.env.GATSBY_SITE_IWANTTOHELP_FR_URL,
  wecharity: process.env.GATSBY_SITE_WECHARITY_URL,
};
const siteUrl = siteUrls[buildSite] || "http://localhost:9000";

/* Config: siteGtm */
const siteGtms = {
  we_org: process.env.GATSBY_SITE_WEORG_GTM_ID,
  tyi: process.env.GATSBY_SITE_TYI_GTM_ID,
  trips: process.env.GATSBY_SITE_TRIPS_GTM_ID,
  covid: process.env.GATSBY_SITE_COVID_GTM_ID,
  m2w: process.env.GATSBY_SITE_M2W_GTM_ID,
  iwanttohelp_en: process.env.GATSBY_SITE_IWANTTOHELP_EN_GTM_ID,
  iwanttohelp_fr: process.env.GATSBY_SITE_IWANTTOHELP_FR_GTM_ID,
  wecharity: process.env.GATSBY_SITE_WECHARITY_GTM_ID,
};
const siteGtm = siteGtms[buildSite] || "GTM-5XXZ4Z5";

/* Config: siteTitle */
const siteTitles = {
  we_org: "WE.org",
  tyi: "Track Your Impact",
  trips: "Trips",
  covid: "COVID updates",
  m2w: "MEtoWE.com",
  iwanttohelp_en: "IWantToHelp.org",
  iwanttohelp_fr: "Jeveux-Aider.org",
  wecharity: "WeCharity.org",
};
const siteTitle = siteTitles[buildSite] || "WE Sites";

/* Config: datasource */
const dataSources = {
  301: `${buildSite}-redirects-301`,
  302: `${buildSite}-redirects-302`,
  noIndexNoFollow: `${buildSite}-no-index-no-follow`,
};

/* Config: noIndexUrl */
const { noIndexUrls } = require("./src/datasources/noIndexNoFollow/sanitized");

const noIndexUrl = noIndexUrls[buildSite] || [];

/* Config: storyblokSettings */
const storyblokSettings = {
  accessToken: process.env.STORYBLOK_PREVIEW_TOKEN,
  spaceId: process.env.STORYBLOK_SPACE_ID,
  oauthToken: process.env.STORYBLOK_OAUTH_TOKEN,
};
if (buildEnvironment === "publisher") {
  storyblokSettings.accessToken = process.env.STORYBLOK_PUBLIC_TOKEN;
}

const overrideLocaleHack = {
  locale: process.env.OVERRIDE_LOCALE_SWITCHER_HACK,
  url: process.env.OVERRIDE_LOCALE_URL_HACK,
};

/* Config: Plugins */
const plugins = [
  "gatsby-plugin-react-helmet",
  "gatsby-plugin-offline",
  "gatsby-plugin-sass",
  "gatsby-plugin-sitemap",
];

plugins.push({
  resolve: "gatsby-source-storyblok-we",
  options: {
    oauthToken: storyblokSettings.oauthToken,
    accessToken: storyblokSettings.accessToken,
    homeSlug: "home",
    version: buildEnvironment === "publisher" ? "published" : "draft",
    clonedSpace: process.env.STORYBLOK_CLONE,
    includeLinks: true,
    // only required if clonedSpace is set to `true`
    destinationSpaceName:
      process.env.GIT_BRANCH ||
      `Cloned @ ${new Date().toLocaleString("en-CA", { hour12: false })}`,
    sourceSpaceId: process.env.STORYBLOK_CLONE_FROM,
    editorDomain: process.env.STORYBLOK_CLONE_EDITOR_DOMAIN,
  },
});

plugins.push({
  resolve: "gatsby-plugin-google-tagmanager",
  options: {
    id: siteGtm,

    // Include GTM in development.
    // Defaults to false meaning GTM will only be loaded in production.
    // Enabling just for testing. We will set to be false, once confirmed.
    includeInDevelopment: true,

    // Specify optional GTM environment details.
    // gtmAuth: "YOUR_GOOGLE_TAGMANAGER_ENVIROMENT_AUTH_STRING",
    // gtmPreview: "YOUR_GOOGLE_TAGMANAGER_ENVIROMENT_PREVIEW_NAME",
    // dataLayerName: "YOUR_DATA_LAYER_NAME",
  },
});

plugins.push({
  resolve: "gatsby-plugin-sitemap",
  options: {
    sitemapSize: 5000,
    exclude: noIndexUrl,
  },
});

plugins.push({
  resolve: "gatsby-plugin-robots-txt",
  options: {
    host: siteUrls[buildSite],
    env: {
      production: {
        policy: [
          {
            userAgent: "*",
            allow: ["/"],
            disallow: noIndexUrl,
          },
        ],
      },
    },
  },
});

module.exports = {
  plugins: plugins,
  pathPrefix: process.env.GATSBY_PATH_PREFIX || "/",
  siteMetadata: {
    overrideLocaleHack: overrideLocaleHack,
    allowedLocales: allowedLocales,
    buildLocale: buildLocale,
    buildSite: buildSite,
    dataSources: dataSources,
    environment: buildEnvironment,
    localeCodes: localeCodes,
    siteCodes: siteCodes,
    siteUrl: siteUrl,
    sites: sites,
    title: siteTitle,
  },
};
