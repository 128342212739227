/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { browser, composeClassName } from "../../utilities";
import "./Button.scss";

class Button extends PureComponent {
  static propTypes = {
    /** The selection for the theme of the button */
    theme: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "Transparent"]),
    /** The selection for the size of the button */
    size: PropTypes.oneOf(["L", "M", "S"]),
    /** The selection for the HTML type of the button */
    type: PropTypes.oneOf(["button", "reset", "submit"]),
    className: PropTypes.string,
    onClickHandler: PropTypes.func,
    onKeyDownHandler: PropTypes.func,
    /** Optional attribute for keyboard Accessibility to fix tabindex issues */
    autoFocus: PropTypes.bool,
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    /** If hidden, the button will have display:none.
     * Used in ButtonSalesforceChat to give Salesforce control over when the button appears. */
    hidden: PropTypes.bool,
    /** Optional attribute for keyboard Accessibility */
    tabIndex: PropTypes.string,
    /** Allow parent component to access to the button DOM for action like `focus` */
    buttonRef: PropTypes.shape({
      current: PropTypes.instanceOf(browser.Element),
    }),
    a11yProps: PropTypes.object,
    dataAttributes: PropTypes.object,
  };

  static defaultProps = {
    autoFocus: false,
    buttonRef: React.createRef(),
    className: "",
    disabled: false,
    hidden: false,
    size: "M",
    theme: "Primary",
    type: "button",
    onClickHandler: () => {},
    onKeyDownHandler: () => {},
  };

  render() {
    const {
      autoFocus,
      ariaLabel,
      buttonRef,
      className,
      children,
      hidden,
      disabled,
      onClickHandler,
      onKeyDownHandler,
      size,
      tabIndex,
      theme,
      type,
      a11yProps,
      dataAttributes,
      ...rest
    } = this.props;

    const composedClassName = composeClassName([
      "Button",
      theme,
      size,
      className,
    ]);

    return (
      <div className="ButtonContainer">
        <button
          {...(hidden ? { style: { display: "none" } } : {})}
          aria-label={ariaLabel}
          autoFocus={autoFocus}
          className={composedClassName}
          data-testid={rest["data-testid"]}
          disabled={disabled}
          onClick={onClickHandler}
          onKeyDown={onKeyDownHandler}
          ref={buttonRef}
          tabIndex={tabIndex}
          type={type}
          {...a11yProps}
          {...dataAttributes}>
          {children}
        </button>
      </div>
    );
  }
}

export default Button;
