import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ImageBackground, Col, Image } from "../../atoms";
import ModalVideo from "../ModalVideo";
import { composeClassName } from "../../utilities";

import "./ContentImage.scss";

class ContentImage extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    videoSource: PropTypes.oneOf(["vimeo", "youtube"]),
    videoId: PropTypes.string,
    videoTitle: PropTypes.string,
    videoButtonTheme: PropTypes.oneOf(["light", "dark"]),
    imgAltText: PropTypes.string,
    imgSource: PropTypes.string.isRequired,
    imgLongDesc: PropTypes.string,
    imgMobileSource: PropTypes.string,
    aspectRatio: PropTypes.oneOf(["OneToOne", "FourToThree", "SixteenToNine"]),
    imageCols: PropTypes.number,
  };

  static defaultProps = {
    videoSource: "vimeo",
    videoId: null,
    videoTitle: null,
    videoButtonTheme: "light",
    imgMobileSource: "",
    imgLongDesc: "",
    aspectRatio: "OneToOne",
  };

  render() {
    const {
      className,
      imgAltText,
      imgMobileSource,
      imgSource,
      imgLongDesc,
      videoButtonTheme,
      videoId,
      videoSource,
      videoTitle,
      aspectRatio,
      imageCols,
    } = this.props;

    const imageBackgroundProps = {
      mobileSource: imgMobileSource,
      source: imgSource,
    };

    const imageProps = {
      altText: imgAltText,
      mobileSource: imgMobileSource,
      source: imgSource,
      longdesc: imgLongDesc,
    };

    const modalProps = {
      source: videoSource,
      videoId: videoId,
      title: videoTitle,
      theme: videoButtonTheme,
    };

    const composedClassName = composeClassName([
      className,
      "ContentImage",
      aspectRatio,
    ]);

    let imageComponent = <Image {...imageProps} />;

    if (videoId && videoTitle) {
      imageComponent = (
        <ImageBackground className="Image" {...imageBackgroundProps}>
          <ModalVideo className="PlayButton" {...modalProps} />
        </ImageBackground>
      );
    }

    return (
      <Col xs={{ span: 12 }} md={{ span: imageCols }}>
        <div className={composedClassName}>{imageComponent}</div>
      </Col>
    );
  }
}

export default ContentImage;
