import React from "react";
import { Link } from "gatsby";
import { Footer as SharedFooter } from "../../../shared/organisms";
import {
  mapNavigationColumn,
  mapLink,
  mapSocialButton,
  withStoryblokLink,
} from "../../utilities";
import { StoryblokContext } from "../../../context";

const Footer = (props) => {
  const {
    overrideLocaleHack,
    allowedLocales,
    currentLocale,
    content,
    logoExternal,
    logoHref,
  } = props;
  const {
    BottomLinks,
    Columns,
    CopyrightInfo,
    Logo,
    LogoLink,
    SocialButtons,
  } = content;

  return (
    <StoryblokContext.Consumer>
      {({ storyblokLinks }) => {
        const columns = Columns.map((column) => {
          return mapNavigationColumn(column, currentLocale, storyblokLinks);
        });
        const socialButtons = SocialButtons.map((socialButton) => {
          return mapSocialButton(socialButton, currentLocale, storyblokLinks);
        });
        const legalLinks = BottomLinks.map((legalLink) => {
          return mapLink(legalLink, currentLocale, storyblokLinks);
        });

        const { href, external } = LogoLink;

        return (
          <SharedFooter
            internalLinkComponent={Link}
            copyrightInfo={CopyrightInfo}
            columns={columns}
            socialButtons={socialButtons}
            legalLinks={legalLinks}
            overrideLocaleHack={overrideLocaleHack}
            allowedLocales={allowedLocales}
            currentLocale={currentLocale}
            logo={Logo}
            logoHref={href || logoHref}
            logoExternal={logoHref ? external : logoExternal}
          />
        );
      }}
    </StoryblokContext.Consumer>
  );
};

export default withStoryblokLink(Footer, { LinkFields: ["LogoLink"] });
