import React, { Component } from "react";
import PropTypes from "prop-types";
import { min, max } from "lodash";

import { BaseLink, DisplayText, Icon } from "../../atoms";
import { composeClassName, browser } from "../../utilities";

import "./NavigationSub.scss";

class NavigationSub extends Component {
  static propTypes = {
    /** An array of the NavigationColumn molecule */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        external: PropTypes.bool,
        label: PropTypes.string,
      }),
    ).isRequired,
    currentHref: PropTypes.string,
    internalLinkComponent: PropTypes.any,
  };

  static defaultProps = {
    internalLinkComponent: null,
  };

  constructor() {
    super();
    this.state = {
      leftArrowHidden: false,
      rightArrowHidden: false,
    };
    this.ref = React.createRef();
    this.timer = null;
  }

  componentDidMount() {
    const subnavElement = this.ref.current;
    this.throttle(() => {
      this.setChildPositions();
      this.setHiddenArrows();
    }, 500);

    subnavElement.addEventListener("scroll", this.setHiddenArrows);
    browser.window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    const subnavElement = this.ref.current;
    subnavElement.removeEventListener("scroll", this.setHiddenArrows);
    browser.window.removeEventListener("resize", this.handleResize);
  }

  setChildPositions = () => {
    const subnavElement = this.ref.current;
    const children = Array.prototype.slice.call(subnavElement.children);
    this.childPositions = children.map((child) => {
      return child.offsetLeft;
    });
  };

  handleClick = (direction) => {
    const subnavElement = this.ref.current;
    const { scrollLeft } = subnavElement;

    let diff = this.childPositions.map((pos) => {
      return pos - scrollLeft;
    });
    let scrollAmount;

    if (direction === "left") {
      diff = diff.filter((val) => {
        return val < 0;
      });
      scrollAmount = max(diff);
    } else {
      diff = diff.filter((val) => {
        return val > 0;
      });
      scrollAmount = min(diff);
    }

    subnavElement.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  handleResize = () => {
    this.throttle(() => {
      this.setChildPositions();
      this.setHiddenArrows();
    }, 100);
  };

  setHiddenArrows = () => {
    const subnavElement = this.ref.current;
    const { rightArrowHidden, leftArrowHidden } = this.state;

    if (
      subnavElement.scrollWidth - subnavElement.clientWidth ===
      subnavElement.scrollLeft
    ) {
      this.setState({ rightArrowHidden: true });
    } else if (rightArrowHidden) {
      this.setState({ rightArrowHidden: false });
    }
    if (subnavElement.scrollLeft === 0) {
      this.setState({ leftArrowHidden: true });
    } else if (leftArrowHidden) {
      this.setState({ leftArrowHidden: false });
    }
  };

  throttle = (func, delay) => {
    if (this.timer) {
      return;
    }

    this.timer = setTimeout(() => {
      func();
      this.timer = null;
    }, delay);
  };

  render() {
    const { className, items, internalLinkComponent, currentHref } = this.props;
    const { rightArrowHidden, leftArrowHidden } = this.state;

    const navigationSubItems = items.map((item) => {
      const { title, href, external, label } = item;
      const activeClass = href === `/${currentHref}` ? "Active" : null;
      return (
        <div
          key={label}
          className={composeClassName(["NavigationSubItem", activeClass])}>
          <BaseLink
            href={href}
            external={external}
            title={title}
            internalLinkComponent={internalLinkComponent}
            className="NavigationSubItemLink">
            <DisplayText size="M">{label}</DisplayText>
          </BaseLink>
        </div>
      );
    });

    const leftArrow = (
      <BaseLink
        className="LeftArrow"
        blank
        role="button"
        onClickHandler={() => {
          this.handleClick("left");
        }}>
        <Icon icon="chevronLeft" size="S" />
      </BaseLink>
    );

    const rightArrow = (
      <BaseLink
        className="RightArrow"
        blank
        role="button"
        onClickHandler={() => {
          this.handleClick("right");
        }}>
        <Icon icon="chevronRight" size="S" />
      </BaseLink>
    );

    return (
      <div className={composeClassName(["NavigationSub", className])}>
        <div className="NavigationSubDesktop block-fluid">
          {navigationSubItems}
        </div>
        <div className="NavigationSubMobile">
          {leftArrowHidden ? null : leftArrow}
          <div ref={this.ref} className="NavigationSubItems">
            {navigationSubItems}
          </div>
          {rightArrowHidden ? null : rightArrow}
        </div>
      </div>
    );
  }
}

export default NavigationSub;
