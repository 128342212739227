import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Button } from "../../atoms";
import { browser, composeClassName } from "../../utilities";
import { playIconSmall, playIconLarge } from "./PlayIcons";
import "./ButtonVideo.scss";

class ButtonVideo extends PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    buttonRef: PropTypes.shape({
      current: PropTypes.instanceOf(browser.Element),
    }),
    className: PropTypes.string,
    dataVideoId: PropTypes.string,
    noDisplayText: PropTypes.bool,
    onClickHandler: PropTypes.func,
    tabIndex: PropTypes.string,
    theme: PropTypes.oneOf(["light", "dark"]),
  };

  static defaultProps = {
    onClickHandler: null,
    autoFocus: false,
    noDisplayText: false,
    tabIndex: "",
    theme: "light",
    dataVideoId: "",
  };

  render() {
    const {
      className,
      buttonRef,
      dataVideoId,
      onClickHandler,
      autoFocus,
      noDisplayText,
      tabIndex,
      theme,
      t,
    } = this.props;

    const dataAttributes =
      dataVideoId.length > 0
        ? {
            "data-video-id": dataVideoId, // eslint-disable-line indent
          } // eslint-disable-line indent
        : null;

    const buttonProps = {
      onClickHandler,
      autoFocus,
      tabIndex,
      buttonRef,
      dataAttributes,
    };

    const composedClassName = composeClassName(["ButtonVideo", className]);
    const themeClass = theme === "dark" ? "Dark" : null;

    const playIcon = (
      <div
        className={composeClassName([
          noDisplayText ? "CircleSmall" : "Circle",
          themeClass,
        ])}
        data-video-id={dataVideoId}>
        {noDisplayText ? playIconSmall : playIconLarge}
      </div>
    );

    return (
      <Button
        title={t("ButtonVideo__title")}
        className={composedClassName}
        type="button"
        {...buttonProps}>
        {playIcon}
      </Button>
    );
  }
}

export default withTranslation("translations")(ButtonVideo);
