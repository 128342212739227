import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import { Col, DisplayText, Divider, Heading } from "../../atoms";
import "./Author.scss";

class Author extends PureComponent {
  static propTypes = {
    authorName: PropTypes.string.isRequired,
    className: PropTypes.string,
    jobTitle: PropTypes.string,
    publishedDate: PropTypes.string,
    readTime: PropTypes.string,
    divider: PropTypes.bool,
  };

  static defaultProps = {
    divider: true,
  };

  render() {
    const {
      authorName,
      className,
      divider,
      jobTitle,
      publishedDate,
      readTime,
    } = this.props;
    const composedClassName = composeClassName(["Author", className]);

    let publishedDateContent;
    if (publishedDate) {
      publishedDateContent = (
        <DisplayText className="PublishedDate" size="S">
          {publishedDate.substring(0, 10)}
        </DisplayText>
      ); // TODO: handle actual date objects across codebase
    }
    let readTimeContent;
    if (readTime) {
      readTimeContent = (
        <DisplayText className="ReadTime" size="S">
          {readTime}
        </DisplayText>
      );
    }
    let jobTitleContent;
    if (jobTitle) {
      jobTitleContent = <DisplayText size="S">{jobTitle}</DisplayText>;
    }

    const content = (
      <div className="AuthorContent">
        <div className="AuthorLeft">
          {publishedDateContent}
          {jobTitleContent}
        </div>
        <div className="AuthorRight">{readTimeContent}</div>
      </div>
    );

    return (
      <Col className={composedClassName} xs={{ span: 12 }}>
        <Heading className="AuthorName" size="M">
          {authorName}
        </Heading>
        {content}
        {divider ? <Divider /> : null}
      </Col>
    );
  }
}

export default Author;
