/* eslint-disable space-before-function-paren */
/* eslint-disable object-shorthand */
import React from "react";
import { I18nextProvider } from "react-i18next";

import Components from "../components/components";
import { withAllStoryblokLinks } from "../components/utilities";
import { GatsbyPageErrorBoundary } from "../sharedgatsby";
import { i18n } from "../i18n";
import { StoryblokContext } from "../context";

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null;
    }

    return StoryblokEntry.prepareStory(props);
  }

  static prepareStory(props) {
    const { storyblokLinks } = props;
    const {
      overrideLocaleHack,
      allowedLocales,
      currentHref,
      localPartials,
      locale,
      partials,
      site,
      siteUrl,
    } = props.pageContext;
    const story = Object.assign({}, props.pageContext.story);
    story.content = JSON.parse(story.content);

    return {
      overrideLocaleHack,
      allowedLocales,
      currentHref,
      localPartials,
      locale,
      partials,
      site,
      siteUrl,
      story,
      storyblokLinks,
    };
  }

  constructor(props) {
    super(props);
    this.state = StoryblokEntry.prepareStory(props);
    const {
      pageContext: { locale },
    } = props;
    i18n.changeLanguage(locale);
  }

  render() {
    const {
      overrideLocaleHack,
      allowedLocales,
      currentHref,
      localPartials,
      locale,
      partials,
      site,
      siteUrl,
      story,
      storyblokLinks,
    } = this.state;

    const { content } = story;
    const pageTitle = story.name;

    const page = React.createElement(Components[content.component], {
      overrideLocaleHack,
      allowedLocales,
      currentHref,
      key: content._uid,
      localPartials,
      locale,
      content,
      pageTitle,
      partials,
      siteUrl,
    });

    return (
      <GatsbyPageErrorBoundary>
        <I18nextProvider i18n={this.i18n}>
          <StoryblokContext.Provider
            value={{
              site,
              locale,
              siteUrl,
              currentHref,
              storyblokLinks,
            }}>
            {page}
          </StoryblokContext.Provider>
        </I18nextProvider>
      </GatsbyPageErrorBoundary>
    );
  }
}

export default withAllStoryblokLinks(StoryblokEntry);
