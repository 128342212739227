import React, { PureComponent } from "react";
import { Image } from "../../../shared/atoms";
import { GatsbyBaseTile } from "../../../sharedgatsby";
import { withStoryblokLink } from "../../utilities";
import "./ImageTile.scss";

class ImageTile extends PureComponent {
  render() {
    const {
      Size,
      Link,
      LinkTitle,
      key,
      global,
      xsSize = 12,
      ...ImageProps
    } = this.props;
    const md = { span: Size };
    const xs = { span: xsSize };
    const imageInfo = {
      altText: ImageProps.AltText,
      longdesc: ImageProps.Longdesc,
      mobileSource: ImageProps.MobileImage,
      source: ImageProps.Image,
    };
    return (
      <GatsbyBaseTile
        xs={xs}
        md={md}
        Link={Link}
        LinkTitle={LinkTitle}
        className="ImageTile">
        <Image {...imageInfo} className="ImageTileImage" />
      </GatsbyBaseTile>
    );
  }
}

export default withStoryblokLink(ImageTile);
