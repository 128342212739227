import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./TestimonialHybrid.scss";
import { BodyText, Col, DisplayText, Image } from "../../atoms";
import { colors } from "../../colors";
import { composeClassName } from "../../utilities";

class TestimonialHybrid extends PureComponent {
  static propTypes = {
    altText: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    backgroundColor: PropTypes.oneOf([
      "brand-navy-color",
      "brand-yellow-color",
      "base-light-blue-color",
    ]),
    className: PropTypes.string,
    source: PropTypes.string,
    sourceDescription: PropTypes.string,
    whiteText: PropTypes.bool,
  };

  render() {
    const {
      altText,
      backgroundColor,
      className,
      image,
      source,
      sourceDescription,
      text,
      whiteText,
    } = this.props;

    const composedClassName = composeClassName([
      "TestimonialHybrid",
      className,
    ]);

    let background;
    if (backgroundColor) {
      background = { backgroundColor: colors[backgroundColor].color };
    }

    return (
      <div className={composedClassName} style={background}>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Image source={image} altText={altText} />
        </Col>
        <Col className="TestimonialContent" xs={{ span: 12 }} md={{ span: 6 }}>
          <BodyText className="TestimonialQuote" size="M" whiteText={whiteText}>
            {text}
          </BodyText>
          <DisplayText
            className="TestimonialSource"
            size="S"
            whiteText={whiteText}>
            {source}
            <br />
            {sourceDescription}
          </DisplayText>
        </Col>
      </div>
    );
  }
}

export default TestimonialHybrid;
