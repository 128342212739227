// These fields are meant for saleforce stuff, where it must always be in this format.
import React, { PureComponent } from "react";
import { InputText } from "../../../shared/forms";

class InputFirstName extends PureComponent {
  render() {
    const { Label, Placeholder } = this.props;
    const fnameProps = {
      ...this.props,
      ...{
        name: "first_name",
        required: true,
        label: Label,
        placeholder: Placeholder,
        title: "First Name",
      },
    };
    return <InputText {...fnameProps} />;
  }
}

export default InputFirstName;
