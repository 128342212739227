import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "../../utilities";
import "./VideoPlayer.scss";

class VideoPlayer extends PureComponent {
  static propTypes = {
    allowFullScreen: PropTypes.bool,
    autoplay: PropTypes.bool,
    className: PropTypes.string,
    /** Video Player source */
    source: PropTypes.oneOf(["vimeo", "youtube"]),
    /** Video Title, required for Accessibility */
    title: PropTypes.string.isRequired,
    /** Video Player URL Id, for each player source its different,
     * can be found at sharing options of the video */
    videoId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    allowFullScreen: true,
    autoplay: false,
    className: "",
    frameBorder: "0",
    source: "youtube",
  };

  render() {
    const {
      allowFullScreen,
      autoplay,
      className,
      source,
      title,
      videoId,
    } = this.props;

    const composedClassName = composeClassName(["VideoPlayer", className]);
    const SOURCE_URL = {
      youtube: "https://www.youtube.com/embed/",
      vimeo: "https://player.vimeo.com/video/",
    };
    const baseUrl = SOURCE_URL[source];
    const queryString = `autoplay=${autoplay ? "1" : "0"}`;

    return (
      <div className="VideoPlayerContainer">
        <iframe
          data-video-id={videoId}
          className={composedClassName}
          title={title}
          src={`${baseUrl}${videoId}?${queryString}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={allowFullScreen}
          tabIndex="-1"
        />
      </div>
    );
  }
}

export default VideoPlayer;
