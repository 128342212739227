import React from "react";

const IconPaths = {
  add: (
    <path
      id="a-add"
      d="M6.495 14.488l-.002-4.972-4.981-.002A1.51 1.51 0 1 1 1.51 6.49h4.982l-.002-4.98a1.51 1.51 0 1 1 3.022 0l.003 4.982 4.972.002a1.51 1.51 0 1 1 .001 3.022H9.516l.001 4.972A1.509 1.509 0 0 1 8.007 16a1.513 1.513 0 0 1-1.512-1.512z"
    />
  ),
  avatar: (
    <path
      id="a-avatar"
      d="M14 12c0-1.08-1.151-2.018-2.862-2.546A3.98 3.98 0 0 1 8 11a3.98 3.98 0 0 1-3.138-1.546C3.151 9.982 2 10.92 2 12v2h12v-2zm-4-8a2 2 0 1 0-4 0v3a2 2 0 1 0 4 0V4zm5 12H1a1 1 0 0 1-1-1v-3c0-1.864 1.648-3.472 4.068-4.331A3.88 3.88 0 0 1 4 7V4a4 4 0 0 1 8 0v3c0 .229-.03.451-.068.669C14.352 8.528 16 10.136 16 12v3a1 1 0 0 1-1 1z"
    />
  ),
  calendar: (
    <path
      id="a-calendar"
      d="M4 9h2V7H4v2zm0 3h2v-2H4v2zm3-3h2V7H7v2zm0 3h2v-2H7v2zm3-3h2V7h-2v2zm0 3h2v-2h-2v2zm4-6a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6zm0 10H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2v1a1 1 0 0 0 2 0V0h4v1a1 1 0 0 0 2 0V0h2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2z"
    />
  ),
  caretLeft: <path id="a-caretLeft" d="M13 0L3 8l10 8z" />,
  caretRight: <path id="a-caretRight" d="M3 0l10 8-10 8z" />,
  checkmark: (
    <path
      id="a-checkmark"
      d="M12.724 5.692L7 11.71a.91.91 0 0 1-1.333 0L3.276 9.197a1.03 1.03 0 0 1 0-1.403.911.911 0 0 1 1.333 0l1.724 1.813 5.057-5.316a.91.91 0 0 1 1.334 0 1.026 1.026 0 0 1 0 1.401"
    />
  ),
  checkmarkSolid: (
    <path
      id="a-checkmarkSolid"
      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm-.56 11.01l4.07-4.07a.999.999 0 1 0-1.414-1.415L6.732 8.89l-.829-.829A1 1 0 0 0 4.49 9.475l1.536 1.535a.999.999 0 0 0 1.414 0z"
    />
  ),
  chevronDown: (
    <path
      id="a-chevronDown"
      d="M4.1249928,5 L8.0049928,8.88 L11.8849928,5 C12.2749928,4.61 12.9049928,4.61 13.2949928,5 C13.6849928,5.39 13.6849928,6.02 13.2949928,6.41 L8.7049928,11 C8.3149928,11.39 7.6849928,11.39 7.2949928,11 L2.7049928,6.41 C2.51774013,6.22316847 2.4125072,5.96951764 2.4125072,5.705 C2.4125072,5.44048236 2.51774013,5.18683153 2.7049928,5 C3.0949928,4.62 3.7349928,4.61 4.1249928,5 L4.1249928,5 Z"
    />
  ),
  chevronLeft: (
    <path
      id="a-chevronLeft"
      d="M6.384 7.456l4.875 4.876a.801.801 0 0 1-.013 1.123l-.222.222a.803.803 0 0 1-1.123.012L4.224 8.012a.776.776 0 0 1-.222-.608.773.773 0 0 1 .222-.503L9.9 1.224a.801.801 0 0 1 1.123.012l.222.222c.314.314.31.826.013 1.123L6.384 7.456z"
    />
  ),
  chevronRight: (
    <path
      id="a-chevronRight"
      d="M9.357 7.315L4.482 2.44a.801.801 0 0 1 .013-1.124l.221-.221a.803.803 0 0 1 1.124-.013l5.677 5.677a.776.776 0 0 1 .222.608.773.773 0 0 1-.222.503L5.84 13.548a.801.801 0 0 1-1.124-.013l-.221-.222a.803.803 0 0 1-.013-1.123l4.875-4.875z"
    />
  ),
  chevronUp: (
    <path
      id="a-chevronUp"
      d="M7.295,5 L2.705,9.59 C2.315,9.98 2.315,10.61 2.705,11 C3.095,11.39 3.725,11.39 4.115,11 L8.005,7.12 L11.885,11 C12.275,11.39 12.905,11.39 13.295,11 C13.685,10.61 13.685,9.98 13.295,9.59 L8.705,5 C8.325,4.61 7.685,4.61 7.295,5 Z"
    />
  ),
  close: (
    <path
      id="a-close"
      d="M15.6450512,0.36706634 C15.4183311,0.139835185 15.1105258,0.0121350019 14.7895336,0.0121350019 C14.4685414,0.0121350019 14.160736,0.139835185 13.9340159,0.36706634 L8,6.28894727 L2.06598407,0.354931338 C1.83926398,0.127700183 1.53145865,-5.59552404e-14 1.21046644,-5.59552404e-14 C0.88947423,-5.59552404e-14 0.581668898,0.127700183 0.354948805,0.354931338 C-0.118316268,0.828196412 -0.118316268,1.59270153 0.354948805,2.06596661 L6.28896473,7.99998253 L0.354948805,13.9339985 C-0.118316268,14.4072635 -0.118316268,15.1717687 0.354948805,15.6450337 C0.828213879,16.1182988 1.592719,16.1182988 2.06598407,15.6450337 L8,9.7110178 L13.9340159,15.6450337 C14.407281,16.1182988 15.1717861,16.1182988 15.6450512,15.6450337 C16.1183163,15.1717687 16.1183163,14.4072635 15.6450512,13.9339985 L9.71103527,7.99998253 L15.6450512,2.06596661 C16.1061813,1.60483653 16.1061813,0.828196412 15.6450512,0.36706634 Z"
    />
  ),
  closeSolid: (
    <path
      id="a-closeSolid"
      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm9.315 2.718a.993.993 0 0 0 1.403-1.403L9.41 8.007l1.308-1.309a.993.993 0 0 0-1.403-1.403L8.007 6.603 6.694 5.291a.991.991 0 1 0-1.403 1.403l1.312 1.312-1.312 1.313a.991.991 0 1 0 1.403 1.403L8.007 9.41l1.308 1.308z"
    />
  ),
  dollar: (
    <path
      id="a-dollar"
      d="M7.154 16v-1.67c-1.73-.159-3.22-.934-4.154-2.226l2.007-1.828c.676.894 1.75 1.55 3.002 1.55.934 0 1.828-.278 1.828-1.073 0-.755-.715-.954-1.331-1.133l-2.207-.577c-1.709-.457-2.802-1.53-2.802-3.398 0-2.028 1.45-3.538 3.657-3.916V0H9.46v1.73c1.45.198 2.663.774 3.319 1.848L10.79 5.386c-.596-.815-1.63-1.192-2.564-1.192-.874 0-1.57.358-1.57 1.093 0 .616.577.815 1.252.994l2.087.576c1.869.497 3.12 1.51 3.12 3.459 0 2.226-1.629 3.537-3.656 3.915V16H7.154z"
    />
  ),
  download: (
    <path
      id="a-download"
      d="M7.61 11.424L4.3 8.114a.552.552 0 1 1 .78-.78l2.368 2.369V.552a.552.552 0 0 1 1.104 0v9.15l2.368-2.368a.552.552 0 1 1 .78.78l-3.31 3.31a.565.565 0 0 1-.084.07l-.043.022c-.017.01-.034.02-.052.028-.018.007-.037.012-.056.017-.016.005-.03.011-.047.014a.551.551 0 0 1-.216 0c-.016-.003-.031-.009-.047-.014-.019-.005-.038-.01-.056-.017-.018-.008-.035-.018-.052-.028-.015-.007-.03-.014-.043-.023a.565.565 0 0 1-.084-.069zm7.838-1.045c.305 0 .552.247.552.552v3.862H0v-3.862a.552.552 0 0 1 1.103 0v2.759h13.794V10.93c0-.305.247-.552.551-.552z"
    />
  ),
  email: (
    <path
      id="a-email"
      d="M8.012 9.02L9 10l-1 1-1-1 .01-.01-.986-.977-1.499 1.483-1.027-.989 1.515-1.495-1.495-1.48.99-1.028 3.506 3.513-.002.002zm2.99-1l1.465 1.484-1.026.985L10 9.033l-.96.97-1.029-.99 3.514-3.506.989.988-1.511 1.525zM1.995 2h12.012C15.106 2 16 2.9 16 4.009v7.982A2.003 2.003 0 0 1 14.006 14H1.994C.894 14 0 13.1 0 11.991V4.01C0 2.902.893 2 1.994 2zm1.008 2C2.45 4 2 4.453 2 4.997v6.006c0 .55.456.997 1.002.997h9.996c.553 0 1.002-.453 1.002-.997V4.997c0-.55-.456-.997-1.002-.997H3.002z"
    />
  ),
  global: (
    <path
      id="a-global"
      d="M3.012 7.885C3.503 7.83 4.52 7.755 5 8c.831.423 1.734 1.448 1.734 1.448L4.74 11.763A4.956 4.956 0 0 0 8 13a5 5 0 0 0 5-5c0-.473-.086-.922-.209-1.355-.407.475-1.232 1.084-1.232 1.084-.7-.078-.36.788-.36.788.295 1.916-.721 2.152-.721 2.152C9.093 12.092 8 9 8 9c-.256-1.721-.404-2.272-.404-2.272-1.773-1.108-.026-1.512.35-1.576h.011c.09-.013.068-.014-.011 0-.502.069-.406-.236-.71-.788-.307-.557-.682.094-.721 0-.038-.093-.215 1.02-.72 1.182-.505.162-.36-1.182-.36-1.182-.975 1.493-1.294 1.112-1.396.621a4.97 4.97 0 0 0-1.027 2.9M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14"
    />
  ),
  hamburger: (
    <path
      id="a-hamburger"
      d="M0.888888889,13.1666667 L15.1111111,13.1666667 C15.6,13.1666667 16,12.7666667 16,12.2777778 C16,11.7888889 15.6,11.3888889 15.1111111,11.3888889 L0.888888889,11.3888889 C0.4,11.3888889 0,11.7888889 0,12.2777778 C0,12.7666667 0.4,13.1666667 0.888888889,13.1666667 Z M0.888888889,8.72222222 L15.1111111,8.72222222 C15.6,8.72222222 16,8.32222222 16,7.83333333 C16,7.34444444 15.6,6.94444444 15.1111111,6.94444444 L0.888888889,6.94444444 C0.4,6.94444444 0,7.34444444 0,7.83333333 C0,8.32222222 0.4,8.72222222 0.888888889,8.72222222 Z M0,3.38888889 C0,3.87777778 0.4,4.27777778 0.888888889,4.27777778 L15.1111111,4.27777778 C15.6,4.27777778 16,3.87777778 16,3.38888889 C16,2.9 15.6,2.5 15.1111111,2.5 L0.888888889,2.5 C0.4,2.5 0,2.9 0,3.38888889 Z"
    />
  ),
  infoSolid: (
    <path
      id="a-infoSolid"
      d="M9.619 12.006H6.38c-.213 0-.385-.201-.385-.448s.172-.448.385-.448L7 11V8l-.62-.116c-.213 0-.385-.201-.385-.448s.172-.448.385-.448h2.514c.077 0 .102-.032.113-.058C9.004 6.945 9 6.959 9 7v4l.619.11c.213 0 .386.201.386.448s-.173.448-.386.448zM7 4h.98c.007 0 .013-.004.02-.004.007 0 .013.004.02.004H9v.94c.001.022.012.04.012.061 0 .022-.011.04-.012.061V6h-.967c-.012.001-.022.007-.033.007-.012 0-.022-.006-.033-.007H7v-.939c-.002-.021-.013-.038-.013-.06 0-.021.011-.039.013-.06V4zm1-4a8 8 0 0 0-8 8 8 8 0 1 0 16 0 8 8 0 0 0-8-8z"
    />
  ),
  locationPin: (
    <path
      id="a-locationPin"
      d="M7.346 15.243S2 9.313 2 6a6 6 0 1 1 12 0c0 3.314-5.346 9.243-5.346 9.243-.365.427-.947.418-1.308 0zM8 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
    />
  ),

  search: (
    <path
      id="a-search"
      d="M9.222 3.033a4.376 4.376 0 1 0-6.19 6.19 4.376 4.376 0 1 0 6.19-6.19m4.552 10.741a.877.877 0 0 1-1.239 0L9.78 11.017c-2.4 1.794-5.804 1.624-7.984-.557a6.126 6.126 0 0 1 0-8.665 6.126 6.126 0 0 1 8.665 0c2.181 2.18 2.351 5.584.557 7.984l2.757 2.757a.876.876 0 0 1 0 1.238"
    />
  ),

  socialFacebook: (
    <path
      id="a-socialFacebook"
      d="M9 16V8h3V6H9V4c.193-.78.413-1.343 2-1h1V0c-.25.074-1.112 0-2 0-2.205 0-3.633 1.329-4 4v2H4v2h2v8h3z"
    />
  ),
  socialInstagram: (
    <path
      id="a-socialInstagram"
      d="M15.58 2.757c.197.507.333 1.088.37 1.942.044.853.05 1.125.05 3.301s-.012 2.448-.05 3.301c-.037.847-.173 1.435-.37 1.942a3.91 3.91 0 0 1-.922 1.415 3.91 3.91 0 0 1-1.415.922c-.507.197-1.088.333-1.942.37-.853.044-1.125.05-3.301.05s-2.448-.012-3.301-.05c-.847-.037-1.435-.173-1.942-.37a3.91 3.91 0 0 1-1.415-.922 3.91 3.91 0 0 1-.922-1.415C.223 12.736.087 12.155.05 11.3.005 10.448 0 10.176 0 8s.012-2.448.05-3.301c.037-.847.173-1.435.37-1.942a3.91 3.91 0 0 1 .922-1.415A3.91 3.91 0 0 1 2.757.42C3.264.223 3.845.087 4.7.05 5.552.005 5.824 0 8 0s2.448.012 3.301.05c.847.037 1.435.173 1.942.37.525.204.97.476 1.415.922.446.445.718.89.922 1.415zm-1.625 8.198C13.989 10.186 14 9.949 14 8c0-1.95-.011-2.18-.045-2.955-.034-.712-.153-1.096-.254-1.356a2.289 2.289 0 0 0-.548-.842 2.264 2.264 0 0 0-.842-.548c-.255-.101-.644-.22-1.356-.254C10.186 2.005 9.949 2 8 2c-1.95 0-2.18.011-2.955.045-.712.034-1.096.153-1.356.254a2.289 2.289 0 0 0-.842.548 2.264 2.264 0 0 0-.548.842c-.101.255-.22.644-.254 1.356C2.005 5.814 2 6.051 2 8c0 1.95.011 2.18.045 2.955.034.712.153 1.096.254 1.356.136.344.294.587.548.842.255.254.503.418.842.548.255.101.644.22 1.356.254.769.04 1 .045 2.955.045s2.18-.011 2.955-.045c.712-.034 1.096-.153 1.356-.254.344-.136.587-.294.842-.548.254-.255.418-.503.548-.842.101-.255.22-.644.254-1.356zM12 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM8 4c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 6c1.102 0 2-.898 2-2a2 2 0 1 0-2 2z"
    />
  ),
  socialLinkedin: (
    <path
      id="a-socialLinkedin"
      d="M1.227 5.649H4.13V15H1.227V5.649zM2.684 1a1.687 1.687 0 0 1 0 3.373A1.684 1.684 0 0 1 1 2.686C1 1.756 1.75 1 2.684 1zm3.27 4.649h2.78v1.28h.038c.388-.734 1.333-1.507 2.747-1.507C14.455 5.422 15 7.357 15 9.876V15h-2.898v-4.546c0-1.086-.016-2.481-1.506-2.481-1.511 0-1.738 1.184-1.738 2.4V15H5.96V5.649h-.005z"
    />
  ),
  socialPinterest: (
    <path
      id="a-socialPinterest"
      d="M8.234 0C3.51 0 1 2.81 1 5.874c0 1.42.855 3.193 2.224 3.755.207.087.32.05.368-.13.04-.135.22-.79.307-1.099.027-.099.013-.185-.074-.278-.454-.488-.815-1.377-.815-2.211 0-2.137 1.83-4.212 4.943-4.212 2.692 0 4.576 1.624 4.576 3.946 0 2.625-1.497 4.441-3.44 4.441-1.076 0-1.877-.784-1.623-1.754.307-1.155.908-2.396.908-3.23 0-.747-.454-1.365-1.383-1.365-1.095 0-1.983 1.007-1.983 2.36 0 .858.327 1.438.327 1.438L4.052 12.36c-.34 1.285.047 3.367.08 3.546.02.099.14.13.208.05.107-.13 1.416-1.866 1.783-3.12l.681-2.31c.361.605 1.403 1.112 2.512 1.112 3.3 0 5.684-2.693 5.684-6.035C14.987 2.397 12.034 0 8.234 0"
    />
  ),
  socialTwitter: (
    <path
      id="a-socialTwitter"
      d="M16 3.42a6.945 6.945 0 0 1-1.885.477c.677-.375 1.198-.968 1.443-1.675-.634.346-1.336.6-2.084.734A3.415 3.415 0 0 0 11.077 2C9.264 2 7.795 3.356 7.795 5.03c0 .236.028.468.084.69-2.728-.127-5.146-1.333-6.766-3.166A2.844 2.844 0 0 0 .67 4.077c0 1.052.58 1.979 1.46 2.522A3.483 3.483 0 0 1 .643 6.22v.038c0 1.468 1.13 2.693 2.632 2.971a3.564 3.564 0 0 1-1.482.052c.418 1.203 1.63 2.079 3.066 2.104a6.941 6.941 0 0 1-4.076 1.297c-.265 0-.526-.014-.783-.042A9.871 9.871 0 0 0 5.032 14c6.037 0 9.34-4.616 9.34-8.62 0-.13-.004-.261-.01-.392A6.4 6.4 0 0 0 16 3.42z"
    />
  ),
  socialVimeo: (
    <path
      id="a-socialVimeo"
      d="M15.992 4.234c-.07 1.577-1.157 3.735-3.263 6.473C10.554 13.569 8.711 15 7.206 15c-.932 0-1.725-.873-2.368-2.613-.429-1.6-.863-3.193-1.291-4.792-.477-1.74-.991-2.613-1.543-2.613-.118 0-.541.255-1.254.764L0 4.765c.788-.7 1.57-1.404 2.336-2.109 1.055-.921 1.843-1.404 2.373-1.453 1.243-.119 2.01.743 2.299 2.586.31 1.99.525 3.226.648 3.708.359 1.648.755 2.478 1.184 2.478.332 0 .836-.537 1.51-1.605.67-1.068 1.03-1.887 1.077-2.445.097-.921-.262-1.388-1.076-1.388-.386 0-.777.087-1.184.266.787-2.608 2.287-3.87 4.505-3.8 1.64.038 2.416 1.117 2.32 3.23z"
    />
  ),
  socialYoutube: (
    <path
      id="a-socialYoutube"
      d="M15.7202794,11.3581866 C15.5241651,12.2089803 14.8281905,12.8371962 13.9904,12.9307581 C12.0072127,13.1518628 9.99928889,13.1533358 7.9991873,13.1518628 C5.99989841,13.1533358 3.9919746,13.1518628 2.00817778,12.9307581 C1.17018413,12.8372469 0.474260317,12.2089803 0.279161905,11.3581866 C0,10.1469104 0,8.82358345 0,7.5759898 C0,6.32839615 0.0033015873,5.00567869 0.282209524,3.7944025 C0.477307937,2.94279615 1.17348571,2.31539298 2.0104127,2.22188186 C3.9944127,2.00007723 6.0032,1.99930409 8.00248889,2.00007723 C10.0015746,1.99935488 12.0097016,2.00007723 13.9934984,2.22188186 C14.8314921,2.31539298 15.5274159,2.94279615 15.7233778,3.7944025 C16.0024889,5.00567869 16,6.32839615 16,7.5759898 C16,8.82358345 15.9991873,10.1469104 15.7202794,11.3581866 Z M5.93549206,10.0597993 C7.63540317,9.17827552 9.32027937,8.30411678 11.0218667,7.42198345 C9.31535238,6.53157075 7.63067937,5.65329774 5.93549206,4.76867552 L5.93549206,10.0597993 Z"
    />
  ),
  subtract: <rect id="a-subtract" width="12" height="2" x="2" y="7" rx="1" />,
};

export default IconPaths;
