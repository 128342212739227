import React from "react";
import { Link } from "gatsby";
import { Map as SharedMap } from "../../../shared/organisms";
import usa from "./usa";
import Block from "../../blocks/Block";

const buildStory = ({
  Title,
  Location,
  Banner,
  AltText,
  Author,
  Content,
  State,
}) => {
  return {
    title: Title,
    location: Location,
    banner: Banner,
    altText: AltText,
    author: Author,
    content: Content,
    state: State,
  };
};

const buildStories = (Stories = []) => {
  const stories = {};
  Stories.forEach((story) => {
    stories[story.State] = buildStory(story);
  });
  return stories;
};

const Map = ({ content }) => {
  const stories = buildStories(content.Stories);
  return (
    <Block content={content}>
      <SharedMap stories={stories} mapData={usa} internalLinkComponent={Link} />
    </Block>
  );
};

export default Map;
