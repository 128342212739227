import React, { PureComponent } from "react";
import { BaseTile, Stories } from "../../../shared/molecules";
import { withStoryblokLink } from "../../utilities";
import "./VideoStoriesTile.scss";

class VideoStoriesTile extends PureComponent {
  render() {
    const { key, global, ...content } = this.props;

    const {
      Image,
      AltText,
      Longdesc,
      VideoSource,
      VideoId,
      VideoTitle,
      VideoButtonTheme,
      Tag,
      ArticleTitle,
    } = content;

    return (
      <BaseTile md={{ span: 3 }} className="VideoStoriesTile">
        <Stories
          image={Image}
          altText={AltText}
          longdesc={Longdesc}
          video={{
            source: VideoSource,
            videoId: VideoId,
            title: VideoTitle,
            theme: VideoButtonTheme,
          }}
          tag={Tag}
          articleTitle={ArticleTitle}
        />
      </BaseTile>
    );
  }
}

export default withStoryblokLink(VideoStoriesTile);
