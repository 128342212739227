import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { composeClassName } from "../../utilities";

import { DisplayText } from "../../atoms";

import "./ReadMoreLayout.scss";
import "./IESupport.scss";

class ReadMoreLayout extends PureComponent {
  static propTypes = {
    readLess: PropTypes.string,
    readMore: PropTypes.string,
    label: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  onClickHandler = () => {
    this.setState((prevState) => {
      return { collapsed: !prevState.collapsed };
    });
  };

  render() {
    const { collapsed } = this.state;
    const { children, label, t } = this.props;
    let { readLess, readMore } = this.props;
    readLess = readLess || t("ReadMoreLayout__readLess");
    readMore = readMore || t("ReadMoreLayout__readMore");

    const composedClassName = composeClassName([
      "ReadMoreLayout",
      collapsed ? "Collapsed" : "",
    ]);

    return (
      <React.Fragment>
        <div
          aria-hidden={collapsed}
          aria-labelledby={`${label}-content`}
          className={composedClassName}>
          {children}
        </div>
        <button
          type="button"
          aria-controls={`${label}-content`}
          aria-expanded={!collapsed}
          className="ExpandButton"
          onClick={this.onClickHandler}>
          <DisplayText size="S">
            {collapsed ? readMore.toUpperCase() : readLess.toUpperCase()}
          </DisplayText>
        </button>
      </React.Fragment>
    );
  }
}

export default withTranslation("translations")(ReadMoreLayout);
