// These fields are meant for saleforce stuff, where it must always be in this format.
import React, { PureComponent } from "react";
import { InputEmail } from "../../../shared/forms";

class Email extends PureComponent {
  render() {
    const { Label, Placeholder } = this.props;
    const emailProps = {
      ...this.props,
      ...{
        name: "email",
        label: Label,
        placeholder: Placeholder,
        title: "Email",
      },
    };
    return <InputEmail {...emailProps} />;
  }
}

export default Email;
