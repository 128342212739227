import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import {
  DisplayText,
  BodyText,
  Label as SharedLabel,
  Image,
} from "../../atoms";
import { composeClassName } from "../../utilities";
import { InputDropdown } from "../../forms/fields";
import ButtonIcon from "../ButtonIcon";
import ButtonLink from "../ButtonLink";

import "./DownloadableResources.scss";

class DownloadableResources extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
    description: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { options } = props;
    this.state = {
      downloadContent: options.length > 0 ? options[0].value : "",
      downloadContentLabel: options.length > 0 ? options[0].label : "",
    };
  }

  handleOnChange = ({ value }) => {
    const { options } = this.props;
    const selectedOption = _.find(options, (option) => {
      return option.value === value;
    });
    this.setState({
      downloadContent: value,
      downloadContentLabel: selectedOption.label,
    });
  };

  handleOnClick = () => {
    if (typeof window.dataLayer !== "undefined") {
      const { downloadContentLabel, downloadContent } = this.state;
      window.dataLayer.push({ documentlabel: downloadContentLabel });
      window.dataLayer.push({ documentlink: downloadContent });
    }
  };

  render() {
    const {
      title,
      label,
      options,
      source,
      altText,
      description,
      t,
    } = this.props;
    const { downloadContent, downloadContentLabel } = this.state;
    // Create valid id string out of title
    const id = title.replace(/\W/g, "_");

    const image = <Image source={source} altText={altText} />;
    const body = (
      <div className="DownloadableResourcesBody">
        <DisplayText size="L" className="Title">
          {title}
        </DisplayText>
        <BodyText size="M">{description}</BodyText>
      </div>
    );
    const dropdown = (
      <SharedLabel htmlFor={id} label={label} className="DownloadLabel">
        <div>
          <InputDropdown
            id={id}
            options={options}
            disabled={options.length <= 1}
            name="downloadable-content"
            onChangeHandler={this.handleOnChange}
            value={downloadContent}
            className="DownloadDropdown"
          />
          <ButtonIcon
            icon="download"
            theme="Primary"
            size="M"
            className="DownloadButtonMobile"
            external
            newTab
            href={downloadContent}
          />
          <ButtonLink
            theme="Primary"
            size="M"
            className="DownloadButton"
            external
            newTab
            href={downloadContent}
            data-documentlink={downloadContent}
            data-documentlabel={downloadContentLabel}
            onClickHandler={this.handleOnClick}>
            {t("DownloadableResources__downloadButtonLabel")}
          </ButtonLink>
        </div>
      </SharedLabel>
    );

    return (
      <React.Fragment>
        <div
          className={composeClassName([
            "DownloadableResources",
            "DownloadMobile",
          ])}>
          {image}
          {body}
          {dropdown}
        </div>
        <div
          className={composeClassName([
            "DownloadableResources",
            "DownloadDesktop",
          ])}>
          {image}
          <div className="DownloadDesktopContent">
            {body}
            {dropdown}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation("translations")(DownloadableResources);
