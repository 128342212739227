import React, { PureComponent } from "react";
import { Link } from "gatsby";
import { MarkdownParser } from "../../shared/molecules";

class GatsbyMarkdownParser extends PureComponent {
  render() {
    const { children, ...props } = this.props;
    return (
      <MarkdownParser internalLinkComponent={Link} {...props}>
        {children}
      </MarkdownParser>
    );
  }
}

export default GatsbyMarkdownParser;
