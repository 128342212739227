/* eslint-disable import/prefer-default-export */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./datasources/translations";

const NAMESPACES = ["translations"];

i18n.use(initReactI18next).init({
  lng: "en-CA",
  fallbackLng: "en-CA",
  ns: NAMESPACES,
  defaultNS: ["translations"],
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
    useSuspense: false,
  },
  resources: resources,
});

export { i18n };
