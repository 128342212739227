import React, { PureComponent } from "react";
import Block from "../Block";
import CarouselMolecule from "../../../shared/molecules/Carousel";
import contentToBlocks from "../../utilities/contentToBlocks";

class Carousel extends PureComponent {
  render() {
    const { content } = this.props;
    const { Slides } = content;

    return (
      <Block content={content} className="CarouselBlock">
        <CarouselMolecule>
          {contentToBlocks(Slides, {
            BlockPaddingTop: "None",
            BlockPaddingBottom: "None",
          })}
        </CarouselMolecule>
      </Block>
    );
  }
}

export default Carousel;
