import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AccordionItem from "../AccordionItem";
import { IconPillar, Heading } from "../../atoms";
import "./PillarItem.scss";

class Pillars extends PureComponent {
  static propTypes = {
    icon: PropTypes.oneOf([
      "pillarStar",
      "pillarWater",
      "pillarLeaf",
      "pillarHeart",
      "pillarKey",
    ]),
    title: PropTypes.string.isRequired,
    defaultOpen: PropTypes.bool,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  };

  static defaultProps = {
    defaultOpen: false,
  };

  render() {
    const { icon, title, children, ...otherProps } = this.props;

    const header = (
      <React.Fragment>
        {icon ? <IconPillar icon={icon} size="M" /> : null}
        <Heading size="S">{title}</Heading>
      </React.Fragment>
    );

    return (
      <AccordionItem className="PillarItem" header={header} {...otherProps}>
        {children}
      </AccordionItem>
    );
  }
}

export default Pillars;
