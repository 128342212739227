import React from "react";

const Pillars = {
  pillarStar: {
    path: (
      <path
        d="M47.9488328,18.1746473 C47.8121202,17.7386957 47.4280531,17.4413954 46.989301,17.4327297 L30.4121007,17.1247639 L24.927699,0.721919787 C24.7827201,0.289301189 24.393566,0 23.9567215,0 C23.9554498,0 23.954178,0 23.954178,0 C23.5154259,0.0013331852 23.1269077,0.292634152 22.9844722,0.726585935 L17.5814622,17.1594267 L1.00553371,17.5587157 C0.56805332,17.5693812 0.183350361,17.8693479 0.0498171025,18.3066326 C-0.0849879014,18.7432507 0.059991065,19.2218642 0.409721028,19.4978336 L13.6492257,29.9626708 L8.88844707,46.6121542 C8.76318016,47.0514387 8.91642547,47.5267193 9.27187829,47.7953561 C9.45119438,47.931341 9.66293997,48 9.8740497,48 C10.0800724,48 10.2873669,47.9346739 10.4641395,47.8046884 L24.0482872,37.8404622 L37.6820329,47.7306966 C38.0413009,47.9893345 38.5188413,47.9860016 38.8742941,47.7146984 C39.2284752,47.4433952 39.3785411,46.9681147 39.2520025,46.5288301 L34.4091963,29.9060104 L47.5946518,19.3685146 C47.9437458,19.0898789 48.0849096,18.6112654 47.9488328,18.1746473"
        id="Fill-4"
        fill="#FFE343"
      />
    ),
    transform1: "translate(-23.000000, -16.000000)",
    transform2: "translate(23.000000, 16.000000)",
    viewBox: "0 0 48 48",
  },
  pillarKey: {
    path: (
      <path
        d="M24,0 C16.8201597,0 11,5.86127404 11,13.0903746 C11,19.149572 15.0939621,24.2328702 20.6449102,25.7241539 L20.6449102,31.0465278 L14.1851297,31.0465278 L14.1851297,35.5314835 L20.6449102,35.5314835 L20.6449102,38.1378006 L14.1851297,38.1378006 L14.1851297,42.6221031 L20.6449102,42.6221031 L20.6449102,47.0881156 L20.6546407,47.0881156 L20.6546407,48 L27.3544411,48 L27.3544411,25.7241539 C32.9053892,24.2328702 37,19.149572 37,13.0903746 C37,5.86127404 31.1791916,0 24,0"
        id="Fill-3"
        fill="#804E8E"
      />
    ),
    transform1: "translate(-34.000000, -368.000000)",
    transform2: "translate(23.000000, 368.000000)",
    viewBox: "0 0 26 48",
  },
  pillarWater: {
    path: (
      <path
        d="M24.3283519,0 C24.3283519,0 9.82743634,21.6715339 9.05568103,30.9824317 C8.28317717,40.2940713 15.6549001,48 24.5693851,48 C33.48387,48 40,41.7766653 40,31.9621749 C40,22.6905855 24.3283519,9.04093079 24.3283519,0"
        id="Fill-6"
        fill="#00A3DA"
      />
    ),
    transform1: "translate(-32.000000, -104.000000)",
    transform2: "translate(23.000000, 104.000000)",
    viewBox: "0 0 31 48",
  },
  pillarHeart: {
    path: (
      <path
        d="M35.4055953,4.08510638 C32.4403807,4.08510638 29.4751661,5.23256202 27.2174561,7.51896312 L24.5079239,10.2620771 L21.7983916,7.51896312 C19.5399811,5.23256202 16.5747665,4.08510638 13.6179579,4.08510638 C10.6527434,4.08510638 7.69593477,5.23256202 5.42911824,7.51896312 C0.913698174,12.0995663 0.913698174,19.5183138 5.42911824,24.0904068 L24.5079239,43.4042553 L43.5853285,24.0904068 C48.109855,19.5183138 48.109855,12.0995663 43.5853285,7.51896312 C41.3276184,5.23256202 38.3708099,4.08510638 35.4055953,4.08510638"
        id="Fill-1"
        fill="#DA2A55"
      />
    ),
    transform1: "translate(-25.000000, -196.000000)",
    transform2: "translate(23.000000, 192.000000)",
    viewBox: "0 0 45 40",
  },
  pillarLeaf: {
    path: (
      <path
        d="M37,27.0300274 C37,19.1887103 23.8559918,7.64625637 23.8559918,0 C23.8559918,0 11.6938449,18.3269306 11.0465949,26.2014896 C10.4646349,33.2826343 15.4059584,39.2630341 21.8445574,40.3963936 L21.8445574,48 L26.1543256,48 L26.1543256,40.4327715 C32.5301458,39.5339867 37,34.5288906 37,27.0300274"
        id="Fill-7"
        fill="#5FB246"
      />
    ),
    transform1: "translate(-34.000000, -280.000000)",
    transform2: "translate(23.000000, 280.000000)",
    viewBox: "0 0 26 48",
  },
};

export default Pillars;
