import React from "react";
import PropTypes from "prop-types";

import { BaseLink, Icon } from "../../atoms";
import { Accordion, AccordionItem } from "../../molecules";

import "./MapMobile.scss";

const Card = ({ name, fill, onClick }) => {
  return (
    <div key={name} className="Card" style={{ backgroundColor: fill }}>
      <BaseLink className="StateName" blank onClickHandler={onClick}>
        {name}
        <Icon className="CardIcon" size="S" icon="chevronRight" />
      </BaseLink>
    </div>
  );
};

const toAccordionItem = ([regionName, items], index) => {
  const defaultOpen = index === 0;

  return (
    <AccordionItem
      key={regionName}
      id={index}
      title={regionName}
      defaultOpen={defaultOpen}>
      <div className="CardContainer">{items}</div>
    </AccordionItem>
  );
};

const MapMobile = ({ mapData, stories, onSelectState }) => {
  const regions = {
    Northwest: [],
    Northeast: [],
    "Mid-West": [],
    Southeast: [],
    Southwest: [],
  };
  Object.entries(stories).forEach(([name, story]) => {
    if (!mapData[name]) return;
    const { color: { fill }, region } = mapData[name];

    const card = (
      <Card
        key={name}
        name={name}
        fill={fill}
        story={story}
        onClick={() => {
          onSelectState(name);
        }}
      />
    );

    regions[region].push(card);
  });

  const accordionItems = Object.entries(regions).map(toAccordionItem);

  return (
    <Accordion className="MapMobile" name="MapRegions">
      {accordionItems}
    </Accordion>
  );
};

const stateShape = {
  fill: PropTypes.shape({
    fill: PropTypes.string,
    border: PropTypes.string,
  }),
};

const storyShape = {
  location: PropTypes.string,
  title: PropTypes.string,
  banner: PropTypes.string,
  author: PropTypes.string,
  altText: PropTypes.string,
  content: PropTypes.string,
};

MapMobile.propTypes = {
  mapData: PropTypes.objectOf(PropTypes.shape(stateShape)),
  stories: PropTypes.shape(storyShape),
};

MapMobile.defaultProps = {
  mapData: {},
  stories: {},
};

export default MapMobile;
