import React, { PureComponent } from "react";
import SbEditable from "storyblok-react";
import Components from "../../components";

class DualImageTile extends PureComponent {
  // TODO: Remove the size choice from the image tiles for this? Or leave them?
  render() {
    const { Tiles } = this.props;
    const tiles = Tiles.map((tile) => {
      const { _uid, component, ...props } = tile;
      props.Size = 3;
      props.xsSize = 6;
      return (
        <React.Fragment key={_uid}>
          <SbEditable content={tile}>
            {React.createElement(Components[component], {
              global,
              ...props,
            })}
          </SbEditable>
        </React.Fragment>
      );
    });
    return tiles;
  }
}
export default DualImageTile;
