import getUserLocale from "./getUserLocale";
import { LOCALSTORAGE_COUNTRYCODE } from "../../constants";

const shouldDisplayLocaleSwitcher = async (currentLocale, callback) => {
  let shouldDisplay;
  const storedLocale = await getUserLocale();

  if (currentLocale === storedLocale[LOCALSTORAGE_COUNTRYCODE]) {
    shouldDisplay = false;
  } else {
    shouldDisplay = true;
  }

  callback(!shouldDisplay);
  return shouldDisplay;
};

export default shouldDisplayLocaleSwitcher;
